import * as React from "react"
import { DatePicker, Divider } from "antd"
import { CaretLeftFilled, CaretRightFilled } from "@ant-design/icons"
import "./less/header.less"
import type { IntlShape } from "react-intl"
import { injectIntl } from "react-intl"
import getLocale from "../../shared/getLocale"
import type { Dayjs } from "dayjs"
import dayjs from "dayjs"

export interface Props {
  intl: IntlShape
  changeStartDate: (start: Date) => void
  startDate: Date
  stepSizeDays: number
}

class MobileCalendarDateHeader extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
    this.changeStartDate = this.changeStartDate.bind(this)
    this.back = this.back.bind(this)
    this.forward = this.forward.bind(this)
  }

  render(): JSX.Element {
    return (
      <div className="mobile-calendar-date-header">
        <div className="mobile-date-picker">
          <DatePicker
            value={this.getStartDateAsDayjs()}
            format={"DD.MM.YYYY"}
            locale={getLocale(this.props.intl.locale)}
            onChange={this.changeStartDate}
          ></DatePicker>
        </div>
        <div className="mobile-date-arrows">
          <CaretLeftFilled onClick={this.back}></CaretLeftFilled>
          <Divider type="vertical"></Divider>
          <CaretRightFilled onClick={this.forward}></CaretRightFilled>
        </div>
      </div>
    )
  }

  private getStartDateAsDayjs() {
    const startDate = this.props.startDate
    return dayjs(startDate)
  }

  private changeStartDate(m: Dayjs | null) {
    const startDate = m?.toDate()
    if (startDate !== undefined) {
      this.props.changeStartDate(startDate)
    }
  }

  private back() {
    const old = this.props.startDate
    const startDate = new Date(
      old.getFullYear(),
      old.getMonth(),
      old.getDate() - this.props.stepSizeDays
    )
    this.props.changeStartDate(startDate)
  }

  private forward() {
    const old = this.props.startDate
    const startDate = new Date(
      old.getFullYear(),
      old.getMonth(),
      old.getDate() + this.props.stepSizeDays
    )
    this.props.changeStartDate(startDate)
  }
}

export default injectIntl(MobileCalendarDateHeader)
