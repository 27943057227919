import types from './actionTypes'
import { fetchREST } from '../../shared/rest.js'

import {
  showGlobalMessage,
  convertBackendError
} from '../../modules/globalMessage/controller'

const loadServerSettings = settings => ({
  type: types.LOADSERVERSETTINGS, settings
})

export function loadServerSettingsAsync() {
  return dispatch => (
    fetchREST("/settings/getServerSettings")
      .then(res => res.json())
      .then(json => {
        if (json.error) {
          showGlobalMessage(dispatch, convertBackendError(json))
          return Promise.resolve()
        }
        dispatch(loadServerSettings(json))
        return Promise.resolve()
      })
  )
}
