const types = {
  SELECTCLIENT: "SELECT_CLIENT_",
  LOADALLORGUNITS: "LOAD_ALL_ORG_UNITS_",
  LOADALLORGUNITSFAILED: "LOAD_ALL_ORG_UNITS_FAILED_",
  LOADORGUNITDETAILS: "LOAD_ORG_UNIT_DETAILS_",
  SAVEORGUNIT: "SAVE_ORG_UNIT_",
  SAVEORGUNITFAILED: "SAVE_ORG_UNIT_FAILED_",
  ARCHIVEORGUNIT: "ARCHIVE_ORG_UNIT_",
  RESTOREORGUNIT: "RESTOE_ORG_UNIG_",
  DELETEORGUNIT: "DELETE_ORG_UNIT_",
  CHANGEHIERARCHY: "CHANGE_HIERARCHY_",
  INSERTTRANSIENTORGUNIT: "INSERT_TRANSIENT_ORG_UNIT_",
  TOGGLEINCLUDEARCHIVED: "TOGGLE_INCLUDE_ARCHIVED_",
  APPENDTOSTACK: "APPEND_TO_STACK_",
  RESETSTACK: "RESET_STACK_",
  POPFROMSTACK: "POP_FROM_STACK_",
  SETREADONLY: "SET_ORGUNIT_DETAILS_FORM_READ_ONLY_",
  SWITCHORDER: "SWITCH_ORGUNIT_ORDER_",
}

export default types
