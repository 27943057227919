/* Component to change the frontend language for the GUI.
 * Loads our translation files and the default de and en translations for
 * react-intl.
 * Wrapped around the IntlProvider, which wraps around the whole app and
 * rerenders everything on a language change!
 * So be carefull with shouldComponentUpdate.
 * Also reacts on a user change and queries the backend for the users prefs.
 *
 * Also sets the global antd localization.
 */

import React, { Component } from "react"
import { IntlProvider } from "react-intl"
import { connect } from "react-redux"
import { ConfigProvider } from "antd"

import { getUserIdState } from "../../login/redux/selectors/loginSelectors"
import { getAntdLocale } from "../../../shared/antdLocales"
import localeData from "../../../locales/messages"

import { getCurrentLanguage } from "../redux/selectors/languagePickerSelectors"
import { setLanguageFromUserGUISettingsAsync } from "../redux/actions/languagePickerActions"
import antdTheme from "../../../apps/shared/antdTheme"

const mapStateToProps = state => ({
  currentLanguage: getCurrentLanguage(state),
  currentUserId: getUserIdState(state),
})

const mapDispatchToProps = dispatch => ({
  setUserLanguage: userId =>
    dispatch(setLanguageFromUserGUISettingsAsync(userId)),
})

class LanguageSwitcher extends Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.currentUserId !== "" &&
      this.props.currentUserId !== prevProps.currentUserId
    ) {
      this.props.setUserLanguage(this.props.currentUserId)
    }
  }

  render() {
    const locale = extractClientLanguage(this.props.currentLanguage)
    const messages = extractMessages(locale)
    return (
      <IntlProvider locale={locale.shortName} messages={messages}>
        <ConfigProvider
          theme={antdTheme}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          locale={getAntdLocale(locale.shortName)}
        >
          {this.props.children}
        </ConfigProvider>
      </IntlProvider>
    )
  }
}

export function extractClientLanguage(language) {
  if (language) {
    return { name: language, shortName: language }
  }
  const nameDefault =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage ||
    "en" // fallback for tests and unforeseen circumstances
  const name =
    nameDefault.startsWith("en") || nameDefault.startsWith("de")
      ? nameDefault
      : "en"
  return {
    name,
    shortName: name.toLowerCase().split(/[_-]+/)[0],
  }
}

function extractMessages({ name, shortName }) {
  return localeData[shortName] || localeData[name] || localeData.en
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher)
