// const moment = require('moment')
import { valueAt } from '../../../../shared/walkObjectHierarchy'
import types from '../actions/actionTypes'

export default function settingsReducer(
  state = {},
  action
) {
  switch (action.type) {
  case types.LOADSETTINGSCONF:
    return {
      ...state,
      settingsConf: action.settingsConf
    }
  case types.LOADFORMSETTINGS:
    return loadFormSettings(state, action)
  case types.SAVEHOLIDAYS:
    return mergeHolidays(state, action)
  case types.SAVEFORM:
    return saveForm(state, action)
  case types.DELTEFORMSETTINGS:
    return deleteFormSettings(state, action)
  default:
    return state
  }
}

/**
 * @param action.data Holidays ({date, name}) indexed by state key
 *
 * The state slice for holidays looks as follows:
 *
 * holidays: {
 *   byState: {
 *     'YYYY': {
 *       stateKey: {
 *         'YYYYMMDD': name
 *       }
 *     }
 *   }
 * }
 */
function mergeHolidays(state, action) {
  const makeKey = date => {
    const res = ''
      + date.year
      + ('0' + date.month).slice(-2)
      + ('0' + date.day).slice(-2)
    return res
  }
  const oldHolidays = valueAt(state, ['holidays', 'byYearAndState']) || {}
  const newHolidays = {}
  const getExisting = (stateKey, date) => {
    const dateKey = makeKey(date)
    const old = oldHolidays[stateKey] || {}
    const oldForYear = old[date.year] || {}
    return oldForYear[dateKey]
  }
  const setDefaultYearAndState = (date, stateKey, name) => {
    const year = date.year
    const dateKey = makeKey(date)
    if ( ! newHolidays[year] ) { newHolidays[year] = {} }
    if ( ! newHolidays[year][stateKey] ) { newHolidays[year][stateKey] = {} }
    newHolidays[year][stateKey][dateKey] = name
  }
  let changesMade = 0
  // XXX No state key! all packed together
  action.holidays.forEach(({ stateKey, year, month, day, name }) => {
    const date = {year, month, day}
    const existingEntry = getExisting(stateKey, date)
    if ( existingEntry !== name ) {
      setDefaultYearAndState(date, stateKey, name)
      changesMade += 1
    }
  })
  if ( changesMade > 0 ) {
    return {
      ...state,
      holidays: {
        byYearAndState: {
          ...oldHolidays,
          ...newHolidays
        }
      }
    }
  }
  return state
}

function loadFormSettings(state, action) {
  const { form, json } = action.data
  const oldByForm = valueAt(state, ['byForm']) || {}
  const byForm = { ...oldByForm }
  byForm[form] = {
    byFieldName: json.reduce((acc, field) => {
      acc[field.type] = field
      return acc
    }, {})
  }
  return {
    ...state,
    byForm
  }
}

function saveForm(state, action) {
  const { form, saveFields } = action.data
  const oldByForm = valueAt(state, ['byForm']) || {}
  const oldByFieldName = valueAt(state, ['byForm', form, 'byFieldName']) || {}
  const changes = saveFields.reduce((acc, f) => {
    acc[f.type] = {
      type: f.type,
      value: f.value,
      orgunitId: f.orgunitId,
      userId: f.userId
    }
    return acc
  }, {})
  return {
    ...state,
    byForm: {
      ...oldByForm,
      [form]: {
        byFieldName: {
          ...oldByFieldName,
          ...changes
        }
      }
    },
  }
}

function deleteFormSettings(state, action) {
  const oldByForm = valueAt(state, ['byForm']) || {}
  const byForm = { ...oldByForm }
  delete byForm[action.form]
  return {
    ...state,
    byForm,
  }
}
