import * as React from "react"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import type { ElementNode, LexicalEditor, TextNode } from "lexical"
import {
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  CLEAR_EDITOR_COMMAND,
  $createParagraphNode,
} from "lexical"
import { $setBlocksType } from "@lexical/selection"
import {
  REMOVE_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  $isListNode,
} from "@lexical/list"
import MainToolbar from "./MainToolbar"
import useKeyDown from "../useKeyDown"
import useMouseDown from "../useMouseDown"
import type { HeadingTagType } from "@lexical/rich-text"
import { $createHeadingNode } from "@lexical/rich-text"
import { TOGGLE_LINK_COMMAND, $isLinkNode } from "@lexical/link"

export default function InfoMessageEditorToolbar(): JSX.Element {
  const [editor] = useLexicalComposerContext()
  const isKeyDown = useKeyDown()
  const isMouseDown = useMouseDown()
  const [format, setFormat] = React.useState<FormatState>([])
  const isBulletList = format.includes("list") // TODO: Refine!
  const toggleBulletList = React.useCallback(() => {
    if (isBulletList) {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
    } else {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
    }
  }, [editor, isBulletList])
  const toggleBold = React.useCallback(() => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold")
  }, [editor])
  const toggleItalic = React.useCallback(() => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic")
  }, [editor])
  const clearEditor = React.useCallback(() => {
    editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined)
  }, [editor])
  React.useEffect(() => {
    if (!isKeyDown && !isMouseDown) {
      // not during update!
      updateFormatStateList(editor, format, setFormat)
    }
  }, [editor, format, isKeyDown, isMouseDown])
  React.useEffect(() => editor.focus(), [editor, format])
  return (
    <MainToolbar
      isLink={format.includes("link")}
      toggleLink={() => {
        editor.update(() => {
          const node = getSelectedNode()
          const url = node?.getTextContent()
          if (format.includes("link")) {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
          } else {
            editor.dispatchCommand(
              TOGGLE_LINK_COMMAND,
              url !== undefined ? { url, target: "_blank" } : null
            )
          }
        })
      }}
      makeParagraph={() => {
        // TODO: check block type!
        editor.update(() => {
          const sel = $getSelection()
          if ($isRangeSelection(sel)) {
            $setBlocksType(sel, () => $createParagraphNode())
          }
        })
      }}
      makeHeading={(num?: number) => {
        // TODO: check block type!
        editor.update(() => {
          const sel = $getSelection()
          if ($isRangeSelection(sel)) {
            $setBlocksType(sel, () =>
              $createHeadingNode(`h${num ?? 1}` as HeadingTagType)
            )
          }
        })
      }}
      isBulletList={format.includes("list")}
      isBold={format.includes("bold")}
      isItalic={format.includes("italic")}
      clearEditor={clearEditor}
      toggleBulletList={toggleBulletList}
      toggleBold={toggleBold}
      toggleItalic={toggleItalic}
    ></MainToolbar>
  )
}

function updateFormatStateList(
  editor: LexicalEditor,
  formatState: FormatState,
  formatStateSetter: (v: FormatState) => void
): void {
  editor.getEditorState().read(() => {
    let newFormatState: FormatState = formatState
    const isBold = hasFormat("bold")
    const isItalic = hasFormat("italic")
    const isBulletList = isListNode()
    const isLink = isLinkNode()
    newFormatState = updateFormatStateArray("link", isLink, newFormatState)
    newFormatState = updateFormatStateArray("bold", isBold, newFormatState)
    newFormatState = updateFormatStateArray("italic", isItalic, newFormatState)
    newFormatState = updateFormatStateArray(
      "list",
      isBulletList,
      newFormatState
    )
    if (newFormatState !== formatState) {
      formatStateSetter(newFormatState)
    }
  })
}

function updateFormatStateArray(
  formatName: FormatStateOptions,
  isCurrentlyUsed: boolean,
  formatState: FormatStateOptions[]
): FormatStateOptions[] {
  if (isCurrentlyUsed && !formatState.includes(formatName)) {
    return [...formatState, formatName]
  } else if (!isCurrentlyUsed && formatState.includes(formatName)) {
    return formatState.filter(n => n !== formatName)
  } else {
    return formatState // unchanged reference!
  }
}

function isLinkNode(): boolean {
  const node = getSelectedNode()
  const parent = node?.getParent()
  const res = $isLinkNode(node) || $isLinkNode(parent)
  return res
}

function isListNode(): boolean {
  const node = getSelectedNode()
  return $isListNode(node?.getTopLevelElement())
}

function getSelectedNode(): TextNode | ElementNode | null {
  const sel = $getSelection()
  const node = $isRangeSelection(sel)
    ? sel.anchor.getNode() ?? sel.focus.getNode()
    : null
  return node
}

function hasFormat(name: "bold" | "italic"): boolean {
  const sel = $getSelection()
  if ($isRangeSelection(sel)) {
    return sel.hasFormat(name)
  } else {
    return false
  }
}

type FormatState = FormatStateOptions[]
type FormatOptions = "bold" | "italic" | "list" | "link"
type FormatStateOptions = FormatOptions
