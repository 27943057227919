/**
 * Container for all the fields in a process form
 *
 * Note that *some* different behaviours are necessary
 * to reflect differences between the desktop and the
 * mobile version!
 */

import { Input, Select, DatePicker } from "antd"
import dayjs from "dayjs"
import * as React from "react"
import type { IntlShape } from "react-intl"
import { injectIntl } from "react-intl"
import type { IFormField } from "../../commonInterfaces/processes/RawFormInfo"
import ColorBullet from "../ColorBullet/ColorBullet"
import getLocale from "../getLocale"

const TextArea = Input.TextArea
const RangePicker = DatePicker.RangePicker

interface Props {
  intl: IntlShape
  cssPrefix: string
  formfields: IFormField[]
  setValue: (formField: IFormField, value: any) => void
  windowHeight?: number
}

class ProcessFormFields extends React.PureComponent<Props> {
  render(): JSX.Element {
    return <>{this.renderFormFields()}</>
  }

  private renderFormFields() {
    const res = this.props.formfields?.map((field: IFormField, idx: number) =>
      this.renderFormField(idx, field)
    )
    return res
  }

  private renderFormField(key: number, formFieldDefinition: IFormField) {
    if (formFieldDefinition.value !== "submit") {
      return (
        <div className={`${this.props.cssPrefix}process-form-field`} key={key}>
          <div className={`${this.props.cssPrefix}process-form-field-label`}>
            <label className={`${this.props.cssPrefix}process-form-label`}>
              {formFieldDefinition.label}
            </label>
          </div>
          <div className={`${this.props.cssPrefix}process-form-field-field`}>
            {formFieldDefinition.datatype === "daterange"
              ? this.renderDateRangeField(formFieldDefinition)
              : this.renderSimpleField(formFieldDefinition)}
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  private renderSimpleField(formFieldDefinition: IFormField) {
    const setSelectValue = (v: string | number | true) =>
      this.props.setValue(formFieldDefinition, v)
    const getDefaultSelectValue = () => {
      const v = (formFieldDefinition.options ?? [])[0]?.value
      console.log("WARNING: Fix if these messages keep repeating!")
      if (v) {
        setSelectValue(v)
      }
      return v
    }
    const defaultValue = getDefaultSelectValue()
    switch (formFieldDefinition.formelement) {
      case "textarea":
        return (
          <TextArea
            name={formFieldDefinition.name}
            onChange={e =>
              this.props.setValue(formFieldDefinition, e.target.value)
            }
          >
            {formFieldDefinition.value ?? ""}
          </TextArea>
        )
      case "select":
        return (
          <Select
            style={{ width: "100%" }}
            defaultValue={defaultValue ? `${defaultValue}` : undefined}
            onChange={e => setSelectValue(e.toString())}
          >
            {(formFieldDefinition.options ?? []).map((o, idx) => {
              let c = null
              if (o.color !== undefined) {
                c = <ColorBullet color={o.color as any}></ColorBullet>
              }
              return (
                <Select.Option key={idx} value={`${o.value}`}>
                  {c}
                  {o.label}
                </Select.Option>
              )
            })}
          </Select>
        )
      case "checkbox":
      case "radio":
      case "button":
      case "date":
        console.log(
          `FormInfo TODO: ${formFieldDefinition.formelement}`,
          formFieldDefinition
        )
        return <></>
      default:
        return (
          <Input
            type="text"
            name={formFieldDefinition.name}
            defaultValue={formFieldDefinition.value ?? ""}
            required={formFieldDefinition.required}
            onChange={e =>
              this.props.setValue(formFieldDefinition, e.target.value)
            }
          ></Input>
        )
    }
  }

  private renderDateRangeField(formFieldDefinition: IFormField) {
    const popupHeight = 280
    let popupStyle: React.CSSProperties = {}
    if (this.props.windowHeight !== undefined) {
      popupStyle = {
        zIndex: 10000,
        top: 60,
        left: 0,
        minHeight: "100%",
      }
      if (this.props.windowHeight < popupHeight + 200) {
        popupStyle = {
          ...popupStyle,
          position: "fixed",
          top: "45px !important",
        }
      }
    }
    const loc = getLocale(this.props.intl.locale)
    return (
      <div className={`${this.props.cssPrefix}process-form-daterange-field`}>
        <div
          className={`${this.props.cssPrefix}range-picker-popup-container`}
          style={{
            zIndex: 10000,
            position: "fixed",
            top: "120px",
            left: 0,
            minHeight: "100%",
          }}
        ></div>
        <RangePicker
          inputReadOnly={true}
          locale={loc}
          format={loc.dateFormat}
          disabledDate={current => current && current < dayjs().endOf("day")}
          getPopupContainer={trigger =>
            (trigger.parentElement?.querySelector(
              ".mobile-range-picker-popup-container"
            ) ?? <div></div>) as HTMLElement
          }
          dropdownClassName={`${this.props.cssPrefix}process-daterange-field`}
          popupStyle={popupStyle}
          defaultValue={[
            this.dayjsFor(
              (formFieldDefinition.value?.startDate as string) ?? ""
            ) as any, // FIXME
            this.dayjsFor(
              (formFieldDefinition.value?.endDate as string) ?? ""
            ) as any, // FIXME
          ]}
          onChange={p => {
            if (p) {
              const [s, e] = p
              const value = {
                startDate: s?.format("YYYY-MM-DD"),
                endDate: e?.format("YYYY-MM-DD"),
              }
              this.props.setValue(formFieldDefinition, value)
            }
          }}
        ></RangePicker>
      </div>
    )
  }

  private dayjsFor(dateString: string) {
    if (dateString) {
      return dayjs(dateString)
    } else {
      return undefined
    }
  }
}

const ProcessFormFieldsComponent = injectIntl(ProcessFormFields)
export default ProcessFormFieldsComponent
