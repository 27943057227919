import { fetchREST } from "../../../rest.js"
import { encodeURIComponentsForActions } from "../../../uriUtils"
import types from "./actionTypes"

export function loadUserOrgUnitsWithPermissionAsync({ clientId, permission }) {
  return dispatch => {
    return fetchREST(
      "/authorisation/getOrgUnitsWithPermission?" +
        encodeURIComponentsForActions({ clientId, permission })
    )
      .then(res => res.json())
      .then(json => {
        if (json.error) {
          // just default to empty permission
          dispatch(loadUserOrgUnitsWithPermission(permission, []))
          return Promise.resolve()
        }
        dispatch(loadUserOrgUnitsWithPermission(permission, json))
        return Promise.resolve()
      })
      .catch(err => {
        console.log(err)
      })
  }
}

function loadUserOrgUnitsWithPermission(permission, orgunits) {
  return { type: types.LOADUSEROUSWITHPERMISSION, permission, orgunits }
}

export function loadUserContextPermissionsAsync({ clientId, orgunitId }) {
  return dispatch => {
    return fetchREST(
      "/authorisation/getPermissionsFor?" +
        encodeURIComponentsForActions({ clientId, orgunitId })
    )
      .then(res => res.json())
      .then(json => {
        if (json.error) {
          // This action is subscribed, so no constant error message please.
          dispatch(loadPermissions({}))
          return Promise.resolve()
        }
        dispatch(loadPermissions(json))
        return Promise.resolve()
      })
      .catch(err => {
        console.log(err)
      })
  }
}

function loadPermissions(permissions) {
  return { type: types.LOADUSERCONTEXTPERMISSIONS, permissions }
}
