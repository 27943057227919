const messages = {
  "bonusReport.title": "Arbeitszeitberichte mit Zuschlägen",
  "bonusReport.generateReport": "Monatsbericht erstellen",
  "bonusReport.generateXLSXReport": "Monatsbericht erstellen (XLSX)",
  "bonusReport.type": "Typ",
  "bonusReport.from": "Von",
  "bonusReport.to": "Bis",
  "bonusReport.Date": "Datum",
  "bonusReport.Week": "Woche",
  "bonusReport.Month": "Monat",
  "bonusReport.Year": "Jahr",
}

export default messages
