const messages = {
  'settings.inheritedfrom': 'Konfiguration geerbt von:',
  'settings.heading': 'Konfiguration',
  'settings.plannersettings': 'Planerdarstellung',
  'settings.portalsettings': 'Mitarbeiterportal',
  'settings.securitysettings': 'Sicherheit',
  'settings.signalsettings': 'Ampelkonto',
  'settings.timetrackingsettings': 'Zeiterfassung',
  'settings.error.validation': 'Es ist ein Fehler bei der Validierung aufgetreten',
  'settings.demandPlanning': 'Bedarfsplanung geschieht nach',
  'settings.overstaffingColor': 'Farbe bei Überbesetzung',
  'settings.understaffingColor': 'Farbe bei Unterbesetzung',
  'settings.onHolidays': 'Plan/Ist-Werte an Feiertagen',
  'settings.showPause': 'Zeige Pause',
  'settings.pauseColor': 'Farbe der Pause',
  'settings.simulationColor': 'Farbe der Simulation',
  'settings.interval': 'Intervall',
  'settings.beginPlanner': 'Beginn in Stunden und Minuten',
  'settings.endPlanner': 'Ende in Stunden und Minuten',
  'settings.showSundays': 'Sonntage anzeigen',
  'settings.barDisplay': 'Balkendarstellung',
  'settings.options.customers': 'Kunden',
  'settings.options.revenue': 'Umsatz',
  'settings.options.packaging': 'Verpackung',
  'settings.options.thirty': '30 min',
  'settings.options.hour': '1 h',
  'settings.options.activity': 'Tätigkeit',
  'settings.options.site': 'Einsatzort',
  'settings.options.activity-site': 'Tätigkeit / Einsatzort',
  'settings.options.site-activity': 'Einsatzort / Tätigkeit',
  'settings.showTimeSheetInPortal': 'Zeitkonto im Mitarbeiterportal anzeigen',
  'settings.futurePlanningDays': 'Anzahl Tage in Zukunft planen',
  'settings.sharedPlans': 'Freigegebene Pläne',
  'settings.showAbsence': 'Fehlzeiten sichtbar',
  'settings.timecredit': 'Gutschrift von Zeiten',
  'settings.accessAllBranches': 'Zugriff auf alle Filialen gestatten',
  'settings.changeablePastDays': 'Anzahl Tage in der Vergangenheit ändern',
  'settings.manualSharing': 'Manuelle Freigabe',
  'settings.automaticNotification': 'Automatisch benachrichtigen',
  'settings.options.target-actual': 'Plan / Ist',
  'settings.options.unrecorded': 'Unerfasste Zeiten',
  'settings.options.actual-only': 'Nur Ist-Zeiten',
  'settings.twofa': '2FA',
  'settings.plusHoursYellowFrom': 'Plusstunden gelb darstellen ab (Stunden und Minuten)',
  'settings.plusHoursRedFrom': 'Plusstunden rot darstellen ab (Stunden und Minuten)',
  'settings.minusHoursYellowFrom': 'Minusstunden gelb darstellen ab (Stunden und Minuten)',
  'settings.minusHoursRedFrom': 'Minusstunden rot darstellen ab (Stunden und Minuten)',
  'settings.mustTrackTime': 'Mitarbeiter muss Zeit erfassen',
  'settings.inheritDepartment': 'Abteilungen übernehmen',
  'settings.inheritTask': 'Tätigkeiten übernehmen',
  'settings.inheritPauses': 'Pausen übernehmen',
  'settings.inheritTime': ' ',
  'settings.arrivalEarly': 'Kommen Morgens zu früh (in Minuten)',
  'settings.arrivalLate': 'Kommen Morgens zu spät (in Minuten)',
  'settings.departureEarly': 'Gehen Abends zu früh (in Minuten)',
  'settings.departureLate': 'Gehen Abends zu spät (in Minuten)',
  'settings.options.none-auto': 'Zeit nicht automatisch übernehmen',
  'settings.options.correct-auto': 'korrekte Zeit automatisch übernehmen',
  'settings.options.all-auto': 'korrekte & außerplanmäßige Zeit automatisch übernehmen',
  'upload-holiday-xml': 'Feiertags-XML hochladen',
  'settings.uploaded-holiday-years': 'Feiertage vorhanden für die Jahre:',
  'settings.holidays-xml-format-error': 'Dateiformat ist ungültig.',
}

export default messages
