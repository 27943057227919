const messages = {
  'settings.inheritedfrom': 'Settings inherited from:',
  'settings.heading': 'Settings',
  'settings.plannersettings': 'Planner display',
  'settings.portalsettings': 'Employee portal',
  'settings.securitysettings': 'Security',
  'settings.signalsettings': 'Signal',
  'settings.timetrackingsettings': 'Time tracking',
  'settings.error.validation': 'Error during validation',
  'settings.demandPlanning': 'Demand planning after',
  'settings.overstaffingColor': 'Color for overstaffing',
  'settings.understaffingColor': 'Color for understaffing',
  'settings.onHolidays': 'Target/actual value on holidays',
  'settings.showPause': 'Show pause',
  'settings.pauseColor': 'Color of pause',
  'settings.simulationColor': 'Color of simulation',
  'settings.interval': 'Interval',
  'settings.beginPlanner': 'Begin in hours and minutes',
  'settings.endPlanner': 'End in hours and minutes',
  'settings.showSundays': 'Show sundays',
  'settings.barDisplay': 'Bardisplay',
  'settings.options.customers': 'Customers',
  'settings.options.revenue': 'Revenue',
  'settings.options.packaging': 'Packaging',
  'settings.options.thirty': '30 min',
  'settings.options.hour': '1 h',
  'settings.options.activity': 'Activity',
  'settings.options.site': 'Job site',
  'settings.options.activity-site': 'Activity / job site',
  'settings.options.site-activity': 'Job site / activity',
  'settings.showTimeSheetInPortal': 'Show time sheet in portal',
  'settings.futurePlanningDays': 'Days in future planning',
  'settings.sharedPlans': 'Shared plans',
  'settings.showAbsence': 'Show absence',
  'settings.timecredit': 'Timecredit',
  'settings.accessAllBranches': 'Access all branches',
  'settings.changeablePastDays': 'Changeable days in the past',
  'settings.manualSharing': 'Manual sharing',
  'settings.automaticNotification': 'Automatic notification',
  'settings.options.target-actual': 'Target / actual',
  'settings.options.unrecorded': 'Only unrecorded times',
  'settings.options.actual-only': 'Only actual times',
  'settings.twofa': '2FA',
  'settings.plusHoursYellowFrom': 'Show overtime in yellow as of (hours and minutes)',
  'settings.plusHoursRedFrom': 'Show overtime in red as of (hours and minutes)',
  'settings.minusHoursYellowFrom': 'Show undertime in yellow as of (hours and minutes)',
  'settings.minusHoursRedFrom': 'Show undertime in red as of (hours and minutes)',
  'settings.mustTrackTime': 'Employee must track time',
  'settings.inheritDepartment': 'Inherit departments',
  'settings.inheritTask': 'Inherit tasks',
  'settings.inheritPauses': 'Inherit pauses',
  'settings.inheritTime': ' ',
  'settings.arrivalEarly': 'Arrival mornings early (in minutes)',
  'settings.arrivalLate': 'Arrival mornings late (in minutes)',
  'settings.departureEarly': 'Departure evenings early (in minutes)',
  'settings.departureLate': 'Departure evenings late (in minutes)',
  'settings.options.none-auto': 'Don`t inherit time automatically',
  'settings.options.correct-auto': 'Inherit correct time automatically',
  'settings.options.all-auto': 'Inherit correct & irregular time automatically',
  'upload-holiday-xml': 'Upload holidays in XML format',
  'settings.uploaded-holiday-years': 'Uploaded holiday years:',
  'settings.holidays-xml-format-error': 'Invalid file format.',
}

export default messages
