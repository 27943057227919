import { valueAt, isEqual } from "../../../../shared/walkObjectHierarchy"
import types from "../actions/actionTypes"

export default function (state = {}, action) {
  switch (action.type) {
    case types.OPENEDITQUALIFICATIONDIALOG:
      return {
        ...state,
        qualificationId: action.qualificationId,
        openDialog: true,
      }
    case types.CLOSEEDITQUALIFICATIONDIALOG:
      return {
        ...state,
        openDialog: false,
        qualificationId: undefined,
      }
    case types.CHANGEORDER:
      return {
        ...state,
        order: action.order,
        orderChanged: new Date(),
      }
    case types.INCLUDEARCHIVEDQUALIFICATIONS:
      return toggleIncludeArchived(state, action)
    case types.LOADQUALIFICATIONS:
      return loadQualifications(state, action)
    case types.SAVEQUALIFICATION:
      return saveQualification(state, action)
    case types.ARCHIVEQUALIFICATION:
      return archiveQualification(state, action)
    case types.RESTOREQUALIFICATION:
      return restoreQualification(state, action)
    case types.DELETEQUALIFICATION:
      return deleteQualification(state, action)
    default:
      return state
  }
}

function toggleIncludeArchived(state) {
  const includeArchived = valueAt(state, ["includeArchived"]) || false
  return {
    ...state,
    includeArchived: !includeArchived,
  }
}

function loadQualifications(state, action) {
  const { qualifications, order } = action.qualifications
  const byId = qualifications.reduce((acc, q) => {
    acc[q.id] = q
    return acc
  }, {})
  const oldById = valueAt(state, ["byId"]) || {}
  const oldOrder = valueAt(state, ["order"]) || []
  if (isEqual(byId, oldById) && isEqual(order, oldOrder)) {
    return state
  }
  return {
    ...state,
    order,
    byId,
  }
}

function saveQualification(state, action) {
  const byIdState = valueAt(state, ["byId"]) || {}
  const order = valueAt(state, ["order"]) || []
  const byId = { ...byIdState }
  const qualification = action.qualification
  const qId = qualification.id
  byId[qId] = qualification
  const newOrder =
    order.findIndex(id => id === qId) !== -1 ? order : [qId, ...order]
  return {
    ...state,
    order: newOrder,
    openDialog: false,
    qualificationId: undefined,
    byId: { ...byId },
  }
}

function archiveQualification(state, action) {
  const byId = valueAt(state, ["byId"]) || {}
  const id = action.qualificationId
  const qualification = byId[id]
  const newById = {
    ...byId,
    [id]: {
      ...qualification,
      isArchived: true,
    },
  }
  const order = [...(state.order || [])]
  return {
    ...state,
    byId: newById,
    order: order.filter(oid => oid !== id),
  }
}

function restoreQualification(state, action) {
  const byId = valueAt(state, ["byId"]) || {}
  const id = action.qualificationId
  const qualification = byId[id]
  const newById = {
    ...byId,
    [id]: {
      ...qualification,
      isArchived: false,
    },
  }
  return {
    ...state,
    byId: newById,
  }
}

function deleteQualification(state, action) {
  const byId = valueAt(state, ["byId"]) || {}
  const id = action.qualificationId
  const newById = Object.keys(byId).reduce((acc, key) => {
    if (key + "" !== id + "") {
      acc[key] = byId[key]
    }
    return acc
  }, {})
  const order = [...(state.order || [])]
  return {
    ...state,
    byId: newById,
    order: order.filter(o => o !== id),
  }
}
