import { MessageOutlined } from "@ant-design/icons"
import { Button } from "antd"
import * as React from "react"
import { useIntl } from "react-intl"

export interface Props {
  showWindow: boolean
  setShowWindow: (flag: boolean) => void
}

export default function SupportButton({
  showWindow,
  setShowWindow,
}: Props): JSX.Element {
  const intl = useIntl()
  const [expanded, setExpanded] = React.useState<boolean>(false)
  const [fullyExpanded, setFullyExpanded] = React.useState<boolean>(false)
  const toggle = React.useCallback(() => {
    setShowWindow(!showWindow)
  }, [showWindow, setShowWindow])
  const expand = React.useCallback(() => setExpanded(true), [setExpanded])
  const contract = React.useCallback(
    () => setFullyExpanded(false),
    [setFullyExpanded]
  )
  React.useEffect(() => {
    if (!fullyExpanded) {
      setTimeout(() => setExpanded(fullyExpanded), 250)
    }
  }, [fullyExpanded, setExpanded])
  React.useEffect(() => {
    if (expanded) {
      setTimeout(() => setFullyExpanded(expanded), 250)
    }
  }, [expanded, setFullyExpanded])
  return (
    <Button
      shape={expanded ? "round" : "circle"}
      icon={<MessageOutlined></MessageOutlined>}
      onClick={toggle}
      type="primary"
      size="large"
      onMouseEnter={expand}
      onMouseLeave={contract}
    >
      {fullyExpanded ? intl.formatMessage({ id: "support-btn" }) : ""}
    </Button>
  )
}
