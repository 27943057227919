import * as React from "react"
import {
  HomeOutlined,
  ClockCircleOutlined,
  CalendarOutlined,
  RocketOutlined,
} from "@ant-design/icons"

export const DASHBOARD = "dashboard"
export const PUNCH = "punch"
export const CALENDAR = "calendar"
export const REQUESTS = "employee-request-for-leave"
export const ROUTES = [DASHBOARD, PUNCH, CALENDAR, REQUESTS]

export const ICONS: Record<string, any> = {
  [DASHBOARD]: <HomeOutlined />,
  [PUNCH]: <ClockCircleOutlined />,
  [CALENDAR]: <CalendarOutlined />,
  [REQUESTS]: <RocketOutlined />,
}
