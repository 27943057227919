import { valueAt, isEqual } from '../../../../shared/walkObjectHierarchy'
import types from '../actions/actionTypes'

export default function userListingReducer(
  state = {},
  action
) {
  switch (action.type) {
  case types.LOADPLANNERMODELUSERSFORORGUNITID:
    return loadUsers(state, action)
  default:
    return state
  }
}

function loadUsers(state, action) {
  const byId = action.users.reduce(
    (acc, u) => {
      // users and candidate_users can have identical ids (sql)
      // add prefix before the number
      if (u.isCandidateUser) {
        /*
        acc["__candidate__" + u.id] = {
          ...activeUser,
          stateId: "__candidate__" + u.id
        }
        */
      } else {
        acc[u.id] = u
      }
      return acc
    },
    {}
  )
  const oldById = valueAt(state, ['users', 'byId']) || {}
  if (isEqual(oldById, byId)) {
    return state
  }
  return {
    ...state,
    users: { byId }
  }
}

/*
function toggleDescendants(state, ___action) {
  const showDescendants = valueAt(state, ['showDescendants']) || false
  return {
    ...state,
    showDescendants: !showDescendants
  }
}

function updateExpiresAt(state, action) {
  const allActiveUsers = valueAt(state, ['allActiveUsers']) || {}
  const byIdState = valueAt(state, ['allActiveUsers', 'byId']) || {}
  const byId = { ...byIdState }
  byId[action.fields.stateId] = {
    ...byId[action.fields.stateId],
    expiresAt: action.fields.expiresAt
  }
  return {
    ...state,
    allActiveUsers: {
      ...allActiveUsers,
      byId
    }
  }
}

function removeCandidateUser(state, action) {
  const allActiveUsers = valueAt(state, ['allActiveUsers']) || {}
  const byIdState = valueAt(state, ['allActiveUsers', 'byId']) || {}
  const byId = { ...byIdState }
  delete byId[action.candidateId]
  return {
    ...state,
    allActiveUsers: {
      ...allActiveUsers,
      byId
    }
  }
}

function updateUser(state, action) {
  const allActiveUsers = valueAt(state, ['allActiveUsers']) || {}
  const byIdState = valueAt(state, ['allActiveUsers', 'byId']) || {}
  const byId = { ...byIdState }
  byId[action.fields.stateId] = action.fields
  return {
    ...state,
    allActiveUsers: {
      ...allActiveUsers,
      byId
    }
  }
}

function deleteUser(state, action) {
  const byIdState = valueAt(state, ['allActiveUsers', 'byId']) || {}
  const byId = { ...byIdState }
  let newById = {
    ...byId
  }
  delete newById[action.stateId]
  return {
    ...state,
    allActiveUsers: {
      byId: {
        ...newById
      }
    }
  }
}

function deactivateUser(state, action) {
  const allActiveUsers = valueAt(state, ['allActiveUsers']) || {}
  const byIdState = valueAt(state, ['allActiveUsers', 'byId']) || {}
  const byId = { ...byIdState }
  byId[action.stateId] = {
    ...byId[action.stateId],
    isDeactivated: true,
    canLogin: false,
    status: 'inactive'
  }
  return {
    ...state,
    allActiveUsers: {
      ...allActiveUsers,
      byId
    }
  }
}

function reactivateUser(state, action) {
  const allActiveUsers = valueAt(state, ['allActiveUsers']) || {}
  const byIdState = valueAt(state, ['allActiveUsers', 'byId']) || {}
  const byId = { ...byIdState }
  byId[action.stateId] = {
    ...byId[action.stateId],
    isDeactivated: true,
    canLogin: true,
    status: 'reactivated'
  }
  return {
    ...state,
    allActiveUsers: {
      ...allActiveUsers,
      byId
    }
  }
}
*/
