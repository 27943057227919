import * as React from "react"
import messages from "./locales/messages"
import { IntlProvider } from "react-intl"

import "./supportButton.less"
import makeBackendService from "./BackendService"
import SupportWindow from "./SupportWindow"
import SupportButton from "./SupportButton"

export interface Props {
  locale: string
  isAdminView: boolean
  clientId?: string
}

export default function SupportButtonApp({
  locale,
  isAdminView,
  clientId,
}: Props): JSX.Element | null {
  const [enabled, setEnabled] = React.useState<boolean>(false)
  const [openaiEnabled, setOpenAIEnabled] = React.useState<boolean>(false)
  const [showWindow, setShowWindow] = React.useState<boolean>(false)
  React.useEffect(() => {
    if (clientId) {
      const backend = makeBackendService()
      void backend.enabled(clientId).then(f => {
        setEnabled(f)
      })
    }
  }, [clientId])
  React.useEffect(() => {
    if (clientId) {
      const backend = makeBackendService()
      void backend.openaiEnabled().then(f => {
        setOpenAIEnabled(f)
      })
    }
  }, [clientId])
  if (!enabled) {
    return null
  }
  return !clientId ? null : (
    <IntlProvider
      locale={locale}
      defaultLocale="de"
      messages={messages[locale.substring(0, 2)]}
    >
      <div className="support-button-app">
        {showWindow ? (
          <SupportWindow
            clientId={clientId}
            openaiEnabled={openaiEnabled && isAdminView}
            showWindow={showWindow}
            setShowWindow={setShowWindow}
          ></SupportWindow>
        ) : (
          <SupportButton
            showWindow={showWindow}
            setShowWindow={setShowWindow}
          ></SupportButton>
        )}
      </div>
    </IntlProvider>
  )
}
