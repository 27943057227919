export interface Color {
  background: string
  foreground: string
}

export interface ColorSet {
  name: string
  colors: Color[]
}

const colors: ColorSet[] = [
  {
    name: 'base',
    colors: [
      {
        background: '#4ca4c7',
        foreground: '#000',
      }
    ]
  },
  {
    name: 'black',
    colors: [
      {
        background: '#374044',
        foreground: '#fff'
      }
    ],
  },
  {
    name: 'gray',
    colors: [
      {
        background: '#f8f9f9',
        foreground: '#000'
      },
      {

        background: '#eaedef',
        foreground: '#000'
      },
      {
        background: '#dce1e3',
        foreground: '#000'
      },
      {
        background: '#ccd4d7',
        foreground: '#000'
      },
      {
        background: '#bac5c9',
        foreground: '#000'
      },
      {
        background: '#a7b5ba',
        foreground: '#000'
      },
      {
        background: '#91a2a9',
        foreground: '#000'
      },
      {
        background: '#778c94',
        foreground: '#000'
      },
      {
        background: '#5e6e74',
        foreground: '#fff'
      },
      {
        background: '#374044',
        foreground: '#fff'
      },
    ],
  },
  {
    name: 'blue',
    colors: [
      {
        background: '#e9f3f8',
        foreground: '#fff'
      },
      {
        background: '#d1e7f0',
        foreground: '#000'
      },
      {
        background: '#b6dae8',
        foreground: '#000'
      },
      {
        background: '#98cbdf',
        foreground: '#000'
      },
      {
        background: '#76b9d4',
        foreground: '#000'
      },
      {
        background: '#4ca4c7',
        foreground: '#000'
      },
      {
        background: '#4494b3',
        foreground: '#000'
      },
      {
        background: '#3c819d',
        foreground: '#000'
      },
      {
        background: '#316b82',
        foreground: '#fff'
      },
      {
        background: '#234c5d',
        foreground: '#fff'
      },
    ],
  },
  {
    name: 'blue',
    colors: [
      {
        background: '#ebeef8',
        foreground: '#000'
      },
      {
        background: '#d5dbf2',
        foreground: '#000'
      },
      {
        background: '#bdc7ea',
        foreground: '#000'
      },
      {
        background: '#a0aee1',
        foreground: '#000'
      },
      {
        background: '#7d90d6',
        foreground: '#000'
      },
      {
        background: '#4c67c7',
        foreground: '#fff'
      },
      {
        background: '#445cb3',
        foreground: '#fff'
      },
      {
        background: '#3b509c',
        foreground: '#fff'
      },
      {
        background: '#304280',
        foreground: '#fff'
      },
      {
        background: '#222e59',
        foreground: '#fff'
      },
    ],
  },
  {
    name: 'violet',
    colors: [
      {
        background: '#efecf9',
        foreground: '#000'
      },
      {
        background: '#dfd7f2',
        foreground: '#000'
      },
      {
        background: '#cbbfeb',
        foreground: '#000'
      },
      {
        background: '#b5a3e2',
        foreground: '#000'
      },
      {
        background: '#9980d7',
        foreground: '#000'
      },
      {
        background: '#6f4cc7',
        foreground: '#fff'
      },
      {
        background: '#6344b3',
        foreground: '#fff'
      },
      {
        background: '#563b9b',
        foreground: '#fff'
      },
      {
        background: '#47307f',
        foreground: '#fff'
      },
      {
        background: '#312159',
        foreground: '#fff'
      },
    ],
  },
  {
    name: 'violet',
    colors: [
      {
        background: '#f6ebf9',
        foreground: '#000'
      },
      {
        background: '#ecd6f2',
        foreground: '#000'
      },
      {
        background: '#e1beea',
        foreground: '#000'
      },
      {
        background: '#d3a2e1',
        foreground: '#000'
      },
      {
        background: '#c37ed6',
        foreground: '#000'
      },
      {
        background: '#ac4cc7',
        foreground: '#000'
      },
      {
        background: '#9b44b3',
        foreground: '#fff'
      },
      {
        background: '#873b9c',
        foreground: '#fff'
      },
      {
        background: '#6f3181',
        foreground: '#fff'
      },
      {
        background: '#4e225b',
        foreground: '#fff'
      },
    ],
  },
  {
    name: 'pink',
    colors: [
      {
        background: '#f9ebf5',
        foreground: '#000'
      },
      {
        background: '#f2d6ea',
        foreground: '#000'
      },
      {
        background: '#eabede',
        foreground: '#000'
      },
      {
        background: '#e1a2cf',
        foreground: '#000'
      },
      {
        background: '#d67ebd',
        foreground: '#000'
      },
      {
        background: '#c74ca4',
        foreground: '#000'
      },
      {
        background: '#b34493',
        foreground: '#fff'
      },
      {
        background: '#9d3b81',
        foreground: '#fff'
      },
      {
        background: '#81316a',
        foreground: '#fff'
      },
      {
        background: '#5b234b',
        foreground: '#fff'
      },
    ],
  },
  {
    name: 'pink',
    colors: [
      {
        background: '#f9ecee',
        foreground: '#000'
      },
      {
        background: '#f2d6dd',
        foreground: '#000'
      },
      {
        background: '#eabfc8',
        foreground: '#000'
      },
      {
        background: '#e2a2b0',
        foreground: '#000'
      },
      {
        background: '#d77f92',
        foreground: '#000'
      },
      {
        background: '#c74c67',
        foreground: '#000'
      },
      {
        background: '#b3445c',
        foreground: '#fff'
      },
      {
        background: '#9c3b51',
        foreground: '#fff'
      },
      {
        background: '#813142',
        foreground: '#fff'
      },
      {
        background: '#5b222f',
        foreground: '#fff'
      },
    ],
  },
  {
    name: 'orange',
    colors: [
      {
        background: '#f8eeea',
        foreground: '#000'
      },
      {
        background: '#f1dbd3',
        foreground: '#000'
      },
      {
        background: '#e9c7ba',
        foreground: '#000'
      },
      {
        background: '#e0af9c',
        foreground: '#000'
      },
      {
        background: '#d59379',
        foreground: '#000'
      },
      {
        background: '#c76f4c',
        foreground: '#000'
      },
      {
        background: '#b36444',
        foreground: '#000'
      },
      {
        background: '#9d573b',
        foreground: '#fff'
      },
      {
        background: '#814831',
        foreground: '#fff'
      },
      {
        background: '#5c3323',
        foreground: '#fff'
      },
    ],
  },
  {
    name: 'orange',
    colors: [
      {
        background: '#f7f3e7',
        foreground: '#000'
      },
      {
        background: '#efe8cd',
        foreground: '#000'
      },
      {
        background: '#e6dbb2',
        foreground: '#000'
      },
      {
        background: '#ddcd94',
        foreground: '#000'
      },
      {
        background: '#d3bd72',
        foreground: '#000'
      },
      {
        background: '#c7ac4c',
        foreground: '#000'
      },
      {
        background: '#b39b44',
        foreground: '#000'
      },
      {
        background: '#9d883c',
        foreground: '#fff'
      },
      {
        background: '#827131',
        foreground: '#fff'
      },
      {
        background: '#5e5123',
        foreground: '#fff'
      },
    ],
  },
  {
    name: 'lime',
    colors: [
      {
        background: '#f2f7e6',
        foreground: '#000'
      },
      {
        background: '#e5efcc',
        foreground: '#000'
      },
      {
        background: '#d7e6b1',
        foreground: '#000'
      },
      {
        background: '#c7dd92',
        foreground: '#000'
      },
      {
        background: '#b7d271',
        foreground: '#000'
      },
      {
        background: '#a4c74c',
        foreground: '#000'
      },
      {
        background: '#94b444',
        foreground: '#000'
      },
      {
        background: '#829e3c',
        foreground: '#000'
      },
      {
        background: '#6c8332',
        foreground: '#000'
      },
      {
        background: '#4d5e24',
        foreground: '#fff'
      },
    ],
  },
  {
    name: 'lime',
    colors: [
      {
        background: '#ebf7e8',
        foreground: '#000'
      },
      {
        background: '#d6f0cf',
        foreground: '#000'
      },
      {
        background: '#bfe7b4',
        foreground: '#000'
      },
      {
        background: '#a6de96',
        foreground: '#000'
      },
      {
        background: '#89d374',
        foreground: '#000'
      },
      {
        background: '#67c74c',
        foreground: '#000'
      },
      {
        background: '#5db344',
        foreground: '#000'
      },
      {
        background: '#519d3c',
        foreground: '#000'
      },
      {
        background: '#438332',
        foreground: '#fff'
      },
      {
        background: '#305e24',
        foreground: '#fff'
      },
    ],
  },
  {
    name: 'teal',
    colors: [
      {
        background: '#e8f8ed',
        foreground: '#000'
      },
      {
        background: '#d0f0d9',
        foreground: '#000'
      },
      {
        background: '#b6e8c4',
        foreground: '#000'
      },
      {
        background: '#98deac',
        foreground: '#000'
      },
      {
        background: '#75d490',
        foreground: '#000'
      },
      {
        background: '#4cc76f',
        foreground: '#000'
      },
      {
        background: '#44b364',
        foreground: '#000'
      },
      {
        background: '#3c9d58',
        foreground: '#000'
      },
      {
        background: '#328349',
        foreground: '#fff'
      },
      {
        background: '#245e34',
        foreground: '#fff'
      },
    ],
  },
  {
    name: 'teal',
    colors: [
      {
        background: '#e8f7f4',
        foreground: '#000'
      },
      {
        background: '#cff0e9',
        foreground: '#000'
      },
      {
        background: '#b5e7dc',
        foreground: '#000'
      },
      {
        background: '#97dece',
        foreground: '#000'
      },
      {
        background: '#74d3be',
        foreground: '#000'
      },
      {
        background: '#4cc7ac',
        foreground: '#000'
      },
      {
        background: '#44b49b',
        foreground: '#000'
      },
      {
        background: '#3c9d88',
        foreground: '#000'
      },
      {
        background: '#328371',
        foreground: '#000'
      },
      {
        background: '#245e51',
        foreground: '#fff'
      },
    ]
  }
]

export default colors