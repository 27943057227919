import {
  CHANGEPASSWORD,
  VIEWOWNLEAVEACCOUNT,
  VIEWOWNMASTERDATA,
  VIEWOWNWORKHOURSACCOUNT,
} from "../../../../shared/permissions"

import {
  hasOneOfPermissions,
  hasPermission,
} from "../../../../shared/permissionCheck/controller"

import {
  getUsernameState,
  getUserIdState,
  getIsLoggedInState,
  getLoginErrorState,
  getUserIsAdminState,
  getOpenPasswordResetDialogState,
} from "../selectors/loginSelectors"
import { getLanguage } from "../../../languagePicker/controller"
import { getContextState } from "../../../../shared/OUContext/redux/selectors/ouContextDropdownSelectors"

/**
 * Some utils for working with the login part of the store, since this is used
 * in multiple places in similar ways
 */
export function mapStateToProps(state) {
  const isLoggedIn = getIsLoggedInState(state)
  return {
    isLoggedIn,
    username: getUsernameState(state),
    isAdmin: getUserIsAdminState(state),
    context: getContextState(state),
    openLoginDialog: !isLoggedIn, // getOpenLoginDialogState(state),
    openPasswordResetDialog: getOpenPasswordResetDialogState(state),
    loginError: getLoginErrorState(state),
    canChangePassword: hasPermission(CHANGEPASSWORD, state),
    canViewOwnData: hasOneOfPermissions(
      [VIEWOWNMASTERDATA, VIEWOWNLEAVEACCOUNT, VIEWOWNWORKHOURSACCOUNT],
      state
    ),
    currentLanguage: getLanguage(state),
    currentUserId: getUserIdState(state),
  }
}
