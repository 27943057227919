import { CoreForm } from '../../../../shared/view-model-form'

export function makeViewModel() {
  const fields = [
    { name: 'showTimeSheetInPortal', type: 'boolean',
      label: 'settings.showTimeSheetInPortal' },
    { name: 'futurePlanningDays', type: 'number',
      label: 'settings.futurePlanningDays' },
    { name: 'sharedPlans', type: 'boolean',
      label: 'settings.sharedPlans' },
    { name: 'showAbsence', type: 'boolean',
      label: 'settings.showAbsence' },
    { name: 'timecredit', type: 'list',
      label: 'settings.timecredit' },
    { name: 'accessAllBranches', type: 'boolean',
      label: 'settings.accessAllBranches' },
    { name: 'changeablePastDays', type: 'number',
      label: 'settings.changeablePastDays' },
    { name: 'manualSharing', type: 'boolean',
      label: 'settings.manualSharing' },
    { name: 'automaticNotification', type: 'boolean',
      label: 'settings.automaticNotification' },
  ]
  return new CoreForm('portalSettingsForm', fields)
}
