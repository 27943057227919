export default {
  token: {
    colorPrimary: "rgb(98, 163, 195)",
    colorBorder: '#ccc',
    colorSplit: '#ccc',
    colorBorderSecondary: '#ccc',
    colorLink: "rgb(98, 163, 195)",
    colorBgLayout: "rgb(47, 62, 67)",
    // unavail: "layout-footer-padding ": "15px 50px 11px",
    // unavail: "table-padding-vertical": "10px",
    // unavail: "table-padding-horizontal": "10px",
    // unavail: "card-padding-base": "10px",
    fontSize: 16,
  }
}