import { Button, Spin } from "antd"
import * as React from "react"
import PlannerData from "../configuration/PlannerData"
import MobileCalendarDateHeader from "./MobileCalendarDateHeader"
import MobileCalendarDay from "./MobileCalendarDay"
import type { Day } from "./MobileHorizCalendar"
import MobileHorizCalendar from "./MobileHorizCalendar"
import "./less/calendar.less"
import type { IntlShape } from "react-intl"
import { FormattedMessage, injectIntl } from "react-intl"
import type FeatureType from "@mina-works/featuresets/dist/definition/interfaces/FeatureType"
import { DEFAULTDAYS } from "./DEFAULTDAYS"

export interface Props {
  intl: IntlShape
  clientId: string
  departmentId: string
  employeeId: string
  changeDisplayedModule: (module: "dashboard") => void
  availableFeatures: FeatureType[]
}

interface State {
  startDate?: Date
  endDate?: Date
  plannerData?: PlannerData
  selectedIndex?: number
  loading: boolean
}
class MobileCalendar extends React.Component<Props, State> {
  private selectIndexFunctions: { [k: string]: () => void }

  constructor(props: Props) {
    super(props)
    const startDate = this.findMonday(new Date())
    this.state = {
      startDate,
      endDate: this.getEndDate(startDate),
      selectedIndex: 0,
      loading: false,
    }
    this.selectIndex = this.selectIndex.bind(this)
    this.selectIndexFunctions = {}
    this.changeDisplayedModule = this.changeDisplayedModule.bind(this)
    this.changeStartDate = this.changeStartDate.bind(this)
    this.handleSWMessage = this.handleSWMessage.bind(this)
  }

  componentDidMount(): void {
    void this.loadPlannerData({} as Props, {} as State)
    this.setupSWMessaging()
  }

  componentWillUnmount(): void {
    this.disconnectSWMessaging()
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    void this.loadPlannerData(prevProps, prevState)
  }

  render(): JSX.Element {
    if (this.state.plannerData) {
      const everyDay = this.state.plannerData?.getEverySingleDay() ?? []
      return (
        <div className="mobile-calendar">
          <Spin spinning={this.state.loading}>
            <MobileCalendarDateHeader
              changeStartDate={this.changeStartDate}
              startDate={this.state.startDate!}
              stepSizeDays={DEFAULTDAYS}
            ></MobileCalendarDateHeader>
            <MobileHorizCalendar
              days={everyDay}
              selectIndex={this.selectIndex}
              selectedIndex={this.getSelectedIndex()}
            ></MobileHorizCalendar>
            <div className="mobile-calendar-days">
              {everyDay.map((d, idx) => (
                <MobileCalendarDay
                  availableFeatures={this.props.availableFeatures}
                  plannerData={this.state.plannerData}
                  loading={this.state.loading}
                  ouId={this.props.departmentId}
                  day={d}
                  key={idx}
                  select={this.selectIndex(idx)}
                  selected={idx === this.getSelectedIndex()}
                ></MobileCalendarDay>
              ))}
              <Button
                size="large"
                shape="round"
                onClick={this.changeDisplayedModule}
                className="mobile-calendar-button"
              >
                <FormattedMessage id="BackToDashboard"></FormattedMessage>
              </Button>
            </div>
          </Spin>
        </div>
      )
    } else {
      return (
        <div className="mobile-calendar-still-loading">
          <Spin />
        </div>
      )
    }
  }

  private changeStartDate(startDate: Date) {
    startDate = this.findMonday(startDate)
    this.setState({
      startDate,
      endDate: this.getEndDate(startDate),
    })
  }

  private findMonday(d: Date) {
    while (d.getDay() !== 1) {
      d = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 1)
    }
    return d
  }

  private getEndDate(startDate?: Date): Date | undefined {
    if (startDate === undefined) {
      startDate = this.state.startDate
    }
    if (startDate !== undefined) {
      const endDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate() + DEFAULTDAYS
      )
      return endDate
    }
  }

  private selectIndex(selectedIndex: number) {
    if (this.selectIndexFunctions[selectedIndex]) {
      return this.selectIndexFunctions[selectedIndex]
    } else {
      const fn = () => this.setState({ selectedIndex })
      this.selectIndexFunctions[selectedIndex] = fn
      return fn
    }
  }

  // TODO: aquire and respect SW messages, spinner the whole page
  private loadPlannerData(prevProps: Props, prevState: State): void {
    if (
      this.props.departmentId !== undefined &&
      (this.props.employeeId !== prevProps.employeeId ||
        this.props.departmentId !== prevProps.departmentId ||
        this.state.startDate !== prevState.startDate)
    ) {
      this.setState(
        {
          plannerData: undefined,
          loading: true,
        },
        () => {
          const plannerData = new PlannerData(
            this.props.clientId,
            this.props.employeeId,
            this.props.intl.locale,
            {
              startDate: this.state.startDate,
              endDate: this.state.endDate,
            }
          )
          void plannerData.load().then(() => {
            const everyDay = plannerData?.getEverySingleDay() ?? []
            let selectedIndex = 0
            for (let idx = 0; idx < everyDay.length; idx++) {
              if (this.isToday(everyDay[idx])) {
                selectedIndex = idx
              }
            }
            this.setState({ plannerData, selectedIndex, loading: false })
          })
        }
      )
    }
  }

  private getSelectedIndex() {
    return this.state.selectedIndex ?? 0
  }

  private isToday(d: Day) {
    const today = new Date()
    if (
      d.date.getFullYear() === today.getFullYear() &&
      d.date.getMonth() === today.getMonth() &&
      d.date.getDate() === today.getDate()
    ) {
      return true
    } else {
      return false
    }
  }

  private changeDisplayedModule() {
    return this.props.changeDisplayedModule("dashboard")
  }

  private setupSWMessaging() {
    navigator.serviceWorker.addEventListener("message", this.handleSWMessage)
  }

  private disconnectSWMessaging() {
    navigator.serviceWorker.removeEventListener("message", this.handleSWMessage)
  }

  private handleSWMessage(event: MessageEvent) {
    if (event.data) {
      const eventType = event.data.type
      if (eventType === "async-state-polling-started") {
        // TODO: This is too broad!
        this.setState({ loading: true })
      }
      if (
        eventType === "async-state-changed" &&
        event.data.aggregator === "loadPlannerLoggedday" &&
        this.state.loading
      ) {
        void this.loadPlannerData({} as Props, {} as State)
      }
    }
  }
}

export default injectIntl(MobileCalendar)
