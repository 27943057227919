/* eslint-disable @typescript-eslint/no-unsafe-argument */

// const SWPATH = "service-worker.js"
// const SWPATH = "sw.js"
const SWPATH = "/service-worker.js"

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/.exec(
      window.location.hostname
    )
)

const MAXPROBLEMSSTORED = 1000
const PROBLEMSTORAGEKEY = "problems_with_network_"
const localStorageHandler = ({ data }) => {
  if (data?.type === "logproblem" && data?.entry?.length) {
    const cur = window.localStorage.getItem(PROBLEMSTORAGEKEY)
    const curval = cur ? JSON.parse(cur) : []
    curval.splice(MAXPROBLEMSSTORED)
    const val = JSON.stringify([data.entry, ...curval])
    window.localStorage.setItem(PROBLEMSTORAGEKEY, val)
  }
}

export default function register() {
  return new Promise(resolve => {
    if (
      /* process.env.NODE_ENV === 'production' && */ "serviceWorker" in
      navigator
    ) {
      navigator.serviceWorker.onmessage = localStorageHandler
      window.addEventListener("load", () => {
        const swUrl = `${SWPATH}`
        if (!isLocalhost) {
          // Is not local host. Just register service worker
          resolve(registerValidSW(swUrl))
        } else {
          // This is running on localhost. Lets check if a service worker still exists or not.
          resolve(checkValidServiceWorker(swUrl))
        }
      })
    }
  })
}

/**
 * Much of the code below is still the default createReactApp code;
 * adapt as needed (code is left as-is to retain some guidance):
 */

async function registerValidSW(swUrl) {
  try {
    // This prevents the loss of the SW for a hard reload.
    await navigator.serviceWorker
      .getRegistration()
      .then(function (reg) {
        // There's an active SW, but no controller for this tab.
        if (reg && reg.active && !navigator.serviceWorker.controller) {
          // Perform a soft reload to load everything from the SW and get
          // a consistent set of resources.
          window.location.reload()
        }
      })
      .catch(e => console.log("REGISTRATION ERR:", e))
    await navigator.serviceWorker.register(swUrl)
  } catch (error) {
    console.error("Error during service worker registration:", error)
  }
}

function checkValidServiceWorker(swUrl) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl)
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      console.log("After fetch", response.headers.get("content-type"))
      if (
        response.status === 404 ||
        response.headers.get("content-type").indexOf("javascript") === -1
      ) {
        // No service worker found. Probably a different app. Reload the page.
        void navigator.serviceWorker.ready.then(registration => {
          void registration
            .unregister()
            .then(() => {
              window.location.reload()
            })
            .catch(e =>
              console.log("Error while unregistering serviceworker:", e)
            )
        })
      } else {
        // Service worker found. Proceed as normal.
        void registerValidSW(swUrl).catch(e =>
          console.log("Error while registering serviceworker:", e)
        )
      }
    })
    .catch(() => {
      console.log(
        "No internet connection found. App is running in offline mode."
      )
    })
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    void navigator.serviceWorker.ready.then(registration => {
      void registration.unregister()
    })
  }
}
