import { valueAt, isEqual } from '../../../../shared/walkObjectHierarchy'
import types from './actionTypes'

export default function reassignmentReducer(
  state = {},
  action
) {
  switch (action.type) {
  case types.LOADALLREASSIGNMENTS:
    return loadAllReassignments(state, action)
  case types.OPENEDITREASSIGNMENTDIALOG:
    return {
      ...state,
      openEditReassignmentDialog: true,
      editReassignmentId: action.id
    }
  case types.CLOSEEDITREASSIGNMENTDIALOG:
    return {
      ...state,
      openEditReassignmentDialog: false,
      editReassignmentId: undefined
    }
  case types.OPENASSIGNUNASSIGNEDDIALOG:
    return {
      ...state,
      openAssignUnassignedDialog: true,
      editUnassignedUserId: action.stateId
    }
  case types.CLOSEASSIGNUNASSIGNEDDIALOG:
    return {
      ...state,
      openAssignUnassignedDialog: false,
      editUnassignedUserId: undefined
    }
  case types.SAVEREASSIGNMENT:
    return saveReassignment(state, action)
  case types.DELETEREASSIGNMENT:
    return deleteReassignment(state, action)
  case types.LOADVALIDORGUNITS:
    return loadValidOrgUnits(state, action)
  case types.REASSIGNUNASSIGNED:
    return reassignUnassigned(state, action)
  default:
    return state
  }
}

function loadAllReassignments(state, action) {
  const byId = action.reassignments.reduce(
    (acc, reassignment) => {
      acc[reassignment.id] = reassignment
      return acc
    },
    {}
  )
  const oldById = valueAt(state, ['reassignments', 'byId']) || {}
  if (isEqual(oldById, byId)) {
    return state
  }
  return {
    ...state,
    reassignments: { byId }
  }
}

function saveReassignment(state, action) {
  const byId = valueAt(state, ['reassignments', 'byId']) || {}
  return {
    ...state,
    openEditReassignmentDialog: false,
    editReassignmentId: undefined,
    reassignments: {
      byId: {
        ...byId,
        [action.reassignment.id]: {
          ...action.reassignment
        }
      }
    }
  }
}

function reassignUnassigned(state, action) {
  const byId = valueAt(state, ['reassignments', 'byId']) || {}
  return {
    ...state,
    openAssignUnassignedDialog: false,
    reassignments: {
      byId: {
        ...byId,
        [action.reassignment.id]: {
          ...action.reassignment
        }
      }
    }
  }
}

function deleteReassignment(state, action) {
  const reassignments = valueAt(state, ['reassignments']) || {}
  const byIdState = valueAt(state, ['reassignments', 'byId']) || {}
  const byId = { ...byIdState }
  delete byId[action.id]
  return {
    ...state,
    reassignments: {
      ...reassignments,
      byId
    }
  }
}

function loadValidOrgUnits(state, action) {
  return {
    ...state,
    reassignmentValidOrgUnits: {
      byId: action.orgUnits.reduce(
        (acc, orgUnit) => {
          acc[orgUnit.id] = orgUnit
          return acc
        },
        {}
      )
    }
  }
}
