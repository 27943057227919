/**
 * input.options must be:
 * [ { label, value, disabled }, ... ] 'disabled' being optional.
 */

import * as React from "react"
import { Form, Radio } from "antd"
import FieldWithHelp from "./support/FieldWithHelp"

const RadioGroupUI = ({ input, label, meta, dataE2E, ...props }) => {
  return (
    <FieldWithHelp {...props}>
      <label>{label}</label>
      <Form.Item
        label={""}
        validateStatus={meta.error ? "error" : "success"}
        hasFeedback={!!meta.error}
        help={meta.error}
      >
        <Radio.Group
          className="fontSizeFix"
          value={input.value}
          onChange={input.onChange}
          options={input.options}
          data-e2e-test-id={dataE2E}
          {...props}
        />
      </Form.Item>
    </FieldWithHelp>
  )
}

function ReadOnlyField({ input: { value }, label, dataE2E }) {
  return (
    <div className="field detailsConnectedFormField">
      <label>{label}</label>
      <div
        className="detailsConnectedFormFieldValue"
        data-e2e-test-id={dataE2E}
      >
        {value}
      </div>
    </div>
  )
}

export default readOnly => (!readOnly ? RadioGroupUI : ReadOnlyField)
