import React from 'react'

import { Form, Group } from '../../../shared/vmi-connected-form'
import { ValidationErrors } from '../../../shared/vmi-connected-form'

import { makeViewModel } from './models/portalSettingsFormModel'
import withForm from '../lib/withForm/withForm'

const validate = (values, dispatch, translate, props) => {
  const v = new ValidationErrors(
    translate('settings.error.validation', props)
  )
  return v
}

const PortalSettingsForm = ({
  translate,
  connectedForm,
  toggleHandler,
  readOnly,
  onlyDetails
}) => {
  const { fields } = connectedForm

  const timecreditOptions = [
    {
      key: 'target-actual',
      value: 'target-actual',
      text: translate('settings.options.target-actual')
    },
    {
      key: 'unrecorded',
      value: 'unrecorded',
      text: translate('settings.options.unrecorded')
    },
    {
      key: 'actual-only',
      value: 'actual-only',
      text: translate('settings.options.actual-only')
    },
  ]

  return (
    <Form
      connectedForm={ connectedForm }
      onToggle={ toggleHandler }
      readOnly={ readOnly }
      onlyDetails= { onlyDetails }
    >
      { fields.showTimeSheetInPortal() }
      { fields.futurePlanningDays() }
      <Group>
        { fields.sharedPlans() }
        { fields.showAbsence() }
      </Group>
      { fields.timecredit({ options: timecreditOptions }) }
      { fields.accessAllBranches() }
      { fields.changeablePastDays() }
      <Group>
        { fields.manualSharing() }
        { fields.automaticNotification() }
      </Group>
    </Form>
  )
}

export default withForm({
  validate,
  makeViewModel,
  form: 'portal'
})(PortalSettingsForm)
