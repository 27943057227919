import PureDateTime from './PureDateTime'
import type { SimpleDateTimeObject } from '../time-utils'
import { InvalidArgumentError } from './errors'

export default class PureDate extends PureDateTime {

  public ['constructor']: PureDate
  public isMEP24PureDate = true
  public typeName = 'PureDate'

  /**
   * Same as the PureDateTime constructor, but drops the hour, minute, and
   * second fields
   */
  constructor(
    { year, month, day }: Partial<SimpleDateTimeObject> = {}
  ) {
    super({ year, month, day })
    this.typeName = 'PureDate'
  }

  /**
   * Check whether format is basically correct; doesn't check every detail
   * (more of an educated guess, really)
   */
  static isValidJSON(json: string) {
    return (
      json
      && json.length === 'yyyy-MM-dd'.length
      && (/^\d\d\d\d-\d\d-\d\d$/.exec(json))
        ? true
        : false
    )
  }

  /**
   * Get a pure date from no matter what (to make legacy JSON
   * autoconversions safer and more reliable).
   */
  static fromAnything(
    anything: PureDate | string | SimpleDateTimeObject | undefined
  ): PureDate | undefined {
    if (!anything) {
      return undefined
    } else if (
      (anything as PureDate).isMEP24PureDate
      || (anything as PureDate).isMEP24PureDateTime
    ) {
      return PureDate.fromPureDateTime(anything as PureDateTime)
    } else if (typeof anything === 'string') {
      const s1 = PureDate.fromString(anything)
      if (s1 === undefined) {
        return PureDate.fromISOString(anything)
      } else {
        return s1
      }
    } else {
      return PureDate.fromObject<PureDate>(anything)
    }
  }

  /**
   * Remove hour, minute, and second
   */
  static fromPureDateTime(dateTime: PureDateTime) {
    return this.fromObject<PureDate>( dateTime.toObject() )
  }

  static fromString(str: string) {
    return this.dateFromString<PureDate>(str)
  }

  /**
   * Instantiate a Date from an ISO8601 string (YYYY-MM-DD); for backward
   * compatibility. This is only available for the PureDate class, not for
   * PureDateTime (to avoid confusion).
   */
  static fromISOString(str: string) { return this.fromString(str) }

  /**
   * @param date A JS Date object
   */
  static fromJSDate(date: Date) {
    const d = PureDateTime.fromJSDate(date)
    d.setHour(0)
    d.setMinute(0)
    d.setSecond(0)
    return PureDate.fromObject<PureDate>(d.toObject())
  }

  static now() {
    return this.fromJSDate(new Date())
  }

  toString() { return this.dateToString() }

  toISOString() { return this.toString() }

  toLocaleDateString() {
    const d = new Date(
      this.getYear(),
      this.getMonth() - 1,
      this.getDay()
    )
    return d.toLocaleDateString()
  }

  toLocaleStringShort() {
    const d = new Date(
      this.getYear(),
      this.getMonth() - 1,
      this.getDay()
    )
    return d.toLocaleString(undefined, { month: 'numeric', day: 'numeric' })
  }

  /**
   * Map every single day between this and toDate to a result list; note that
   * toDate is *included* in the result list (this is more natural for the
   * real world scenarios involved, despite going against the "normal" range
   * bounding principle).
   *
   * Note also that this API *could* have been part of PureDateTime (like
   * addDays()), but that it seemed safer to restrict its usage to actual
   * dates.
   *
   * Sanity checks are minimal: This method will throw a number of standard
   * errors if the arguments don't conform to the PureDate API, for example,
   * but the only *explicit* sanity check is whether the mapping can ever
   * (theoretically) terminate. This *doesn't* prevent you from passing crazy
   * time spans, however, so you should always check your own code's assumptions
   * first!
   */
  mapDays(toDate: PureDate, fun: (a: PureDate) => any) {
    if ( toDate.lt(this) ) {
      throw new InvalidArgumentError()
    }
    let fromDate: PureDate = this
    const res = []
    while ( fromDate.lte( toDate ) ) {
      res.push(fun(fromDate))
      fromDate = fromDate.addDays<PureDate>()
    }
    return res
  }

  /**
   * Need to implement this here due to constraints with static methods and
   * dynamic classes (no easy way to do this.constructor.staticMethod(...))
   */
  clone(): PureDate {
    return PureDate.fromObject(this.toObject())
  }

}
