const messages: Record<string, Record<string, string>> = {
  de: {
    empty: 'Alles löschen',
    'formats-dropdown': 'Absatzformat',
    'normal-text': 'Normaler Text',
    'heading-1': 'Überschrift 1',
    'heading-2': 'Überschrift 2',
  },
  en: {
    empty: 'Empty',
    'formats-dropdown': 'Apply format',
    'normal-text': 'Regular text',
    'heading-1': 'Heading 1',
    'heading-2': 'Heading 2',
  }
}

export default messages