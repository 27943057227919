// import { push } from "connected-react-router"

import { fetchREST } from "../../../../shared/rest.js"
import { resetStore } from "../../../../redux/actions/rootActions"
import {
  showGlobalMessage,
  convertBackendError,
} from "../../../globalMessage/controller"

import types from "./actionTypes"
// import { setContext } from "../../../../shared/OUContext/controller.js"
import appClipboard from "../../../../apps/shared/AppClipboard"

let globalOverrideInserted = false

export function loginAsync(username, password, token) {
  return dispatch => {
    return fetchREST(
      "/authentication/login",
      { username, password, token },
      { method: "POST" }
    )
      .then(res => res.json())
      .then(json => {
        if (json.error) {
          showGlobalMessage(dispatch, convertBackendError(json))
          return Promise.resolve()
        }
        const { success, user } = json
        if (success) {
          dispatch(login(user))
        } else {
          dispatch(loginFailed(json))
        }
        return Promise.resolve(json)
      })
      .catch(err => {
        console.log(err)
        dispatch(loginFailed(err))
      })
  }
}

const ISLOGGEDINTIMEOUTMS = 1800 // After this timeout, paint a warning in the menu bar after request returns
const ISLOGGEDINWARNTIMEOUTMS = 10000 // After this timeout, produce a popup with an extra warning regardless
const DOUBLECHECKNETWORKURL = "https://www.leiner-wolff.de"
const DOUBLECHECKNETWORKMS = 2500 // Try to reach www.lw.de for this long – after that, consider nw faulty
const MAINTWARNINHEADERTEXT = "Bitte versuchen Sie es in einigen Minuten wieder"
const NETWORKWARNINGTEXT =
  // eslint-disable-next-line max-len
  "Es scheint Probleme mit dem Netzwerk zu geben. Wenn neu laden das Problem nicht behebt könnte es ein generelles Problem mit Ihrer Internetverbindung oder Ihrem WLAN geben."
const MAINTWARNINGTEXT =
  // eslint-disable-next-line max-len
  "Falls das Problem länger besteht ist es möglich, dass das Netzwerk nicht verfügbar ist. Wenden Sie sich im Zweifelsfall an den mina.works-Support: "
const MAINTWARNINGSUFFIX = "Bitte entschuldigen Sie die Störung."
const NETWORKWARNINGHTML = `
<div style='padding: 10px; margin: 0px; border: 2px solid rgb(113, 161, 192)'>
  <p>${NETWORKWARNINGTEXT}</p>
  <a href="${
    window.location.pathname
  }?reload=${new Date().getTime()}">Neu laden</a>
</div>
`

const MAINTWARNINGHTML = `
<div style='padding: 10px 30px 10px 30px; margin: 0px; border: 2px solid rgb(113, 161, 192)'>
  <p><strong>${MAINTWARNINHEADERTEXT}</strong></p>
  <p>${MAINTWARNINGTEXT}<a 
    style="color: #69b1ff; text-decoration: none"
    href="mailto:support@mina.works">support@mina.works</a></p>
  <p>${MAINTWARNINGSUFFIX}</p>
  <p>
    <a 
      style="color: #69b1ff; text-decoration: none"
      href="${
        window.location.pathname
      }?reload=${new Date().getTime()}">Neu laden</a>
  </p>
</div>
`

function warnVerySlowNetwork() {
  if (globalOverrideInserted) return
  const onlineBefore = navigator.onLine
  const controller = new AbortController()
  const signal = controller.signal
  const timeout = setTimeout(() => {
    controller.abort()
    if (globalOverrideInserted) return
    console.log(
      "Timeout for www.leiner-wolff.de triggered:",
      DOUBLECHECKNETWORKMS
    )
    if (onlineBefore) {
      alertUserAboutNetworkProblems()
    } else {
      console.warn(
        "Would have warned the user about network, but machine was completely offline when the request started"
      )
    }
  }, DOUBLECHECKNETWORKMS)
  void fetch(DOUBLECHECKNETWORKURL, {
    mode: "no-cors",
    cache: "no-cache",
    signal,
  })
    .then(res => {
      clearTimeout(timeout)
      if (globalOverrideInserted) return
      console.log("www.leiner-wolff.de reacted", res.status, res.statusText)
      if (onlineBefore) {
        alertUserAboutMaintenance()
      } else {
        console.warn(
          "Would have warned the user about maintenance, but machine was completely offline when the request started"
        )
      }
    })
    .catch(e => {
      clearTimeout(timeout)
      if (globalOverrideInserted) return
      console.log("Error when calling www.leiner-wolff.de", e)
      if (e.name === "AbortError") {
        console.log(
          "The request was aborted due to a timeout:",
          DOUBLECHECKNETWORKMS
        )
      } else {
        console.error(
          "The request was aborted with an actual error (not a timeout)!"
        )
        if (onlineBefore) {
          alertUserAboutNetworkProblems()
        } else {
          console.warn(
            "Would have warned the user about network, but machine was completely offline when the request started"
          )
        }
      }
    })
}

function alertUserAboutNetworkProblems() {
  if (navigator.onLine) {
    insertCompleteOverlay(NETWORKWARNINGHTML)
  } else {
    console.warn(
      "Would have warned the user about network, but machine if completely offline"
    )
  }
}

function alertUserAboutMaintenance() {
  if (navigator.onLine) {
    insertCompleteOverlay(MAINTWARNINGHTML)
  } else {
    console.warn(
      "Would have warned the user about maintenance, but machine if offline"
    )
  }
}

/**
 * Make warning available for manual testing
 */
window.testWarning = () => insertCompleteOverlay(MAINTWARNINGHTML)

function insertCompleteOverlay(html) {
  if (!globalOverrideInserted) {
    globalOverrideInserted = true
    // const body = window.document.body.innerHTML
    window.document.body.innerHTML += `
    <div style="
      min-width: 100vw;
      max-width: 100vw;
      min-height: 100vh;
      max-height: 100vh;
      background-color: white;
      opacity: 0.45;
      position: fixed;
      left: 0px;
      top: 0px;
      z-index: 20000000;
    ">&nbsp;</div>
    <div style="
      background-color: white;
      opacity: 1;
      position: fixed;
      width: 600px;
      left: calc(50vw - 300px);
      top: 80px;
      z-index: 20000001;
      box-shadow: 0 0 8px gray;
    ">
      ${html}
    </div>`
  }
}

export function loginWithSessionAsync() {
  return dispatch => {
    return fetchREST(
      "/authentication/isloggedin",
      {},
      {
        timeout: {
          warnTimeout: ISLOGGEDINWARNTIMEOUTMS,
          warnTimeoutHandler: warnVerySlowNetwork,
          milliseconds: ISLOGGEDINTIMEOUTMS,
          handler: res => {
            return new Promise(resolve => {
              res.json().then(json => {
                dispatch(networkIsVerySlow(json.isLoggedIn, json.user))
                resolve({ alreadyDispatched: true })
              })
            })
          },
        },
      }
    )
      .then(res => {
        try {
          return res.json()
        } catch (e) {
          if (e instanceof TypeError && res.alreadyDispatched) {
            return res
          } else {
            throw e
          }
        }
      })
      .then(json => {
        if (json.alreadyDispatched) {
          console.warn("isLoggedIn: Resolving from timeout!")
          return Promise.resolve()
        }
        if (json.error) {
          showGlobalMessage(dispatch, convertBackendError(json))
          return Promise.resolve()
        }
        const { isLoggedIn, user } = json
        if (isLoggedIn) {
          dispatch(login(user))
        } else {
          dispatch(logout())
        }
        return Promise.resolve()
      })
      .catch(err => {
        // TODO: display problems in the browser
        console.log(err)
        dispatch(loginFailed(err))
      })
  }
}

export function logoutAsync(path, navigate) {
  return dispatch => {
    return fetchREST("/authentication/logout", {}, { method: "POST" })
      .then(res => res.json())
      .then(json => {
        if (json.error) {
          showGlobalMessage(dispatch, convertBackendError(json))
          return Promise.resolve()
        }
        if (json.success) {
          dispatch(logout())
          dispatch(resetStore())
          navigate(path ?? "/")
          // dispatch(push(path ?? "/"))
          dispatch(closeLoginDialog())
          appClipboard.clear()
          /*
           */
        } else {
          showGlobalMessage(dispatch, convertBackendError(json))
          return Promise.resolve()
        }
        return Promise.resolve()
      })
      .catch(err => console.log(err))
  }
}

/**
 * Sign the user in - make them known to the rest of the UI
 */
function login(user) {
  return { type: types.LOGIN, user }
}

function loginFailed(error) {
  return { type: types.LOGINFAILED, error }
}

/**
 * NOTIFY: Network slow/backend down
 */
function networkIsVerySlow(isLoggedIn, user) {
  return { type: types.NETWORKVERYSLOW, user: isLoggedIn ? user : undefined }
}

/**
 * Sign the user out
 */
function logout() {
  return { type: types.LOGOUT }
}

/**
 * Open login modal
 */
export function openLoginDialog() {
  return { type: types.OPENLOGINDIALOG }
}

/**
 * Close login modal
 */
export function closeLoginDialog() {
  return { type: types.CLOSELOGINDIALOG }
}

/**
 * Open the password recovery form (carry the username over for convenience)
 * and close the login modal.
 */
export function openPasswordResetDialog(username) {
  return { type: types.OPENPASSWORDRESETDIALOG, username }
}

/**
 * Close the password recovery form.
 */
export function closePasswordResetDialog() {
  return { type: types.CLOSEPASSWORDRESETDIALOG }
}

/*
function appendPasswordResetError(error) {
  return { type: types.APPENDPASSWORDRESETERROR, error  }
}
*/

/**
 * Triggers the reset mail on the server for the given username.
 */
export function resetPasswordAsync(username, path, navigate) {
  return dispatch => {
    return fetchREST(
      "/userManager/resetPassword",
      { username },
      { method: "POST" }
    )
      .then(res => res.json())
      .then(json => {
        if (json.error) {
          /* XXX: This would be information leakage, no?
          // Append the username to the error object for detailed translations.
          dispatch(appendPasswordResetError({
            ...json,
            username
          }))
          */
          showGlobalMessage(dispatch, convertBackendError(json))
          return Promise.resolve()
        } else if (path) {
          navigate(path)
          // dispatch(push(path))
        } else {
          showGlobalMessage(dispatch, {
            message: "Reset mail sent.",
            description: "Check your mail and spam folders.",
          })
          dispatch(closePasswordResetDialog())
        }
        return Promise.resolve()
      })
      .catch(err => {
        console.log(err)
      })
  }
}
