import * as React from "react"
import { Form, Select } from "antd"
import messages from "./l10n"
import FieldWithHelp from "./support/FieldWithHelp"

const SelectUI = ({
  input,
  label,
  options,
  multiple,
  type,
  meta,
  dataE2E,
  ...props
}: any) => {
  let value =
    (multiple || type === "multiple") && `${input.value}`.trim() === ""
      ? []
      : input.value
  if ((multiple || type === "multiple") && typeof value === "string") {
    value = [value]
  }
  return (
    <FieldWithHelp {...props}>
      <label>{label}</label>
      <Form.Item
        label={""}
        validateStatus={meta.error ? "error" : "success"}
        hasFeedback={!!meta.error}
        help={meta.error}
      >
        <Select
          showSearch
          filterOption={(filterInput, option) => {
            // XXX There is a typing/API error here!
            const ch = option.children as unknown as string
            const res =
              ch?.toLowerCase().indexOf(filterInput.toLowerCase()) >= 0
            return res
          }}
          mode={multiple || type === "multiple" ? "multiple" : undefined}
          {...props}
          notFoundContent={translate("no data")}
          value={value}
          onChange={data => input.onChange(data)}
          data-e2e-test-id={dataE2E}
        >
          {options.map((o: any, idx: any) => (
            <Select.Option key={idx} value={o.value}>
              {o.text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </FieldWithHelp>
  )
}

function ReadOnlyField({
  input: { value },
  label,
  options,
  multiple,
  dataE2E,
}: any) {
  const _text = multiple
    ? (value || [])
        .map((v: any) => options.find((o: any) => o.value === v))
        .filter((v: any) => v !== undefined && v !== null)
        .map((v: any) => v.text)
        .join(", ") || value
    : (options.find((o: any) => o.value === value) || { text: value }).text
  return (
    <div className="field detailsConnectedFormField">
      <label>{label}</label>
      <div
        className="detailsConnectedFormFieldValue"
        data-e2e-test-id={dataE2E}
      >
        {_text}
      </div>
    </div>
  )
}

// TODO: This may be needed in other places as well
function translate(msg: string) {
  const lang = getLang()
  if (lang in messages) {
    return (messages as any)[lang][msg] || msg
  }
}

function getLang() {
  let lang = "en" // simplifying a bit here
  if (navigator.languages !== undefined && navigator.languages.length > 0) {
    lang = navigator.languages[0]
  } else {
    lang = navigator.language || lang
  }
  const l = lang.substr(0, 2)
  return ["en", "de"].includes(l) ? l : "en"
}

export default (readOnly: boolean): any =>
  !readOnly ? SelectUI : ReadOnlyField
