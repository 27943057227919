import { createStore, applyMiddleware, compose } from "redux"
// import { routerMiddleware } from 'connected-react-router'
import thunk from "redux-thunk"

import createRootReducer from "../reducers/rootReducer"
// import ReduxDevTools from '../../setup/ReduxDevTools'

const env = process.env.NODE_ENV || "development"

export default function (/* history */) {
  return createStore(
    // connectRouter(history)(rootReducer),
    createRootReducer(/* history */),
    {},
    compose(
      applyMiddleware(
        // routerMiddleware(history),
        thunk
      ),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ...(env === "development" && window.__REDUX_DEVTOOLS_EXTENSION__
        ? [
            // Chrome devtools
            window.__REDUX_DEVTOOLS_EXTENSION__({}),
          ]
        : [])
      /*
      ...(
        (false && env === 'development') ? [
          ReduxDevTools.instrument()
        ] : []
      )
      */
    )
  )
}
