/**
 * This container manages a (dynamic) number of edit forms for breaks
 *
 * It needs to resist changes when its content is tainted, but may need to
 * react to new content being loaded.
 *
 * TODO: Use a form ID?
 */

import { PlusOutlined } from "@ant-design/icons"
import { Button } from "antd"
import * as React from "react"
import type { IntlShape } from "react-intl"
import { FormattedMessage, injectIntl } from "react-intl"
import type { Break } from "../configuration/PlannedDay"
import type EditableLoggedEntry from "./EditableLoggedEntry"
import MobileBreakForm from "./MobileBreakForm"

export interface MultiBreakFormContainerProps {
  intl: IntlShape
  entry: EditableLoggedEntry
  breaks: Break[]
  addBreak: () => void
  removeBreak: (idx: number) => void
  entryUpdateCallback: () => void
  startDate: string
}

class MobileMultiBreakFormContainer extends React.PureComponent<MultiBreakFormContainerProps> {
  render(): JSX.Element {
    return (
      <div className="mce-break-forms">
        <div className="mce-breaks-label">
          <FormattedMessage id="Breaks"></FormattedMessage>
        </div>
        {this.props.breaks.length > 0 ? (
          <>
            <div className="mce-break-forms-entries">{this.renderBreaks()}</div>
          </>
        ) : (
          <div className="mce-no-breaks"></div>
        )}
        <div className="mce-break-forms-controls">
          <Button onClick={this.props.addBreak} type="link" size="small">
            <PlusOutlined /> {this.t("add-break")}
          </Button>
        </div>
      </div>
    )
  }

  private t(id: string, v: { [k: string]: string } = {}): string {
    return `${this.props.intl.formatMessage({ id }, v)}`
  }

  private renderBreaks() {
    return this.props.breaks.map((e, idx) => (
      <MobileBreakForm
        idx={idx}
        startDate={this.props.startDate}
        entry={this.props.entry}
        removeBreak={this.props.removeBreak}
        key={`mbfc-break-${idx}`} // XXX removed -${e.getKey()}
        entryUpdateCallback={this.props.entryUpdateCallback}
        break={e}
      ></MobileBreakForm>
    ))
  }
}

export default injectIntl(MobileMultiBreakFormContainer)
