import * as React from "react"
import { Button, Form, Input } from "antd"
import { useIntl } from "react-intl"

const PasswordResetForm = ({
  resetPassword,
}: {
  resetPassword: (n: string, p: string) => void
}): JSX.Element => {
  const intl = useIntl()
  const [form] = Form.useForm()
  const [backLinkShown, showBackLink] = React.useState(false)
  const onFinish = ({ username }: { username: string }) => {
    resetPassword(username, "/mobile")
    showBackLink(true)
  }
  return (
    <div className="mobile-login-form">
      <img
        onClick={() => (window.location.href = "/mobile")}
        alt="mina.works"
        src="mina_works_icon.svg"
        className="mobile-logo"
        style={{ cursor: "pointer" }}
      ></img>

      <Form form={form} layout="vertical" onFinish={onFinish}>
        <div className="mobile-hero-title">
          <span className="mobile-hero-title-mina">mina.</span>
          <span className="mobile-hero-title-works">works</span>
        </div>
        <Form.Item
          label={intl.formatMessage({ id: "Username" })}
          name="username"
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: "PleaseEnterUsername" }),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <div
          style={{
            display: backLinkShown ? "block" : "none",
          }}
          className="mobile-password-reset-link"
        >
          <a href="/mobile">{intl.formatMessage({ id: "BackToHome" })}</a>
        </div>

        <Form.Item>
          {backLinkShown ? (
            <>
              <span>{intl.formatMessage({ id: "PasswordAlreadyReset" })}</span>
              <br />
              <span>{intl.formatMessage({ id: "FindResetMailExpl" })}</span>
            </>
          ) : (
            <Button type="primary" htmlType="submit">
              {intl.formatMessage({ id: "ResetPassword" })}
            </Button>
          )}
        </Form.Item>

        <div className="mobile-imprint-link">
          <a
            href="https://mina.works/imprint/"
            target="_blank"
            rel="noreferrer"
          >
            {intl.formatMessage({ id: "Imprint" })}
          </a>
        </div>
      </Form>
    </div>
  )
}

export default PasswordResetForm
