import types from './actionTypes'

export function addGlobalMessage(message) {
  // return { type: types.ADDGLOBALMESSAGE, message }
  // XXX REDIRECTING to the new Error Handler component:
  return {
    type: 'ignore', error: {
      title: message.message ? message.message : message,
      message: message.description ? message.description : message
    }
  }
}

export function closeGlobalMessage() {
  return { type: types.CLOSEGLOBALMESSAGE }
}
