/* The HOC for handling login; wrapped around the LoginModal */

import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'

import LoginModal from '../components/LoginModal'
import {
  closeLoginDialog,
} from '../redux/actions/loginActions'
import { mapStateToProps } from '../redux/utils/loginUtils'

const mapDispatchToProps = dispatch => {
  return {
    dispatchCloseLoginDialog: () => dispatch(closeLoginDialog()),
  }
}

/**
 * HOC for the login modal
 *
 * provides
 * - onCancel (callback when cancelling the form)
 *
 * Automatically wraps in
 * - injectIntl (props.intl)
 */
export const LoginModalContainer = props => {
  const onCancel = ({ dispatchCloseLoginDialog }) => {
    dispatchCloseLoginDialog()
  }
  return (
    <LoginModal
      translate={ id => props.intl.formatMessage({id}) }
      openLoginDialog={ props.openLoginDialog }
      onCancel={ () => onCancel(props) }
    />
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(
  injectIntl(LoginModalContainer)
)
