// import genUniqueId from '../../../shared/uniqueId'

// return all needed default values for an empty orgunit.
export function getEmptyOrgUnitProps( moreFields = {} ) {
  // const id = 'tempOrgUnit' + genUniqueId()
  return {
    // id,
    abbrev: '',
    accounts: '',
    children: [],
    color: [0, 0, 0],
    country: '',
    email: '',
    history: [],
    importKey: '',
    isArchived: false,
    isDeleted: false,
    isLocation: false,
    isRoot: false,
    name: '',
    showInPlanner: false,
    state: '',
    type: '',
    ...moreFields
  }
}
