/**
 * This is the webpack entry file!
 */

/**
 * We have to import this here because otherwise it is either
 * - not imported (and parts of the mobile app don't work properly), or it's
 * - imported too late, and our button styles are overriden
 */
import { v4 as uuid } from "uuid"
import * as React from "react"
import { createRoot } from "react-dom/client"
import { Provider, ReactReduxContext } from "react-redux"
// import { ConnectedRouter } from "connected-react-router"
// import { createBrowserHistory } from "history"
import configureStore from "./redux/store/configureStore"
import { loginWithSessionAsync } from "./modules/login/redux/actions/loginActions"
import registerServiceWorker from "./setup/registerServiceWorker"
import "antd"
import "./index.css"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import routes from "./ui/layout/routes"
import tabClientIdInit from "./apps/shared/TabSpecificSession/init"
import { ISLOGGEDINLOCK, CHECKLOGININTERVALSEC } from "./constants"

window.global = window // shim global manually
;(window as any).__webpack_nonce__ = uuid() // eslint-disable-line
// __webpack_nonce__ = uuid() // eslint-disable-line

const router = createBrowserRouter(routes)

// const history = createBrowserHistory()
const store = configureStore(/* history */)

// TODO:
// Here we synchronize state with the server
// Currently, this means logging the user back in via their cookie
// It should also mean other things in the future, and should then be moved
// somewhere more sensible
const checkLoggedIn = () =>
  void navigator.locks.request(
    ISLOGGEDINLOCK,
    {
      mode: "exclusive",
      ifAvailable: true,
    },
    lock =>
      lock !== null
        ? store.dispatch(loginWithSessionAsync())
        : console.warn("isloggedin lock unavailable")
  )

checkLoggedIn()

// TODO: This should be optimized/improved!
setInterval(checkLoggedIn, CHECKLOGININTERVALSEC * 1000)

const container = document.getElementById("root")
const root = createRoot(container)

tabClientIdInit()

root.render(
  <Provider store={store} context={ReactReduxContext}>
    <RouterProvider router={router}></RouterProvider>
  </Provider>
)

console.log("[0] Register service worker...")
// XXX const registerServiceWorker = require("./setup/registerServiceWorker").default
// eslint-disable-next-line @typescript-eslint/no-floating-promises
registerServiceWorker().then(() =>
  console.log("[1] Service worker registration finished.")
)
