/**
 * input.options must be:
 * [ { label, value, disabled }, ... ] 'disabled' being optional.
 */

import * as React from "react"
import { Form, Checkbox, Row, Col } from "antd"
import FieldWithHelp from "./support/FieldWithHelp"

interface Option {
  label?: string
  value?: string
}

const createOption = (option: Option) => (
  <Checkbox value={option.value}>{option.label}</Checkbox>
)

const createReadOnlyOption = (option: Option) => (
  <Checkbox className="readOnlyDisabled" checked={true} disabled={true}>
    {option.label}
  </Checkbox>
)

const createOptionsColumns = (options: Option[], readOnly = false) => (
  <Row>
    {(options || []).map((option: Option, idx) => (
      <Col key={idx} span={12}>
        {readOnly === false
          ? createOption(option)
          : createReadOnlyOption(option)}
      </Col>
    ))}
  </Row>
)

const CheckboxGroupUI = ({
  input,
  label,
  meta,
  options,
  dataE2E,
  ...props
}) => {
  return (
    <FieldWithHelp {...props}>
      <label>{label}</label>
      <Form.Item
        label={""}
        validateStatus={meta.error ? "error" : "success"}
        hasFeedback={!!meta.error}
        help={meta.error}
      >
        <Checkbox.Group
          value={input.value}
          onChange={input.onChange}
          data-e2e-test-id={dataE2E}
        >
          {createOptionsColumns(options)}
        </Checkbox.Group>
      </Form.Item>
    </FieldWithHelp>
  )
}

function ReadOnlyField({ input: { value }, label, options, dataE2E }) {
  const displayedOptions = options.filter(o => value.includes(o.value))
  // .map(o => o.label)
  // .join(', ')
  return (
    <div className="field detailsConnectedFormField">
      <label>{label}</label>
      <div
        className="detailsConnectedFormFieldValue"
        data-e2e-test-id={dataE2E}
      >
        {createOptionsColumns(displayedOptions, true)}
      </div>
    </div>
  )
}

export default readOnly => (!readOnly ? CheckboxGroupUI : ReadOnlyField)
