const messages = {
  "clients.ticketNumbers": "Tickets",
  "clients.masterdata": "Masterdata",
  "clients.licenses": "Licenses",
  "clients.licenseVolume": "Current license volume",
  "clients.heading": "Clients",
  "clients.clientname": "Name of client",
  "clients.clientnumber": "Identifier",
  "clients.currentKeyFigures": "Current Keyfigures",
  "clients.licensedUsers": "License volume",
  "clients.totalUsers": "Total users",
  "clients.usedUsers": "Licenses used",
  "clients.activeUsers": "Active users",
  "clients.recentlySeenUsers": "Recently logged in users",
  "clients.availableUsers": "Available users",
  "clients.lockedUsers": "Locked users",
  "clients.hiddenUsers": "Hidden users",
  "clients.deletedUsers": "Deleted users",
  "clients.archivedUsers": "Archived users",
  "clients.availableLicenses": "Free volume",
  "clients.stats.licenseVolume": "License volume",
  "clients.stats.currentlyInUse": "Currently in use",
  "clients.stats.stillRemaining": "Still remaining",
  "clients.edit": "Edit",
  "clients.new": "Create new",
  "client.couldntSave": "Client could not be saved!",
  "clients.contact": "Contact",
  "clients.history": "History",
  "clients.current": "Current",
  "clients.contactPerson": "Contact person",
  "clients.contactPrefix": "Title",
  "clients.contactSalutation": "Salutation",
  "clients.contactFirstname": "Given Name",
  "clients.contactLastname": "Lastname",
  "clients.phone": "Phone",
  "clients.email": "Email",
  "clients.contactPhone": "Phone number",
  "clients.contactEmail": "Email address",
  "clients.notes": "Notes",
  "clients.category": "Category",
  "clients.highlight": "Mark",
  "clients.ignoreForStatistics": "Ignore for statistics",
  "clients.marked": "Marked",
  "clients.changeddate": "Last Change ",
  "clients.changedby": "Changed by...",
  "clients.delete": "Delete client",
  "clients.reallydelete": "Do you really want to delete this client?",
  "clients.billingExport": "Billing CSV",
  "clients.totalClients": "Clients",
  "clients.totalEmployees": "Employees",
  "clients.totalLicenses": "Licenses",
  "clients.totalLicensesInUse": "Licenses in use",
  "no date": "no date provided",
  "form.error": "Error",
  "client.missingName": "client name missing",
  "client.missingNumber": "client number missing",
  "clients.perPage": "Page",
  "license.modalHeader": "Edit License",
  "license.effectivedate": "Effective date",
  "license.expirydate": "Expiry date",
  "license.firstbillingdate": "First billing date",
  "license.numberofusers": "License volume",
  "license.isinvalid": "Invalid",
  "license.validationErrors": "Validation error",
  "license.missingEffectivedate": "Missing effective date",
  "license.missingFirstbillingdate": "Missing first billing date",
  "license.newLicenseButton": "New license",
  "license.unlimited": "unlimited",
  "license.expirydateBeforeEffectivedate": "Expiry date before effective date",
  "license.firstbillingdateAfterExpirydate":
    "First billing date after expiry date",
  "expiration.heading": "Expiration",
  "expiration.description":
    "If an expiration exists and the date is reached, users of this client won't be able to log into the system.",
  "expiration.newButton": "Add Expiration",
  "expiration.deleteMessage":
    "If the Expiration is deleted, all active users can log into the system immediately.",
  "expiration.updateOrCreateMessage":
    "If the given date is today or in the past, all active users will be logged out!",
  "non-unique-number": "This identifier is already in use.",
  activeUsers: "Active Users",
  showclientlicensehistory: "Show history",
  hideclientlicensehistory: "Hide history",
}

export default messages
