import * as React from "react"
import { Button } from "antd"
import type { IAvailableTransitionType } from "../../commonInterfaces/processes/IAvailableTransitionType"
import type {
  IFormField,
  IWorkflowFormInfo,
} from "../../commonInterfaces/processes/RawFormInfo"
import type { IntlShape } from "react-intl"
import { injectIntl } from "react-intl"
import type {
  FieldMapper,
  UIInteraction,
  WorkflowInstanceInfo,
} from "../../shared/processManagerUtils/saveForm"
import saveForm from "../../shared/processManagerUtils/saveForm"
import ProcessFormFields from "../../shared/processManagerUtils/ProcessFormFields"
import ProcessFormTransitionButtons from "../../shared/processManagerUtils/ProcessFormTransitionButtons"
import {
  OVERTIMEREQUEST,
  VACATIONREQUEST,
} from "../../commonConfiguration/processes/processTypes"

export interface Props {
  intl: IntlShape
  clientId: string
  departmentId: string
  employeeId: string
  processType?: string
  id?: string
  changeDisplayedModule: (module: string) => void
  propagateChanges: () => void
  availableTransitions: IAvailableTransitionType[]
  locale: string
}

interface State {
  selectedProcessType?: string
  selectedTransition?: IAvailableTransitionType
  forminfo?: IWorkflowFormInfo
  windowHeight?: number
  values?: { [name: string]: any }
}

class MobileProcessManagerForm extends React.PureComponent<Props, State> {
  private resizeTicker?: number

  constructor(props: Props) {
    super(props)
    this.state = {
      windowHeight: window.innerHeight,
      values: {},
    }
    this.save = this.save.bind(this)
    this.loadTransition = this.loadTransition.bind(this)
    this.updateWindowHeight = this.updateWindowHeight.bind(this)
  }

  componentDidMount(): void {
    // The iPhone doesn't trigger a resize event when
    // the keyboard pops up, so we need to track the
    // size ourselves
    // TODO: Can/should we restrict this to iOS Safari?
    this.resizeTicker = window.setInterval(this.updateWindowHeight, 250)
    this.setValue = this.setValue.bind(this)
  }

  componentWillUnmount(): void {
    window.clearInterval(this.resizeTicker)
  }

  componentDidUpdate(prevProps: Props): void {
    if (prevProps.id !== this.props.id) {
      this.setState({
        selectedTransition: undefined,
        forminfo: undefined,
        values: {},
      })
    }
  }

  render(): JSX.Element {
    return (
      <div className="mobile-process-form">
        {this.state.forminfo !== undefined ? (
          <div className="mobile-process-form-info">
            {this.renderProcessForm()}
          </div>
        ) : (
          this.renderTransitionButtons()
        )}
      </div>
    )
  }

  private getProcessType(): string | undefined {
    return this.state.selectedProcessType ?? this.props.processType
  }

  private save() {
    if (this.state.values !== undefined && this.getProcessType()) {
      const wf: WorkflowInstanceInfo = {
        id: this.props.id,
        processType: this.getProcessType()!,
        departmentId: this.props.departmentId,
        clientId: this.props.clientId,
        selectedTransition: this.state.selectedTransition,
      }
      const formfields: IFormField[] = this.state.forminfo?.formfields ?? []
      const values: Record<string, any> = { ...this.state.values }
      const fields: FieldMapper<IFormField, string> = {
        getId: f => f.name,
        getAllFields: () => formfields,
        getValue: id => values[id],
        getFormInfo: (_id, f) => f,
      }
      const ui: UIInteraction = {
        alert: window.alert.bind(window),
        locale: this.props.intl.locale,
        success: () =>
          this.setState(
            { values: {}, selectedTransition: undefined, forminfo: undefined },
            () => this.props.propagateChanges()
          ),
      }
      // return saveForm(wf, fields, ui).then(() => this.setState({ values: {} }))
      return this.setState(
        { selectedTransition: undefined, values: {}, forminfo: undefined },
        () => void saveForm(wf, fields, ui)
      )
    }
  }

  private renderTransitionButtons() {
    const afterLoadTransition = (
      t?: IAvailableTransitionType,
      forminfo?: IWorkflowFormInfo
    ) =>
      this.setState({
        selectedTransition: t,
        forminfo,
      })
    const onCancel = () =>
      this.setState({ forminfo: undefined }, this.props.propagateChanges)
    const processType = this.getProcessType()
    const setProcessType = (selectedProcessType: string) =>
      this.setState({ selectedProcessType })
    return (
      <ProcessFormTransitionButtons
        availableTransitions={this.props.availableTransitions}
        id={this.props.id}
        onCancel={onCancel}
        afterLoadTransition={afterLoadTransition}
        processType={processType}
        setProcessType={setProcessType}
        departmentId={this.props.departmentId}
        clientId={this.props.clientId}
        classPrefix={"mobile-"}
      ></ProcessFormTransitionButtons>
    )
  }

  private renderProcessForm() {
    // TODO: Closure, handle submit directly, don't update this object
    //       while form is open
    const b = this.state.forminfo?.formfields?.find(
      f => f.name === "submit" && f.formelement === "button"
    )
    return (
      <div className="mobile-process-form">
        {this.props.id && this.state.selectedTransition ? (
          <h5>{this.state.selectedTransition.label}</h5>
        ) : this.state.selectedProcessType === VACATIONREQUEST ? (
          <h5>{this.props.intl.formatMessage({ id: "RequestVacationH" })}</h5>
        ) : this.state.selectedProcessType === OVERTIMEREQUEST ? (
          <h5>{this.props.intl.formatMessage({ id: "RequestOvertimeH" })}</h5>
        ) : (
          <h5>{this.props.intl.formatMessage({ id: "RequestGeneralH" })}</h5>
        )}
        <ProcessFormFields
          cssPrefix="mobile-"
          formfields={this.state.forminfo?.formfields ?? []}
          setValue={this.setValue}
          windowHeight={this.getWindowHeight()}
        ></ProcessFormFields>
        <div className="mobile-form-submit">
          <Button
            className={`mobile-button-${b?.visualisationtype}`}
            onClick={() => void this.save()}
          >
            {this.props.intl.formatMessage({ id: "Send" })}
          </Button>
        </div>
        {this.renderCancelButton()}
      </div>
    )
  }

  private renderCancelButton() {
    return (
      <Button
        className="mobile-process-form-cancel"
        onClick={() =>
          this.setState({ forminfo: undefined }, this.props.propagateChanges)
        }
      >
        {this.props.intl.formatMessage({ id: "vacareq-cancel-all" })}
      </Button>
    )
  }

  private setValue(formFieldDefinition: any, value: any) {
    this.setState({
      values: {
        ...this.state.values,
        [formFieldDefinition.name]: value,
      },
    })
  }

  private updateWindowHeight() {
    this.setState({
      windowHeight:
        (window as any).visualViewport?.height ?? window.innerHeight,
    })
  }

  private getWindowHeight() {
    return this.state.windowHeight
  }

  private async loadTransition(t?: IAvailableTransitionType) {
    /**
     * FormInfo is required for rendering the form
     *
     * This is either retrieved directly for a *new* process
     * (no ID, no button pressed), **or** it is retrieved
     * following a button press selecting *one* of the
     * available transactions.
     */
    let url = "/rest/workflows/getWorkflowTransactionFormInfo"
    let body: { [k: string]: any } = {
      workflowType: this.getProcessType(),
      orgunitId: this.props.departmentId,
      contextOrgunitId: this.props.departmentId,
      contextClientId: this.props.clientId,
      locale: this.props.intl.locale,
    }
    if (this.props.id !== undefined) {
      body.workflowInstanceId = this.props.id
      body = {
        ...body,
        ...t,
      }
    } else {
      url = "/rest/workflows/getNewWorkflowInstanceFormInfo"
    }
    const res = await fetch(url + "?" + encodeURIComponentsForActions(body), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    const forminfo = await res.json()
    if (
      forminfo.feedback !== undefined &&
      forminfo.feedback?.statusCode !== "ok"
    ) {
      alert(
        `${this.props.intl.formatMessage({
          id: "vacareq-unexpected-error-occurred",
        })}: ${forminfo.feedback?.description ?? "-"}`
      )
    } else {
      this.setState({
        selectedTransition: t,
        forminfo,
      })
    }
  }
}

function encodeURIComponentsForActions(
  props: Record<string, string | number | boolean>
): string {
  const fixURIComponent = (v: string | number | boolean) =>
    encodeURIComponent(v === undefined ? "" : v)
  return Object.getOwnPropertyNames(props)
    .reduce((acc: string[], key) => {
      acc.push(key + "=" + fixURIComponent(props[key]))
      return acc
    }, [])
    .join("&")
}

const MobileProcessManagerFormWithIntl = injectIntl(MobileProcessManagerForm)
export default MobileProcessManagerFormWithIntl
