import * as React from "react"
import messages from "./locales/messages"
import { IntlProvider } from "react-intl"
import SupportTicketsForm from "./SupportTicketsForm"
import makeBackendService from "./BackendService"

import "./supportTicket.less"

export interface Props {
  clientId: string
  locale: string
  showForm: boolean
  setShowForm: (flag: boolean) => void
}

export default function SupportTicketsButtonApp({
  locale,
  clientId,
  showForm,
  setShowForm,
}: Props): JSX.Element | null {
  const [enabled, setEnabled] = React.useState<boolean>(false)
  React.useEffect(() => {
    const backend = makeBackendService()
    void backend.enabled(clientId).then(f => {
      setEnabled(f)
    })
  }, [clientId])
  if (!enabled) {
    return null
  }
  return (
    <IntlProvider
      locale={locale}
      defaultLocale="de"
      messages={messages[locale.substring(0, 2)]}
    >
      <div className="support-tickets-button-app">
        <SupportTicketsForm
          showForm={showForm}
          setShowForm={setShowForm}
        ></SupportTicketsForm>
      </div>
    </IntlProvider>
  )
}
