import React from 'react'

import { Form, Group } from '../../../shared/vmi-connected-form'
import { ValidationErrors } from '../../../shared/vmi-connected-form'

import { makeViewModel } from './models/timetrackingSettingsFormModel'
import withForm from '../lib/withForm/withForm'

const validate = (values, dispatch, translate, props) => {
  const v = new ValidationErrors(
    translate('settings.error.validation', props)
  )
  return v
}

const TimetrackingSettingsForm = ({
  translate,
  connectedForm,
  toggleHandler,
  readOnly,
  onlyDetails
}) => {
  const { fields } = connectedForm

  const options = [
    {
      key: 'none-auto',
      value: 'none-auto',
      text: translate('settings.options.none-auto')
    },
    {
      key: 'correct-auto',
      value: 'correct-auto',
      text: translate('settings.options.correct-auto')
    },
    {
      key: 'all-auto',
      value: 'all-auto',
      text: translate('settings.options.all-auto')
    }
  ]

  return (
    <Form
      connectedForm={ connectedForm }
      onToggle={ toggleHandler }
      readOnly={ readOnly }
      onlyDetails= { onlyDetails }
    >
      <Group>
        { fields.mustTrackTime() }
        { fields.inheritDepartment() }
      </Group>
      <Group>
        { fields.inheritTask() }
        { fields.inheritPauses() }
      </Group>
      { fields.inheritTime({ options }) }
      <Group>
        { fields.arrivalEarly() }
        { fields.arrivalLate() }
      </Group>
      <Group>
        { fields.departureEarly() }
        { fields.departureLate() }
      </Group>
    </Form>
  )
}

export default withForm({
  validate,
  makeViewModel,
  form: 'timetracking'
})(TimetrackingSettingsForm)
