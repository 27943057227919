/**
 * Version part 1: Manual version prefix
 *
 * The current GIT version description, build date, and time are appended to
 * this by the build script if available
 *
 * **Please note**: The version is injected into the page as HTML. Any
 * code contained within the string will be executed, so *so not
 * generate it from user input*!
 */

import v from './build-auto-version-suffix'

let ver = ''
if (v) {
  ver += v
}

export default ver