import * as React from "react"
import { Form, InputNumber } from "antd"
import FieldWithHelp from "./support/FieldWithHelp"

// error={ !!props.meta.error }
const NumberUI = ({ input, label, meta, dataE2E, ...props }) => {
  return (
    <FieldWithHelp {...props}>
      <label>{label}</label>
      <Form.Item
        label={""}
        validateStatus={meta.error ? "error" : "success"}
        hasFeedback={!!meta.error}
        help={meta.error}
      >
        <InputNumber
          value={input.value}
          onChange={input.onChange}
          data-e2e-test-id={dataE2E}
          {...props}
        />
      </Form.Item>
    </FieldWithHelp>
  )
}

function ReadOnlyField({ input: { value }, label, dataE2E }) {
  return (
    <div className="field detailsConnectedFormField">
      <label>{label}</label>
      <div
        className="detailsConnectedFormFieldValue"
        data-e2e-test-id={dataE2E}
      >
        <em style={{ textAlign: "right" }}>{value}</em>
      </div>
    </div>
  )
}

export default readOnly => (!readOnly ? NumberUI : ReadOnlyField)
