const messages = {
  "pbbTemplateForm.newButton": "Add plan building block",
  "pbbTemplateForm.formModalHeader": "Edit plan building block",
  "pbbTemplateForm.fixedOE": "*fixed department chosen",
  "pbbTemplateManager.toggleSortOnName": "Sort on name",
  "pbbTemplateManager.toggleSortOnAbbrv": "Sort on abbreviation",
  "pbbTemplateManager.toggleSortOnStart": "Sort on start time",
  "pbbTemplateManager.toggleSortOnEnd": "Sort on end time",
  "pbbTemplateManager.toggleGroupByType": "Group by type",
  "pbbTemplateManager.availabilities": "Availabilities",
  "pbbTemplateManager.unavailabilities": "Unavailabilities",
  "pbbTemplateManager.fixedOETitle": "Fixed department chosen!",
  "pbbTemplateManager.sortBy": "Sort by",
  "Back to Planner": "Return to Planner",
}

export default messages
