import type { PickerLocale } from "antd/lib/date-picker/generatePicker"
import de from 'antd/lib/date-picker/locale/de_DE'
import en from 'antd/lib/date-picker/locale/en_US'
import rcde from 'antd/node_modules/rc-picker/lib/locale/de_DE'
import rcen from 'antd/node_modules/rc-picker/lib/locale/en_US'
import antdDe from 'antd/lib/locale/de_DE'
import antdEn from 'antd/lib/locale/en_US'

const locales = {
  de: {
    ...de,
    ...antdDe,
    lang: {
      ...rcde,
      ...de.lang,
      locale: "de_DE",
      placeholder: "Datum auswählen",
      rangePlaceholder: ["Startdatum", "Enddatum"],
      today: "Heute",
      now: "Jetzt",
      backToToday: "Zurück zu heute",
      ok: "OK",
      clear: "Leeren",
      month: "Monat",
      year: "Jahr",
      timeSelect: "Zeit auswählen",
      dateSelect: "Datum auswählen",
      monthSelect: "Monat auswählen",
      yearSelect: "Jahr auswählen",
      decadeSelect: "Jahrzehnt auswählen",
      yearFormat: "YYYY",
      dateFormat: "D.M.YYYY",
      dayFormat: "D",
      dateTimeFormat: "D.M.YYYY HH:mm:ss",
      monthFormat: "MMMM",
      monthBeforeYear: true,
      previousMonth: "Letzter Monat (PgUp)",
      nextMonth: "Nächster Minat (PgDown)",
      previousYear: "Letztes Jahr (Strg + links)",
      nextYear: "Nächstes Jahr (Strg + rechts)",
      previousDecade: "Letztes Jahrzehnt",
      nextDecade: "Nächstes Jahrzehnt",
      previousCentury: "Letztes Jahrhundert",
      nextCentury: "Nächstes Jahrhundert",
    },
    timePickerLocale: {
      ...de.timePickerLocale,
      placeholder: "Zeit auswählen",
    },
    dateFormat: "DD.MM.YYYY",
    dateTimeFormat: "DD.MM.YYYY HH:mm:ss",
    weekFormat: "YYYY-w",
    monthFormat: "YYYY-MM",
  },
  en: {
    ...en,
    ...antdEn,
    lang: {
      ...rcen,
      ...en.lang,
      locale: "en_US",
      placeholder: "Select date",
      rangePlaceholder: ["Start date", "End date"],
      today: "Today",
      now: "Now",
      backToToday: "Back to today",
      ok: "OK",
      clear: "Clear",
      month: "Month",
      year: "Year",
      timeSelect: "Select time",
      dateSelect: "Select date",
      monthSelect: "Select month",
      yearSelect: "Select year",
      decadeSelect: "Select decade",
      yearFormat: "YYYY",
      dateFormat: "YYYY/M/D",
      dayFormat: "D",
      dateTimeFormat: "YYYY/M/D HH:mm:ss",
      monthFormat: "MMMM",
      monthBeforeYear: false,
      previousMonth: "Previous month (PgUp)",
      nextMonth: "Next month (PgDown)",
      previousYear: "Previous year (Strg + left)",
      nextYear: "Next year (Strg + right)",
      previousDecade: "Previous decade",
      nextDecade: "Next decade",
      previousCentury: "Previous century",
      nextCentury: "Next century",
    },
    timePickerLocale: {
      ...en.timePickerLocale,
      placeholder: "Select time",
    },
    dateFormat: "YYYY/MM/DD",
    dateTimeFormat: "YYYY/MM/DD HH:mm:ss",
    weekFormat: "YYYY-w",
    monthFormat: "YYYY-MM",
  },
} as {
  [lng: string]: PickerLocale
}
export default locales
