/**
 *
 */

import React from "react"
import { connect } from "react-redux"
import { injectIntl } from "react-intl"

import GlobalMessage from "./GlobalMessage"
import { closeGlobalMessage } from "./redux/actions/globalMessageActions"
import { getGlobalMessageState } from "./redux/selectors/globalMessageSelectors"

const mapStateToProps = state => ({
  globalMessage: getGlobalMessageState(state),
})

const mapDispatchToProps = dispatch => ({
  dispatchCloseGlobalMessage: () => dispatch(closeGlobalMessage()),
})

const GlobalMessageContainer = props => {
  const afterClose = () => props.dispatchCloseGlobalMessage()
  const translate = id => props.intl.formatMessage({ id })
  const getMessage = () => {
    const msg = props.globalMessage
    if (msg && msg.frontendTranslation === true) {
      return {
        ...msg,
        message: msg.message ? translate(msg.message) : "",
        description: msg.description ? translate(msg.description) : "",
      }
    }
    return msg
  }
  const globalMessage = getMessage()
  return <GlobalMessage globalMessage={globalMessage} afterClose={afterClose} />
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(GlobalMessageContainer))
