import * as React from "react"
import { Outlet } from "react-router-dom"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { Layout } from "antd"
import type { Action } from "redux"

import { loadServerSettingsAsync } from "../redux/uiActions.js"

import { isLoggedIn, login, logout } from "../../modules/login/controller"
import LoginModalContainer from "../../modules/login/containers/LoginModalContainer"
import PasswordResetModalContainer from "../../modules/login/containers/PasswordResetModalContainer"
import ErrorContainer from "../../modules/errorHandler/ErrorContainer"
import GlobalMessageContainer from "../../modules/globalMessage/GlobalMessageContainer"
import LoginInfo from "../../apps/LoginInfo/LoginInfo"
import { getUserIdState } from "../../modules/login/redux/selectors/loginSelectors"
import { resetPasswordAsync } from "../../modules/login/redux/actions/loginActions"
import { subscribeToServiceWorkerMessage } from "../../apps/shared/serviceWorker"
import withRouter from "../../shared/withRouter"
import wrapAppIntl from "../../setup/loadIntl"
import startTelemetrySync from "../../apps/appShellUtilities/telemetrySync/startTelemetrySync"

import "./App.less"
import "./Scaffold.css"
import ReloadClient from "./ReloadClient.js"

export class LoginContainerComponent extends React.Component<any, any> {
  private unsubServiceWorkerConsoleLog?: () => void
  private stopTelemetrySync?: () => void

  componentDidMount(): void {
    this.props.dispatchLoadServerSettings()
    this.unsubServiceWorkerConsoleLog = subscribeToServiceWorkerMessage(
      "client-dbg-log",
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      d => console.log(...d.text)
    )
    this.stopTelemetrySync = startTelemetrySync()
  }

  componentWillUnmount(): void {
    if (this.unsubServiceWorkerConsoleLog !== undefined) {
      this.unsubServiceWorkerConsoleLog()
    }
    if (this.stopTelemetrySync !== undefined) {
      this.stopTelemetrySync()
    }
  }

  isRootPath(): boolean {
    return this.props.location !== undefined
      ? this.props.location.pathname === "/"
      : false
  }

  render(): JSX.Element {
    if (navigator.serviceWorker === undefined) {
      alert(this.props.intl.formatMessage({ id: "no-service-worker" }))
    }
    return (
      <>
        <ReloadClient></ReloadClient>
        <LoginInfo
          locale={this.props.intl.locale}
          userId={this.props.userId}
        ></LoginInfo>
        {
          /* Fragments prevent computedMatch warning! */
          !this.props.loggedIn ? (
            this.props.loggedIn === undefined ? null : (
              <div className="main-container">
                <GlobalMessageContainer />
                <ErrorContainer />
                <Layout style={{ minHeight: "100vh" }}>
                  <PasswordResetModalContainer {...({} as any)} />
                  {/* location={this.props.location}> */}
                  <LoginModalContainer />
                </Layout>
              </div>
            )
          ) : (
            <Outlet />
          )
        }
      </>
    )
  }
}

const LoginContainer = connect(
  (state: any) => ({
    loggedIn: isLoggedIn(state),
    userId: getUserIdState(state),
  }),
  (dispatch, { navigate }: any) => ({
    dispatchResetPassword: (username, path) =>
      // TODO: Check the following line:
      dispatch(
        resetPasswordAsync(username, path, navigate) as unknown as Action<any>
      ),
    dispatchLogin: async (username, password, path) =>
      await login(username, password, dispatch, path, navigate),
    dispatchLogout: path => logout(dispatch, path, navigate),
    dispatchLoadServerSettings: () =>
      // TODO: Check the following line:
      dispatch(loadServerSettingsAsync() as unknown as Action<any>),
    changeURL: url => {
      navigate(url)
      // dispatch(push(url))
    },
  })
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
)(withRouter<any>(injectIntl(LoginContainerComponent) as any) as any)

const MainContainer = (): JSX.Element => wrapAppIntl(LoginContainer)
export default MainContainer
