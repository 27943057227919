const types = {
  SELECTCLIENT: 'SELECT_CLIENT_',
  SELECTORGUNIT: 'SELECT_ORG_UNIT_',
  LOADALLACTIVEORGUNITS: 'LOAD_ALL_ACTIVE_ORG_UNITS_',
  LOADALLACTIVECLIENTS: 'LOAD_ALL_ACTIVE_ORG_CLIENTS_',
  LOADALLACTIVEORGUNITSFAILED: 'LOAD_ALL_ACTIVE_ORG_UNITS_FAILED_',
  LOADALLACTIVECLIENTSFAILED: 'LOAD_ALL_ACTIVE_ORG_CLIENTS_FAILED_',
}

export default types
