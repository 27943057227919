import type { IParticipant } from "../../commonInterfaces/processes/IParticipant"

export class ProcessActor {
  constructor(private readonly rawActor: IParticipant) {}

  getId(): string {
    return `${this.rawActor?.userId}`
  }
  getUsername(): string {
    return this.rawActor?.username ?? ""
  }
  getSurname(): string {
    return this.rawActor?.lastname ?? ""
  }
  getFirstname(): string {
    return this.rawActor?.firstname ?? ""
  }
  getName(): string {
    if (this.getFirstname() || this.getSurname()) {
      const title = this.rawActor.title ? `, ${this.rawActor.title}` : ""
      return `${this.getFirstname() ?? ""} ${this.getSurname() ?? ""}${title}`
    } else {
      return this.getUsername()
    }
  }
  getDepartmentName(): string {
    return this.rawActor?.orgunitName ?? ""
  }
  getRole(): string {
    return this.rawActor?.wfRole
  }
}
