import type {
  OnSubmitSuccessFunction,
  OnSubmitSuccessOptions,
  ValidationResult,
  TranslationFunction,
  ConnectFormArguments,
  ConnectFormInjectedArguments,
  TranslationProps,
} from './connectedForm/interfaces'
import ValidationErrors from './connectedForm/ValidationErrors'
import connectForm, {
  SubmissionError,
} from './connectedForm/connectForm'
import Form, { Group, FieldSet } from './connectedForm/Form'

/**
 * Please note that to ensure availability of the redux store, user code *must*
 * use makeConnectForm; connectForm is therefore *not* exported!
 *
 * @param connect The user code's react-redux connect (dependency injection)
 */
const makeConnectForm = ({ connect, reduxFormModule }: ConnectFormInjectedArguments) => <
  FormFields = any,
  CallbackProps extends TranslationProps = any
>(
  args: ConnectFormArguments<FormFields, CallbackProps>
): any => connectForm<FormFields, CallbackProps>(args, { connect, reduxFormModule })

export { makeConnectForm }
export { Form }
export { Group }
export { FieldSet }
export { ValidationErrors }
export { SubmissionError }
export type { ConnectFormArguments }
export type { OnSubmitSuccessFunction }
export type { OnSubmitSuccessOptions }
export type { ValidationResult }
export type { TranslationFunction }