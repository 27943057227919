export function encodeURIComponentsForActions(props: Record<string, string | number | boolean>): string {
  const fixURIComponent = (v: string | number | boolean) => encodeURIComponent(v === undefined ? '' : v)
  return Object.getOwnPropertyNames(props).reduce(
    (acc, key) => {
      acc.push(key + '=' + fixURIComponent(props[key]))
      return acc
    },
    []
  ).join('&')
}
