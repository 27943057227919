import types from './actionTypes'
import { valueAt } from '../../../shared/walkObjectHierarchy'

export default function (
  state = {},
  action
) {
  switch (action.type) {
  case types.TOGGLEMAINMENU:
    return toggleMainMenu(state)
  default:
    return state
  }
}

const toggleMainMenu = state => {
  const oldCollapsed = valueAt(state, ['collapsed'])
  return {
    ...state,
    collapsed: !oldCollapsed
  }
}
