/**
 * Use this instead of antd's Form element *when rendering the
 * form itself*. Use the original to get associated functionality if necessary
 * (Form.Group is integrated in this module)
 *
 * Example:
 * ```
 * import Form, { Group } from '../../../shared/ConnectedForm/ConnectedForm'
 * // ...
 * <Form
 *   error={ error }
 *   formErrorHeader={ translations['form.error'] }
 * >
 *   <Group widths='equal'>
 *     { fields.name }
 *     { fields.number }
 *   </Group>
 * </Form>
 * ```
 *
 * `error` (from the redux-form validation exception) should be supplied if
 * noErrorMessageContainer is false (the default).
 *
 * NOTE: Do not use the ConnectedFormFields directly - they should always be
 *       rendered via the view model.
 */

import * as React from "react"
import { Form, Alert, Row, Col } from "antd"

interface ConnectedFormProps {
  error?: string
  formErrorHeader?: string
  noSubmitOnReturnFix?: boolean
  noErrorMessageContainer?: boolean
  method?: "POST" | "GET" | "DELETE" | "PUT"
  readOnly: boolean
  children: React.ReactNodeArray | React.ReactNode
}
type ConnectedFormComponent = React.FC<ConnectedFormProps>

/**
 * Wraps an antd Form element
 *
 * Most of the API is identical to Form
 *
 * @param method Just for the rendered form: Specify a method to convey intent
 * (e.g., to password managers) when using fetch
 * @param formErrorHeader The (translated) header message for form errors;
 * should usually be _('form.error')
 * @param noSubmitOnReturnFix If true, doesn't supply an invisible
 * submit button (to enable submit-on-return
 * when the buttons are in a separate container)
 * @param noErrorMessageContainer If true, doesn't render a form error
 * container at the top (message is
 * contained in error prop, which is a
 * common pattern with Form)
 */
const ConnectedForm: ConnectedFormComponent = ({
  error,
  formErrorHeader,
  noSubmitOnReturnFix,
  noErrorMessageContainer,
  method = "POST",
  children,
  readOnly,
}) => {
  return (
    <Form
      className={readOnly ? "connected-form readOnly" : "connected-form"}
      method={method}
    >
      {!!error && !noErrorMessageContainer ? (
        <Alert
          type="error"
          message={formErrorHeader}
          description={error ? error : undefined}
          showIcon
        />
      ) : undefined}
      {children}
      {!noSubmitOnReturnFix ? (
        <button
          style={{
            height: 0,
            width: 0,
            padding: 0,
            margin: 0,
            border: 0,
            lineHeight: 0,
            borderImageWidth: 0,
            overflow: "hidden",
            fontSize: 0,
            outline: "none",
            /*
              userFocus: 'none',
              MozUserFocus: 'none',
              */
          }}
          type="submit"
          tabIndex={-1}
        />
      ) : undefined}
    </Form>
  )
}

export default ConnectedForm
// Form.create<ConnectedFormProps & FormComponentProps>()(ConnectedForm)

export const Group = ({
  className,
  children,
}: {
  className?: string
  children: any
}): JSX.Element => {
  return (
    <Row className={className}>
      {
        // eslint-disable-next-line no-mixed-operators
        (
          (children !== undefined && children.length && children) || [children]
        ).map((c: any, idx: any) => (
          <Col {...groupSizeKeys(children)} key={idx}>
            {c}
          </Col>
        ))
      }
    </Row>
  )
}

function groupSizeKeys(children: any) {
  const xs = 24
  const sm = 24
  let md = 24
  let lg = 24
  let xl = 24
  if (children !== undefined && children && children.length) {
    const len = children.length
    xl = 24 / Math.min(len, 6)
    lg = 24 / Math.min(len, 4)
    md = 24 / Math.min(len, 2)
  }
  return { xs, sm, md, lg, xl }
}

export const FieldSet = ({
  label,
  children,
}: {
  label: string
  children: any
}): JSX.Element => (
  <fieldset className="connected-form-fieldset">
    <div className="connected-form-legend">{label}</div>
    <div className="connected-form-fieldset-content">{children}</div>
  </fieldset>
)
