export default {
  de: {
    VacationRequest: "Urlaubsanfrage",
    VacationRequests: "Urlaubsanfragen",
    MyVacationRequest: "Meine Urlaubsanfrage",
    VacationRequestFrom: "Urlaubsanfrage von {name}",
    OlderClosedVacationRequests: "Ältere abgeschlossene Anfragen",
    ArchivedVacationRequests: "Archivierte Anfragen",
    "no-notifications-available": "Keine Meldungen vorhanden",
    "only-from-selected-dept":
      "Nur Meldungen der ausgewählten Abteilung anzeigen",
    "show-all-notifications": "Alle Meldungen anzeigen",
    Employee: "Mitarbeiter",
    Department: "Abteilung",
    Content: "Inhalt",
    Status: "Stand",
    ChangedBy: "Geändert von",
    ChangedOn: "Geändert am",
    "history-by": "von",
    "vacareq-error-occurred": "Ein Fehler ist aufgetreten:",
    "vacareq-unexpected-error-occurred":
      "Ein unerwarteter Fehler ist aufgetreten:",
    "vacareq-cancel-all": "Abbrechen",
    isRequired: "muss ausgefüllt werden",
    yesterday: "gestern",
    today: "heute",
  },
  en: {
    VacationRequest: "Vacation Request",
    VacationRequests: "Vacation Requests",
    MyVacationRequest: "My Vacation Request",
    VacationRequestFrom: "{name}'s Vacation Request",
    OlderClosedVacationRequests: "Closed Requests",
    ArchivedVacationRequests: "Archived Requests",
    "no-notifications-available": "No notifications found",
    "only-from-selected-dept":
      "Only show notifications from selected department",
    "show-all-notifications": "Show all notifications",
    Employee: "Employee",
    Department: "Department",
    Content: "Content",
    Status: "Status",
    ChangedBy: "Changed by",
    ChangedOn: "Changed on",
    "history-by": "by",
    "vacareq-error-occurred": "An error has occurred",
    "vacareq-unexpected-error-occurred": "An unexpected error has occurred",
    "vacareq-cancel-all": "Cancel",
    isRequired: "is required",
    yesterday: "yesterday",
    today: "today",
  },
} as {
  [lng: string]: Record<string, string>
}
