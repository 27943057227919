import { v4 as uuid } from "uuid"

/**
 * This ID *should* be unique. It is - theoretically - not *completely* safe
 * from clashes (inside the same millisecond, if randomBytes generates the
 * same result twice in a row).
 *
 * So: Don't use it for cryptography (obviously). All other uses should be
 * safe.
 */
export default function uniqueId() {
  return "" + new Date().getTime() + "T" + uuid() // crypto.randomBytes(16).toString('base64')
}
