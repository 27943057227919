import { valueAt, isEqual } from '../../../../shared/walkObjectHierarchy'
import types from '../actions/actionTypes'

export default function pbbtemplateManagerReducer(
  state = {},
  action
) {
  switch (action.type) {
  case types.OPENPBBTEMPLATEFORMMODAL:
    return {
      ...state,
      openPBBTemplateFormModal: true,
      pbbTemplateEditId: action.id
    }
  case types.CLOSEPBBTEMPLATEFORMMODAL:
    return {
      ...state,
      openPBBTemplateFormModal: false,
      pbbTemplateEditId: undefined,
      validationFailed: false,
      validationErrors: undefined
    }
  case types.SAVEPBBTEMPLATE:
    return savePBBTemplate(state, action)
  case types.SAVEPBBTEMPLATEFAILED:
    return {
      ...state,
      saveFailed: true,
      saveError: action.error
    }
  case types.CREATEPBBTEMPLATE:
    return createPBBTemplate(state, action)
  case types.CREATEPBBTEMPLATEFAILED:
    return {
      ...state,
      createFailed: true,
      createError: action.error
    }
  case types.DELETEPBBTEMPLATE:
    return deletePBBTemplate(state, action)
  case types.DELETEPBBTEMPLATEFAILED:
    return {
      ...state,
      deleteFailed: true,
      deleteError: action.error
    }
  case types.LOADALLPBBTEMPLATES:
    return loadAll(state, action)
  case types.LOADPBBTEMPLATESFAILED:
    return {
      ...state,
      loadFailed: true,
      loadError: action.error
    }
  default:
    return state
  }
}

function loadAll(state, action) {
  const byId = action.allPBBTemplates.reduce(
    (acc, pbbTemplate) => {
      acc[pbbTemplate.id] = pbbTemplate
      return acc
    },
    {}
  )
  const oldById = valueAt(state, [ 'pbbTemplates', 'byId' ]) || {}
  if (isEqual(oldById, byId)) {
    return state
  }
  return {
    ...state,
    pbbTemplates: { byId },
  }
}

function savePBBTemplate(state, action) {
  const oldById = valueAt(state, ['pbbTemplates', 'byId']) || {}
  const {
    pbbTemplate,
    oldId
  } = action.data
  const byId = { ...oldById }
  delete byId[oldId]
  return {
    ...state,
    openPBBTemplateFormModal: false,
    pbbTemplateEditId: undefined,
    pbbTemplates: {
      byId: {
        ...byId,
        [pbbTemplate.id]: pbbTemplate
      }
    },
    validationFailed: false,
    validationErrors: undefined
  }
}

function createPBBTemplate(state, action) {
  const byId = valueAt(state, ['pbbTemplates', 'byId']) || {}
  return {
    ...state,
    openPBBTemplateFormModal: false,
    pbbTemplateEditId: undefined,
    pbbTemplates: {
      byId: {
        ...byId,
        [action.pbbTemplate.id]: action.pbbTemplate
      }
    },
    validationFailed: false,
    validationErrors: undefined
  }
}

function deletePBBTemplate(state, action) {
  const oldById = valueAt(state, ['pbbTemplates', 'byId']) || {}
  const byId = { ...oldById }
  delete byId[action.id]
  return {
    ...state,
    openPBBTemplateFormModal: false,
    pbbTemplateEditId: undefined,
    pbbTemplates: {
      byId: { ...byId }
    },
    validationFailed: false,
    validationErrors: undefined
  }
}
