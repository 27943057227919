const SUBMITURL = '/rest/supportchat/generateASingleReply'
const CLOSEURL = '/rest/supportchat/closeConversation'
const SCOREURL = '/rest/supportchat/logUserScore'
const ENABLEDURL = '/rest/supportchat/isEnabled'

export default function makeBackendService(): BackendService {
  return new BackendService()
}

export class BackendService {

  async closeConversation(
  ): Promise<{ reply: string | null }> {
    const r = await fetch(
      CLOSEURL,
      {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json; charset=utf-8'
        },
        method: 'POST',
        body: JSON.stringify({ async: false, })
      }
    )
    if (r.status !== 200) {
      return { reply: "Leider ist ein Fehler aufgetreten", }
    } else {
      return { reply: null, }
    }
  }

  async submitChatMessage(
    message: string
  ): Promise<{ reply: string, correlationId: string }> {
    const r = await fetch(
      SUBMITURL,
      {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json; charset=utf-8'
        },
        method: 'POST',
        body: JSON.stringify({ async: false, message })
      }
    )
    const json = await r.json()
    if (r.status !== 200) {
      return { reply: "Leider ist ein Fehler aufgetreten", correlationId: "" }
    } else {
      return { reply: json.reply, correlationId: json.correlationId }
    }
  }

  async logUserScore(score: {
    usefulnessScore: number
    correctnessScore: number
    remarks: string
    correlationId: string
  }): Promise<{ reply: string, correlationId: string }> {
    const r = await fetch(
      SCOREURL,
      {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json; charset=utf-8'
        },
        method: 'POST',
        body: JSON.stringify({ ...score, async: false })
      }
    )
    const json = await r.json()
    return { reply: json.reply, correlationId: json.correlationId }
  }

  async enabled(clientId: string): Promise<boolean> {
    const r = await fetch(
      `${ENABLEDURL}?${new URLSearchParams({ clientId }).toString()}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
    const json = await r.json()
    return json?.enabled ?? false
  }

}
