/**
 * Timezone-agnostic DateTime representation
 *
 * Months are counted from 1 (unlike in moment and JS Date)
 *
 * Please note: You cannot calculate durations (diff) without converting to
 * timezone-aware objects, because for durations you must always take special
 * cases (such as the DST switch-over) into account.
 *
 * PureDates are mainly there for capturing, saving, and displaying exact
 * dates and times without worrying about timezones (especially in the context
 * of persistence).
 */

import type { SimpleDateTimeObject } from '../time-utils'

export default class PureDateTime {

  public ['constructor']: PureDateTime
  public isMEP24PureDateTime = true
  public typeName = 'PureDateTime'
  private value: SimpleDateTimeObject

  /**
   * @param date A JS Date object
   *
   * Note: this is highly problematic, since you can't really control the
   *       date's timezone! Only use if you are 100% sure that you're not
   *       just ignoring the resulting problems!
   */
  static fromJSDate(date: Date) {
    if (date === undefined) {
      date = new Date()
    }
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hour = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()
    /*
    console.log(
      "WARNING: Using a JS Date to construct a PureDateTime is a terrible idea!"
    )
    */
    return new PureDateTime({
      year, month, day, hour, minute, second
    })
  }

  /**
   * Convert from JS Date and preserve the local time zone; same caveats apply,
   * and for PureDateTimes, this actually has exactly the same result as
   * fromJSDate.
   */
  static fromLocalJSDate(date: Date) {
    return this.fromJSDate(date)
  }

  /**
   * Construct a PureDateTime instance from an object; cf. the constructor's
   * documentation for explanations of the parameters
   */
  static fromObject<T extends PureDateTime = PureDateTime>(obj = {}) {
    return new this(obj) as T
  }

  /**
   * Construct a PureDateTime instance from a JSON serialisation (a string).
   * Identical to fromString.
   */
  static fromJSON(json: string) { return this.fromString(json) }

  /**
   * Construct a timezone-unaware date time object. Most of the parameters are
   * simply integers.
   *
   * Maximum resolution is seconds
   *
   * **Please do not use the constructor directly!** Always use the
   * static factory methods!
   *
   * @param month The month in "natural" sequence (i.e., starting at 1 for
   *        January, unlike JS Date's months)
   *
   * Defaults:
   * - The date defaults to today as per the local time zone (note that this can
   *   differ from the client to the server!)
   * - The hours, minutes and seconds default to 0
   */
  constructor({
    year,
    month,
    day,
    hour = 0,
    minute = 0,
    second = 0
  }: Partial<SimpleDateTimeObject> = {}) {
    const now = new Date()
    if (!year) { year = now.getFullYear() }
    if (!month) { month = now.getMonth() + 1 }
    if (!day) { day = now.getDate() }
    this.value = { year, month, day, hour, minute, second }
  }

  /**
   * Return a timestamp for *now*; note the potential mismatch if you use this
   * while another timezone is involved!
   */
  static now() {
    return this.fromJSDate(new Date())
  }

  /**
   * Check whether format is basically correct; doesn't check every detail
   * (more of an educated guess, really)
   */
  static isValidJSON(json: string) {
    return (
      json
        && json.length === 'yyyy-MM-dd HH:mm:ss'.length
        && (/^\d\d\d\d-\d\d-\d\d \d\d:\d\d:\d\d$/.exec(json))
        ? true
        : false
    )
  }

  /**
   * Construct from string, must be "yyyy-MM-dd HH:mm:ss", time and, if
   * time is provided, seconds are optional
   */
  static fromString(s: string) {
    const [date, time] = s.split(' ')
    const d = this.dateFromString(date)
    d.setTimeFromString(time)
    return d
  }
  static fromISOString(s: string) { return this.fromString(s) }

  /**
   * Create a new date (no time) from the param string
   *
   * @param date "YYYY-MM-DD" format string
   */
  static dateFromString<T extends PureDateTime = PureDateTime>(date: string) {
    const [year, month, day] = date.split('-')
    return new this({
      year: parseInt(year, 10),
      month: parseInt(month, 10),
      day: parseInt(day, 10)
    }) as T
  }

  /**
   * Set the time from param string
   *
   * @param time "hh:mm:ss" format string (every component is
   *        optional)
   */
  setTimeFromString(time: string) {
    const [hour = '0', minute = '0', second = '0'] = time.split(':')
    this.setHour(parseInt(hour, 10))
    this.setMinute(parseInt(minute, 10))
    this.setSecond(parseInt(second, 10))
  }

  /**
   * Clones this instance; preserves type
   */
  clone() {
    return PureDateTime.fromObject(this.toObject())
  }

  /**
   * Clones this instance; preserves type (identical to `clone()`)
   */
  copy() {
    return this.clone()
  }

  /**
   * Adds one or more days and returns the new instance; preserves type and time
   * (if any)
   *
   * @param numberOfDays The number of days to add; defaults to 1
   */
  addDays<T extends PureDateTime = PureDateTime>(numberOfDays = 1) {
    const n = this.clone()
    // XXX relying on JS Date auto conversion
    //     we temporarily feed this Date a potentially incorrect day:
    const d = new Date(n.getYear(), n.getMonth() - 1, n.getDay() + numberOfDays)
    n.setYear(d.getFullYear())
    n.setMonth(d.getMonth() + 1)
    n.setDay(d.getDate())
    return n as T
  }

  /**
   * Returns a structure with the primary attributes of this PureDateTime
   * object. Note that, as always, the month is 1-indexed!
   */
  toObject() {
    return {
      type: this.typeName,
      year: this.getYear(),
      month: this.getMonth(),
      day: this.getDay(),
      hour: this.typeName === 'PureDateTime' ? this.getHour() : 0,
      minute: this.typeName === 'PureDateTime' ? this.getMinute() : 0,
      second: this.typeName === 'PureDateTime' ? this.getSecond() : 0
    }
  }

  eql(other: PureDateTime) {
    return this.toString() === other.toString()
  }

  lt(other: PureDateTime) {
    return this.toSortableString() < other.toSortableString()
  }

  lte(other: PureDateTime) {
    return this.toSortableString() <= other.toSortableString()
  }

  gt(other: PureDateTime) {
    return this.toSortableString() > other.toSortableString()
  }

  gte(other: PureDateTime) {
    return this.toSortableString() >= other.toSortableString()
  }

  /**
   * This returns a sortable string representation
   */
  toSortableString() {
    return this.toString()
  }

  toString() {
    return `${this.dateToString()} ${this.timeToString()}`
  }

  dateToString() {
    const p = (k: keyof SimpleDateTimeObject) => this.pad(k)
    return `${p('year')}-${p('month')}-${p('day')}`
  }

  timeToString() {
    const p = (k: keyof SimpleDateTimeObject) => this.pad(k)
    return `${p('hour')}:${p('minute')}:${p('second')}`
  }

  /**
   * Same as `toString`; use in preparation for JSON serialisation
   * (used automatically by JSON.stringify), and for serialisation to a DB.
   */
  toJSON() { return this.toString() }

  /**
   * Convert to regular JS Date object - loses time zone specifics as well as
   * milliseconds!
   *
   * Only use this if you know exactly what you're doing!
   */
  toJSDate() {
    return new Date(
      this.getYear(), this.getMonth() - 1, this.getDay(),
      this.getHour(), this.getMinute(), this.getSecond()
    )
  }

  setYear(year: number) { this.value.year = year }
  setMonth(month: number) { this.value.month = month }
  setDay(day: number) { this.value.day = day }
  setHour(hour: number) { this.value.hour = hour }
  setMinute(minute: number) { this.value.minute = minute }
  setSecond(second: number) { this.value.second = second }

  getYear() { return this.value.year }
  getMonth() { return this.value.month }
  getDay() { return this.value.day }
  getHour() { return this.value.hour }
  getMinute() { return this.value.minute }
  getSecond() { return this.value.second }

  private pad(key: keyof SimpleDateTimeObject) {
    if (key === 'year') {
      return this.value[key]
    }
    const val = this.value[key]  // TODO: improve
    if (val < 10) {
      return `0${val}`
    } else {
      return '' + val
    }
  }

}
