import React from "react"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import {
  isLoggedIn,
  login,
  logout,
  getUserClientAndOrgunitId,
} from "../../modules/login/controller"
import MobileApp from "../../apps/MobileApp/MobileApp"
import {
  getUserIdState,
  getUsernameState,
} from "../../modules/login/redux/selectors/loginSelectors"
import { resetPasswordAsync } from "../../modules/login/redux/actions/loginActions"
import {
  loadUserSettingsAsync,
  saveUserSettingsAsync,
} from "../../modules/userSettings/controller"
import type { Action } from "redux"
import withRouter from "../../shared/withRouter"

export class Mobile extends React.Component<any, any> {
  render(): JSX.Element {
    const { clientId, orgunitId } = this.props.userClientAndOrgunitId
    return (
      <MobileApp
        isLoggedIn={this.props.loggedIn}
        userId={this.props.userId}
        username={this.props.username}
        userClientId={clientId}
        userDepartmentId={orgunitId}
        login={this.props.dispatchLogin}
        resetPassword={this.props.dispatchResetPassword}
        logout={this.props.dispatchLogout}
        locale={this.props.intl.locale}
        loadUserSettings={loadUserSettingsAsync}
        saveUserSettings={saveUserSettingsAsync}
      />
    )
  }
}

export default connect(
  (state: any) => ({
    loggedIn: isLoggedIn(state),
    userId: getUserIdState(state),
    username: getUsernameState(state),
    userClientAndOrgunitId: getUserClientAndOrgunitId(state),
  }),
  (dispatch, { navigate }: any) => ({
    dispatchResetPassword: (username, path) =>
      // TODO: Check the following line:
      dispatch(
        resetPasswordAsync(username, path, navigate) as unknown as Action<any>
      ),
    dispatchLogin: async (username, password, path) =>
      await login(username, password, dispatch, path, navigate),
    dispatchLogout: path => logout(dispatch, path, navigate),
  })
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
)(withRouter<any>(injectIntl(Mobile) as any) as any)
