/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { createSelector } from "reselect"
import { valueAt } from "../../../../shared/walkObjectHierarchy"
import moment from "moment"

const allHolidaysByYearAndState = state =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  valueAt(state, ["settings", "holidays", "byYearAndState"])

/**
 * This selector requires an argument, and therefore returns a function object!
 *
 * @param date The date for which the holiday name should be returned
 * @param stateKey The state key (e.g., DE@BW)
 *
 * @return The name of the holiday (untranslated, and there are no translations)
 *         or *undefined*
 *
 * Usage:
 *
 *   const getHoliday = newHolidayGetter(state) // probably in mapStateToProps
 *   const name = getHoliday(date, 'IT@IT') // probably while rendering
 *
 * Cf.:
 * https://github.com/reduxjs/reselect/blob/master/README.md#q-how-do-i-create-a-selector-that-takes-an-argument
 */
export const newHolidayGetter = createSelector(
  allHolidaysByYearAndState,
  byYearAndState => (date, stateKey) => {
    const year = date.getFullYear()
    const dateKey = moment(date).format("YYYYMMDD")
    const forYear = byYearAndState[year] || {}
    const forState = forYear[stateKey] || {}
    return forState[dateKey]
  }
)

/**
 * Return a list of years that have holidays assigned
 */
export const getHolidayYears = createSelector(
  allHolidaysByYearAndState,
  holidays => Object.keys(holidays || {})
)

const getSettingsConf = state => valueAt(state, ["settings", "settingsConf"])
export const getSettingsConfState = createSelector(getSettingsConf, conf =>
  conf !== undefined ? conf : {}
)

const getFormSettings = (state, form) =>
  valueAt(state, ["settings", "byForm", form, "byFieldName"])
export const getFormSettingsState = createSelector(getFormSettings, settings =>
  Object.values(settings || [])
)
