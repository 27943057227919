/* eslint-disable id-blacklist */
/**
 * Central React field type registry. Register new fields and their rendered
 * versions here (you may also need to add them to CoreForm.types)!
 *
 * Consult the list below for available types. Since this may change quickly,
 * separate documentation of the list is **discouraged**!
 */

import ColorUI from './formfields/ColorUI'
import SelectUI from './formfields/SelectUI'
import TextAreaUI from './formfields/TextAreaUI'
import CheckboxUI from './formfields/CheckboxUI'
import InputUI from './formfields/InputUI'
import DateUI from './formfields/DateUI'
import NumberUI from './formfields/NumberUI'
import TimeUI from './formfields/TimeUI'
import RadioGroupUI from './formfields/RadioGroupUI'
import CheckboxGroupUI from './formfields/CheckboxGroupUI'
import HoursUI from './formfields/HoursUI'

/**
 * Note: Every type entered here (on the left side) must conform to a
 * VALIDFIELDTYPES entry in `CoreForm.types`!
 */
export default {
  color: ColorUI,
  time: TimeUI,
  hours: HoursUI,
  list: SelectUI,
  boolean: CheckboxUI,
  checkboxGroup: CheckboxGroupUI,
  text: TextAreaUI,
  string: InputUI,
  number: NumberUI,
  date: DateUI,
  default: InputUI,
  radioGroup: RadioGroupUI
}
