import type { ColorJSON } from "./Color"
import Color from "./Color"

export default class EntryClassification {

  constructor(
    private readonly id: string,
    private readonly name: string,
    private readonly abbreviation: string,
    private readonly color?: Color,
    private readonly isActivity: boolean = true
  ) { }

  static fromJSON(json: Record<string, string | ColorJSON | boolean>): EntryClassification {
    return new EntryClassification(
      json.id as string,
      json.name as string,
      json.abbreviation as string,
      Color.fromJSON(json.color as ColorJSON),
      !!json.isActivity
    )
  }

  getId(): string { return `${this.id}` }
  getName(): string { return this.name }
  getAbbreviation(): string { return this.abbreviation }
  getColor(): Color | undefined { return this.color }

  appliesToFullDays(): boolean { return !this.isActivity }
  appliesToPartsOfDays(): boolean { return this.isActivity }

  getKey(): string {
    // XXX Color or name changes don't signify a difference!
    return this.getId()
  }

  toJSON(): Record<string, any> {
    return {
      id: this.id,
      name: this.name,
      abbreviation: this.abbreviation,
      color: this.color?.toJSON(),
      isActivity: this.isActivity
    }
  }

  clone(): EntryClassification {
    return new EntryClassification(
      this.id,
      this.name,
      this.abbreviation,
      this.color?.clone(),
      this.isActivity
    )
  }

}