const messages = {
  "users.confirmDeactivate2fa":
    "Confirm the deactivation of the selected User's 2fa with your password.",
  "users.tooltip.disable2fa": "Deactivate second factor",
  "users.edit-username": "Change username",
  "users.heading": "Employees overview",
  "users.username": "Username",
  "users.usernameAsMail": "Username (email)",
  "users.isAdmin": "Admin?",
  "users.optionalRegistrationInfos":
    "Please fill out the following information for personalized mails.",
  "users.registrationEntryDateInfo":
    "Set this value to change the initial reassignment date. Today's will be used by default.",
  "users.select": "Select",
  "users.forAll": "All selected",
  "users.name": "Name",
  "users.department": "Department (OU)",
  "users.orgaIsDeleted": "(gelöscht)",
  "users.orgaIsArchived": "(archived)",
  "users.branch": "Branch",
  "users.location": "Location",
  "users.hasContract": "Contract",
  "users.hasAutoplan": "Autoplan",
  "users.qualifications": "Qualifications",
  "users.roles": "Roles",
  "users.checkCol": "Selected",
  "users.rolesincontext": "Roles for the selected organisational unit",
  "users.active-login": "Active",
  "users.active-no-login": "Aktive, no login",
  "users.deleted": "Deleted",
  "users.candidate": "Invitationmail sent",
  "users.hidden": "Hidden",
  "users.login": "Login",
  "users.client": "Client",
  "users.addUser": "Add employee",
  "users.addCandidateUser": "Invite employee",
  "users.editUser": "User",
  "users.editUsername": "Mail(adress)",
  "users.employee": "Employee",
  "users.hide": "Hide in planer",
  "users.show": "Show in planer",
  "users.lock": "Disable login",
  "users.unlock": "Enable login",
  "users.activate": "Activate",
  "users.mailOnChange": "Send password recovery mail?",
  "users.renameOnRestore": "New username on restore",
  "users.renameOnRestoreHelp":
    "The username/email of the user was already re-used. Rename the user here.",
  "users.restoreFailuer": "Restoration failed",
  "users.resendReactivate": "Send new reactivation",
  "users.editCandidateUser": "Edit candidate",
  "users.confirmDeleteHeader": "Really delete user?",
  "users.confirmRestoreHeader": "Really restore user?",
  "users.confirmLockHeader": "Really lock user?",
  "users.confirmUnlockHeader": "Really unlock user?",
  "users.confirmActivateHeader": "Really activate user?",
  "users.showConfirm.header": "Send mail with password link to users?",
  "users.tooltip.add": "Add a user without an active login account.",
  "users.tooltip.save": "Save changes.",
  "users.tooltip.delete": "Delete a user.",
  "users.tooltip.restore": "Restore a user.",
  "users.tooltip.hide": "Hide user in planer.",
  "users.tooltip.show": "Show user in planer.",
  "users.tooltip.lock": "Disable login.",
  "users.tooltip.unlock": "Enable login.",
  "users.tooltip.activate": "Firsttime activiation.",
  "users.close": "Close",
  "users.add": "Add",
  "users.createdBy": "Created by",
  "users.createdAt": "Created at",
  "users.expiresAt": "Expires at",
  "users.workHoursAccount.deleted-corrections": "Deleted Corrections",
  "users.workHoursAccount.hideDeletedCorrections": "Hide deleted corrections",
  "users.workHoursAccount.showDeletedCorrections": "Show deleted corrections",
  "users.workHoursAccount.deleted-absolute-correction":
    "Absolute correction on {date} by {by}: {value} ({comment}); deleted by {deletedBy} on {deletionDate}",
  "users.workHoursAccount.deleted-relative-correction":
    "Relative correction on {date} by {by}: {value} ({comment}); deleted by {deletedBy} on {deletionDate}",
  "users.account.enable-deletion": "Delete correction",
  "users.workHoursAccount.hideDeletedCorrections": "Hide deleted corrections",
  "users.workHoursAccount.showDeletedCorrections": "Show deleted corrections",
  "users.workHoursAccount.dayRow.deleteCorrection": "delete",
  "users.workHoursAccount.dayRow.confirmCorrectionDeletion":
    "Really delete correction?",
  "users.account.noContract":
    "The employee has no contract. No data can be displayed.",
  "users.workHoursAccount": "Work hours account",
  "users.workHoursAccount.selectYear": "Select year",
  "users.workHoursAccount.cachedSum":
    "This sum is the result of cached values from previous weeks and can therefore differ from the correct sum.",
  "users.workHoursAccount.outdatedWeek":
    'A (!) displayed after "Start" and a striped background means the cache for the relevant timeframe was not updated after the most recent changes. The displayed sums are possibly outdated. Please try to reload this page in 1 - 2 minutes.',
  "users.workHoursCorrection.title": "Work hours account: Correction",
  "users.workHoursCorrection.createButton": "Add correction",
  "users.workHoursCorrection.closeButton": "Show work hours account",
  "users.workHoursCorrection.effectiveDate": "Effective date",
  "users.workHoursCorrection.isSubtraction": "Add or subtract time",
  "users.workHoursCorrection.isAbsolute": "Absolute change",
  "users.workHoursCorrection.paytime": "Paytime",
  "users.workHoursCorrection.overtime": "Overtime",
  "users.workHoursCorrection.comment": "Comment",
  "users.workHoursCorrection.pause": "Pause",
  "users.workHoursCorrection.correction": "Correction",
  "users.workHoursCorrection.contract": "Contract",
  "users.workHoursCorrection.overtimeShort": "OT",
  "users.workHoursCorrection.paytimeShort": "PT",
  "users.workHoursCorrection.overtimeDiffShort": "diff OT",
  "users.workHoursCorrection.paytimeDiffShort": "diff PT",
  "users.workHoursCorrection.running": "Running",
  "users.workHoursCorrection.dateHeader": "Date",
  "users.workHoursCorrection.PlanHeader": "Plan",
  "users.workHoursCorrection.SumDayHeader": "Sum day",
  "users.workHoursCorrection.SumRunningHeader": "Sum week OT",
  "users.workHoursCorrection.absolute": "absolute",
  "users.workHoursCorrection.holiday": "Holiday",
  "users.leaveAccount": "Leave account",
  "users.leaveAccount.cacheWarning":
    "Some vacations are still being calculated and might not appear.",
  "users.leaveAccount.selectYear": "Select year",
  "users.leaveAccount.yearlyLeaveAvailable": "Yearly leave abailable",
  "users.leaveAccount.preYearRemains": "Remaining leave from previous year",
  "users.leaveAccount.yearlyLeaveTake": "Leave taken",
  "users.leaveAccount.yearlyLeaveRemains": "Leave remainder",
  "users.leaveAccount.date": "Date",
  "users.leaveAccount.action": "Action",
  "users.leaveAccount.leave": "Leave",
  "users.leaveAccount.paytime": "PT",
  "users.leaveAccount.overtime": "OT",
  "users.leaveAccount.remainder": "Remainder",
  "users.leaveAccount.yearBegin": "Yearly leave",
  "users.leaveAccount.prevousRemains": "Remainder previous year",
  "users.leaveAccount.contractChange": "Yearly leave acc. contract",
  "users.leaveAccount.leaveTaken": "Leave taken",
  "users.leaveAccount.relativeCorretions": "Relative correction",
  "users.leaveAccount.absoluteCorrection": "Absolute correction",
  "users.leaveCorrection.title": "Leave account: Correction",
  "users.leaveCorrection.createButton": "Add correction",
  "users.leaveCorrection.effectiveDate": "Effective date",
  "users.leaveCorrection.isSubtraction": "Add or subtract days",
  "users.leaveCorrection.isAbsolute": "Absolute change",
  "users.corrections.isAbsolute.help":
    "The account is set to the exact value given. All other entries or values from this day are overriden.",
  "users.leaveCorrections.isAbsolute.help":
    "The account is set to the exact value given. All other entries or values from this day are overriden.",
  "users.leaveCorrection.useHours": "Use hours",
  "users.leaveCorrection.leaveDays": "Leave days (up to two decimal points)",
  "users.leaveCorrection.leaveHoursAndMinutes": "Leave hours",
  "users.leaveCorrection.tooManyDecimals": "Only two decimals allowed.",
  "users.leaveCorrection.comment": "Comment",
  "users.leaveCorrection.absolute": "absolute",
  "users.leaveCorrection.wholeDays": "Only whole days",
  "users.leaveCorrection.below90": "Maximum of 90 days",
  "users.roleassignments": "Role assigments",
  "users.removeLocalRoles": "Remove local roles",
  "users.userroles": "User roles",
  "users.localRoles": "Local roles for ",
  "users.remoteRoles": "Roles in different organisational units",
  "users.clientroles": "Roles for this client",
  "users.selectOrgunit": "Please select a organisational unit.",
  "users.addType": "Type of user",
  "users.fullUser": "Full user",
  "users.candidateUser": "Candidate user",
  "Candidate user": "Candidate user",
  "users.invite.descr":
    "An invited user can`t be used in the planner. The user must first use the registration mail to set a password and thereby activate the account.",
  "users.add.descr":
    "A directly added user can be used in the planner at once. But the account is inactive until the reactivation mail is sent and a password is set.",
  "users.qualificationAssignments": "Qualification assignments",
  "users.masterData": "Master data",
  "users.entryDate": "Entry date",
  "users.firstname": "Firstname",
  "users.lastname": "Lastname",
  "users.prefix": "Title",
  "users.abbrv": "Abbreviation",
  "users.gender": "Gender",
  "users.persnr": "Personal number",
  "users.birthday": "Birtdate",
  "users.contact": "Contact",
  "users.str": "Street",
  "users.city": "City",
  "users.postal": "Postalcode",
  "users.country": "Country",
  "users.tel": "Phone",
  "users.mobile": "Mobile",
  "users.importkey": "Importkey",
  "users.photo": "Photo",
  "users.male": "male",
  "users.female": "female",
  "users.na": "Not specified",
  "users.status": "Status",
  "users.inactive": "Deactived",
  "users.reactivated": "Reactivated, mail sent",
  "users.unknown": "Unknown user state",
  "users.invited": "Invited, mail sent",
  "users.managed": "Managed",
  "users.modal.warn": "Discard unsaved changes?",
  "users.edit.empty": "No permission to show any further employee data.",
  "users.contracts.heading": "Workcontracts",
  "users.addContract": "New workcontract",
  "users.contracts.action": "Action",
  "users.contracts.weeklyhours": "Weekly hours (hours)",
  "users.contracts.yearlyvacationdays": "Yearly vacation days",
  "users.contracts.collectiveAgreement": "Contract base",
  "users.contracts.modelWeekHeader": "Model week",
  "users.contracts.modelWeekText":
    'Insert regualr working hours here if you want to use the option "model week" in the calculation settings.',
  "users.contracts.hoursMo": "Monday",
  "users.contracts.hoursTu": "Tuesday",
  "users.contracts.hoursWe": "wednesday",
  "users.contracts.hoursTh": "Thursday",
  "users.contracts.hoursFr": "Friday",
  "users.contracts.hoursSa": "Saturday",
  "users.contracts.hoursSu": "Sunday",
  "users.contracts.vacationDaysFirstYear": "Vacation first year",
  "users.contracts.startVacation": "Begin yearly vacation",
  "users.contracts.startVacation.base": "Basesettings",
  "users.contracts.startVacation.january": "01.01. of year",
  "users.contracts.startVacation.entry": "Entrydate",
  "users.contracts.vacationDays": "Yearly vacation days",
  "users.contracts.hiatus": "Disruption of the current employment relationship",
  "users.contracts.hideUser": "Hide employee",
  "users.contracts.validFrom": "Valid from",
  "users.contracts.validUntil": "Valid until",
  "users.contracts.updatedAt": "Edit date",
  "users.contracts.edit": "Workcontract",
  "users.contracts.invalidContract": "Invalid contract",
  "users.contracts.overrideweeklyhours": "Override weekly hours?",
  "users.contracts.overrideyearlyvacationdays":
    "Override yearly vacation days?",
  "users.contracts.yearlyvacationdays": "Yearly vacation days",
  "users.contracts.overrideyellowlimit": "Override limit for yellow light?",
  "users.contracts.yellowlimit": "Limit for yellow light (overtime)",
  "users.contracts.yellowlimitnegative": "Limit for yellow light (undertime)",
  "users.contracts.overrideredlimit": "Override limit for red light?",
  "users.contracts.redlimit": "Limit for red light (overtime)",
  "users.contracts.redlimitnegative": "Limit for red light (undertime)",
  "users.contracts.overridesHeader": "Collective agreement customization",
  "users.contracts.overridesText":
    "Override selected fields from the collective agreement.",
  "users.contracts.regularworkdaysHeader": "Regular workdays for calculations",
  "users.contracts.regularworkdaysText":
    "Select regular workdays for calculations or toggle to ignore them for calculations.",
  "users.contracts.or": "or",
  "users.contracts.regularworkdays": "Regular workdays",
  "users.contracts.neveruserregularworkdaysforcalculations":
    "Never use regular workdays for calculations",
  "users.contracts.showObsolete": "Show obsolete versions",
  "users.contracts.caWeeklyHours": "Contract base weekly hours: ",
  "users.contracts.caVacationDays": "Contract base yearly vacation days: ",
  "selected collective agreement requires defined regular workdays":
    "The selected collective agreement requires defined regular workdays.",
  "selected collective agreement has no effective settings for this contract":
    "The selected collective agreement can not provide valid settings for the set contract start date. Calculations can only be performed if valid settings are available.",
  "users.tempassignments.heading": "Temporary assignments",
  "users.tempassignments.edit": "Temporary assignment",
  "users.tempassignments.toOrgUnit": "To organisational unit",
  "users.addTempAssignment": "Add temporary assignment",
  "users.reassignments.heading": "Reassigments",
  "users.reassignments.edit": "Reassignments",
  "users.reassignment.from": "From",
  "users.reassignment.to": "To",
  "users.reassignment.when": "When",
  "users.reassignment.to.label": "To (Department = Target of reassignment)",
  "users.reassignment.when.label": "When (Date of reassignment)",
  "users.reassignment.deleteConfirm": "Really delete this reassignment?",
  "users.reassignments.futureReassignment": "Will be reassigned at: ",
  "users.reassignments.futureTo": " to: ",
  "users.addReassignment": "Add reassignment",
  "users.addUserQualification": "Add user qualification",
  "users.showQualifications": "Show qualifications",
  "users.qualifications.qualification": "Qualification",
  "users.qualifications.effectiveDate": "Effective date",
  "users.qualifications.isPrimary": "Is primary qualification?",
  "users.qualifications.primaryEffectiveDate": "Primary from",
  "users.qualifications.primaryOverlapHeader": "Warning: Primary from overlap!",
  "users.qualifications.primaryOverlapText":
    "The chosen primary from date overlaps with another user qualification. The currently edited user qualification will have priority on save.",
  "users.qualifications.edit": "Qualification",
  "users.qualifications.invalid": "Invalid",
  "users.qualifications.obsolete": "Old version",
  "users.qualifications.error.missingEffectiveDate":
    "Please select an effective date.",
  "users.qualifications.error.missingQualification":
    "Please select a qualification.",
  "users.qualifications.error.missingPrimaryEffectiveDate":
    "Please select an effective date.",
  "users.orgunit": "Organisational unit",
  "users.showDescendants": "Show from children",
  "users.showHidden": "Show hidden",
  "users.showDeleted": "Show deleted",
  "users.unassignedUsers": "Unassigned users",
  "users.misassignedUsers": "Misassigned users",
  "users.settings.heading": "Settings",
  "users.error": "Error",
  "users.error.missingField": "Please enter a value",
  "users.error.dateInPast": "The date mustn`t be in the past.",
  "users.error.editing":
    "Error in the form. Please check the highlighted fields.",
  "users.error.username": "The username is not available.",
  "users.error.adding": "User could not be added.",
  "users.error.missingUsername": "Please add a username.",
  "users.error.wrongEmail": "The username must be a valid email.",
  "users.error.emailInUse": "The username is already in use.",
  "users.error.abbrvTooLong": "The abbreviation is too long.",
  "users.error.roleassigment": "Error with the role assignment.",
  "users.error.missingContext": "No client or organisational unit selected.",
  "users.contracts.error.missingWeeklyHours": "Please add the weekly hours.",
  "users.contracts.error.missingYearlyvacationdays":
    "Please add the yearly vacation days.",
  "users.contracts.error.missingVacationDaysFirstYear":
    "Please add the vacation days for the first year.",
  "users.contracts.error.missingStartVacation":
    "Please add the begin of the yearly vacation.",
  "users.contracts.error.missingVacationDays":
    "Please add the yearly vacation days.",
  "users.contracts.error.missingValidFrom": "Please add the valid from date.",
  "users.contracts.error.or": "Please select one of the options.",
  "users.contracts.error.required": "A value is required.",
  "users.tempassignments.error.untilBeforeFrom":
    'The "validUntil" date must be after the "validFrom" date.',
  "users.adminConditionHeader": "Admin Role",
  "users.adminConditionText":
    "Users without a direct client assignment are admins and have access to all clients!",
  "candidate.resend": "Send new invitation",
  "candidate.refresh": "Refresh expiry",
  "candidate.expire": "Cancel invitation",
  "registration.heading": "Registration",
  "registration.error": "Error",
  "registration.password": "Password",
  "registration.password-repeat": "Password (repeat)",
  "registration.password-weak": "Password too weak.",
  "registration.password-unequal": "Passwords are not identical.",
  "registration.decodeTokenError":
    "Invalid URL token. Please check the link from the registration mail.",
  "registration.registrationError": "No user found for this token.",
  "change.changePassword": "Change password",
  "change.oldPassword": "Old password",
  "change.newPassword": "New Password",
  "change.newPasswordRepeat": "New password (repeat)",
  "A problem with the password occurred":
    "A problem with the password change request occurred",
  incorrectPassword: "Incorrect password",
  "password-change-success": "Password successfully changed.",
  userNotFound: "User not found",
  "userNavigation.box.title": "Search for user",
  "users.workHoursAccount.noData":
    "No data available. The employee could be missing a contract or assignment for this date range.",
  "users.workHoursAccount.break": "Break",
  "users.workHoursAccount.breaks": "Breaks",
  "users.workHoursAccount.weekNr": "CW",
  "users.workHoursAccount.weekStart": "Start",
  "users.workHoursAccount.weekEnd": "End",
  "users.workHoursAccount.weekUntil": "until",
  "users.workHoursAccount.overtimeLong": "Overtime",
  "users.workHoursAccount.paytimeLong": "Paytime",
  "users.workHoursAccount.dayHeader.day": "Date",
  "users.workHoursAccount.dayHeader.details": "Description",
  "users.workHoursAccount.dayHeader.plan": "Planed",
  "users.workHoursAccount.dayHeader.log": "Logged",
  "users.workHoursAccount.dayHeader.plannedtime": "Planed",
  "users.workHoursAccount.dayHeader.loggedtime": "Logged",
  "users.workHoursAccount.dayHeader.bonus": "Bonus",
  "users.workHoursAccount.dayHeader.bonuses": "Bonuses",
  "users.workHoursAccount.dayHeader.total": "Saldo",
  "users.workHoursAccount.dayHeader.segment": "Description",
  "users.workHoursAccount.dayHeader.overtime": "OT",
  "users.workHoursAccount.dayHeader.paytime": "PT",
  "users.workHoursAccount.dayHeader.weekdiff": "Week-",
  "users.workHoursAccount.dayHeader.diff": "difference",
  "users.workHoursAccount.dayHeader.contracttime": "Contract",
  "users.workHoursAccount.dayHeader.loggedOvertime": "OT IST",
  "users.workHoursAccount.dayHeader.loggedPaytime": "PT IST",
  "users.workHoursAccount.dayHeader.correction": "Correction!",
  "users.workHoursAccount.bothCorrections": "Abs. and Rel.",
  "users.workHoursAccount.relCorrections": "Relative",
  "users.workHoursAccount.absCorrections": "Absolute",
  "users.workHoursAccount.dayHeader.paytimeDiff": "Diff PT",
  "users.workHoursAccount.dayHeader.planDiff": "Diff Plan / AV",
  "users.workHoursAccount.dayHeader.loggedDiff": "Diff Log / AV",
  "users.workHoursAccount.dayHeader.loggedSaldo": "Saldo Log",
  "users.workHoursAccount.dayHeader.paytimeWeek": "PT cum.",
  "users.workHoursAccount.dayHeader.overtimeWeek": "OT cum.",
  "users.workHoursAccount.dayHeader.workWeek": "Achieved",
  "users.workHoursAccount.dayHeader.toworkWeek": "To do",
  "users.workHoursAccount.dayHeader.diffWeek": "Saldo Log",
  "users.workHoursAccount.dayHeader.overtimeTotal": "Saldo OT",
  "users.workHoursAccount.dayHeader.paytimeTotal": "Saldo PT",
  "users.workHoursAccount.dayRow.absCorrection": "Absolute correction",
  "users.workHoursAccount.dayRow.relCorrection": "Relative corrections",
  "usernameEdit.sendInfoMail": "Send mail to inform about change?",
  "usernameEdit.error.invalidEmail": "Please enter a valid email.",
  "usernameEdit.change": "Change username",
  "Trying to update invalid user for userId!":
    "The current user seems to be invalid!",
  alreadyExistsError: "Error: Already in use",
  "The username is already in use!": "The chosen username is already in use!",
  "Username changed.": "Username changed!",
  "prefix.dr": "PhD",
  "prefix.ma": "M.A.",
  "prefix.masters": "Master`s degree",
  "prefix.msc": "M.Sc.",
  "prefix.pharm": "Dipl. Pharm.",
  "users.exceededLicenseWarning":
    "License volume exceeded! You can still add/invite a new user, but further licensing costs are possible.",
  "users.lowLicenseWarning": "Only {remainingLicenses} licenses remaining.",
  "users.licenseText": "Licenses still available",
  "users.warning": "Attention",
  "page.pwchange.title": "Change your password",
  "page.pwchange.description":
    "Please provide the current password as verification.",
  "pages.registration.title": "Welcome to Mina.Works!",
  "pages.registration.description":
    "Please set the password you wish to use in the future for mina.works.",
  username: "Username",
  save: "Save",
  cancel: "Cancel",
  Edit: "Edit",
  "form.error": "Couldn't save form.",
}

export default messages
