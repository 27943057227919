import * as React from 'react'
import ColorPicker from './support/ColorPicker'
import FieldWithHelp from './support/FieldWithHelp'

const ColorUI = ({ input, ...props }) => {
  const value = input.value
  return (
    <FieldWithHelp { ...props }>
      <ColorPicker {...props}
        value={{ r: value[0], g: value[1], b: value[2] }}
        error={ props.meta.error ? props.meta.error : undefined }
        onChange={ ({ r: cr, g: cg, b: cb }) => input.onChange([cr, cg, cb]) }
      />
    </FieldWithHelp>
  )
}

function ReadOnlyField( { input: { value }, label, dataE2E } ) {
  return (
    <div className='field detailsConnectedFormField'>
      <label>{ label }</label>
      <div
        style={{
          display: 'inline-block',
          width: '26px',
          height: '26px',
          borderRadius: '14px',
          margin: '2px',
          backgroundColor: `rgb(${value})`
        }}
        className={
          [
            'detailsConnectedFormFieldValue',
            'detailsConnectedFormColorField'
          ].join(' ')
        }
        data-e2e-test-id={ dataE2E }
      >
        &nbsp;
      </div>
    </div>
  )
}

export default readOnly => !readOnly ? ColorUI : ReadOnlyField
