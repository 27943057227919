import types from "./actionTypes"
import { fetchREST } from "../../../../shared/rest.js"
import { encodeURIComponentsForActions } from "../../../../shared/uriUtils"
import {
  showGlobalMessage,
  convertBackendError,
} from "../../../globalMessage/controller"

function loadSettingsConf(settingsConf) {
  return { type: types.LOADSETTINGSCONF, settingsConf }
}

export function loadSettingsConfAsync() {
  return (dispatch, getState) =>
    fetchREST(
      "/settings/getSettingsConfig",
      { undefined },
      { state: getState() }
    )
      .then(res => res.json())
      .then(json => {
        if (json.error) {
          showGlobalMessage(dispatch, convertBackendError(json))
          return Promise.resolve()
        }
        dispatch(loadSettingsConf(json))
        return Promise.resolve()
      })
      .catch(err => {
        console.log(err)
      })
}

export function saveHolidaysFor(year, statesMap) {
  return dispatch =>
    fetchREST(
      "/settings/saveHolidaysFor",
      { year, statesMap },
      { method: "POST" }
    )
      .then(res => res.json())
      .then(json => {
        if (json.error) {
          showGlobalMessage(dispatch, convertBackendError(json))
          return Promise.resolve()
        } else {
          dispatch(mergeHolidays(json))
          return Promise.resolve()
        }
      })
      .catch(err => {
        console.log(err)
      })
}

export function loadAllHolidays() {
  return dispatch =>
    fetchREST("/settings/loadAllHolidays")
      .then(res => res.json())
      .then(json => {
        if (json.error) {
          showGlobalMessage(dispatch, convertBackendError(json))
          return Promise.resolve()
        } else {
          dispatch(mergeHolidays(json))
          return Promise.resolve()
        }
      })
      .catch(err => {
        console.log(err)
      })
}

function mergeHolidays(holidays) {
  return { type: types.SAVEHOLIDAYS, holidays }
}

function saveForm(data) {
  return { type: types.SAVEFORM, data }
}

export function saveFormAsync(data) {
  return (dispatch, getState) =>
    fetchREST(
      "/settings/saveForm",
      { data },
      { method: "POST", state: getState() }
    )
      .then(res => res.json())
      .then(json => {
        if (json.error) {
          showGlobalMessage(dispatch, convertBackendError(json))
          return Promise.resolve()
        }
        dispatch(saveForm(data))
        return Promise.resolve()
      })
      .catch(err => {
        console.log(err)
      })
}

function deleteFormSettings(form) {
  return { type: types.DELTEFORMSETTINGS, form }
}

export function deleteFormSettingsAsync(props) {
  return (dispatch, getState) =>
    fetchREST(
      "/settings/deleteFormSettings",
      { props },
      { method: "POST", state: getState() }
    )
      .then(res => res.json())
      .then(json => {
        if (json.error) {
          showGlobalMessage(dispatch, convertBackendError(json))
          return Promise.resolve()
        }
        dispatch(deleteFormSettings(props.form)) // TODO: doesn`t update!
        return Promise.resolve()
      })
      .catch(err => {
        console.log(err)
      })
}

function loadFormSettings(data) {
  return { type: types.LOADFORMSETTINGS, data }
}

export function loadFormSettingsAsync(props) {
  const { clientId, orgunitId, userId, isUser, form } = props
  return (dispatch, getState) =>
    fetchREST(
      "/settings/getSettingsForForm?" +
        encodeURIComponentsForActions({
          clientId,
          orgunitId,
          userId,
          isUser,
          form,
        }),
      { undefined },
      { state: getState() }
    )
      .then(res => res.json())
      .then(json => {
        if (json.error) {
          showGlobalMessage(dispatch, convertBackendError(json))
          return Promise.resolve()
        }
        dispatch(loadFormSettings({ form, json }))
        return Promise.resolve()
      })
      .catch(err => {
        console.log(err)
      })
}
