import * as React from "react"
import version from "./version"

interface State {
  serverLabel: string
  serverVersion: string
}

export default class TypeScriptBadge extends React.Component<
  Record<string, never>,
  State
> {
  constructor(props: Record<string, never>) {
    super(props)
    this.state = {
      serverLabel: "mina.works Server",
      serverVersion: "unbekannt",
    }
  }

  componentDidMount(): void {
    void fetch("/rest/infoPages/version")
      .then(response => response.json())
      .then(json =>
        this.setState({
          serverLabel: json?.label ?? "mina.works Server",
          serverVersion: json?.hash ?? "unbekannt",
        })
      )
  }

  render(): JSX.Element {
    return (
      <div className="versions">
        <div className="client-version-badge">
          <span className="client-version-label" style={{ fontWeight: "bold" }}>
            mina.works Client:
          </span>
          &nbsp;
          <span className="version-badge">{version}</span>
        </div>
        <div className="server-version-badge">
          <span className="server-version-label" style={{ fontWeight: "bold" }}>
            {this.state.serverLabel}:
          </span>
          &nbsp;
          <span className="server-version-string">
            {this.state.serverVersion}
          </span>
        </div>
      </div>
    )
  }
}
