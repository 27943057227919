import PlannerDate from "../../../shared/plannerDateTime/PlannerDate"

export default class Holiday {

  // TODO: Do we need more information? E.g., bank vs. other holidays?
  constructor(private date: PlannerDate, private name: string) { }

  static fromJSON(json: { date: string, name: string }): Holiday {
    return new Holiday(PlannerDate.fromJSON(json.date), json.name)
  }

  getName(): string { return this.name }
  getDate(): PlannerDate { return this.date }

  clone(): Holiday { return new Holiday(this.date.clone(), this.name) }
  toJSON(): Record<string, string> { return { date: this.date.toJSON(), name: this.name } }
}