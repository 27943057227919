export class InvalidMethodError extends Error {
  constructor() {
    super('method-is-invalid-in-this-context')
  }
}

export class InvalidArgumentError extends Error {
  constructor() {
    super('argument-is-invalid')
  }
}
