const messages = {
  "qualifications.heading": "Qualifications",
  "qualifications.name": "Name",
  "qualifications.abbrv": "Abbreviation",
  "qualifications.color": "Color",
  "qualifications.useForQualiCheck": "Use for Qualification Check",
  "qualifications.ignoreBreaks": "Ignore breaks in attendance checks.",
  "qualifications.newButton": "New Qualification",
  "qualifications.newTitle": "New Qualification",
  "qualifications.editTitle": "Qualification",
  "qualifications.showarchived": "Show archived?",
  "qualifications.archiveButton": "Archive",
  "qualifications.deleteButton": "Delete",
  "qualifications.restoreButton": "Restore",
  "qualifications.upButton": "Up",
  "qualifications.downButton": "Down",
  "qualifications.moveButton": "Move to upper organisational unit",
  "qualifications.confirmDeleteHeader":
    "Deletion confirmation for qualification",
  "qualifications.confirmDeleteText":
    "The display/the functions of already assigned employees will change on deletion!",
  "qualifications.error.editing": "Error",
  "qualifications.error.missingName": "Please enter a name",
  "qualifications.deleteImpact":
    "Some users have this qualification currently assigned. The deletion would affect some of them:",
}
export default messages
