const messages = {
  "userReports.title": "Arbeitszeitberichte",
  "userReports.generateReport": "Monatsbericht erstellen",
  "userReports.generateXLSXReport": "Monatsbericht erstellen (XLSX)",
  "userReports.type": "Typ",
  "userReports.from": "Von",
  "userReports.to": "Bis",
  "userReports.Date": "Datum",
  "userReports.Week": "Woche",
  "userReports.Month": "Monat",
  "userReports.Year": "Jahr",
}

export default messages
