const messages = {
  "unavailabilityReports.title": "Fehlzeitenübersicht",
  "unavailabilityReports.chooseTimespan": "Zeitraum auswählen",
  "unavailabilityReports.calendarWeekStart": "Von Woche",
  "unavailabilityReports.calendarWeekEnd": "Bis Woche",
  "unavailabilityReports.generateReport": "Übersicht erstellen",
  "unavailabilityReports.generateXLSXReport": "Übersicht erstellen (XLSX)",
  "unavailabilityReports.chooseUnavailabilities": "Fehlzeitenarten auswählen",
  "unavailabilityReports.toggleArchived": "Archivierte",
  "unavailabilityReports.showArchived": "anzeigen",
  "unavailabilityReports.hideArchived": "verstecken",
}

export default messages
