/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-shadow */
import React from "react"
import { connect } from "react-redux"
import { injectIntl } from "react-intl"

import {
  subscribe,
  unsubscribe,
} from "../../modules/actionProvider/controllers/subscribe"
import { isUserOutsideClients } from "../../modules/login/controller"
// eslint-disable-next-line max-len
import { getAllClientsState as getClientManagerState } from "../../modules/clientManager/redux/selectors/clientManagerSelectors"

import OUContextDropdownComponent from "./OUContextDropdownComponent"
import { setContextOrgUnit, setContext } from "./controller"
import { selectClient } from "./redux/actions/ouContextDropdownActions"
import {
  getCurrentClientIdState,
  getCurrentOrgUnitIdState,
} from "./redux/selectors/ouContextDropdownSelectors"

// TODO: Move these
import {
  loadAllActiveClientsAsync,
  loadAllActiveOrgUnitsAsync,
} from "./redux/actions/ouContextDropdownActions"
import {
  getAllActiveClientsState,
  getAllActiveOrgUnitsState,
} from "./redux/selectors/ouContextDropdownSelectors"
import Hierarchy from "../models/Hierarchy"
import withRouter from "../withRouter"

const OESUBSCRIBERID = "uniqueOESubscriber"

class OUContextDropdownContainer extends React.Component {
  componentDidMount(oldProps) {
    this.props.subscribeClients() // never unsub!
    this.props.setInitialState(
      this.props.currentClientId,
      this.props.currentOrgUnitId
    )
    this.updateOESub(oldProps || {}, this.props)
  }

  componentDidUpdate(oldProps) {
    if (
      this.props.clientManagerState.length !==
      oldProps.clientManagerState.length
    ) {
      this.props.loadAllClients()
    }
    this.updateOESub(oldProps || {}, this.props)
  }

  updateOESub(oldProps, newProps) {
    if (oldProps.currentClientId !== newProps.currentClientId) {
      this.props.unsubscribeOUs()
      this.props.subscribeOUs(newProps.currentClientId)
    }
  }

  render() {
    const hierarchy = Hierarchy.createHierarchy(this.props.allOrgUnits)
    return (
      <OUContextDropdownComponent
        selectClient={this.props.selectClient}
        selectOrgUnit={this.props.selectOrgUnit}
        showClientList={this.props.showClientList} // based on admin flags
        currentClientId={this.props.currentClientId}
        currentOrgUnitId={this.props.currentOrgUnitId}
        clients={this.props.allClients}
        orgUnits={hierarchy !== null ? hierarchy.traverse() : []}
        hierarchy={hierarchy}
        intl={this.props.intl}
      />
    )
  }
}

export default withRouter(
  connect(
    state => ({
      currentClientId: getCurrentClientIdState(state),
      currentOrgUnitId: getCurrentOrgUnitIdState(state),
      showClientList: isUserOutsideClients(state),
      allClients: getAllActiveClientsState(state),
      allOrgUnits: getAllActiveOrgUnitsState(state),
      clientManagerState: getClientManagerState(state),
    }),
    (dispatch, { navigate }) => ({
      loadAllClients: () => dispatch(loadAllActiveClientsAsync()),
      selectClient: clientId => {
        dispatch(selectClient(clientId))
        navigate("/")
      },
      selectOrgUnit: orgunitId => setContextOrgUnit(dispatch, orgunitId),
      subscribeClients: () => {
        // Subscribe to clients and oes, unconditionally
        dispatch(loadAllActiveClientsAsync())
        subscribe(dispatch, {
          uniqueId: "uniqueClientSubscriber",
          mainFunction: dispatch => {
            return dispatch(loadAllActiveClientsAsync())
          },
        })
      },
      subscribeOUs: clientId => {
        // Still need full lists in org chart! (arch!)
        dispatch(loadAllActiveOrgUnitsAsync(clientId))
        subscribe(dispatch, {
          uniqueId: OESUBSCRIBERID,
          mainFunction: dispatch => {
            return dispatch(loadAllActiveOrgUnitsAsync(clientId))
          },
        })
      },
      unsubscribeOUs: () => {
        unsubscribe(dispatch, OESUBSCRIBERID)
      },
      setInitialState: (clientId, orgunitId) =>
        setContext(dispatch, clientId, orgunitId),
    })
  )(injectIntl(OUContextDropdownContainer))
)
