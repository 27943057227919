import types from "../actions/actionTypes"
import { valueAt } from "../../../../shared/walkObjectHierarchy"
import type {
  CalendarEntryTypeJSON
} from '../../interfaces'
import type {
  AllActions,
  SaveCalendarEntryTypeAction,
  LoadCalendarEntryTypesAction,
  SimpleCetAction

} from '../interfaces'

export default function (state: Record<string, any> = {}, action: AllActions): Record<string, any> {
  switch (action.type) {
    case types.OPENEDITCALENDARENTRYTYPESDIALOG:
      return {
        ...state,
        calendarentrytypeId: action.calendarentrytypeId,
        openDialog: true,
        metatype: action.metatype,
        reload: false,
      }
    case types.CLOSEEDITCALENDARENTRYTYPESDIALOG:
      return {
        ...state,
        calendarentrytypeId: undefined,
        openDialog: false,
        metatype: undefined,
        reload: false,
      }
    case types.SAVECALENDARENTRYTYPE:
      return saveCalendarEntryType(state, action)
    case types.LOADCALENDARENTRYTYPES:
      return loadCalendarEntryTypes(state, action)
    case types.ARCHIVECET:
      return archiveCET(state, action)
    case types.RESTORECET:
      return restoreCET(state, action)
    case types.DELETECET:
      return deleteCET(state, action)
    case types.INCLUDEARCHIVEDCETS:
      return {
        ...state,
        includeArchived: !valueAt(state, ["includeArchived"]),
      }
    // add other cases here
    default:
      return state
  }
}

function saveCalendarEntryType(
  state: Record<string, any> = {},
  action: SaveCalendarEntryTypeAction
): Record<string, any> {
  const ecet = action.calendarentrytype
  const byId = valueAt(state, ["byId"])
  return {
    ...state,
    byId: {
      ...byId,
      [ecet.calendarentrytypeId]: ecet,
    },
    calendarentrytypeId: undefined,
    openDialog: false,
  }
}

function loadCalendarEntryTypes(
  state: Record<string, any> = {},
  action: LoadCalendarEntryTypesAction
): Record<string, any> {
  // Compare old and new data to decide changes in state are necessary
  // this is to avoid unneccessary load
  const oldById = state.byId as number | undefined
  const olddata =
    oldById !== undefined
      ? JSON.stringify(
        Object.values(oldById)
          .map((cet: CalendarEntryTypeJSON) => [cet.updatedAt, cet.id])
          .sort()
      )
      : ""
  const newdata = JSON.stringify(
    action.data.map(cet => [cet.updatedAt, cet.id]).sort()
  )
  if (olddata !== newdata) {
    return {
      ...state,
      byId: formatForLoadById(action.data),
    }
  }
  return state
}

function formatForLoadById(data: CalendarEntryTypeJSON[]) {
  // formates data (activities or unavailabilities or both) to be
  // a dictionary indexed by calendarentrytypeId
  return data.reduce((acc, item) => {
    acc[item.calendarentrytypeId] = item
    return acc
  }, {})
}

function archiveCET(
  state: Record<string, any> = {},
  action: SimpleCetAction
): Record<string, any> {
  const cet = action.cet
  const byId = valueAt(state, ["byId"])
  return {
    ...state,
    byId: {
      ...byId,
      [cet.calendarentrytypeId]: cet,
    },
  }
}

function restoreCET(
  state: Record<string, any> = {},
  action: SimpleCetAction
): Record<string, any> {
  const cet = action.cet
  const byId = valueAt(state, ["byId"])
  return {
    ...state,
    byId: {
      ...byId,
      [cet.calendarentrytypeId]: cet,
    },
  }
}

function deleteCET(
  state: Record<string, any> = {},
  action: SimpleCetAction
): Record<string, any> {
  const cet = action.cet
  const oldById = valueAt(state, ["byId"])
  const byId = Object.keys(oldById).reduce((acc, calendarentrytypeId) => {
    if (`${calendarentrytypeId}` !== `${cet.calendarentrytypeId}`) {
      acc[calendarentrytypeId] = oldById[calendarentrytypeId]
    }
    return acc
  }, {})
  return {
    ...state,
    byId,
  }
}
