import React, { Component } from "react"
import { Outlet } from "react-router-dom"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"

import { Layout } from "antd"

import ReduxDevTools from "../../setup/ReduxDevTools"
import FooterContent from "../FooterContent"

import { getContext } from "../../shared/OUContext/controller"

import MainMenuContainer from "../../modules/mainMenu/MainMenuContainer"
import PasswordResetModalContainer from "../../modules/login/containers/PasswordResetModalContainer"
import ErrorContainer from "../../modules/errorHandler/ErrorContainer"
import GlobalMessageContainer from "../../modules/globalMessage/GlobalMessageContainer"
import PermissionCheckContainer from "../../shared/permissionCheck/lib/PermissionCheckContainer"
import ActionProvider from "../../modules/actionProvider/containers/ActionProvider"

import "./Scaffold.css"
// import { push } from "connected-react-router"
import withRouter from "../../shared/withRouter"
import { isUserOutsideClients } from "../../modules/login/controller"
import SupportButtonApp from "../../apps/SupportButtonApp/SupportButtonApp"

const env = process.env.NODE_ENV || "development"

export class Scaffold extends Component<any, any> {
  isRootPath(): boolean {
    return this.props.location !== undefined
      ? this.props.location.pathname === "/"
      : false
  }

  render(): JSX.Element {
    const isRoot = this.isRootPath()
    return (
      <div className="main-container">
        <PermissionCheckContainer />
        <GlobalMessageContainer />
        <ErrorContainer />
        <Layout hasSider={true} style={{ minHeight: "100vh" }}>
          {false && env === "development" && <ReduxDevTools />}
          <ActionProvider />
          <PasswordResetModalContainer {...({} as any)} />
          <MainMenuContainer
            clientId={this.props.oucontext.clientId}
            departmentId={this.props.oucontext.orgunitId}
            userId={this.props.userId}
            locale={this.props.intl.locale}
          />
          <SupportButtonApp
            clientId={this.props.oucontext.clientId}
            isAdminView={this.props.isAdminView}
            locale={this.props.intl.locale}
          ></SupportButtonApp>
          <Layout>
            <Layout.Content
              className={`mainContent${
                this.props.isAdminView ? " admin-view" : ""
              }`}
            >
              <Outlet />
            </Layout.Content>
            {isRoot && (
              <Layout.Footer className="dyn-footer">
                <FooterContent />
              </Layout.Footer>
            )}
          </Layout>
        </Layout>
      </div>
    )
  }
}

const ConnectedDesktop = connect(
  (state: any) => ({
    oucontext: getContext(state),
    isAdminView: isUserOutsideClients(state),
  })
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
)(withRouter<any>(injectIntl(Scaffold) as any) as any)

export default ConnectedDesktop
