/**
 * TODO: Please move and document this module and its functionality:
 * - The name "parsers" is very misleading and unspecific
 * - The module doesn't do much
 * - The module isn't documented enough to be of shared use
 *
 * Suggestion for name: idFixes.js (if I interpret this correctly)
 */
export const parseOrgunitId = orgunitId => {
  if (('' + orgunitId) === 'new') {
    return undefined
  }
  return orgunitId
}
