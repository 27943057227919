import React from 'react'

import { Form } from '../../../shared/vmi-connected-form'
import { ValidationErrors } from '../../../shared/vmi-connected-form'

import { makeViewModel } from './models/securitySettingsFormModel'
import withForm from '../lib/withForm/withForm'

const validate = (values, dispatch, translate, props) => {
  const v = new ValidationErrors(
    translate('settings.error.validation', props)
  )
  return v
}

const SecuritySettingsForm = ({
  connectedForm,
  toggleHandler,
  readOnly,
  onlyDetails
}) => {
  const { fields } = connectedForm

  return (
    <Form
      connectedForm={ connectedForm }
      onToggle={ toggleHandler }
      readOnly={ readOnly }
      onlyDetails= { onlyDetails }
    >
      { fields.twofa() }
    </Form>
  )
}

export default withForm({
  validate,
  makeViewModel,
  form: 'security'
})(SecuritySettingsForm)
