import * as React from "react"
import { Button, Form, Input } from "antd"
// import { Offline } from "react-detect-offline"
import PasswordResetForm from "./MobilePasswordResetForm"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"

export interface Props {
  setUserData: (username: string, departmentId?: string) => void
  resetPassword: (username: string, path: string) => void
  login: (
    username: string,
    password: string,
    path: string,
    navigate: ReturnType<typeof useNavigate>
  ) => Promise<LoginResult>
}

interface LoginResult {
  success: boolean
  orgunitId: string
}

const MobileLoginForm = (props: Props): JSX.Element => {
  const [resetPassword, setResetPassword] = React.useState(false)
  const navigate = useNavigate()
  if (!resetPassword) {
    return (
      <LoginForm
        setShowResetPassword={(b: boolean) => setResetPassword(b)}
        setUserData={props.setUserData}
        login={(uname, pw, path) => props.login(uname, pw, path, navigate)}
      ></LoginForm>
    )
  } else {
    return (
      <PasswordResetForm
        resetPassword={props.resetPassword}
      ></PasswordResetForm>
    )
  }
}

const LoginForm = ({
  setShowResetPassword,
  setUserData,
  login,
}: {
  setShowResetPassword: (b: boolean) => void
  setUserData: (uname: string, ouId?: string) => void
  login: (uname: string, pw: string, path: string) => Promise<LoginResult>
}) => {
  const intl = useIntl()
  const [form] = Form.useForm()
  const [showError, setShowError] = React.useState(false)
  const onFinish = ({
    username,
    password,
  }: {
    username: string
    password: string
  }) => {
    const go = async () => {
      try {
        const result = await login(username, password, "/mobile")
        if (!result) {
          console.log("Error during mobile login")
          alert(intl.formatMessage({ id: "mobileCouldntSignIn" }))
          setShowError(true)
        } else {
          const { success, orgunitId } = result
          if (!success) {
            setShowError(true)
          } else {
            setUserData(username ?? "", orgunitId ? `${orgunitId}` : undefined)
          }
        }
      } catch (e) {
        console.log("Error during mobile login:", e)
        alert(intl.formatMessage({ id: "mobileWeirdCouldntSignIn" }))
        setShowError(true)
      }
    }
    void go()
  }
  return (
    <div className="mobile-login-form">
      <img
        onClick={() => (window.location.href = "/mobile")}
        alt="mina.works"
        src="mina_works_icon.svg"
        className="mobile-logo"
        style={{ cursor: "pointer" }}
      ></img>

      <Form form={form} layout="vertical" onFinish={onFinish}>
        <div className="mobile-hero-title">
          <span className="mobile-hero-title-mina">mina.</span>
          <span className="mobile-hero-title-works">works</span>
        </div>

        {/*
        <Offline>
          <div className="mobile-login-error">
            {intl.formatMessage({ id: "YouAreOffline" })}
          </div>
        </Offline>
        */}

        {showError ? (
          <div className="mobile-login-error">
            {intl.formatMessage({ id: "SignInFailed" })}
          </div>
        ) : null}

        <Form.Item
          label={intl.formatMessage({ id: "Username" })}
          name="username"
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: "PleaseEnterUsername" }),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={intl.formatMessage({ id: "Password" })}
          name="password"
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: "PleaseEnterPassword" }),
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <div className="mobile-password-reset-link">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a onClick={() => setShowResetPassword(true)}>
            {intl.formatMessage({ id: "ResetPassword" })}
          </a>
        </div>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {intl.formatMessage({ id: "SignIn" })}
          </Button>
        </Form.Item>
        <div className="mobile-imprint-link">
          <a
            href="https://mina.works/imprint/"
            target="_blank"
            rel="noreferrer"
          >
            {intl.formatMessage({ id: "Imprint" })}
          </a>
        </div>
      </Form>
    </div>
  )
}

export default MobileLoginForm
