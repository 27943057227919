import * as React from "react"
import * as ReactDOM from "react-dom"
import DayContent from "../utils/DayContent"
import type { Day } from "./MobileHorizCalendar"
import "./less/mobileCalendarDay.less"
import type { IntlShape } from "react-intl"
import { injectIntl } from "react-intl"
import MobileCalendarDayOtherPeople from "./MobileCalendarDayOtherPeople"
import type PlannerData from "../configuration/PlannerData"
import MobileCalendarEditDay from "./MobileCalendarEditDay"
import EditableLoggedEntry from "./EditableLoggedEntry"
import { CloseOutlined, EditOutlined } from "@ant-design/icons"
import type FeatureType from "@mina-works/featuresets/dist/definition/interfaces/FeatureType"
import MobileLoggedHasPunched from "./MobileLoggedHasPunched"

export interface Props {
  intl: IntlShape
  ouId: string
  day: Day
  selected: boolean
  select: () => void
  plannerData?: PlannerData
  loading: boolean
  availableFeatures: FeatureType[]
}

interface State {
  showForm: boolean
}

class MobileCalendarDay extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      showForm: false,
    }
    this.saveLogged = this.saveLogged.bind(this)
    this.emptyLogged = this.emptyLogged.bind(this)
    this.deleteLogged = this.deleteLogged.bind(this)
  }

  componentDidUpdate(prevProps: Props): void {
    if (this.props.selected && !prevProps.selected) {
      ;(ReactDOM.findDOMNode(this) as any)?.scrollIntoView()
    }
  }

  render(): JSX.Element {
    const header = this.getHeader()
    // TODO: Move common parts of day contents out
    return (
      <div className="mobile-calendar-day">
        <div
          onClick={this.props.select}
          className={`mobile-calendar-day-header${
            this.props.selected ? " mobile-calendar-day-today" : ""
          }`}
        >
          {header}
        </div>
        {this.props.day.holiday ? (
          <div className="mobile-calendar-holiday-banner">
            {this.props.day.holiday}
          </div>
        ) : null}
        {this.canEditOwnLoggedDays() ? (
          <div
            className="mobile-form-toggle"
            style={{
              float: "right",
              cursor: "pointer",
              height: "100%",
            }}
            onClick={() => this.setState({ showForm: !this.state.showForm })}
          >
            {this.state.showForm ? (
              <CloseOutlined></CloseOutlined>
            ) : (
              <EditOutlined></EditOutlined>
            )}
          </div>
        ) : null}
        {this.props.day.dateString ? (
          <MobileLoggedHasPunched
            date={this.props.day.dateString}
          ></MobileLoggedHasPunched>
        ) : null}
        {this.state.showForm ||
        this.props.day.plannedDay === null ||
        this.props.day.plannedDay === undefined ? null : (
          <DayContent
            day={this.props.day.plannedDay}
            showHeader={false}
          ></DayContent>
        )}
        {this.canEditOwnLoggedDays() && this.props.plannerData !== undefined ? (
          <MobileCalendarEditDay
            date={this.props.day.dateString}
            loading={this.props.loading}
            saveLogged={this.saveLogged}
            emptyLogged={this.emptyLogged}
            deleteLogged={this.deleteLogged}
            plannerData={this.props.plannerData}
            show={this.state.showForm}
            ouId={this.props.ouId}
            entries={
              this.props.day.plannedDay
                ?.getEntries()
                .map(
                  e =>
                    new EditableLoggedEntry(e.getEntryV3(), e.getPlannerData())
                ) ?? []
            }
            organisationalUnits={this.props.plannerData.getAvailableDepartments()}
          ></MobileCalendarEditDay>
        ) : null}
        <MobileCalendarDayOtherPeople
          plannerData={this.props.plannerData}
          day={this.props.day}
        ></MobileCalendarDayOtherPeople>
      </div>
    )
  }

  private canEditOwnLoggedDays() {
    return (
      !!this.props.plannerData?.getPlannerV3()?.canEditOwnLoggedTimes &&
      !this.isInTheFuture() &&
      this.props.availableFeatures.includes("LoggedDays") &&
      this.isWithinRetroactiveLimits()
    )
  }

  private isWithinRetroactiveLimits() {
    return (
      !this.props.plannerData?.retroactiveLimit ||
      this.props.day.dateString >= this.props.plannerData?.retroactiveLimit
    )
  }

  private isInTheFuture() {
    const now = new Date()
    const y = now.getFullYear()
    const m = `${now.getMonth() + 1}`.padStart(2, "0")
    const d = `${now.getDate()}`.padStart(2, "0")
    const today = `${y}-${m}-${d}`
    return this.props.day.dateString > today
  }

  private saveLogged(
    date: string,
    entries: EditableLoggedEntry[],
    message?: string
  ): void {
    void this.props.plannerData?.planLogged(date, entries, message ?? "")
  }

  private emptyLogged(date: string): void {
    void this.props.plannerData?.emptyLogged(date)
  }

  private deleteLogged(date: string): void {
    void this.props.plannerData?.deleteLogged(date)
  }

  private getHeader() {
    const d = this.props.day
    const isToday: boolean = this.isToday()
    const isTomorrow: boolean = this.isTomorrow()
    const loggedOrRegular = d.plannedDay
      ? d.plannedDay.isLoggedDay()
        ? `${this.props.intl.formatMessage({ id: "Logged Day" })}`
        : ""
      : ""
    const prefix = isToday
      ? `${this.props.intl.formatMessage({ id: "TODAY" })} - `
      : isTomorrow
      ? `${this.props.intl.formatMessage({ id: "TOMORROW" })} - `
      : ""
    const date = d.date.toLocaleDateString(this.props.intl.locale, {
      weekday: "long",
      year: "numeric",
      month: "numeric",
      day: "numeric",
    })
    return (
      <>
        {`${prefix}${date}`}
        <div className="mobile-logged-header">{loggedOrRegular}</div>
      </>
    )
  }

  private isToday() {
    const d = this.props.day
    const today = new Date()
    if (
      d.date.getFullYear() === today.getFullYear() &&
      d.date.getMonth() === today.getMonth() &&
      d.date.getDate() === today.getDate()
    ) {
      return true
    } else {
      return false
    }
  }

  private isTomorrow() {
    const d = this.props.day
    const today = new Date()
    const tomorrow = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 1
    )
    if (
      d.date.getFullYear() === tomorrow.getFullYear() &&
      d.date.getMonth() === tomorrow.getMonth() &&
      d.date.getDate() === tomorrow.getDate()
    ) {
      return true
    } else {
      return false
    }
  }
}

const MobileCalendarDayWithIntl = injectIntl(MobileCalendarDay)
export default MobileCalendarDayWithIntl
