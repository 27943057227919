import {
  getAllSubscribersState
} from '../redux/selectors/actionProviderSelectors'

/**
 * Run all subscribers in sequence, optionally running test actions first
 * (if they exist).
 *
 * This is an unused convenience wrapper (used in tests); you'll probably
 * want to use runAllSubscribers with connect
 *
 * @return [Promise]
 */
export function runAll(state) {
  return runAllSubscribers(state.dispatch, getAllSubscribersState(state))
}

/**
 * Run all subscribers in sequence, optionally running test actions first
 * (if they exist).
 *
 * Remember that every mainFunction etc. is supposed to return a promise!
 *
 * @return [Promise]
 */
export function runAllSubscribers(dispatch, subscribers) {
  // console.log("- tick -", new Date())
  return subscribers.map(s => runSubscriber(dispatch, s))
}


function runSubscriber(
  dispatch,
  {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ___uniqueName,
    mainFunction, mainFunctionArguments = [],
    testFunction, testFunctionArguments = []
  }
) {
  const fun = test => {
    if (test) {
      return mainFunction(dispatch, ...mainFunctionArguments)
    }
  }
  if (testFunction !== undefined) {
    return testFunction(
      dispatch,
      ...testFunctionArguments
    )
      .then(fun)
  } else {
    return fun(true)
  }
}
