import {
  _subscribe,
  _unsubscribe
} from '../redux/actions/actionProviderActions'
import genUniqueId from '../../../shared/uniqueId'

/**
 * Subscribe a new interval function; this function will be run periodically
 *
 * **PLEASE USE THE CONTROLLER TO INTERACT WITH THE ACTION PROVIDER!**
 *
 * The functions passed here will *always* receive dispatch as their first
 * argument. They should behave like thinly wrapped actions. Be sure to
 * use them in a way that is compatible with the way actions are used! (I.e.,
 * don't run data back and forth to and from the store in the function. Just
 * perform an action, and perhaps a followup action.)
 *
 * The main function will only be performed if test returns true (as a promise),
 * or if no testFunction is defined
 *
 * This test can - for example - be used to
 *  - check for changes on the server first before retrieving a new list of
 *    rows (which may be expensive and disruptive on the client side)
 *  - check for larger intervals (instead of just going with the default
 *    subscriber interval)
 *
 * Please note that the test will also have to be very careful with how it
 * treats the store! It is highly recommended to avoid using selectors or state
 * from the redux store in both the test and the main functions!
 *
 * Use closures or other sources for input to the test data.
 *
 * **Both functions must return promises** (testFunction may be undefined).
 *
 * You can let this function generate a unique ID for you, or you
 * can use shared/uniqueId directly to generate an ID. Either way you should
 * keep track of that ID (in order to be able to unsubscribe).
 *
 * @param uniqueId The unique ID for this subscription; use it to unsubscribe;
 *                 can be left undefined (a new one will be returned then)
 * @param mainFunction The function object (should wrap an action creator);
 *                     passed dispatch as the first argument
 * @param mainFunctionArguments The parameters for the function (as a
 *                              list; these are the parameters after
 *                              dispatch!)
 * @param testFunction The function object for an test that is used to
 *                     determine whether mainFunction should be run.
 * @param testFunctionArguments The parameters for the test function
 *                              (after dispatch!)
 *
 * @return uniqueId (**not** a Promise!)
 */
export function subscribe(dispatch, {
  uniqueId,
  mainFunction, mainFunctionArguments = undefined,
  testFunction = undefined, testFunctionArguments = undefined,
}) {
  uniqueId = uniqueId || genUniqueId()
  dispatch(_subscribe(uniqueId, {
    mainFunction, mainFunctionArguments,
    testFunction, testFunctionArguments,
  }))
  return uniqueId
}

export function unsubscribe(dispatch, uniqueId) {
  dispatch(_unsubscribe(uniqueId))
}
