import * as React from "react"

export default function useKeyDown(): boolean {
  const [isKeyDown, setKeyDown] = React.useState<boolean>(false)
  React.useEffect(() => {
    const handleKeyUp = () => {
      document.removeEventListener("keyup", handleKeyUp)
      setKeyDown(false)
    }
    const handleKeyDown = () => {
      document.removeEventListener("keyup", handleKeyUp)
      document.addEventListener("keyup", handleKeyUp)
      setKeyDown(true)
    }
    document.addEventListener("keydown", handleKeyDown)
    return () => {
      document.removeEventListener("keydown", handleKeyDown)
      document.removeEventListener("keyup", handleKeyUp)
    }
  }, [])
  return isKeyDown
}
