import { createSelector } from 'reselect'
import valueAt from '../valueAt'

const getReduxReactTable = state => state.reduxReactTable
export const getReduxReactTableState = createSelector(
  getReduxReactTable,
  reduxReactTable => ({ reduxReactTable: reduxReactTable || {} })
)

export function getBatchStart(state, name) {
  const {
    batchStart
  } = valueAt(state, ['reduxReactTable', 'tables', 'byName', name], {})
  return batchStart
}

export function getBatchSize(state, name) {
  const {
    batchSize
  } = valueAt(state, ['reduxReactTable', 'tables', 'byName', name], {})
  return batchSize
}

export function getFilter(state, name) {
  const {
    filterText
  } = valueAt(state, ['reduxReactTable', 'tables', 'byName', name], {})
  return filterText
}

/**
 * Defaults to undefined
 */
export function getColumnFilterText(state, name, columnName) {
  const {
    filterText
  } = valueAt(
    state,
    ['reduxReactTable', 'tables', 'byName', name, 'columnFilters', columnName],
    {}
  )
  return filterText
}

export function getSort(state, name) {
  const {
    sortColumnName, sortDirection
  } = valueAt(state, ['reduxReactTable', 'tables', 'byName', name], {})
  return {
    sortColumnName, sortDirection
  }
}
