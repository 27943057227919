import * as React from "react"
import { Form, Checkbox } from "antd"
import { CheckSquareOutlined, MinusSquareOutlined } from "@ant-design/icons"
import FieldWithHelp from "./support/FieldWithHelp"

const CheckboxUI = ({
  input: { value, onChange },
  label,
  meta,
  dataE2E,
  ...props
}) => {
  return (
    <FieldWithHelp {...props}>
      <Form.Item
        label={""}
        validateStatus={meta.error ? "error" : "success"}
        hasFeedback={!!meta.error}
        help={meta.error}
      >
        <Checkbox
          {...props}
          // defaultChecked={ !!value }
          checked={!!value}
          onChange={e => onChange(e.target.checked)}
          data-e2e-test-id={dataE2E}
          // error={ !!props.meta.error }
        >
          {label}
        </Checkbox>
      </Form.Item>
    </FieldWithHelp>
  )
}

function ReadOnlyField({ input: { value }, label, dataE2E }) {
  let valueEl = <MinusSquareOutlined />
  if (value) {
    valueEl = <CheckSquareOutlined />
  }
  return (
    <div className="field detailsConnectedFormField">
      <label>{label}</label>
      <div
        className="detailsConnectedFormFieldValue"
        data-e2e-test-id={dataE2E}
      >
        {valueEl}
      </div>
    </div>
  )
}

export default readOnly => (!readOnly ? CheckboxUI : ReadOnlyField)
