/* Settings main page */

import React from 'react'
import { Tabs } from 'antd'

import PlannerSettingsForm
  from '../forms/PlannerSettingsForm'
import PortalSettingsForm
  from '../forms/PortalSettingsForm'
import SecuritySettingsForm
  from '../forms/SecuritySettingsForm'
import SignalSettingsForm
  from '../forms/SignalSettingsForm'
import TimetrackingSettingsForm
  from '../forms/TimetrackingSettingsForm'
import HolidayUpload from '../lib/holidays/HolidayUpload'

const Settings = ({
  translate,
  settingsConf,
  userId,
  isUser,
  isAdmin,
  oucontext
}) => {
  if (
    (oucontext.clientId === undefined || oucontext.clientId === 'undefined')
  ) {
    if ( isAdmin ) {
      return <HolidayUpload />
    } else {
      return null
    }
  }
  return (
    <div data-e2e-test-id='settings'>
      <Tabs>
        <Tabs.TabPane
          key='plannersettings'
          tab={ translate('settings.plannersettings') }
        >
          <PlannerSettingsForm
            settingsConf={ settingsConf }
            isUser={ isUser }
            userId={ userId }
          />
        </Tabs.TabPane>
        { !isUser &&
          <Tabs.TabPane
            key='portalsettings'
            tab={ translate('settings.portalsettings') }
          >
            <PortalSettingsForm
              settingsConf={ settingsConf }
              isUser={ isUser }
              userId={ userId }
            />
          </Tabs.TabPane>
        }
        { !isUser && false && // disabled for now
          <Tabs.TabPane
            key='securitysettings'
            tab={ translate('settings.securitysettings') }
          >
            <SecuritySettingsForm
              settingsConf={ settingsConf }
              isUser={ isUser }
              userId={ userId }
            />
          </Tabs.TabPane>
        }
        <Tabs.TabPane
          key='signalsettings'
          tab={ translate('settings.signalsettings') }
        >
          <SignalSettingsForm
            settingsConf={ settingsConf }
            isUser={ isUser }
            userId={ userId }
          />
        </Tabs.TabPane>
        { !isUser &&
          <Tabs.TabPane
            key='timetrackingsettings'
            tab={ translate('settings.timetrackingsettings') }
          >
            <TimetrackingSettingsForm
              settingsConf={ settingsConf }
              isUser={ isUser }
              userId={ userId }
            />
          </Tabs.TabPane>
        }
      </Tabs>
    </div>
  )
}

export default Settings
