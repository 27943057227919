/**
 * This is the main entry point for reduxForm-specific implementation code
 * in CoreTables.
 *
 * **Please note**: Form processing is probably the most involved part of how
 * we use React, redux, and Semantic-UI-React. **Please read the documentation
 * completely, and carefully!** Also, please be sure you know how to deal with
 * lifecycle methods in React components (especially how to allow the correct
 * updates and avoid extraneous ones), because otherwise, you'll very likely
 * be very unhappy with this type of form processing.
 *
 * Please note that this code uses the ConnectedForm enhancement (for
 * Antd integration), so we have three immediate dependencies:
 *
 * - react-redux
 * - antd
 * - ConnectedForm
 *
 * Please note that this is not a real controller:
 * This module dispatches all the low-level code; since we're *only* dealing
 * with React-specific code here, a further division by using even more
 * injection etc. would be very cumbersome, and would help us very little.
 *
 * Also, this controller depends on react-intl, even though strictly speaking
 * it *can* be used without it. This is a convenience decision: It streamlines
 * the code and makes using the HOC significantly simpler.
 *
 * Read: This is a compromise.
 */

import * as React from "react"
import { Component } from "react"
import { createSelector } from "reselect"
import type {
  ConnectFormArguments,
  ConnectFormInjectedArguments,
  OnSubmitSuccessFunction,
  TranslationFunction,
  FormWrapperProps,
  ConnectedFormProps,
  TranslationProps,
} from "./interfaces"
import { render as renderField } from "./fieldMapper"
import { AntdLocaleWrapper } from "../../ui/AntdLocaleWrapper"
export { SubmissionError } from "redux-form"
export { Group } from "./Form"

/**
 * Apply reduxForm and add some props to the component (this is a HoC that is
 * used where connect and wrapWithDefaults are used); the added props are:
 *
 * - connectedForm.fields (The form fields as a simple function, rendered
 *   directly when called)
 * - connectedForm.submit (A callback to submit the form manually, e.g. from an
 *   external save button)
 * - connectedForm.initialize (A callback to reset the form's content
 *   completely, back to whatever object is passed in; this object is a
 *   mapping of field names to values)
 * - connectedForm.getValue (A function that returns the value for the field
 *   whose name is passed in the single parameter; this is basically a wrapper
 *   around a redux-form formValueSelector)
 *
 * Please use the `Form` component from the `./Form` module. This provides
 * functionality from ConnectedForm (unifying redux-form and semantic-ui's Form
 * tag), and allows you to mostly declaratively specify your forms with some
 * minimal boilerplate).
 *
 * The `<Form />` tag requires a connectedForm prop (passing on the one injected
 * by connectForm is the recommended way to provide this - don't pick and choose)
 * and an `onSubmit` prop that gets passwed a redux-form compatible
 * submit handler.
 *
 * The `<Form />` tag also takes some additional props:
 * - onlyDetails disables the toggle button and doesn't allow editing
 * - onlyEdit disables the toggle button and only shows the form
 * - onToggle(readOnly) is a callback for the container (to enable
 *   toggling of button bars, for instance)
 * - readOnly true/false determines initial mode (careful of updates!)
 *   THIS MUST BE PASSED BACK IN IF YOU'RE USING onToggle! (Just use the
 *   onToggle parameter as a prop in your presentational component!)
 *
 * Please note that if you use the edit/details toggling functionality for forms
 * (and you should), basically the only thing you'll need to take care of is
 * that your buttons are toggled as well. You'll need to use the toggleHandler
 * for that (it is invoked with the current readOnly state of the form).
 *
 * Please note that fields are not fully rendered (because some of them expect
 * dynamic data - for the time being this is mostly the select dropdown). They
 * are returned as a function that must be executed to render. Its
 * argument is an object with additional properties (such as the `options` for
 * a dropdown). You can override field properties this way (but you should
 * keep track of what you're doing and use it sparingly). This can be useful if
 * more information becomes available when rendering.
 *
 * Part of the reduxForm prop based API is simply "re-bundled" under the
 * connectedForm prop (note that some of these are just state flags):
 *
 * - connectedForm.error
 * - connectedForm.submitting
 * - connectedForm.handleSubmit
 * - connectedForm.onSubmit
 * - connectedForm.submitFailed
 * - connectedForm.invalid
 * - connectedForm.dirty (pristine and anyTouched are *deliberately* left out)
 * - connectedForm.reset (function to completely reset the form when re-opened)
 *
 * Note that handleSubmit can be used to mimick hidden fields; cf.:
 * https://stackoverflow.com/questions/37168954/redux-form-how-to-handle-multiple-buttons
 *
 * If you use handleSubmit to call onSubmit, you *must* provide onSubmit
 * with the dispatch and props arguments as well! E.g.:
 *
 *  onClick = { handleSubmit(values => this.props.onSubmit(
 *    { ...values, specialKey: 'special value' }, dispatch, this.props
 *  ))}
 *
 * You can just add dispatch itself to mapDispatchToProps.
 *
 * A new utility is added to the connectedForm prop to make form submission
 * extensible (via side effects):
 *
 * - connectedForm.extendSubmit(key, function) - the key must be unique for a
 *   form instance (connectForm), so it can just be the name of the
 *   functionality; the function receives all the same arguments the submit
 *   handler receives; it is executed *before* the submit handler
 *
 * This utility is used internally, but if you have need of it it *can* be used
 * from the outside
 *
 * Several assumptions *must* hold:
 *
 * - You are using submit-based validation, and the submit-based functionality
 *   of reduxForm (this is an area where it's easy to get confused!)
 * - You have provided a wrapper for everything else that your callbacks require
 *   (e.g. an intl prop for the translate function) BEFORE using connectForm
 *   (i.e. `i18nWrap(connectForm(YourComponent))` and not the other way around,
 *   keeping in mind that a HOC renders the wrapped component)
 * - You have connected this component to some redux store (an ancestor is a
 *   store component)
 * - injectIntl is called *first* (it is required as an injected prop in the
 *   component created by `connectForm`)
 *
 * @param coreForm A CoreForm instance describing your form fields
 * @param translate A function that can be used to translate a field name etc.:
 *                  `translate(msg, props)`; please note that this must also
 *                 supply a translation for the error header (`form.error`)
 *                 Note: The translate function is passed back to the component
 *                 (in props.connectedForm.translate) to avoid duplication;
 *                 the **default** is to use the react.intl formatMessage
 *                 with the message as the ID
 * @param validate A function that can be used to validate a form before
 *                 submitting it: `validate(fields, dispatch, translate, props)`
 *                 - must return either a ValidationErrors instance or a falsy
 *                 result
 * @param onSubmitSuccess A function that is used to submit the form after
 *                        validation was successful:
 *                        `onSubmitSuccess({fields, dispatch, translate, props})`
 *                        You can throw a SubmissionError from this function
 *                        directly (for server side validation), but you
 *                        should import it from this module (it's currently
 *                        just a re-export of redux-form's SubmissionError)
 *                        SubmissionErrors you throw yourself will have to
 *                        conform to redux-form's API spec for error objects!
 *                        You can use the `ValidationErrors` object and its
 *                        `getReduxFormErrors` method to prepare error objects!
 *                        **Please note**: If onSubmitSuccess returns a
 *                        promise, this promise is assumed to contain the
 *                        new form fields, and its contents will be used to
 *                        re-initialize the form.
 *                        To avoid this don't return a Promise.
 *
 * @param connect The user code's react-redux connect function
 * @param reduxFormModule The user code's redux-form module content
 *
 * connect and reduxFormModule are required because the store will otherwise not
 * be available
 *
 * Please note that you should always make sure that your form doesn't update
 * for the wrong reasons. (As always, check your selectors for failures to
 * memoize, and your reducers for failures to skip writing new state when the
 * state hasn't *actually* changed.)
 */
export default function connectForm<
  FormFields = any, // only as default for quickly converted JS code
  CallbackProps extends TranslationProps = TranslationProps
>(
  {
    coreForm,
    validate,
    onSubmitSuccess,
  }: ConnectFormArguments<FormFields, CallbackProps>,
  { connect, reduxFormModule }: ConnectFormInjectedArguments
): any {
  // Lexical closure; somewhat long-ish, but hard to replace...
  const {
    reduxForm,
    Field,
    initialize,
    submit,
    SubmissionError,
    reset,
    formValueSelector,
  } = reduxFormModule
  const onSubmitSuccessExtensions: {
    [key: string]: OnSubmitSuccessFunction
  } = {}
  const onSubmitSuccessArg = onSubmitSuccess
  onSubmitSuccess = obj => {
    Object.values(onSubmitSuccessExtensions).forEach(
      (fun: OnSubmitSuccessFunction) => void fun(obj)
    )
    return onSubmitSuccessArg(obj)
  }
  const translate: TranslationFunction = (id, props) =>
    props.intl.formatMessage({
      id,
      defaultMessage: id,
    })
  return WrappedComponent => {
    const formName = coreForm.getName()
    const selector = formValueSelector(formName)
    const fieldNames = coreForm.getFieldNames()
    const connectedFormSelector = createSelector(
      // memoize for field names!
      fieldNames.map(k => state => selector(state, k)),
      (...values) => ({
        getValues: () =>
          fieldNames.reduce((acc, k, idx) => {
            acc[k] = values[idx]
            return acc
          }, {}),
        getValue: fieldName =>
          fieldNames.reduce((acc, k, idx) => {
            acc[k] = values[idx]
            return acc
          }, {})[fieldName],
      })
    )
    const submitReduxForm = (fields, dispatch, props) =>
      submitHandlerForReduxForm(
        coreForm.getName(),
        translate,
        validate,
        onSubmitSuccess,
        fields,
        dispatch,
        props,
        SubmissionError,
        reset,
        initialize
      )

    const mapStateToProps = state => ({
      // Initial connectedForm (from state) -
      // just provides the getValue[s] functions in a memoized way:
      connectedForm: connectedFormSelector(state),
    })

    const mapDispatchToProps = dispatch => ({
      connectedForm: {
        reset: () => dispatch(reset(formName)),
        submit: e => {
          if (e !== undefined) {
            e.preventDefault() // REQUIRED to avoid reloads on RETURN
          }
          dispatch(submit(coreForm.getName()))
          return false // REQUIRED to avoid reloads on RETURN
        },
        initialize: fields => {
          return dispatch(initialize(coreForm.getName(), fields))
        },
      },
    })

    const mergeProps = (stateProps, dispatchProps, ownProps) => ({
      ...ownProps,
      connectedForm: {
        ...stateProps.connectedForm,
        ...dispatchProps.connectedForm,
        intl: ownProps.intl,
      },
    })

    class FormWrapper extends Component<FormWrapperProps<FormFields>> {
      private formFieldsProp: FormFields

      constructor(props) {
        super(props)
        this.formFieldsProp = this.buildFieldsProp()
      }

      buildProps(): ConnectedFormProps<FormFields> {
        return {
          // from mapStateToProps:
          ...this.props.connectedForm,
          fields: this.formFieldsProp, // TODO: Verify!
          handleSubmit: this.props.handleSubmit, // to unify the reduxForm-API
          onSubmit: this.props.onSubmit, // to unify the reduxForm-API
          submitting: this.props.submitting, // to unify the reduxForm-API
          dirty: this.props.dirty, // to unify the reduxForm-API
          error: this.props.error, // to unify the reduxForm-API
          invalid: this.props.invalid, // to unify the reduxForm-API
          submitFailed: this.props.submitFailed, // to unify the reduxForm-API
          translate, // to avoid duplication
          extendSubmit: (key, fun) => {
            onSubmitSuccessExtensions[key] = fun
          },
        }
      }

      buildFieldsProp(): FormFields {
        const fields: Partial<FormFields> = {}
        coreForm.forEachField((f, name) => {
          const fieldFn = moreProps =>
            renderField(
              {
                ...f,
                ...(moreProps || {}),
              },
              this.props.intl,
              Field
            )
          fields[name] = fieldFn
        })
        return fields as FormFields
      }

      t(msg) {
        return translate(msg, this.props)
      }

      render() {
        const connectedForm = this.buildProps()
        const props = { ...this.props, connectedForm }
        /**
         * We need to wrap this dynamic component in a ConfigProvider
         * again, even tho the entire App was already wrapped...
         */
        return (
          <AntdLocaleWrapper>
            <WrappedComponent {...props} />
          </AntdLocaleWrapper>
        )
      }
    }
    const wrapForm = reduxForm({
      form: coreForm.getName(),
      onSubmit: submitReduxForm,
    })
    const wrapConnect = connect(mapStateToProps, mapDispatchToProps, mergeProps)
    const wrappedForm = wrapForm(FormWrapper)
    const inner = wrapConnect(wrappedForm)
    return inner
  }
}

const failOrReset = (
  formName,
  translate,
  validationHandler,
  fields,
  dispatch,
  props,
  SubmissionError,
  reset
) => {
  const v = validationHandler(fields, dispatch, translate, props)
  if (v && v.hasErrors()) {
    throw new SubmissionError(v.getReduxFormErrors())
  } else {
    dispatch(reset(formName))
  }
}

const submitHandlerForReduxForm = (
  formName,
  translate,
  validationHandler,
  submitHandler,
  fields,
  dispatch,
  props,
  SubmissionError,
  reset,
  initialize
) => {
  failOrReset(
    formName,
    translate,
    validationHandler,
    fields,
    dispatch,
    props,
    SubmissionError,
    reset
  )
  const submitResult = submitHandler({ fields, dispatch, translate, props })
  if (
    submitResult !== undefined &&
    submitResult.constructor !== undefined &&
    submitResult.then !== undefined
  ) {
    // assuming Promise argument to be the new fields
    return submitResult
      .then(newFields => {
        return dispatch(initialize(formName, newFields))
      })
      .catch(e => {
        dispatch(initialize(formName, fields))
        throw e
      })
  } else {
    return submitResult
  }
}
