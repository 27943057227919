import * as React from "react"
import type PunchedStep from "../../configuration/PunchedStep"

const INTERVAL = 50

interface Props {
  steps: PunchedStep[]
}

export default function useRunningBreak({
  steps,
}: Props): {
  breakRunningSeconds?: number
} {
  const [breakRunningSeconds, setBreakRunningSeconds] = React.useState<number | undefined>()
  React.useEffect(() => {
    const step = steps[steps.length - 1]
    if (step?.type === 'break-start') {
      const cb = () => {
        const cb_step = steps[steps.length - 1]
        const start = cb_step.utc.timestamp
        const end = Date.now()
        const target = (end - start) / 1000
        setBreakRunningSeconds(target)
      }
      cb()
      const i = window.setInterval(cb, INTERVAL)
      return () => window.clearInterval(i)
    } else {
      setBreakRunningSeconds(undefined)
    }
  }, [steps])
  return {
    breakRunningSeconds
  }
}