import { Tree, TreeSelect } from "antd"
import type { IntlShape } from "react-intl"
import { injectIntl } from "react-intl"
import * as React from "react"
// import Color from "../../commonInterfaces/Color"
import ColorBullet from "../ColorBullet/ColorBullet"
import "./css/departmentWidget.less"
import type { Key } from "antd/lib/table/interface"

export interface Props {
  placeholder?: string
  multiSelectCallback?: (d: DepartmentNode[]) => void
  selectCallback?: (d?: DepartmentNode) => void
  selected?: DepartmentNode
  multiSelected?: DepartmentNode[]
  root: DepartmentNode[]
  allowClear?: boolean
  dropdownClassName?: string
  multi?: boolean
  disabled?: boolean
  intl: IntlShape
}

export interface DepartmentNode {
  getId: () => DepartmentNodeId
  getName: () => string
  getAbbreviation: () => string
  getChildren: () => DepartmentNode[]
  isDisabled?: () => boolean
  getColor?: () => any // TODO: Color type conversion is broken here
}

export interface State {
  currentParentNodeIds: string[]
}

export type DepartmentNodeId = string

class DepartmentWidget extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.handleSelect = this.handleSelect.bind(this)
    this.handleMultiSelect = this.handleMultiSelect.bind(this)
    this.state = {
      currentParentNodeIds: [],
    }
    this.renderNode = this.renderNode.bind(this)
  }

  componentDidMount(): void {
    this.updateCurrentParentNodeIds([])
  }

  componentDidUpdate(props: Props): void {
    this.updateCurrentParentNodeIds(props.root)
  }

  render(): JSX.Element {
    return <div className="department-widget">{this.renderTree()}</div>
  }

  private updateCurrentParentNodeIds(root: DepartmentNode[]) {
    // Required for initial expansion of nodes
    if (
      // note that the expansion state is also changed via a TreeSelect cb!
      root.length !== this.props.root.length ||
      this.props.root.reduce((acc: boolean, node: DepartmentNode) => {
        return acc || !root.find(oldnode => oldnode.getId() === node.getId())
      }, false)
    ) {
      const currentParentNodeIds = [] as string[]
      // TODO: Fold all completely disabled subtrees
      // - addl parm tempParentNodeIds []
      // - track hasEnabledNode |= (initially false)
      // - return hasEnabledNode
      // - *after* each node recursion, only push if rec enabled true?
      const pushNode = (n: DepartmentNode): boolean => {
        let hasEnabledNode: boolean =
          n.isDisabled !== undefined ? !n.isDisabled() : true
        for (const c of n.getChildren()) {
          const cHasEnabledNode = pushNode(c)
          hasEnabledNode = hasEnabledNode || cHasEnabledNode
        }
        if (hasEnabledNode) {
          currentParentNodeIds.push(n.getId())
        }
        return hasEnabledNode
      }
      for (const r of this.props.root) {
        pushNode(r)
      }
      if (
        currentParentNodeIds.join(",") !==
        this.state.currentParentNodeIds.join(",")
      ) {
        this.setState({ currentParentNodeIds })
      }
    }
  }

  private renderTree() {
    return this.props.multi
      ? this.renderTreeUsingTree()
      : this.renderTreeUsingTreeSelect()
  }

  private renderTreeUsingTree() {
    const treeData: TreeDataNode[] = this.props.root.map(this.renderNode)
    return (
      <Tree
        disabled={this.props.disabled}
        style={{
          minWidth: "300px",
          maxWidth: "500px",
          overflowX: "hidden",
        }}
        multiple={true}
        checkable={true}
        selectable={false}
        onExpand={currentParentNodeIds =>
          this.setState({
            currentParentNodeIds: currentParentNodeIds.map(n => n.toString()),
          })
        }
        expandedKeys={this.state.currentParentNodeIds}
        checkedKeys={
          this.props.multiSelected !== undefined
            ? this.props.multiSelected?.filter(s => !!s)?.map(s => s.getId())
            : undefined
        }
        onCheck={this.handleMultiSelect}
        treeData={treeData}
      ></Tree>
    )
  }

  private renderTreeUsingTreeSelect() {
    const treeData = this.props.root.map(this.renderNode)
    if (treeData.length > 0) {
      treeData.unshift({
        value: "0",
        title: <div></div>,
        key: "0",
        text: "",
        disabled: false,
        children: [],
      })
    }
    return (
      <TreeSelect
        disabled={this.props.disabled}
        dropdownClassName="department-widget-tree"
        style={{
          minWidth: "300px",
          maxWidth: "500px",
          overflowX: "hidden",
          overflowY: "hidden",
        }}
        dropdownStyle={{
          minWidth: "380px",
          maxWidth: "800px",
        }}
        popupClassName={this.props.dropdownClassName}
        showSearch={true}
        treeNodeFilterProp="text"
        allowClear={this.props.allowClear}
        onTreeExpand={currentParentNodeIds =>
          this.setState({
            currentParentNodeIds: currentParentNodeIds.map(n => n.toString()),
          })
        }
        treeExpandedKeys={this.state.currentParentNodeIds}
        value={
          this.props.selected !== undefined
            ? "" + this.props.selected?.getId()
            : undefined
        }
        placeholder={this.props.placeholder ?? ""}
        onChange={this.handleSelect}
        treeData={treeData}
      ></TreeSelect>
    )
  }

  private renderNode(d: DepartmentNode): TreeDataNode {
    const abbrv = d.getAbbreviation()
    const text = `${d.getName()}${abbrv ? " (" + abbrv + ")" : ""}`
    const title = (
      <>
        {d.getColor !== undefined ? (
          <ColorBullet color={d.getColor()}></ColorBullet>
        ) : null}
        {text}
      </>
    )
    return {
      key: d.getId(),
      text,
      value: "" + d.getId(),
      title, // : `${d.getName()}${abbrv ? " (" + abbrv + ")" : ""}`,
      children: d.getChildren().map(this.renderNode),
      disabled: d.isDisabled !== undefined ? d.isDisabled() : false,
    }
  }

  private handleSelect(value: string) {
    if (this.props.selectCallback) {
      const res = this.findNode(value)
      this.props.selectCallback(res)
    }
  }

  private handleMultiSelect(
    value:
      | Key[]
      | {
          checked: Key[]
          halfChecked: Key[]
        }
  ) {
    const keys: Key[] =
      (value as { checked: Key[]; halfChecked: Key[] }).checked !== undefined
        ? (value as { checked: Key[] }).checked
        : (value as Key[])
    const res = keys
      .map(n => this.findNode(n as string))
      .filter((n): n is DepartmentNode => n !== undefined)
    if (this.props.multiSelectCallback) {
      this.props.multiSelectCallback(res)
    }
  }

  private findNode(id: DepartmentNodeId) {
    for (const r of this.props.root) {
      const found = this.findNodeInTree(r, id)
      if (found !== undefined) {
        return found
      }
    }
    return undefined
  }

  private findNodeInTree(
    tree: DepartmentNode,
    id: DepartmentNodeId
  ): DepartmentNode | undefined {
    if ("" + tree.getId() === "" + id) {
      return tree
    } else {
      for (const c of tree.getChildren()) {
        const found = this.findNodeInTree(c, id)
        if (found !== undefined) {
          return found
        }
      }
    }
    return undefined
  }
}

interface TreeDataNode {
  key: string
  value: string
  title: JSX.Element // string
  text: string
  children: TreeDataNode[]
  disabled: boolean
}
export default injectIntl(DepartmentWidget)
