import types from "../actions/actionTypes"

export default function candidateReducer(state = {}, action) {
  switch (action.type) {
    case types.OPENEDITCANDIDATEUSERDIALOG:
      return {
        ...state,
        openEditCandidateUserDialog: true,
        editCandidateId: action.stateId,
      }
    case types.CLOSEEDITCANDIDATEUSERDIALOG:
      return {
        ...state,
        openEditCandidateUserDialog: false,
        editCandidateId: undefined,
      }
    case types.PASSWORDCHANGED:
      return {
        ...state,
        passwordChangedAt: new Date(),
        passwordChangeError: undefined,
      }
    case types.APPENDPASSWORDCHANGEERROR:
      return {
        ...state,
        passwordChangeError: action.error,
      }
    case types.APPENDREGISTRATIONERROR:
      return {
        ...state,
        registrationError: action.error,
      }
    case types.UPDATEEXPIRESAT:
      return {
        ...state,
        openEditCandidateUserDialog: false,
      }
    case types.REMOVECANDIDATEUSER:
      return {
        ...state,
        openEditCandidateUserDialog: false,
      }
    default:
      return state
  }
}
