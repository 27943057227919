const messages = {
  "calendarentrytypes.actitvity": "Activity",
  "calendarentrytypes.unavailability": "Unavailability",
  "calendarentrytypes.name": "Name*",
  "calendarentrytypes.abbrv": "Abbreviation",
  "calendarentrytypes.color": "Color",
  "calendarentrytypes.unavailabilitytype": "This calendarentrytype is ...",
  "calendarentrytypes.effectivedate": "Effective date",
  "calendarentrytypes.allday": "Whole day",
  "calendarentrytypes.availableToEmployees": "Available to employees",
  "calendarentrytypes.visibleForEmployees": "Visible for employees",
  "calendarentrytypes.restrictedfurther": "Restricted further",
  "calendarentrytypes.importkey": "Import key",
  "calendarentrytypes.datevkey": "DATEV Key",
  "calendarentrytypes.newActivityButton": "Activity",
  "calendarentrytypes.activities": "Activities",
  "calendarentrytypes.unavailabilities": "Unavailabilities",
  "calendarentrytypes.newActivity": "New activity",
  "calendarentrytypes.newActivityButtonTitle": "Create new activity",
  "calendarentrytypes.newUnavailabilityButton": "Unavailability",
  "calendarentrytypes.newUnavailabilityButtonTitle":
    "Create new unavailability",
  "calendarentrytypes.newUnavailability": "New unavailability",
  "calendarentrytypes.openEditCet": "TODO xxx",
  "calendarentrytypes.newundefined": "Nothing selected",
  "calendarentrytypes.unavailabilitytypesick": "Sick leave",
  "calendarentrytypes.unavailabilitytypevacation": "Vacation",
  "calendarentrytypes.unavailabilitytypeovertimereduction":
    "Overtime reduction",
  "calendarentrytypes.unavailabilitytypespecial": "Special leave",
  "calendarentrytypes.unavailabilitytypeother": "Other",
  "calendarentrytypes.error": "Please correct the marked errors.",
  "calendarentrytypes.error.editing": "Please enter a name",
  "calendarentrytypes.error.missingName": "Please enter a name",
  "calendarentrytypes.restrictedfurtherHelp": "TODO",
  "cet.confirmDeleteHeader": "Confirm the deletion of this calendarentrytype",
  "cet.confirmDeleteText":
    "The behaviour of already planned employees will change after the deletion of this calendarentrytype!",
}
export default messages
