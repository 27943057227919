/**
 * The form component used by connectForm to extend and unify antd and
 * redux-form forms while staying in the ViewModel paradigm.
 *
 * **PLEASE NOTE:**
 * connectForm provided the `props.connectedForm.Form` prop. This is a shortcut
 * for older code and should not longer be used!
 *
 * Please also use `Group` from this module.
 *
 * Example:
 * ```
 * import {
 *   Form,
 *   Group
 * } from '@mep24/vmi-connected-form'
 * // ...
 * <Form
 *   error={ error }
 *   connectedForm={ props.connectedForm }
 * >
 *   <Group widths='equal'>
 *     { fields.name }
 *     { fields.number }
 *   </Group>
 * </Form>
 * ```
 */

import * as React from "react"
import { Divider, Button } from "antd"
import { EditOutlined, CloseOutlined, EditTwoTone } from "@ant-design/icons"
import { Form } from "../../connected-form"
import type { ConnectedFormProps } from "./interfaces"
export { Group, FieldSet } from "../../connected-form"

export type ToggleReadonly = (readonly: boolean) => void
interface State {
  readOnly: boolean
  onToggle?: ToggleReadonly
}
export interface Props<FormFields = any> {
  // , CallbackProps extends TranslationProps = any> {
  readOnly?: boolean // load read only variant
  onlyEdit?: boolean // onlyEdit disables read only view
  onlyDetails?: boolean // onlyDetails disables edit (form) view
  connectedForm: ConnectedFormProps<FormFields>
  // the cF form representation
  onToggle?: ToggleReadonly // called when toggling to update readOnly
  disableToggleOnSubmit?: boolean // disable submit auto toggle
  children: React.ReactNode | React.ReactNodeArray
}
interface ChildProps {
  readOnly?: boolean
  children?: React.ReactNode | React.ReactNodeArray
  // [prop: string]: any
}

/**
 * Form component to be used in place of redux-form Form or ConnectedForm
 *
 * Please **use only this** with viewModel. It will automatically connect a
 * ConnectedForm to the fields provided by connectForm.
 *
 * **DO NOT USE CONNECTEDFORM DIRECTLY** unless you are 100% sure that's what
 * you want to do (i.e., use a redux- and AntD-connected form that
 * isn't built via a CoreForm definition)
 *
 * Required props:
 * - connectedForm - the parent's `connectedForm` prop as provided by connectForm
 *
 * The component takes some additional props:
 * - onlyDetails disables the toggle button and doesn't allow editing
 * - onlyEdit disables the toggle button and only shows the form
 * - readOnly true/false determines initial mode (careful of updates!)
 * - onToggle(readOnly) is a callback for the container (to enable
 *   toggling of button bars, for instance)
 * - disableToggleOnSubmit can be set to true to avoid forms going back to
 *   readonly state after submitting
 */
class ExtendedForm<FormFields = any> extends React.Component<
  Props<FormFields>,
  State
> {
  constructor(props) {
    super(props)
    this.state = {
      readOnly: props.readOnly !== undefined ? props.readOnly : !props.onlyEdit,
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.readOnly !== state.readOnly) {
      // readOnly updated from the outside
      return { readOnly: props.readOnly }
    } else {
      return null
    }
  }

  extendSubmit() {
    const readOnly = !this.props.onlyEdit
    return this.setState(() => {
      const onToggle = this.props.onToggle || (() => undefined)
      if (!this.props.disableToggleOnSubmit) {
        onToggle(readOnly)
      }
      return { readOnly }
    })
  }

  handleToggle() {
    return this.setState(state => {
      const readOnly = !state.readOnly
      const onToggle = this.props.onToggle || (() => undefined)
      onToggle(readOnly)
      return { readOnly }
    })
  }

  render() {
    const connectedForm = this.props.connectedForm
    const translate = id => connectedForm.intl.formatMessage({ id })
    connectedForm.extendSubmit("switchReadOnlyFromVMI", () =>
      this.extendSubmit()
    )
    return [
      /*
        <Prompt
          key = 'connected-form-prompt'
          when = {
            connectedForm.dirty
            && ! connectedForm.submitting
          }
          message='You have unsaved changes, are you sure you want to leave?'
        />,
      */
      <Form
        key="connected-form"
        error={connectedForm.error}
        formErrorHeader={translate("form.error")}
        readOnly={this.state.readOnly}
      >
        <div style={{ textAlign: "end", marginBottom: 10 }}>
          <Button
            disabled={this.props.onlyDetails || this.props.onlyEdit}
            icon={!this.state.readOnly ? <CloseOutlined /> : <EditTwoTone />}
            type={!this.state.readOnly ? "text" : "link"}
            onClick={() => {
              if (
                this.state.readOnly ||
                !connectedForm.dirty ||
                window.confirm(translate("Cancel despite modifications?"))
              ) {
                this.handleToggle()
              }
              return false
            }}
            title={translate("Edit")}
            data-e2e-test-id="button-form-edit"
          >
            {/* translate(!this.state.readOnly ? "View" : "Edit") */ null}
          </Button>
        </div>
        {this.injectReadOnly(this.props.children)}
      </Form>,
    ]
  }

  injectReadOnly(children) {
    return React.Children.map(children, child => {
      const props: ChildProps = {}
      if (child !== null && child !== undefined && child.props !== undefined) {
        if (child.props.children) {
          if (child.props.children.length > 0) {
            props.children = this.injectReadOnly(child.props.children)
          } else {
            // workaround for single child (not an array)
            props.children = this.injectReadOnly([child.props.children])
          }
        }
        if (
          child.type !== undefined &&
          child.type.isConnectedFormField === true
        ) {
          props.readOnly = this.state.readOnly
        }
      } else {
        return child
      }
      return React.cloneElement(child, props)
    })
  }
}

export default ExtendedForm
