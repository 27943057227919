/**
 * Container component for the settings main page
 *
 * @prop isUser (optional) Defaults to false: Defines whether the context node
 *              for this configuration view is a user or an orgunit
 * @prop userId (optional) Only needed when displaying the user settings.
 */

import React, { Component } from "react"
import { connect } from "react-redux"
import { injectIntl } from "react-intl"

import { VIEWSETTINGS, EDITSETTINGS } from "../../../shared/permissions"
import { hasPermission } from "../../../shared/permissionCheck/controller"
import { getContext } from "../../../shared/OUContext/controller"

import { getUserIsAdminState } from "../../login/redux/selectors/loginSelectors"

import Settings from "../components/Settings"
import { loadSettingsConfAsync } from "../redux/actions/settingsActions"
import { getSettingsConfState } from "../redux/selectors/settingsSelectors"

const mapStateToProps = state => ({
  isAdmin: getUserIsAdminState(state), // Ok for admin and restricted admin (HolidayUpload)!
  settingsConf: getSettingsConfState(state),
  oucontext: getContext(state),
  canView: hasPermission(VIEWSETTINGS, state),
  canEdit: hasPermission(EDITSETTINGS, state),
})

const mapDispatchToProps = dispatch => ({
  dispatchLoadSettingsConf: () => dispatch(loadSettingsConfAsync()),
})

class SettingsContainer extends Component {
  componentDidMount() {
    this.props.dispatchLoadSettingsConf()
  }

  checkPropsAreConsistent() {
    if (!this.props.isUser && this.props.userId !== undefined) {
      throw new Error("inconclusive-settings-context") // should be user or ou
    } else {
      return true
    }
  }

  canRender() {
    return this.permissionCheck() && this.checkPropsAreConsistent()
  }

  permissionCheck() {
    return this.props.canView || this.props.canEdit
  }

  render() {
    if (!this.canRender()) {
      return null
    }
    return (
      <Settings
        {...this.props}
        translate={id => this.props.intl.formatMessage({ id })}
      />
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(SettingsContainer))
