/* The HOC for handling login; wrapped around the LoginForm */

import React from "react"
import { connect } from "react-redux"
import { injectIntl } from "react-intl"

import LoginForm from "./LoginForm"
import {
  loginAsync,
  closeLoginDialog,
  openPasswordResetDialog,
} from "../redux/actions/loginActions"
import { mapStateToProps } from "../redux/utils/loginUtils"
import withRouter from "../../../shared/withRouter"

const mapDispatchToProps = dispatch => {
  return {
    dispatchLogin: (username, password, totp) =>
      dispatch(loginAsync(username, password, totp)),
    dispatchCloseLoginDialog: () => dispatch(closeLoginDialog()),
    dispatchOpenPasswordResetDialog: username =>
      dispatch(openPasswordResetDialog(username || "")),
  }
}

/**
 * HOC for the login form
 *
 * provides
 * - onCancel (callback when cancelling the form)
 *
 * Automatically wraps in
 * - injectIntl (props.intl)
 */
export const LoginFormContainer = props => {
  const translate = id => props.intl.formatMessage({ id })

  const onLogin = values => {
    if (values.username && values.password) {
      props.dispatchLogin(values.username, values.password, values.token)
    }
  }

  const onCancel = ({ dispatchCloseLoginDialog }) => {
    dispatchCloseLoginDialog()
  }

  const onPasswordForgotten = e => {
    e.stopPropagation()
    e.preventDefault()
    props.dispatchOpenPasswordResetDialog()
    return false
  }

  return (
    <LoginForm
      translate={translate}
      loginError={props.loginError} // Server error
      onLogin={onLogin}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      onCancel={() => onCancel(props)}
      onPasswordForgotten={onPasswordForgotten}
      openPasswordResetDialog={props.openPasswordResetDialog}
    />
  )
}

const LoginFormContainerComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(injectIntl(LoginFormContainer)))

export default LoginFormContainerComponent
