/**
 * Abstraction for the organisational chart hierarchy
 *
 * The organisational chart is made up out of individual organisational units;
 * these are all part of a *tree* structure (not a more general graph!)
 *
 * Each node is an instance of the hierarchy, with some basic traversal
 * methods (for parents, siblings, and children) and an option t return
 * the basic data structure (including all extraneous data for extensibility)
 *
 * Note that "no node" in this module is always represented as `null`!
 */

/**
 * A Hierarchy (node or subtree)
 *
 * This hierarchy is created with a single parameter, containing a serialised
 * list of organisational units; each unit has the following structure:
 *
 * {
 *   id: 'the unit ID',
 *   type: 'the unit type',
 *   isRoot: true, // only for the client root node!
 *   name: 'the unit name',
 *   abbrev: 'tun',
 *   color: [123, 255, 0], // single-byte RGB values
 *   importKey: 'external import key',
 *   children: [id1, id2, id3, ...],
 *   ...moreAttributes
 * }
 *
 * Please note that the depth of this tree is limited by the processing stack!
 *
 * The hierarchy is entirely constructed of subtrees (which is why this class
 * has two names: The module name "Hierarchy" and the internal name "Node").
 *
 * Please construct the hierarchy using the static method `createHierarchy`
 */
import { getEmptyOrgUnitProps } from "./orgUnitUtils.js"
import OpeningHoursDelegate from "./OpeningHoursDelegate"

export class TimezoneDateMissing {
  constructor(message) {
    this.name = "TimezoneDateMissing"
    this.message = message
  }
}

export default class Node {
  /**
   * Pass the clientId if you need to verify that a
   * hierarchy is "current" with the selected client
   */
  constructor(unit, clientId = undefined) {
    this.clientId = clientId
    this.parent = null
    this.raw = unit
    this.id = this.raw.id
    this._isRoot = !!unit.isRoot
    this.children = [] // cannot auto-add children!
    this.openingHoursDelegate = new OpeningHoursDelegate(unit.openingHours)
  }

  getClientId() {
    return this.clientId
  }

  getSize() {
    return this.traverse().length
  }

  isRoot() {
    return this._isRoot
  }

  getId() {
    return this.id
  }

  get(key) {
    return this.raw[key]
  }

  getCountry() {
    return this.get("country")
  }

  getState() {
    return this.get("state")
  }

  getName() {
    return this.raw.name
  }

  getAbbreviation() {
    return this.raw.abbrev
  }

  getNameWithLocation() {
    const nearestLocation = this.findNearestLocation()
    let name = this.getName()
    if (nearestLocation) {
      name = "[" + nearestLocation.getName() + "] " + name
    }
    return name
  }

  getNameWithParent() {
    const parent = this.getParent()
    let name = this.getName()
    if (parent !== null) {
      name = "[" + parent.getName() + "] " + name
    }
    return name
  }

  getHistory() {
    const h = this.get("history")
    if (h !== undefined) {
      return h
    } else {
      return []
    }
  }

  getFields() {
    return this.raw
  }

  getColorAsHex() {
    return (
      "#" +
      (this.raw.color || [0, 0, 0])
        .map(c =>
          parseInt(c || 0, 10)
            .toString(16)
            .padStart(2, 0)
        )
        .join("")
    )
  }

  getColorAsRGBA(alpha) {
    const c = this.raw.color || [0, 0, 0]
    const res = "rgba(" + c[0] + "," + c[1] + "," + c[2]
    if (alpha !== undefined) {
      return res + "," + alpha + ")"
    } else {
      return res + ")"
    }
  }

  isLocation() {
    return !!this.raw.isLocation
  }

  getOpeningHours() {
    return this.raw.openingHours || []
  }

  getOpensOnDate(defaultValue) {
    return this.openingHoursDelegate.getOpensOnDate(defaultValue)
  }
  getClosesOnDate(defaultValue) {
    return this.openingHoursDelegate.getClosesOnDate(defaultValue)
  }
  getEarliestOpens(defaultValue) {
    return this.openingHoursDelegate.getEarliestOpens(defaultValue)
  }
  getLatestCloses(defaultValue) {
    return this.openingHoursDelegate.getLatestCloses(defaultValue)
  }

  getLevel() {
    if (this.isRoot()) {
      return 0
    } else {
      let lvl = 0
      const up = node => {
        if (node.isRoot()) {
          return
        } else {
          lvl++
          return up(node.getParent())
        }
      }
      up(this)
      return lvl
    }
  }

  appendChild(node) {
    this.children.push(node)
    node.setParent(this)
  }

  setParent(node) {
    this.parent = node
  }

  getParent() {
    return this.parent
  }

  getChildren() {
    return this.children
  }

  getChild(idx) {
    return this.children.length > idx ? this.children[idx] : null
  }

  getSiblings() {
    return this.getParent()
      .getChildren()
      .filter(c => c.id !== this.id)
  }

  hasFieldValue(fieldname) {
    const value = this.get(fieldname)
    return value !== undefined && value !== null
  }

  /**
   * Return the valid Timezone for this node.
   *
   * @param pureDateTime a @mep24/PureDateTime to determine the valid timezone.
   * @param useNewest (optional) if this is set the pureDateTime parameter
   *                  can be undefined and the most current timezone
   *                  is returned.
   * @return a timezone object
   */
  getTimezone(pureDateTime, { useNewest = false } = {}) {
    if (pureDateTime === undefined && useNewest !== true) {
      throw new TimezoneDateMissing("undetermined-timezone-date")
    }
    const timezones = this.get("timezones")
    if (timezones === undefined) {
      // for client-only new nodes
      return undefined
    }
    if (useNewest === true) {
      // XXX: assumes that the array is ordered on effectivedate!
      return timezones[timezones.length - 1]
    }
    // TODO: search for most recent effectivedate <= pureDateTime
    return timezones[timezones.length - 1]
  }

  getTimezoneName(pureDateTime, { useNewest = false } = {}) {
    const timezone = this.getTimezone(pureDateTime, { useNewest })
    return timezone ? timezone.zoneName : undefined
  }

  /**
   * @return the stateKey from the nearest location node with a state.
   */
  getStateKey() {
    const location = findNearestLocationWithValueFor(this, "state")
    return location ? location.get("state") : undefined
  }

  /**
   * Find the nearest ancestor node that is a location
   *
   * @param includeThisNode Whether to return this node in case it is
   *        already a location (returns null otherwise!)
   *
   * @return A hierarchy node, or null
   */
  findNearestLocation(includeThisNode = false) {
    if (this.isLocation() && !includeThisNode) {
      return null
    }
    return findNearestLocationFor(this)
  }

  /**
   * Return the node with this ID, or `null`
   *
   * Note: This is depth first; consider alternatives if used heavily!
   *       (Read: Build an index during construction, on every node.)
   */
  find(id) {
    let result = null
    if ("" + id === "" + this.id) {
      result = this
    } else {
      // eslint-disable-next-line
      recursive_children: {
        for (const c of this.children) {
          const rec = c.find(id)
          if (rec !== null) {
            result = rec
            // eslint-disable-next-line
            break recursive_children
          }
        }
      }
    }
    return result
  }

  /**
   * Collect all nodes from _here_ to root.
   */
  getPathToRootNode() {
    const parent = this.getParent()
    if (parent !== null) {
      return [this, ...parent.getPathToRootNode()]
    } else {
      return [this]
    }
  }

  /**
   * Get next node "up", i.e. the left sibling or the parent
   */
  goUp() {
    if (this.isRoot()) {
      return null
    }
    const allSiblings = this.getParent().getChildren()
    const myIdx = allSiblings.indexOf(this)
    if (myIdx > 0) {
      return allSiblings[myIdx - 1]
    }
    return this.getParent()
  }

  /**
   * Get next node "down", i.e. the right sibling or the first child, or the
   * parent's next sibling
   */
  goDown() {
    if (this.getChild(0)) {
      return this.getChild(0)
    }
    const parent = this.getParent()
    if (parent !== null) {
      const allSiblings = parent.getChildren()
      const myIdx = allSiblings.indexOf(this)
      if (myIdx < allSiblings.length - 1) {
        return allSiblings[myIdx + 1]
      }
      return this.findViableFirstSibling(parent)
    }
    return null
  }

  /**
   * Similar to goUp, but never leaves the siblings tree.
   */
  getPreviousSibling() {
    if (this.isRoot()) {
      return undefined
    }
    const allSiblings = this.getParent().getChildren()
    const myIdx = allSiblings.indexOf(this)
    if (myIdx > 0) {
      return allSiblings[myIdx - 1]
    } else {
      return undefined
    }
  }

  /**
   * Similar to goDown, but never leaves the siblings tree.
   */
  getNextSibling() {
    if (this.isRoot()) {
      return undefined
    }
    const allSiblings = this.getParent().getChildren()
    const myIdx = allSiblings.indexOf(this)
    if (myIdx < allSiblings.length) {
      return allSiblings[myIdx + 1]
    } else {
      return undefined
    }
  }

  isOnlySibling() {
    if (this.isRoot()) {
      return true
    }
    const allSiblings = this.getParent().getChildren()
    return allSiblings.length === 1
  }

  isFirstSibling() {
    if (this.isRoot()) {
      return true
    }
    const allSiblings = this.getParent().getChildren()
    const myIdx = allSiblings.indexOf(this)
    return myIdx === 0
  }

  isLastSibling() {
    if (this.isRoot()) {
      return true
    }
    const allSiblings = this.getParent().getChildren()
    const myIdx = allSiblings.indexOf(this)
    return myIdx === allSiblings.length - 1
  }

  /**
   * @returns a flat list of all descendant nodes and this node, depth first
   */
  getSubtreeAsArray() {
    return this.traverse().map(({ orgUnit }) => orgUnit)
  }

  /**
   * @returns a flat list of nodes and their indentation levels, depth first
   *          order: [ { lvl, orgUnit }]; nodes are full Hierarchy instances!
   */
  traverse(lvl) {
    if (lvl === undefined) {
      lvl = 0
    }
    return [
      { lvl, orgUnit: this },
      ...this.children.reduce((acc, c) => [...acc, ...c.traverse(lvl + 1)], []),
    ]
  }

  /**
   * For navigation inside the hierarchy the next node might not
   * always be a child.
   *
   * @returns the child or the next sibling of the node
   *          or the first next sibling of the ancestors or null.
   */
  findViableFirstSibling(node) {
    if (node.isRoot()) {
      return null
    }
    const allSiblings = node.getParent().getChildren()
    const myIdx = allSiblings.indexOf(node)
    if (myIdx < allSiblings.length - 1) {
      return allSiblings[myIdx + 1]
    }
    return this.findViableFirstSibling(node.getParent())
  }

  toString(lvl) {
    if (lvl === undefined) {
      lvl = 0
    }
    const name = this.get("name") + " (" + this.get("id") + ")"
    return (
      "" +
      name.padStart(name.length + lvl, " ") +
      (this.children && this.children.length > 0 ? "\n" : "") +
      this.children.map(c => c.toString(lvl + 1)).join("\n")
    )
  }

  addTransientNodeFor(parentId) {
    const json = getEmptyOrgUnitProps({
      parentId,
      id: "new",
    })
    const node = new Node(json)
    const parent = this.find(parentId)
    parent.appendChild(node)
  }

  isTransient() {
    return "" + this.get("id") === "new"
  }

  /**
   * @param units The list of all units (as described above),
   *              used for initial construction
   */
  static createHierarchy(units, clientId = undefined) {
    units = units || []
    const rawRoot = units.find(u => u.isRoot)
    if (rawRoot) {
      const root = new Node(rawRoot, clientId)
      Node.createNodes(units, root, rawRoot.children)
      return root
    } else {
      return null
    }
  }

  static createNodes(units, parent, nextChildren) {
    ;(nextChildren || [])
      .map(cid => units.find(u => u.id === cid))
      .sort(orgaSort)
      .forEach(c => {
        if (c !== undefined) {
          // could be isDeleted, isArchived
          const cNode = new Node(c)
          Node.createNodes(units, cNode, c.children)
          parent.appendChild(cNode)
        }
      })
  }
}

const findNearestLocationFor = node =>
  node === null
    ? null
    : node.isLocation()
    ? node
    : findNearestLocationFor(node.getParent())

const findNearestLocationWithValueFor = (node, fieldname) =>
  node === null
    ? null
    : node.isLocation() && node.hasFieldValue(fieldname)
    ? node
    : findNearestLocationWithValueFor(node.getParent(), fieldname)

const orgaSort = (a, b) => a.posinparent - b.posinparent
