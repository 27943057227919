export default {
  de: {
    "Dismiss until next time?":
      "Änderungshinweise erst bei neuen Änderungen wieder anzeigen",
    "Keep until next time?": "Bei der nächsten Anmeldung wieder anzeigen",
    Changes: "Neu in mina.works",
    "dont-dismiss-login-info": "Beim nächsten Mal anzeigen",
    "dismiss-login-info": "Bei neuen Änderungen anzeigen",
  },
  en: {
    "Dismiss until next time?":
      "Would you like to dismiss this window until there is new information?",
    "Keep until next time?": "Display again next time I sign in",
    Changes: "Changes",
    "dont-dismiss-login-info": "Show next time",
    "dismiss-login-info": "Only show for new changes",
  },
} as {
  [lng: string]: Record<string, string>
}
