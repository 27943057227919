/**
 * Load l10n and i18n, and inject intl into context
 * (react-intl)
 *
 * This is very hard to test in any interesting way, and we're already
 * globally mocking react-intl anyway, therefore this module has NO TEST
 *
 * TODO: We might think about a slightly integrate-y unit test that
 * actually tests the IntlProvider integration with a simulated
 * navigator.language
 */
import React from 'react'

import LanguageSwitcher
  from '../modules/languagePicker/containers/LanguageSwitcher'

/**
 * HOC to wrap the main App component in an IntlProvider
 */
const wrapAppIntl = AppComponent => {
  return (
    <LanguageSwitcher>
      <AppComponent />
    </LanguageSwitcher>
  )
}

export default wrapAppIntl
