import * as React from "react"

export type Position = "left" | "center" | "right"

export interface Props {
  position: Position
  children?: React.ReactNode
}

export default class HorizontalNavbarSegment extends React.PureComponent<Props> {
  render() {
    return (
      <div
        className={[
          "mm-horizontal-navbar-segment",
          `mm-horizontal-navbar-segment-${this.props.position}`,
        ].join(" ")}
      >
        {this.props.children}
      </div>
    )
  }
}
