import * as React from "react"
import type { MenuProps } from "antd"
import { Button, Divider, Dropdown } from "antd"
import {
  BoldOutlined,
  CaretDownOutlined,
  ItalicOutlined,
  LinkOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons"
import { useIntl } from "react-intl"

interface Props {
  isBulletList: boolean
  isBold: boolean
  isItalic: boolean
  isLink: boolean
  makeHeading: (lvl?: number) => void
  makeParagraph: () => void
  toggleBulletList: () => void
  toggleBold: () => void
  toggleItalic: () => void
  toggleLink: () => void
  clearEditor: () => void
}

export default function MainToolbar({
  isBulletList,
  isBold,
  isItalic,
  isLink,
  toggleLink,
  makeHeading,
  makeParagraph,
  toggleBulletList,
  toggleBold,
  toggleItalic,
  clearEditor,
}: Props): JSX.Element {
  const intl = useIntl()
  const handleFormatMenuClick: MenuProps["onClick"] = ({
    key,
  }: {
    key: string
  }) => {
    switch (key) {
      case "h1":
        makeHeading(1)
        break
      case "h2":
        makeHeading(2)
        break
      case "normal":
        makeParagraph()
        break
      default:
    }
  }
  const formatMenu: MenuProps["items"] = [
    {
      label: intl.formatMessage({ id: "normal-text" }),
      key: "normal",
    },
    {
      label: intl.formatMessage({ id: "heading-1" }),
      key: "h1",
    },
    {
      label: intl.formatMessage({ id: "heading-2" }),
      key: "h2",
    },
  ]
  return (
    <div className="simple-editor-toolbar">
      <Button
        type={isBold ? "primary" : "default"}
        icon={<BoldOutlined></BoldOutlined>}
        onMouseDown={toggleBold}
      ></Button>
      &nbsp;
      <Button
        type={isItalic ? "primary" : "default"}
        icon={<ItalicOutlined></ItalicOutlined>}
        onMouseDown={toggleItalic}
      ></Button>
      &nbsp;
      <Button
        type={isBulletList ? "primary" : "default"}
        icon={<UnorderedListOutlined></UnorderedListOutlined>}
        onMouseDown={toggleBulletList}
      ></Button>
      <Divider type="vertical"></Divider>
      <Button
        type={isLink ? "primary" : "default"}
        icon={<LinkOutlined></LinkOutlined>}
        onMouseDown={React.useCallback(() => toggleLink(), [toggleLink])}
      ></Button>
      <Divider type="vertical"></Divider>
      <Dropdown
        trigger={["click"]}
        menu={{
          items: formatMenu,
          onClick: handleFormatMenuClick,
        }}
      >
        <Button>
          {intl.formatMessage({ id: "formats-dropdown" })}
          <CaretDownOutlined></CaretDownOutlined>
        </Button>
      </Dropdown>
      <Divider type="vertical"></Divider>
      <Button style={{ float: "right" }} onMouseDown={clearEditor}>
        {intl.formatMessage({ id: "empty" })}
      </Button>
    </div>
  )
}
