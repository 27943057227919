/**
 * Show globaly important messages like errors or status updates at the
 * top of the window.
 */

import React from 'react'
import {
  Alert
} from 'antd'

import './css/globalMessage.css'

const GlobalMessage = ({
  globalMessage,
  afterClose
}) => {
  return (
    <React.Fragment>
      {
        globalMessage.message &&
        <Alert
          className='globalMessage'
          closable={ true }
          type={ globalMessage.type }
          message={ globalMessage.message }
          description={ globalMessage.description }
          afterClose={ afterClose }
        />
      }
    </React.Fragment>
  )
}

export default GlobalMessage
