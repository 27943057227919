import * as React from 'react'
import { makeField } from '../../connected-form'

/**
 * Render a field; don't use this directly - use connectForm!
 *
 * Note that rendering makes in-place changes to the field object! (Translations
 * of various labels.)
 */
export function render(f: any, intl: any, Field: any) {
  // TODO: process undefined correctly
  // TODO: Fix ConnectedFormField interface for label and placeholder (-intl)
  f = translateField(f, id => intl.formatMessage({ id }))
  const ReduxField = makeField({ Field })
  const fieldOptions = (f.fieldOptions !== undefined)
    ? {
      ...f.fieldOptions,
      help:
        (f.fieldOptions.help !== undefined)
          ? intl.formatMessage({ id: f.fieldOptions.help })
          : undefined
    }
    : {}
  return (
    <ReduxField
      { ...fieldOptions }
      intl = { intl }
      type={ f.type }
      name={ f.name }
      key={ f.name }
      label={ f.label }
      placeholder={ f.placeholder }
      readOnly={ f.readOnly }
      { ...(f.options !== undefined ? { options: f.options } : {}) }
    />
  )
}

function translateField(field: any, translate: any) {
  const newField = {}
  Object.getOwnPropertyNames(field).forEach(key => {
    newField[key] = translateKey(field, key, translate)
  })
  return newField
}

function translateKey(field: any, key: any, translate: any) {
  let result = field[key]
  switch (key) {
  // disabling option text translation because react-intl doesn't want us to
  // use defaultMessage...
  // case 'text':
  case 'label':
  case 'placeholder':
    if (field[key]) {
      result = translate(field[key])
    }
    break
  case 'options':
    result = translateOptions(field[key], translate)
    break
  default:
    break
  }
  return result
}

function translateOptions(options: any, translate: any) {
  return options.map(
    o => Object.getOwnPropertyNames(o).reduce(
      (newO, key) => {
        newO[key] = translateKey(o, key, translate)
        return newO
      },
      {}
    )
  )
}
