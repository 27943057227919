import { DeleteOutlined } from "@ant-design/icons"
import { Button } from "antd"
import * as React from "react"
import type { IntlShape } from "react-intl"
import { injectIntl } from "react-intl"
import PlannerTime from "../../shared/plannerDateTime/PlannerTime"
import TimeWidget from "../../shared/TimeWidget/TimeWidget"
import type { Break } from "../configuration/PlannedDay"
import type EditableLoggedEntry from "./EditableLoggedEntry"
import { simpleTime } from "./EditableLoggedEntry"

export interface BreakFormProps {
  intl: IntlShape
  entry: EditableLoggedEntry
  break: Break
  idx: number // TODO: remove break
  removeBreak: (idx: number) => void
  entryUpdateCallback: () => void
  startDate: string
}

export interface BreakFormState {
  windowStartTime?: string
  windowEndTime?: string
  lengthInMinutes?: number
}

class MobileBreakForm extends React.Component<BreakFormProps, BreakFormState> {
  constructor(props: BreakFormProps) {
    super(props)
    this.state = {
      windowStartTime: "00:00",
      windowEndTime: "24:00",
    }
    this.changeStartTime = this.changeStartTime.bind(this)
    this.changeEndTime = this.changeEndTime.bind(this)
    this.changeLength = this.changeLength.bind(this)
    this.removeBreak = this.removeBreak.bind(this)
  }

  componentDidMount(): void {
    const windowStartTime = this.props.break.getStartTime() ?? "00:00"
    const windowEndTime = this.props.break.getEndTime() ?? "24:00"
    this.setState({
      windowStartTime,
      windowEndTime,
      lengthInMinutes: this.getLengthInMinutes(windowStartTime, windowEndTime),
      /* this.props.break.getDurationInMinutes(), */
    })
  }

  componentDidUpdate(oldProps: BreakFormProps): void {
    const oBreak = oldProps.break
    const brk = this.props.break
    if (
      oBreak !== brk ||
      (oBreak.getStartTime() && oBreak.getStartTime() !== brk.getStartTime()) ||
      (oBreak.getEndTime() && oBreak.getEndTime() !== brk.getEndTime())
    ) {
      const windowStartTime = this.props.break.getStartTime() ?? "00:00"
      const windowEndTime = this.props.break.getEndTime() ?? "24:00"
      this.setState({
        windowStartTime,
        windowEndTime,
        lengthInMinutes: this.getLengthInMinutes(
          windowStartTime,
          windowEndTime
        ),
      })
    }
  }

  render(): JSX.Element | null {
    const e = this.props.break
    if (!e) {
      return null
    } else {
      return this.renderBreakDetails()
    }
  }

  private removeBreak() {
    this.props.removeBreak(this.props.idx)
  }

  private changeStartTime(tp: PlannerTime) {
    const t = tp.toString()
    const lengthInMinutes = this.getLengthInMinutes(t, this.state.windowEndTime)
    this.props.break.getBreakV3().startTime = simpleTime(t)
    this.props.break.getBreakV3().durationInMinutes = lengthInMinutes
    this.setState(
      {
        windowStartTime: t,
        lengthInMinutes,
      },
      this.props.entryUpdateCallback
    )
  }

  private changeEndTime(tp: PlannerTime) {
    const t = tp.toString()
    const lengthInMinutes = this.getLengthInMinutes(
      this.state.windowStartTime,
      t
    )
    this.props.break.getBreakV3().endTime = simpleTime(t)
    this.props.break.getBreakV3().durationInMinutes = lengthInMinutes
    this.setState(
      {
        windowEndTime: t,
        lengthInMinutes,
      },
      this.props.entryUpdateCallback
    )
  }

  private changeLength(m: number) {
    this.props.break.getBreakV3().durationInMinutes = m
    this.setState({ lengthInMinutes: m })
  }

  private getLengthInMinutes(s?: string, e?: string): number {
    s = s !== undefined ? s : "00:00"
    e = e !== undefined ? e : "24:00"
    const [sh, sm] = s.split(":").map(x => parseInt(x, 10))
    const [eh, em] = e.split(":").map(x => parseInt(x, 10))
    const [y, m, d] = this.props.startDate.split("-").map(x => parseInt(x, 10))
    const sd = new Date(y, m - 1, d, sh, sm)
    const ed = new Date(y, m - 1, e < s ? d + 1 : d, eh, em)
    return (ed.getTime() - sd.getTime()) / 60000
  }

  private renderBreakDetails() {
    return (
      <div className="cal-break-details">
        <TimeWidget
          value={PlannerTime.fromJSON(this.state.windowStartTime ?? "00:00")}
          onChange={this.changeStartTime}
          size="small"
        ></TimeWidget>
        <span className="break-entry-separator">&ndash;</span>
        <TimeWidget
          value={PlannerTime.fromJSON(this.state.windowEndTime ?? "24:00")}
          onChange={this.changeEndTime}
          size="small"
        ></TimeWidget>
        <div className="mce-remove-break">
          <Button danger type="link" size="small" onClick={this.removeBreak}>
            <DeleteOutlined />
          </Button>
        </div>
      </div>
    )
  }
}

export default injectIntl(MobileBreakForm)
