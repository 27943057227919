/**
 * Toplevel error container component; default reducer adds error to state
 * (for *all* actions containing an "error" field!)
 */

import React from "react"
import { connect } from "react-redux"
import { injectIntl } from "react-intl"
import { Alert } from "antd"

const ErrorContainer = ({ errors = [], intl, remove }) => {
  const res = errors
    .filter(e => e.title && e.message) // sanitize
    .map((e, idx) => (
      <Alert
        key={"error-" + idx}
        className="globalMessage"
        closable={true}
        type="error"
        message={intl.formatMessage({ id: e.title })}
        description={intl.formatMessage({ id: e.message })}
        onClose={() => remove(e)}
      />
    ))
  return res
}

export default connect(
  state => ({
    // TODO: add selector when convenient
    errors: state.errorHandler ? state.errorHandler : [],
  }),
  dispatch => ({
    // TODO: add action and const when convenient
    remove: rerror => dispatch({ type: "removeError", rerror }),
  })
)(injectIntl(ErrorContainer))
