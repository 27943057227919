/* The login modal component */

import React from "react"
import { Modal } from "antd"

import LoginFormContainer from "../loginFormWithOTP/LoginFormApp"
import "./css/login.css"

/**
 * LoginModal modal component
 */
const LoginModal = props => {
  const {
    onCancel,
    openLoginDialog,
    // translate
  } = props
  return (
    <Modal
      // title={ translate('login') }
      getContainer={document.getElementById("root")}
      className="login-modal"
      onCancel={onCancel}
      closable={false}
      open={openLoginDialog}
      footer={null}
      style={{
        top: "calc(100vh / 4)",
      }}
      maskStyle={{
        backgroundImage: "url(/mina-login-bg.svg)",
        backgroundSize: "cover",
        backgroundColor: "rgba(255,255,255,1)",
        // top: '60px'
      }}
    >
      <LoginFormContainer />
    </Modal>
  )
}

export default LoginModal
