import * as React from "react"
import "./less/horizontalNavbar.less"

export interface Props {
  className?: string
  topPx?: number
  children?: React.ReactNode
  extraClassName?: string
}

export default class HorizontalNavbar extends React.Component<Props> {
  render(): JSX.Element {
    return (
      <div className={this.getClassName()} style={this.getStyle()}>
        {this.props.className ? (
          <div
            className={`mm-horizontal-navbar-border-container ${this.props.extraClassName}`}
          >
            {this.props.children}
          </div>
        ) : (
          <div className="mm-horizontal-navbar-shadow-container">
            <div
              className={`mm-horizontal-navbar-border-container ${this.props.extraClassName}`}
            >
              {this.props.children}
            </div>
          </div>
        )}
      </div>
    )
  }

  private getClassName(): string {
    // The "-bordered" class is to move the border back under the container
    // (with the shadow it must be with the shadow container!)
    const cls = this.props.className
      ? ` ${this.props.className} mm-horizontal-navbar-bordered`
      : ""
    return `mm-horizontal-navbar${cls}`
  }

  private getStyle() {
    return {
      top: this.props.topPx ?? 0,
    }
  }
}
