import { Button, Form, Input, Rate, Spin } from "antd"
import * as React from "react"
import { useIntl } from "react-intl"
import makeBackendService from "./BackendService"

const RATINGDISAPPEARSAFTERMILLISECONDS = 2000

export interface Props {
  correlationId?: string
}

export default function SupportRateWidget({
  correlationId,
}: Props): JSX.Element | null {
  const intl = useIntl()
  const [loading, setLoading] = React.useState(false)
  const [logged, setLogged] = React.useState<string | undefined>()
  const [score, setScore] = React.useState<{
    usefulnessScore?: number
    correctnessScore?: number
    remarks?: string
  }>({})
  const changeRemarks = React.useCallback(
    (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
      setScore({
        usefulnessScore: score.usefulnessScore,
        correctnessScore: score.correctnessScore,
        remarks: evt.target.value as string,
      })
    },
    [setScore, score]
  )
  const changeCorrectnessScore = React.useCallback(
    (correctnessScore: number) => setScore({ ...score, correctnessScore }),
    [setScore, score]
  )
  const changeUsefulnessScore = React.useCallback(
    (usefulnessScore: number) => setScore({ ...score, usefulnessScore }),
    [setScore, score]
  )
  const submit = React.useCallback(() => {
    if (
      score.usefulnessScore !== undefined &&
      score.correctnessScore !== undefined &&
      correlationId !== undefined
    ) {
      setLoading(true)
      void makeBackendService()
        .logUserScore({
          correlationId,
          remarks: "",
          ...(score as {
            usefulnessScore: number
            correctnessScore: number
          }),
        })
        .then(() => {
          setTimeout(
            () => setLogged(correlationId),
            RATINGDISAPPEARSAFTERMILLISECONDS
          )
        })
    }
  }, [setLoading, setLogged, score, correlationId])
  const cancel = React.useCallback(() => {
    setLogged(correlationId)
  }, [setLogged, correlationId])
  React.useEffect(() => setLoading(false), [logged])
  React.useEffect(() => setScore({}), [correlationId])
  if (correlationId === logged) {
    return null
  }
  return (
    <div className="submit-chat-score-container">
      <Spin className="submit-chat-spin-container" spinning={loading}>
        <div className="submit-chat-score-form">
          <Form
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            size="small"
            disabled={correlationId === logged}
          >
            <Form.Item label={intl.formatMessage({ id: "rate-correctness" })}>
              <Rate
                value={score.correctnessScore}
                onChange={changeCorrectnessScore}
              ></Rate>
            </Form.Item>
            <Form.Item label={intl.formatMessage({ id: "rate-usefulness" })}>
              <Rate
                value={score.usefulnessScore}
                onChange={changeUsefulnessScore}
              ></Rate>
            </Form.Item>
            <Form.Item label={intl.formatMessage({ id: "rate-remarks" })}>
              <Input.TextArea
                rows={2}
                value={score.remarks}
                onChange={changeRemarks}
              ></Input.TextArea>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" onClick={submit}>
                {intl.formatMessage({ id: "submit" })}
              </Button>{" "}
              <Button onClick={cancel}>
                {intl.formatMessage({ id: "cancel" })}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Spin>
    </div>
  )
}
