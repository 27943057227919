import React from "react"
import { Button, Popover } from "antd"

export interface ButtonProps {
  key?: string
  popUpContent?: string
  onClickParameter?: string | number
  buttonIcon?: React.ReactNode
  buttonContent?: string
  shape?: "circle" | "round"
  disabled?: boolean
  primary?: boolean
  danger?: boolean
  negative?: boolean
  size?: "large" | "small" | "middle" // TODO: Default is missing from v4
  placement?:
    | "top"
    | "left"
    | "right"
    | "bottom"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight"
    | "leftTop"
    | "leftBottom"
    | "rightTop"
    | "rightBottom"
  marginRight?: string
  marginLeft?: string
  dataE2E?: string
  onClickHandler?(param?: string | number): void
}

/**
 * Most of the params for this function are params borrowed from (and passed
 * down to) antd's `Button`. `negative` is an alternative way of specifying a
 * `danger` button type. The margins are style options for the Button.
 */
export default function createPopupButton({
  key,
  popUpContent,
  onClickHandler,
  onClickParameter,
  buttonIcon,
  buttonContent,
  shape,
  disabled = false,
  primary = false,
  danger = false,
  negative = false,
  size = "middle",
  placement = "top",
  marginRight = "4px",
  marginLeft = "0px",
  dataE2E = "",
}: ButtonProps): JSX.Element {
  const type = primary
    ? "primary"
    : negative || danger
    ? "dashed" // TODO: Danger type is missing!
    : "default"
  if (popUpContent === buttonContent) {
    return (
      <Button
        key={key}
        disabled={disabled ? true : false}
        icon={buttonIcon}
        type={type}
        size={size}
        shape={shape}
        style={{
          marginRight,
          marginLeft,
        }}
        data-e2e-test-id={dataE2E}
        onClick={() =>
          onClickHandler ? onClickHandler(onClickParameter) : null
        }
      >
        {buttonContent}
      </Button>
    )
  } else {
    return (
      <Popover key={key} content={popUpContent} placement={placement}>
        <Button
          disabled={disabled ? true : false}
          icon={buttonIcon}
          type={type}
          size={size}
          shape={shape}
          style={{
            marginRight,
            marginLeft,
          }}
          data-e2e-test-id={dataE2E}
          onClick={() =>
            onClickHandler ? onClickHandler(onClickParameter) : null
          }
        >
          {buttonContent}
        </Button>
      </Popover>
    )
  }
}
