const messages = {
  "infoPages.heading": "Account overview",
  "infoPages.month": "Billing month",
  "infoPages.totalUsers": "Total users",
  "infoPages.activeUsers": "Active users",
  "infoPages.lockedUsers": "Locked users",
  "infoPages.hiddenUsers": "Hidden users",
  "infoPages.deletedUsers": "Deleted users",
  "infoPages.archivedUsers": "Archived users",
  "infoPages.licensedUsers": "Licensed users",
  "infoPages.licenseVolume": "License volume",
  "infoPages.currentlyInUse": "Currently in use",
  "infoPages.stillRemaining": "Still remaining",
  "infoPages.exceededLicense":
    "Exceeded license limit. Please contact Mina.Works to change your license volume.",
  "infoPages.clientInfos": "Master data",
  "infoPages.licensInformations": "License information",
  "infoPages.contactInfos": "Save contact data",
  "infoPages.phone": "Phone",
  "infoPages.email": "Email",
  "infoPages.effectiveusers": "Number of users",
  "infoPages.billedusers": "Number of billed users",
  "infoPages.effectivedate": "Valid from",
  "infoPages.expirydate": "Valid until",
  "infoPages.licensesTable": "Billing",
  "infoPages.nolicense": "no license",
  "infoPages.currentStats": "Current usage key figures",
  "infoPages.keyFiguresLicences": "Licenses",
  "infoPages.keyFiguresUsers": "Employees",
  "infoPages.keyFiguresCalculationProfiles": "Calculation profiles",
}

export default messages
