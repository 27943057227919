const messages = {
  "context.context": "Current Context",
  "context.client": "Client",
  "context.orgunit": "Organisatonal Unit",
  "context.no-contact": "No contact",
  "context.contact": "Contact",
  "context.select-client": "Select client",
}

export default messages
