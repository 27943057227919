import {
  PauseCircleOutlined,
  PlayCircleOutlined,
  StopOutlined,
} from "@ant-design/icons"
import { Button, Statistic } from "antd"
import * as React from "react"
import type PunchedStep from "../configuration/PunchedStep"
import { useIntl } from "react-intl"
import { isoDateStringToJSDate } from "@mina-works/datetime-utils"
import makeCounterString from "./makeCounterString"
import useRunningBreak from "./hooks/useRunningBreak"
import useSumsForToday from "./hooks/useSumsForToday"

interface Props {
  date?: string
  play: () => void
  pause: () => void
  stop: () => void
  steps: PunchedStep[]
}

export default function MobilePunchControls({
  date,
  play,
  pause,
  stop,
  steps,
}: Props): JSX.Element {
  const intl = useIntl()
  const { breakRunningSeconds } = useRunningBreak({ steps })
  const { currentStep, started } = useSumsForToday({ steps })
  const jsDate = React.useMemo(
    () => (date ? isoDateStringToJSDate(date) : undefined),
    [date]
  )
  const breakCounter = React.useMemo(() => {
    if (breakRunningSeconds !== undefined) {
      return makeCounterString({ seconds: breakRunningSeconds })
    } else {
      return ""
    }
  }, [breakRunningSeconds])
  const isBreak = React.useMemo(
    () => breakRunningSeconds !== undefined,
    [breakRunningSeconds]
  )
  const primaryCls = React.useMemo(() => {
    return `mobile-punch-controls mobile-punch-controls-${
      isBreak ? "break" : "work"
    }`
  }, [isBreak])
  return (
    <div className={primaryCls}>
      {currentStep ? null : (
        <div className="mobile-punch-check-date">
          {jsDate?.toLocaleDateString(intl.locale)}
        </div>
      )}
      <div
        className={`mobile-punch-controls-status-title`}
        style={{
          opacity: isBreak ? "0" : "1",
        }}
      >
        {intl.formatMessage({ id: "work" })}
      </div>
      <div
        className={`mobile-punch-controls-status-title`}
        style={{
          opacity: !isBreak ? "0" : "1",
        }}
      >
        {intl.formatMessage({ id: "break" })}
      </div>
      <Status
        steps={steps}
        currentStep={currentStep}
        started={started}
      ></Status>
      <div
        className={`mobile-punch-break-status${
          breakRunningSeconds === undefined
            ? ""
            : " mobile-punch-break-status-visible"
        }`}
      >
        <Statistic value={breakCounter}></Statistic>
      </div>
      <div className="mobile-punch-controls-buttons">
        <Button
          size="large"
          shape="round"
          disabled={!!started || currentStep?.type === "end"}
          icon={<PlayCircleOutlined></PlayCircleOutlined>}
          onClick={play}
        >
          {intl.formatMessage({ id: "cmd-start" })}
        </Button>
        <Button
          size="large"
          shape="round"
          disabled={!started}
          icon={<PauseCircleOutlined></PauseCircleOutlined>}
          onClick={pause}
        >
          {intl.formatMessage({ id: "cmd-break" })}
        </Button>
        <Button
          size="large"
          shape="round"
          disabled={!started}
          icon={<StopOutlined></StopOutlined>}
          onClick={stop}
        >
          {intl.formatMessage({ id: "cmd-end" })}
        </Button>
      </div>
    </div>
  )
}

function Status({
  steps,
  currentStep,
}: {
  steps: PunchedStep[]
  currentStep?: PunchedStep
  started?: number
}): JSX.Element {
  const intl = useIntl()
  const { sumStepsInSeconds, paused } = useSumsForToday({ steps })
  const workdayEnded = React.useMemo(
    () => currentStep?.type === "end",
    [currentStep]
  )
  const value = React.useMemo(() => {
    if (workdayEnded) {
      return intl.formatMessage({ id: "end-of-day" })
    }
    if (currentStep === undefined) {
      return intl.formatMessage({ id: "punchTitle" })
    }
    if (sumStepsInSeconds > 24 * 3600) {
      return "ERR"
    }
    return makeCounterString({ seconds: sumStepsInSeconds })
  }, [workdayEnded, currentStep, sumStepsInSeconds, intl])
  return (
    <div
      className={`mobile-punch-status${
        workdayEnded ? " mobile-punch-status-after-hours" : ""
      }${paused ? " mobile-punch-status-paused" : ""}${
        currentStep === undefined ? " mobile-punch-status-not-started" : ""
      }`}
    >
      <Statistic value={value}></Statistic>
    </div>
  )
}
