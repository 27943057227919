const messages = {
  "qualifications.heading": "Qualifikationen",
  "qualifications.name": "Name",
  "qualifications.abbrv": "Abkürzung",
  "qualifications.color": "Farbe",
  "qualifications.useForQualiCheck": "Im Qualifikationscheck berücksichtigen",
  "qualifications.ignoreBreaks":
    "Pausen beim Anwesenheit-Check nicht berücksichtigen.",
  "qualifications.newButton": "Neue Qualifikation",
  "qualifications.newTitle": "Neue Qualifikation",
  "qualifications.editTitle": "Qualifikation",
  "qualifications.showarchived": "Zeige Archivierte?",
  "qualifications.archiveButton": "Archivieren",
  "qualifications.deleteButton": "Löschen",
  "qualifications.restoreButton": "Wiederherstellen",
  "qualifications.upButton": "Hoch",
  "qualifications.downButton": "Runter",
  "qualifications.moveButton": "Zur oberen Organisationseinheit umziehen",
  "qualifications.confirmDeleteHeader": "Löschen der Qualifikation bestätigen",
  "qualifications.confirmDeleteText":
    "Nach dem Löschen der Qualifikation wird sich das Verhalten/die Darstellung der bereits eingeplanten Mitarbeiter verändern!",
  "qualifications.error.editing": "Fehler",
  "qualifications.error.missingName": "Bitte einen Namen eingeben",
  "qualifications.deleteImpact":
    "Einige Benutzer haben diese Qualifikation aktuell zugewiesen. Eine Löschung würde eine Anzahl von Benutzern betreffen:",
}
export default messages
