const messages = {
  "pbbTemplateForm.newButton": "Planbaustein hinzufügen",
  "pbbTemplateForm.formModalHeader": "Planbaustein bearbeiten",
  "pbbTemplateForm.fixedOE": "*feste Abteilung ausgewählt",
  "pbbTemplateManager.toggleSortOnName": "Sortieren nach Name",
  "pbbTemplateManager.toggleSortOnAbbrv": "Sortieren nach Abkürzung",
  "pbbTemplateManager.toggleSortOnStart": "Sortieren nach Anfangszeit",
  "pbbTemplateManager.toggleSortOnEnd": "Sortieren nach Endzeit",
  "pbbTemplateManager.toggleGroupByType": "Gruppieren nach Typ",
  "pbbTemplateManager.availabilities": "Tätigkeiten",
  "pbbTemplateManager.unavailabilities": "Nichtverfügbarkeiten",
  "pbbTemplateManager.fixedOETitle": "Feste Abteilung ausgewählt!",
  "pbbTemplateManager.sortBy": "Sortieren nach",
  "Back to Planner": "Zurück zum Planer",
}

export default messages
