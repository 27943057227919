import { get } from "../shared/lib/requestUtils"

const ENABLEDURL = '/rest/supporttickets/isEnabled'
const OPENAIENABLEDURL = '/rest/experimental/isExperimentalEnabled'

export default function makeBackendService(): BackendService {
  return new BackendService()
}

export class BackendService {

  async enabled(clientId: string): Promise<boolean> {
    const r = await fetch(
      `${ENABLEDURL}?${new URLSearchParams({ clientId }).toString()}/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
    const json = await r.json()
    return json?.enabled ?? false
  }

  async openaiEnabled(): Promise<boolean> {
    const json = await get<{
      enabled: boolean
    }>(
      `${OPENAIENABLEDURL}`,
      {
        name: "openai"
      }
    )
    return json?.enabled ?? false
  }

}
