const types = {
  OPENCLIENTMANAGERDIALOG: "OPEN_CLIENT_MANAGER_DIALOG_",
  OPENCLIENTMANAGERHISTORYDIALOG: "OPEN_CLIENT_MANAGER_HISTORY_DIALOG_",
  LOADALLCLIENTS: "LOAD_ALL_CLIENTS_",
  LOADALLCLIENTSFAILED: "LOAD_ALL_CLIENTS_FAILED_",
  SAVECLIENT: "SAVE_CLIENT_",
  SAVECLIENTFAILED: "SAVE_CLIENT_FAILED_",
  CANCELEDITCLIENT: "CANCEL_EDIT_CLIENT_",
  DELETECLIENT: "DELETE_CLIENT_",
  DELETECLIENTFAILED: "DELETE_CLIENT_FAILED_",
  SAVELICENSE: "SAVE_LICENSE_",
  OPENLICENSEFORM: "OPEN_LICENSE_FORM_",
  CLOSELICENSEFORM: "CLOSE_LICENSE_FORM_",
  LOADLICENSES: "LOAD_LICENSES_",
  LOADCLIENTEXPIRATION: "LOAD_CLIENT_EXPIRATION_",
  DELETECLIENTEXPIRATION: "DELETE_CLIENT_EXPIRATION_",
  UPDATECLIENTEXPIRATION: "UPDATE_CLIENT_EXPIRATION_",
}

export default types
