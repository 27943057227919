const messages = {
  "unavailabilityReports.title": "Unavailability report",
  "unavailabilityReports.chooseTimespan": "Choose timespan",
  "unavailabilityReports.calendarWeekStart": "From week",
  "unavailabilityReports.calendarWeekEnd": "Until week",
  "unavailabilityReports.generateReport": "Generate report",
  "unavailabilityReports.generateXLSXReport": "Generate report (XLSX)",
  "unavailabilityReports.chooseUnavailabilities": "Select unavailability types",
  "unavailabilityReports.toggleArchived": "Archived",
  "unavailabilityReports.showArchived": "show",
  "unavailabilityReports.hideArchived": "hide",
}

export default messages
