/* eslint-disable max-len */
const messages: Record<string, Record<string, string>> = {
  de: {
    'selected-chat': 'KI-Chat',
    'selected-ticket': 'Ticket erstellen',
    'selected-close': 'Schließen',
    'empty-reply': 'Leider können wir auf diese Anfrage nicht eingehen.',
    failed: 'Bei der Beantwortung der Anfrage ist ein Fehler aufgetreten.',
    timeout: 'Die KI braucht zu lange, um die Frage zu beantworten.',
    'ticket-sent': "Ihre Anfrage wurde erfolgreich gespeichert",
    'ticket-failed': "Leider konnte Ihre Anfrage nicht gespeichert werden",
    'support-btn': 'Support',
    'support-h': 'Kontakt aufnehmen',
    submit: 'Abschicken',
    cancel: 'Abbrechen',
    'chat-help': 'Ihre Angaben werden mit Hilfe von OpenAI (GPT) verarbeitet!',
  },
  en: {
    'selected-chat': 'AI Chat',
    'selected-ticket': 'Submit Ticket',
    'selected-close': 'Close',
    'empty-reply': 'Sorry, but we cannot find a reply to this message.',
    failed: 'An error has occurred while handling your message.',
    timeout: 'Sorry, AI is taking too long to answer.',
    'ticket-sent': "Your request was sent",
    'ticket-failed': "Your request could not be sent",
    'support-btn': 'Support',
    'support-h': 'Start a conversation',
    submit: 'Submit',
    cancel: 'Cancel',
    'chat-help': 'Information shared with mina.works will be submitted to OpenAI for processing!',
  }

}

export default messages