import type { OrganisationalUnitV3 } from "../../commonInterfaces/PlannerV3"

export function findDepartment(
  departmentId?: string,
  roots?: OrganisationalUnitV3[]
): OrganisationalUnitV3 | undefined {
  let result
  for (const r of roots ?? []) {
    if (`${r.id}` === departmentId) {
      result = r
    }
    if (!result) {
      result = findDepartment(departmentId, r.children)
    }
    if (result) {
      break
    }
  }
  return result
}
