import { CoreForm } from '../../../../shared/view-model-form'

export function makeViewModel() {
  const fields = [
    { name: 'plusHoursYellowFrom', type: 'time',
      label: 'settings.plusHoursYellowFrom' },
    { name: 'plusHoursRedFrom', type: 'time',
      label: 'settings.plusHoursRedFrom' },
    { name: 'minusHoursYellowFrom', type: 'time',
      label: 'settings.minusHoursYellowFrom' },
    { name: 'minusHoursRedFrom', type: 'time',
      label: 'settings.minusHoursRedFrom' },
  ]
  return new CoreForm('signalSettingsForm', fields)
}
