const WEEKDAYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]

/**
 * Constants that should be used when dealing with time
 */
export default {
  WEEKDAYS,
  WEEKDAY: 'weekday',
  HOLIDAY: 'holiday',
  MONDAY: WEEKDAYS[0],
  TUESDAY: WEEKDAYS[1],
  WEDNESDAY: WEEKDAYS[2],
  THURSDAY: WEEKDAYS[3],
  FRIDAY: WEEKDAYS[4],
  SATURDAY: WEEKDAYS[5],
  SUNDAY: WEEKDAYS[6],
}
