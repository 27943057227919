export default function makeTranslations(t) {
  return {
    login: t("login"),
    logout: t("logout"),
    loggedInAs: t("loggedInAs"),
    username: t("username"),
    password: t("password"),
    settings: t("usersettings"),
    changePassword: t("changePassword"),
    profile: t("menu.userdata"),
    langSelect: t("lang.select"),
    problemReport: t("problemReportUserMenu"),
    clearProblemReport: t("clearProblemReportUserMenu"),
  }
}
