const types = {
  LOGIN: "LOGIN_",
  LOGOUT: "LOGOUT_",
  OPENLOGINDIALOG: "OPEN_LOGIN_DIALOG_",
  CLOSELOGINDIALOG: "CLOSE_LOGIN_DIALOG_",
  ISLOGGEDIN: "IS_LOGGED_IN_",
  LOGINFAILED: "LOGIN_FAILED_",
  NETWORKVERYSLOW: "NETWORK_OR_BACKEND_SLOW_OR_OFFLINE_",
  OPENPASSWORDRESETDIALOG: "OPEN_PASSWORD_RESET_DIALOG_",
  CLOSEPASSWORDRESETDIALOG: "CLOSE_PASSWORD_RESET_DIALOG_",
  APPENDPASSWORDRESETERROR: "APPEND_PASSWORD_RESET_ERROR_",
}

export default types
