import React from "react"
import { useSelector } from "react-redux"
import { ConfigProvider } from "antd"
import dayjs from "dayjs"
import "dayjs/locale/en"
import "dayjs/locale/de"

import { getLanguage } from "../../modules/languagePicker/controller"
import { extractClientLanguage } from "../../modules/languagePicker/containers/LanguageSwitcher"

import { getAntdLocale } from "../antdLocales"
import antdTheme from "../../apps/shared/antdTheme"

export const AntdLocaleWrapper = (props: {
  children: any
}): React.ReactElement => {
  const current_lang = useSelector(getLanguage)
  const lang = extractClientLanguage(current_lang)
  const locale = getAntdLocale(lang.shortName)
  dayjs.locale(lang.shortName)
  return (
    <ConfigProvider theme={antdTheme} locale={locale}>
      {props.children}
    </ConfigProvider>
  )
}
