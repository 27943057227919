import types from './actionTypes'

export default function userRoleAssignmentReducer(
  state = {},
  action
) {
  switch (action.type) {
  case types.SAVEUSERROLESFORCONTEXT:
    return saveUserRolesForContext(state, action)
  case types.LOADCLIENTROLES:
    return {
      ...state,
      clientRoles: {
        byId: action.roles.reduce(
          (acc, role) => {
            acc[role.id] = role
            return acc
          },
          {}
        )
      }
    }
  case types.LOADUSERROLESFORCLIENT:
    return loadUserRolesForClient(state, action)
  case types.LOADVALIDORGUNITS:
    return loadValidOrgUnits(state, action)
  default:
    return state
  }
}

function saveUserRolesForContext(state, action) {
  return {
    ...state,
    userRoles: {
      byId: action.uroles.reduce(
        (acc, urole) => {
          acc[urole.id] = urole
          return acc
        },
        {}
      )
    }
  }
}

function loadUserRolesForClient(state, action) {
  return {
    ...state,
    userRoles: {
      byId: action.rolesProps.roles.reduce(
        (acc, role) => {
          acc[role.id] = role
          return acc
        },
        {}
      )
    }
  }
}

function loadValidOrgUnits(state, action) {
  return {
    ...state,
    roleassignmentValidOrgUnits: {
      byId: action.orgUnits.reduce(
        (acc, orgUnit) => {
          acc[orgUnit.id] = orgUnit
          return acc
        },
        {}
      )
    }
  }
}
