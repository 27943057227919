/**
 * Include first in reducers as "errorHandler"
 *
 * Errors must have a title and a message:
 * { title, message }
 *
 * Both will be translated when displayed (if a translation is available
 * to react-intl)
 */

export default (
  state = [],
  action
) => {
  if (action.error) {
    return [...state, action.error ]
  } else if (action.type === 'removeError') {
    const r = action.rerror
    return state.filter(
      e => !( e.message === r.message && e.title === r.title)
    )
  }
  return state
}