/**
 * Selector model for the client management UI
 * XXX TODO: Not yet attached to any normalized client!
 * XXX Doesn't use advanced selectors (due to its flat nature)
 *
 * There is a "current client" concept here: We're just editing or viewing one
 * client at a time to reduce confusion
 */
import { createSelector } from "reselect"
import { PureDate } from "../../../../shared/legacy/puredatetime"
import { valueAt } from "../../../../shared/walkObjectHierarchy"

const getOpenClientManagerDialog = state =>
  valueAt(state, ["clientManager", "openDialog"])

const getOpenClientManagerHistoryDialog = state =>
  valueAt(state, ["clientManager", "openHistoryDialog"])

export const getAllClientsState = createSelector(getById, clients =>
  Object.values(clients || {}).map(c => ({
    ...c,
    // These are only extrated to the top level for the column sorter!
    licensedUsers: c.users && c.users.licensed,
    totalUsers: c.users && c.users.total,
    activeUsers: c.users && c.users.active,
    availableLicenses: c.users && c.users.remaining,
  }))
)

function getTotalStats(state) {
  return valueAt(state, ["clientManager", "totalStats"])
}

export const getTotalClientStatsState = createSelector(getTotalStats, stats => {
  return stats || {}
})

export const getCurrentClientId = state =>
  valueAt(state, ["clientManager", "currentClientId"])

export const getOpenClientManagerDialogState = createSelector(
  getOpenClientManagerDialog,
  selector => (selector ? selector : false)
)

export const getOpenClientManagerHistoryDialogState = createSelector(
  getOpenClientManagerHistoryDialog,
  selector => (selector ? selector : false)
)

/**
 * This gets the current clients persisted server state!
 */
export const getCurrentClientState = createSelector(
  getCurrentClientId,
  getById,
  (currentId, clients) =>
    currentId && clients !== undefined
      ? clients[currentId]
      : createMinimalClient()
)

function getById(state) {
  return valueAt(state, ["clientManager", "clients", "byId"])
}

function createMinimalClient() {
  return {} // may need a name field!
}

const getLicenseFormOpen = state =>
  valueAt(state, ["clientManager", "openLicenseForm"])

export const getLicenseFormOpenState = createSelector(
  getLicenseFormOpen,
  selector => (selector ? selector : false)
)

const getEditLicenseId = state =>
  valueAt(state, ["clientManager", "editLicenseId"])

export const getLicensesById = state =>
  valueAt(state, ["clientManager", "licensesById"])

export const getLicensesByIdState = createSelector(getLicensesById, licenses =>
  Object.values(licenses || {}).reduce((acc, l) => {
    acc[l.id] = {
      ...l,
      effectivedateAsPureDate: PureDate.fromString(l.effectivedate),
      expirydateAsPureDate: l.expirydate
        ? PureDate.fromString(l.expirydate)
        : undefined,
      firstbillingdateAsPureDate: PureDate.fromString(l.firstbillingdate),
    }
    return acc
  }, {})
)

export const getEditLicenseState = createSelector(
  getEditLicenseId,
  getLicensesById,
  (id, byId) => (id && byId ? byId[id] : {})
)

const getClientExpiration = state =>
  valueAt(state, ["clientManager", "expiration"])

export const getClientExpirationState = createSelector(
  getClientExpiration,
  expiration =>
    expiration && {
      ...expiration,
      expiration_date:
        expiration &&
        expiration.expiration_date &&
        PureDate.fromString(expiration.expiration_date),
    }
)
