const messages = {
  "bonusReport.title": "Work hours repot with bonuses",
  "bonusReport.generateXLSXReport": "Generate month report (XLSX)",
  "bonusReport.type": "Type",
  "bonusReport.from": "From",
  "bonusReport.to": "To",
  "bonusReport.Date": "Date",
  "bonusReport.Week": "Week",
  "bonusReport.Month": "Month",
  "bonusReport.Year": "Year",
}

export default messages
