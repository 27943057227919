import * as React from 'react'
import { Component } from 'react'
import fieldTypes from './fieldTypes'

// TODO: radio
// TODO: Special abstractions for frequently used field types

let memoizedField: any

export interface ConnectedFormFieldProps {
  readOnly: boolean,
  value: any, // TODO: Improve value type
  name: string,
  type: string,
  label?: string,
  placeholder?: string
}

/**
 * Render a simplified and translated semantic UI form field capable of
 * interaction with redux-form
 *
 * Rendering depends on the type, which is mapped to a proper control and
 * layout.
 *
 * Types are the type names of CoreForm fields, such as "string" (for inputs),
 * "color" (for ColorUI fields) etc.
 *
 * All relevant additional arguments (props), such as min, max, step,
 * are passed through to the input component!
 *
 * **Props**:
 *
 * - name  (The field's name for use by redux-form and the value mappings)
 * - type  (The field's type: currently one of
 *          - string
 *          - text
 *          - boolean
 *          - checkboxGroup
 *          - list
 *          - color
 *          - date
 *          - number
 *          - time (for points in time, i.e. HH:MM))
 *          - radioGroup
 * - label (label, translated)
 * - placeholder (placeholder, translated)
 * - value (not set by user)
 * - readOnly (to display the details instead of a form field)
 * - options (for select: {key, value, flag, text}, where flag is optional)
 *
 * Please avoid setting the value.
 *
 * The placeholder defaults to the label.
 */
export default ({ Field }: { Field: any }) => { // TODO: Improve Field type

  if ( memoizedField !== undefined ) {
    return memoizedField
  } else {
    class ConnectedFormField extends Component<ConnectedFormFieldProps> {

      /* TODO: These should be simplified! */
      static get isConnectedFormField() { return true }
      get isConnectedFormField() { return true }

      render() {
        const {
          readOnly,
          value,
          name,
          type = 'string',
          label,
          placeholder,
          ...rest
        } = this.props
        const placeholderOrLabel = placeholder === undefined
          ? label
          : placeholder
        if (readOnly) {
          const SemanticUIDetailsField = typeToControlFactory(type)(true)
          return (
            <Field
              { ...rest }
              label={ label }
              placeholder={ placeholderOrLabel }
              name={ name }
              component={ SemanticUIDetailsField }
              dataE2E={'form-field-' + name}
            />
          )
        }
        return (
          <ReduxFormField
            Field = { Field }
            { ...rest }
            label={ label }
            placeholder={ placeholderOrLabel }
            type={ type }
            name={ name }
            { ...typeDefaultValueProps(type, value) }
          />
        )
      }
    }

    memoizedField = ConnectedFormField

    return ConnectedFormField
  }

}

function ReduxFormField({Field, type, ...props}) {
  const SemanticUIFormField = typeToControlFactory(type)()
  return (
    <Field
      { ...props }
      component={ SemanticUIFormField }
      dataE2E={ 'form-field-' + props.name }
    />
  )
}

function typeToControlFactory(type) {
  if (type in fieldTypes) {
    return fieldTypes[type]
  } else {
    return fieldTypes.default
  }
}

function typeDefaultValueProps(type, value) {
  switch (type) {
  case 'checkbox':
    return { checked: value, defaultChecked: value }
  case 'textarea':
  case 'input':
  default:
    return { value, defaultValue: value }
  }
}
