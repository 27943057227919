import * as React from "react"

export default function ReloadClient(): JSX.Element {
  // Need component ONLY because the scaffolding doesn't support hooks yet
  const [, setContent] = React.useState<string>("")
  React.useEffect(() => {
    const f = () => {
      void fetch("/")
        .then(r => r.text())
        .then(newContent => {
          setContent(oldContent => {
            const differ = newContent !== oldContent
            if (differ) {
              const diffs: string[] = []
              let thisDiffNew = []
              let thisDiffOld = []
              for (let idx = 0; idx < newContent.length; idx++) {
                const idxDiffers = newContent[idx] !== oldContent[idx]
                if (!idxDiffers && thisDiffNew.length > 0) {
                  diffs.push(
                    `${thisDiffNew.join("")} <> ${thisDiffOld.join("")}`
                  )
                  thisDiffNew = []
                  thisDiffOld = []
                  continue
                } else if (idxDiffers) {
                  thisDiffNew.push(newContent[idx])
                  thisDiffOld.push(oldContent[idx])
                }
              }
              if (diffs.length > 0) {
                console.log("Reloading due to update...", diffs.join("\n"))
                window.location.reload()
              }
            }
            return newContent
          })
        })
    }
    const i = setInterval(f, 20000)
    return () => clearInterval(i)
  }, [])
  return <></>
}
