/**
 * TODO: This is still more of the example and experimental than actual code
 * in use!
 * Needs to be split into multiple modules, potentially!
 * XXX Also, there is too much logic here!
 */
import React from "react"
import MainAppMenu from "./MainAppMenu"
import MainAdminMenu from "./MainAdminMenu"

import "./less/mainMenu.less"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MainMenu = ({
  vacationBadge,
  loggedIn,
  selectedKeys,
  changeAppModule,
  onGoHome,
  networkIsVerySlow,
  isUserOutsideClients,
  usersSeen,
  canViewCalendar,
  canViewClients,
  canEditAdminAccess,
  isAdmin,
  canViewTelemetry,
  canViewConfiguration,
  canUploadHolidays,
  canViewUserReports,
  canViewCalendarReports,
  canViewUnavailabilityReports,
  canViewProcesses,
  canManageInfoMessages,
}: any): JSX.Element => {
  const main = (
    <MainAppMenu
      vacationBadge={vacationBadge}
      selectedKey={selectedKeys[0]}
      loggedIn={loggedIn}
      changeAppModule={changeAppModule}
      onGoHome={onGoHome}
      canViewCalendar={canViewCalendar}
      canViewProcesses={canViewProcesses}
      canViewConfiguration={canViewConfiguration}
      canViewUserReports={canViewUserReports}
      canViewCalendarReports={canViewCalendarReports}
      canViewUnavailabilityReports={canViewUnavailabilityReports}
      topPx={isUserOutsideClients ? 50 : 0}
      networkIsVerySlow={networkIsVerySlow}
    ></MainAppMenu>
  )
  if (isUserOutsideClients) {
    const admin = (
      <MainAdminMenu
        selectedKey={selectedKeys[0]}
        changeAppModule={changeAppModule}
        usersSeen={usersSeen}
        canViewClients={canViewClients}
        canViewTelemetry={canViewTelemetry}
        canUploadHolidays={canUploadHolidays}
        canEditAdminAccess={canEditAdminAccess}
        isAdmin={isAdmin}
        canManageInfoMessages={canManageInfoMessages}
      ></MainAdminMenu>
    )
    return (
      <>
        {admin}
        {main}
      </>
    )
  } else {
    return main
  }
}

export default MainMenu
