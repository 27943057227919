import { Divider } from "antd"
import * as React from "react"
import type { IntlShape } from "react-intl"
import { injectIntl } from "react-intl"
import HorizontalNavbar from "../lib/HorizontalNavbar"
import HorizontalNavbarItem from "../lib/HorizontalNavbarItem"
import HorizontalNavbarSegment from "../lib/HorizontalNavbarSegment"
import { MoreOutlined } from "@ant-design/icons"
import { get } from "../../../apps/shared/lib/requestUtils"

export interface Props {
  intl: IntlShape
  selectedKey?: string
  changeAppModule: (moduleName: string) => void
  usersSeen: number
  canViewClients: boolean
  canViewTelemetry: boolean
  canUploadHolidays: boolean
  canEditAdminAccess: boolean
  isAdmin: boolean
  canManageInfoMessages: boolean
}

interface State {
  burgerOpen?: boolean
  manualVisible?: boolean
  aiVisible?: boolean
}

class MainAdminMenu extends React.Component<Props, State> {
  private ref?: React.RefObject<HTMLDivElement>

  constructor(props: Props) {
    super(props)
    this.state = {}
    this.ref = React.createRef<HTMLDivElement>()
    this.toggleBurger = this.toggleBurger.bind(this)
    this.closeBurgerHandler = this.closeBurgerHandler.bind(this)
  }
  componentDidMount() {
    void (async () => {
      const manual = await get<{ enabled: boolean }>(
        "/rest/experimental/isExperimentalEnabled",
        { name: "manual" }
      )
      const ai = await get<{ enabled: boolean }>(
        "/rest/experimental/isExperimentalEnabled",
        { name: "openai" }
      )
      this.setState({ manualVisible: manual.enabled, aiVisible: ai.enabled })
    })()
  }
  render(): JSX.Element {
    return (
      <>
        <HorizontalNavbar topPx={0} className="mm-admin-menu">
          <HorizontalNavbarSegment position="left"></HorizontalNavbarSegment>
          <HorizontalNavbarSegment position="center"></HorizontalNavbarSegment>
          <HorizontalNavbarSegment position="right">
            {this.renderUsersSeen()}
            {this.renderClientMenuItem()}
            {this.renderMoreMenuItem()}
          </HorizontalNavbarSegment>
        </HorizontalNavbar>
        <div
          ref={this.ref}
          className="more-admin-menu"
          style={{
            position: "fixed",
            right: 0,
            top: 48,
            width: 200,
            zIndex: 20000,
            display: this.state.burgerOpen ? "block" : "none",
          }}
        >
          {this.state.manualVisible ? this.renderReferenceMenuItem() : null}
          {this.renderAdminAccessMenuItem()}
          {this.renderPackageOverviewMenuItem()}
          {this.renderHolidaysMenuItem()}
          {this.renderInfoMessageMenuItem()}
          {this.state.aiVisible ? this.renderAILogMenuItem() : null}
          {this.renderTelemetryMenuItem()}
        </div>
      </>
    )
  }

  private toggleBurger() {
    const burgerOpen = !this.state.burgerOpen
    this.setState({ burgerOpen }, () => {
      if (burgerOpen) {
        this.removeCloseBurgerHandler()
        document.addEventListener("click", this.closeBurgerHandler, true)
      } else {
        this.removeCloseBurgerHandler()
      }
    })
  }

  private removeCloseBurgerHandler() {
    if (this.closeBurgerHandler !== undefined) {
      document.removeEventListener("click", this.closeBurgerHandler)
    }
  }

  private closeBurgerHandler(evt: MouseEvent) {
    if (
      this.ref?.current !== undefined &&
      this.ref?.current !== null &&
      !this.ref?.current.contains(evt.target as Node)
    ) {
      this.setState({ burgerOpen: false }, () =>
        this.removeCloseBurgerHandler()
      )
    }
  }

  private renderMoreMenuItem() {
    return (
      <HorizontalNavbarItem
        value={"more-admin-menu"}
        onClick={this.toggleBurger}
      >
        <MoreOutlined></MoreOutlined>
      </HorizontalNavbarItem>
    )
  }

  private renderTelemetryMenuItem() {
    return this.props.canViewTelemetry ? (
      <>{this.renderBurgerMenuItem("telemetry", "Telemetrie", "telemetry")}</>
    ) : null
  }

  private renderAILogMenuItem() {
    return this.props.isAdmin ? (
      <>{this.renderBurgerMenuItem("ailog", "AI Log", "ailog")}</>
    ) : null
  }

  private renderInfoMessageMenuItem() {
    return this.props.canManageInfoMessages ? (
      <>
        {this.renderBurgerMenuItem(
          "infoMessageManager",
          "InfoMessages",
          "infoMessageManager"
        )}
      </>
    ) : null
  }

  private renderPackageOverviewMenuItem() {
    return (
      <>
        {this.renderBurgerMenuItem(
          "packageoverview",
          "PackageOverview",
          "packageoverview"
        )}
      </>
    )
  }

  private renderReferenceMenuItem() {
    return this.props.isAdmin ? (
      <a
        className="admin-more-menu-item"
        target="_blank"
        rel="noreferrer"
        href="/public/reference_de.html"
      >
        {this.props.intl.formatMessage({ id: "compact-reference" })}
      </a>
    ) : null
  }

  private renderAdminAccessMenuItem() {
    return this.props.canEditAdminAccess ? (
      <>
        {this.renderBurgerMenuItem(
          "adminAccessManager",
          "Administratoren",
          "adminAccessManager"
        )}
      </>
    ) : null
  }

  private renderClientMenuItem() {
    return this.props.canViewClients
      ? this.renderMenuItem(
          "clientManager",
          "Mandantenverwaltung",
          "clientManager"
        )
      : null
  }

  private renderHolidaysMenuItem() {
    return this.props.canUploadHolidays ? (
      <>
        {this.renderBurgerMenuItem(
          "holidayUpload",
          "Feiertage",
          "holidayUpload"
        )}
      </>
    ) : null
  }

  private renderUsersSeen() {
    const text = `${this.props.usersSeen} ${this.props.intl.formatMessage({
      id: "users-active",
    })}`
    return (
      <>
        <HorizontalNavbarItem
          value={"usersSeen"}
          label={text}
          currentValue={text}
        >
          {/* TODO: Move label to children prop */}
        </HorizontalNavbarItem>
        <Divider type="vertical" />
      </>
    )
  }

  private renderMenuItem(key, label, appModuleName: string) {
    return (
      <HorizontalNavbarItem
        value={key}
        label={this.props.intl.formatMessage({ id: label })}
        currentValue={this.props.selectedKey}
        onClick={() => this.props.changeAppModule(appModuleName)}
      >
        {/* TODO: Move label to children prop */}
      </HorizontalNavbarItem>
    )
  }

  private renderBurgerMenuItem(key, label, appModuleName: string) {
    return (
      <div
        className="admin-more-menu-item"
        onClick={() =>
          this.setState({ burgerOpen: false }, () =>
            this.props.changeAppModule(appModuleName)
          )
        }
      >
        {this.props.intl.formatMessage({ id: label })}
      </div>
    )
  }
}

const Exported = injectIntl(MainAdminMenu)
export default Exported
