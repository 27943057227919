import { get } from "../requestUtils"

export type LimitType =
  | "planners"
  | "contracts"
  | "calculationprofiles"
  | "transfers"
  | "lendings"
  | "autoplans"
  | "workhourscorrections"
  | "leavecorrections"
export type LimitInfo = {
  days: number | null
  notAllowedBefore?: string
}
type ApplicableLimits = Record<LimitType, LimitInfo>
type Result = {
  success: boolean
  limits?: ApplicableLimits
}

export default async function getApplicableLimit(
  type: LimitType
): Promise<LimitInfo | undefined> {
  const url = "/rest/retroactiveLimits/applicableLimits"
  let res: Result = {
    success: false,
  }
  try {
    res = await get<Result>(url)
  } catch (e) {
    console.error("An error occurred while getting the ApplicableLimits:", e)
  }
  const limits = res.limits
  return limits ? limits[type] : undefined
}

export const canMakeRetroactiveChangeFor = async (
  type: LimitType,
  date: string
): Promise<boolean> => {
  const limit = await getApplicableLimit(type)
  if (limit) {
    // Per definition a limit without a notAllowedBefore value is unlimited
    if (!limit.notAllowedBefore) {
      return true
    } else {
      return date >= limit.notAllowedBefore
    }
  } else {
    // Should be the same as on the backend!
    return false
  }
}

export const checkLimit = (date: string, limit?: LimitInfo): boolean => {
  if (limit) {
    // Per defintion a limit without a notAllowedBefore value is unlimited
    if (!limit.notAllowedBefore) {
      return true
    } else {
      return date >= limit.notAllowedBefore
    }
  } else {
    // Should be the same as on the backend!
    return false
  }
}
