import * as React from "react"
import type { IntlShape } from "react-intl"
import { injectIntl } from "react-intl"
import { ProcessActor } from "../../commonConfiguration/processes/ProcessActor"
import type { IFormField } from "../../commonInterfaces/processes/RawFormInfo"
import type { IWorkflowInstanceDetails } from "../../commonInterfaces/processes/RawProcessDetails"
import {
  OVERTIMEREQUEST,
  VACATIONREQUEST,
} from "../../commonConfiguration/processes/processTypes"

interface Props {
  intl: IntlShape
  id?: string
  clientId: string
  departmentId: string
  employeeId: string
  processType: string
  classPrefix: string
  lastChange?: any
  showTitle?: boolean
}

interface State {
  details?: IWorkflowInstanceDetails
}

class ProcessDetails extends React.PureComponent<Props, State> {
  private historyRef
  constructor(props: Props) {
    super(props)
    this.state = {}
    this.historyRef = React.createRef<HTMLDivElement>()
  }

  componentDidMount(): void {
    if (this.props.id !== undefined) {
      void this.loadDetails()
    }
  }

  componentDidUpdate(prevProps: Props): void {
    if (
      prevProps.id !== this.props.id ||
      prevProps.lastChange !== this.props.lastChange
    ) {
      void this.loadDetails()
    }
    this.scrollHistoryToBottom()
  }

  render(): JSX.Element {
    return (
      <div className={`${this.props.classPrefix}process-details`}>
        {this.state.details !== undefined ? this.renderProcessDetails() : null}
      </div>
    )
  }

  private getProcessType(): string | undefined {
    return this.state.details?.workflowType ?? this.props.processType
  }

  // TODO: any typing for dates
  private renderProcessDetails() {
    const toLocaleDateString = (d: any) =>
      d?.toLocaleDateString(this.props.intl.locale, {
        day: "numeric",
        month: "long",
        year: "numeric",
      })
    const toLocaleString = (d: any, addl = {}) =>
      d?.toLocaleString(this.props.intl.locale, {
        hour: "numeric",
        minute: "numeric",
        ...addl,
      })
    const localDateTime = (d: any, headerFormat: boolean = false) => {
      let s = ""
      const dString = toLocaleDateString(d)
      const now = new Date()
      const yesterday = new Date(now)
      yesterday.setDate(yesterday.getDate() - 1)
      if (dString === toLocaleDateString(now)) {
        s = `${this.t("today")}, ${toLocaleString(d)}`
      } else if (dString === toLocaleDateString(yesterday)) {
        s = `${this.t("yesterday")}, ${toLocaleString(d)}`
      } else {
        s = toLocaleString(d, {
          weekday: "long",
          month: "long",
          day: "numeric",
        })
      }
      return headerFormat && s.length > 0
        ? `${s[0].toUpperCase()}${s.substr(1)}`
        : s
    }
    const processType = this.getProcessType()
    let titleName =
      processType === VACATIONREQUEST
        ? this.t("VacationRequest")
        : processType === OVERTIMEREQUEST
        ? this.t("OvertimeRequest")
        : this.t("GeneralRequest")
    if (
      this.state.details &&
      this.state.details.initiatorId === this.props.employeeId
    ) {
      // The alternative isn't really valid in the mobile app!
      titleName =
        processType === VACATIONREQUEST
          ? this.t("MyVacationRequest")
          : processType === OVERTIMEREQUEST
          ? this.t("MyOvertimeRequest")
          : this.t("MyGeneralRequest")
    }
    const transactionHistory = this.state.details?.transactionHistory ?? []
    return (
      <div className={`${this.props.classPrefix}process-details-form-info`}>
        {this.props.showTitle ? <h5>{titleName}</h5> : null}
        <div
          className={`${this.props.classPrefix}details-history-entries-wrapper`}
        >
          <div className={`${this.props.classPrefix}details-history-entries`}>
            {transactionHistory.map((historyEntry, idx) => {
              const actor = new ProcessActor(historyEntry.actor)
              return (
                <div
                  className={`${this.props.classPrefix}process-details-history-entry`}
                  key={idx}
                >
                  <div
                    className={`${this.props.classPrefix}process-details-history-date`}
                  >
                    {localDateTime(new Date(historyEntry.createdAt), true)}
                  </div>
                  <div
                    className={`${this.props.classPrefix}process-details-transition-and-actor`}
                  >
                    {historyEntry.label} {this.t("history-by")}{" "}
                    {actor.getName()} - {actor.getDepartmentName()}
                  </div>
                  <div
                    className={`${this.props.classPrefix}process-details-history-fields`}
                  >
                    {historyEntry.formInfo?.map((fifield, fiidx) =>
                      this.renderProcessDetailField(fifield, fiidx)
                    )}
                  </div>
                  <div
                    className={`${this.props.classPrefix}process-details-history-message`}
                  >
                    <div className="fancy-quotation-leader">&ldquo;</div>
                    {historyEntry.message}
                    <br style={{ clear: "both", float: "none" }} />
                  </div>
                </div>
              )
            })}
            <div
              className={`${this.props.classPrefix}details-history-scroll-ref`}
              ref={this.historyRef}
            ></div>
          </div>
        </div>
      </div>
    )
  }
  private scrollHistoryToBottom() {
    this.historyRef?.current?.scrollIntoView({ behavior: "smooth" })
  }

  private t(id: string, v: { [k: string]: string } = {}): string {
    return `${this.props.intl.formatMessage({ id }, v)}`
  }

  private renderProcessDetailField(formInfoField: IFormField, idx: number) {
    let v: any = formInfoField.value
    let ds
    let de
    switch (formInfoField.datatype) {
      case "daterange":
        ds = new Date(v.startDate as string).toLocaleDateString(
          this.props.intl.locale
        )
        de = new Date(v.endDate as string).toLocaleDateString(
          this.props.intl.locale
        )
        v = `${ds} - ${de}`
        break
      default:
    }
    return (
      <div
        key={idx}
        className={`${this.props.classPrefix}process-details-history-field`}
      >
        <div className={`${this.props.classPrefix}pdhf-label`}>
          {formInfoField.label}
        </div>
        <div className={`${this.props.classPrefix}pdhf-value`}>{v}</div>
      </div>
    )
  }

  private async loadDetails() {
    // TODO: Move to configuration!
    if (this.props.id === undefined) {
      this.setState({ details: undefined })
    } else {
      const url = "/rest/workflows/getWorkflowInstanceDetails"
      const body: { [k: string]: any } = {
        workflowType: this.getProcessType(),
        orgunitId: this.props.departmentId,
        contextOrgunitId: this.props.departmentId,
        contextClientId: this.props.clientId,
        workflowInstanceId: this.props.id,
        locale: this.props.intl.locale,
      }
      const res = await fetch(url + "?" + encodeURIComponentsForActions(body), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      const details = await res.json()
      if (
        details.feedback !== undefined &&
        details.feedback?.statusCode !== "ok"
      ) {
        alert(
          `${this.t("vacareq-unexpected-error-occurred")}: ${
            details.feedback?.description ?? "-"
          }`
        )
      } else {
        this.setState({ details })
      }
    }
  }
}

function encodeURIComponentsForActions(
  props: Record<string, string | number | boolean>
): string {
  const fixURIComponent = (v: string | number | boolean) =>
    encodeURIComponent(v === undefined ? "" : v)
  return Object.getOwnPropertyNames(props)
    .reduce((acc: string[], key) => {
      acc.push(`${key}=${fixURIComponent(props[key])}`)
      return acc
    }, [])
    .join("&")
}

const ProcessDetailsWithIntl = injectIntl(ProcessDetails)
export default ProcessDetailsWithIntl
