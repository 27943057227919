import * as React from "react"
import type { IntlShape } from "react-intl"
import { injectIntl } from "react-intl"
import type { Entry } from "../configuration/PlannedDay"
import type PlannedDay from "../configuration/PlannedDay"
import { getWeekday } from "../configuration/PlannedDay"
import "./less/horizCalendar.less"

export interface Props {
  intl: IntlShape
  days: Day[]
  selectIndex: (idx: number) => () => void
  selectedIndex: number
}

// TODO: Move this interface, and improve it!
//       (Shared with PlannerData!)
export interface Day {
  plannedDay?: PlannedDay | null
  date: Date
  dateString: string
  holiday?: string
}

class MobileHorizCalendar extends React.Component<Props> {
  render(): JSX.Element {
    return <div className="mobile-horiz-calendar">{this.renderDays()}</div>
  }

  private renderDays() {
    return this.props.days.map((d, idx) => this.renderDay(d, idx))
  }

  private renderDay(d: Day, idx: number) {
    const title = d.holiday
    const changedFromAutoplan = !!(d.plannedDay?.getEntries() ?? []).find(
      e => !e.getEntryV3().isUnchangedFromAutoplan
    )
    const cls = `mobile-horiz-calendar-day-weekday-header${
      title !== undefined ? " mobile-holiday-bullet" : ""
    }`
    return (
      <div
        className={`mobile-horiz-calendar-day${
          changedFromAutoplan ? " mobile-changed-from-autoplan" : ""
        }`}
        key={idx}
      >
        <div className={cls} title={title}>
          <span>{getWeekday(d.date, this.props.intl.locale).slice(0, 1)}</span>
        </div>
        <div
          onClick={this.props.selectIndex(idx)}
          className={`mobile-horiz-calendar-day-date-header${
            this.isSelected(idx) ? " mobile-horiz-calendar-day-today" : ""
          }`}
        >
          <div className="mobile-horiz-calendar-date">
            {changedFromAutoplan ? (
              <div className="mobile-diverges-badge">&nbsp;</div>
            ) : null}
            {d.date.getDate()}
          </div>
        </div>
        <div className="mobile-horiz-calendar-day-bs">
          {this.renderDayBlobs(d.plannedDay)}
        </div>
      </div>
    )
  }

  private renderDayBlobs(d?: PlannedDay | null) {
    if (d === undefined || d === null) {
      return <span>&nbsp;</span>
    }
    return d.getEntries().map((e, idx) => this.renderDayBlob(e, idx))
  }

  private renderDayBlob(e: Entry, idx: number) {
    return (
      <div
        className="mobile-horiz-calendar-day-b"
        key={idx}
        style={{
          backgroundColor: e.getCSSColor(),
        }}
      >
        &nbsp;
      </div>
    )
  }

  private isSelected(idx: number) {
    return this.props.selectedIndex === idx
  }
}

export default injectIntl(MobileHorizCalendar)
