const messages = {
  "calendarReport.checkCol": "Ausgewählt",
  "calendarReport.name": "Name",
  "calendarReports.title": "Wochenplan",
  "calendarReports.generateReport": "Wochenplan erstellen",
  "calendarReports.generateXLSXReport": "Wochenplan erstellen (XLSX)",
  "calendarReports.toggleBreaks": "Pausen anzeigen",
  "calendarReports.toggleAccounts": "Zeitkontodaten anzeigen",
  "calendarReports.toggleObfuscate": "Abweseneheiten anoymisieren",
  "calendarReports.fiveDays": "Mo-Fr",
  "calendarReports.sixDays": "Mo-Sa",
  "calendarReports.sevenDays": "Mo-So",
  "calendarReports.calendarWeek": "Kalenderwoche auswählen",
}

export default messages
