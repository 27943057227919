import { createSelector } from 'reselect'
import { valueAt } from '../../../../shared/walkObjectHierarchy'

const getGlobalMessage = state => valueAt(state, [
  'globalMessage',
  'globalMessage'
])
export const getGlobalMessageState = createSelector(
  getGlobalMessage,
  msg => msg !== undefined ? msg : {}
)
