import { valueAt, isEqual } from '../../../../shared/walkObjectHierarchy'
import types from './actionTypes'

export default function userQualificationReducer(
  state = {},
  action
) {
  switch (action.type) {
  case types.OPENUSERQUALIFICATIONEDIT:
    return {
      ...state,
      openUserQualificationEdit: true,
      userQualificationEditId: action.id
    }
  case types.CLOSEUSERQUALIFICATIONEDIT:
    return {
      ...state,
      openUserQualificationEdit: false,
      userQualificationEditId: undefined
    }
  case types.LOADUSERQUALIFICATIONS:
    return loadUserQualifications(state, action)
  case types.SAVEUSERQUALIFICATION:
    return saveUserQualification(state, action)
  case types.LOADCONTEXTQUALIFICTAIONS:
    return loadContextQualifications(state, action)
  default:
    return state
  }
}

function loadUserQualifications(state, action) {
  const byId = action.userQualifications.reduce(
    (acc, uq) => {
      acc[uq.id] = uq
      return acc
    },
    {}
  )
  const oldById = valueAt(state, ['userQualifications', 'byId']) || {}
  if (isEqual(oldById, byId)) {
    return state
  }
  return {
    ...state,
    userQualifications: { byId }
  }
}

function saveUserQualification(state, action) {
  const byId = valueAt(state, ['userQualifications', 'byId']) || {}
  return {
    ...state,
    openUserQualificationEdit: false,
    userQualificationEditId: undefined,
    userQualifications: {
      byId: {
        ...byId,
        [action.userQualification.id]: {
          ...action.userQualification
        }
      }
    }
  }
}

function loadContextQualifications(state, action) {
  const ordered = action.contextQualifications
  const old = valueAt(state, ['contextQualifications']) || []
  if (isEqual(ordered, old)) {
    return state
  }
  return {
    ...state,
    contextQualifications: ordered
  }
}
