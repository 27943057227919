import types from '../actions/actionTypes'

export default function actionProviderReducer(
  state = {},
  action
) {
  const subscribers = getAllSubscribers(state)
  switch (action.type) {
  case types.SUBSCRIBE:
    subscribers.push({
      // TODO: mainFunction should be dynamically imported
      //       (indexed by string in state - and serializable)
      uniqueId: action.uniqueId,
      mainFunction: action.mainFunction, // XXX can't be copied!
      mainFunctionArguments: action.mainFunctionArguments,
      testFunction: action.testFunction, // XXX can't be copied!
      testFunctionArguments: action.testFunctionArguments,
    })
    return {
      ...state,
      subscribers
    }
  case types.UNSUBSCRIBE:
    return {
      ...state,
      subscribers: dropSubscriber(action.uniqueId, subscribers)
    }
  default:
    return state
  }
}

/**
 * Should copy all subscribers, except that it doesn't make much sense to
 * copy them given that we have a - currently hard - requirement to keep
 * a reference to a function or two around
 */
function getAllSubscribers(state) {
  return state.subscribers || []
}

function dropSubscriber(uniqueId, subscribers = []) {
  return subscribers.filter(s => s.uniqueId !== uniqueId)
}
