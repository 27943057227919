import React, { Component } from 'react'

import { isEqual } from '../../../../shared/walkObjectHierarchy'

import SettingsFormWrapper from './SettingsFormWrapper'

/**
 * Base class for Settings Form wrappers.
 *
 * Wrapper class must set data and WrappedComponent in constructor!
 */
export default class extends Component {

  componentDidMount() {
    // XXX: this seems wrong in the redux sense
    this.loadSettings()
      .then(() => this.initForm())
  }

  componentDidUpdate(prevProps) {
    const shdContext = !(isEqual(prevProps.oucontext, this.props.oucontext))
      && this.props.oucontext.orgunitId

    if (shdContext) {
      this.loadSettings()
    }
    const shdFormSettings = !(isEqual(
      this.props.formSettings, prevProps.formSettings
    ))
    if (shdFormSettings) {
      this.initForm()
    }
  }

  initForm() {
    this.props.connectedForm.initialize(
      this.mapFormSettingsValues(this.props.formSettings)
    )
    this.setState({
      readOnly: this.props.formSettings && this.props.formSettings.length === 0
        ? false
        : true
    })
  }

  loadSettings() {
    const oucontext = this.props.oucontext
    if (oucontext.clientId) {
      return this.props.dispatchLoadFormSettings({
        clientId: oucontext.clientId,
        orgunitId: oucontext.orgunitId,
        userId: this.props.userId,
        isUser: this.props.isUser,
        form: this.data.form
      })
    }
  }

  mapFormSettingsValues(formSettings) {
    if (formSettings) {
      const formValues = formSettings.reduce((res, setting) => {
        return {
          ...res,
          [setting.type]: setting.value,
        }
      }, {})
      return formValues
    }
    return {}
  }

  mapFormSettingsMetadata(formSettings) {
    /* XXX: This basicly reduces all metadata of the form fields to
            only the information from the last field...but as long as we
            combine fields into one form we might need to do this...
    */
    if (formSettings) {
      const metadata = formSettings
        .reduce((res, setting) => (
          {
            ...res,
            orgunitId: setting.orgunitId,
            userId: setting.userId
          }
        ), {})
      return metadata
    }
    return {}
  }

  render() {
    const toggleHandler = readOnly => {
      this.setState({ readOnly })
    }
    const deleteHandler = () => {
      this.props.dispatchDeleteFormSettings({
        clientId: this.props.oucontext.clientId,
        orgunitId: this.props.userId
          ? undefined
          : this.props.oucontext.orgunitId,
        userId: this.props.userId,
        isUser: this.props.isUser,
        form: this.data.form
      })
    }
    const inheritedFrom = () => {
      const metadata = this.mapFormSettingsMetadata(this.props.formSettings)
      const settingsOUId = metadata.orgunitId
      const oucontext = this.props.oucontext.orgunitId
      const isInherited = "" + settingsOUId !== "" + oucontext
      const isInheritedForUser = metadata.userId === null && this.props.isUser
      const orgUnits = this.props.orgUnits ? this.props.orgUnits : []
      if (isInherited || isInheritedForUser ) {
        const name = orgUnits.filter(ou => (
          ou.id === settingsOUId
        )).reduce((name, ou) => (ou.name), '')
        return name
      }
    }
    const hasSettings = () => {
      const metadata = this.mapFormSettingsMetadata(this.props.formSettings)
      return Object.getOwnPropertyNames(metadata).length !== 0
    }
    const WrappedComponent = this.WrappedComponent
    return (
      <SettingsFormWrapper
        hasSettings={ hasSettings }
        readOnly={ this.state.readOnly }
        inheritedFrom={ inheritedFrom }
        deleteHandler={ deleteHandler }
        form={ this.data.form }
        translate={ msg => this.props.connectedForm.translate(msg, this.props) }
        { ...this.props }
      >
        <WrappedComponent
          toggleHandler={ toggleHandler }
          readOnly={ this.state.readOnly }
          onlyDetails={ !this.props.canEdit }
          translate={ msg => this.props.connectedForm.translate(
            msg,
            this.props
          )}
          { ...this.props }
        />
      </SettingsFormWrapper>
    )
  }
}
