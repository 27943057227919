import { get, post } from "../../shared/lib/requestUtils"
import type PunchedStep from "./PunchedStep"

export default function makePunchAPI(): PunchAPI {
  return new PunchAPI()
}

export class PunchAPI {

  public async isAllowedToPunchIn(): Promise<boolean> {
    const url = "/rest/daylogger/isAllowedToPunchIn"
    try {
      const res = await get<{
        allowed?: boolean
      }>(url, {})
      return res?.allowed ?? false
    } catch (e) {
      console.error("An error occurred getting permission:", e)
      return false
    }
  }

  public async getRawMinsSummary(date: string): Promise<{
    rawWorkMinutesForRunningYear: number
    rawWorkMinutesForRunningMonth: number
    rawWorkMinutesForRunningWeek: number
  }> {
    const url = "/rest/cache/getRawMinsSummary"
    const emptyValue = {
      rawWorkMinutesForRunningYear: 0,
      rawWorkMinutesForRunningMonth: 0,
      rawWorkMinutesForRunningWeek: 0,
    }
    try {
      const res = await get<{
        data: {
          rawWorkMinutesForRunningYear: number
          rawWorkMinutesForRunningMonth: number
          rawWorkMinutesForRunningWeek: number
        }
      }>(url, { date })
      return res?.data ?? emptyValue
    } catch (e) {
      console.error("An error occurred getting a time logging date:", e)
      return emptyValue
    }
  }

  // TODO: Move this to a custom route!
  public async getReliableDate(): Promise<string | null> {
    const url = "/rest/daylogger/reliableDate"
    try {
      const res = await get<{
        date?: string
      }>(url, {})
      return res?.date ?? null
    } catch (e) {
      console.error("An error occurred getting a time logging date:", e)
      return null
    }
  }

  public async hasManuallyLoggedToday(date: string): Promise<boolean> {
    const url = "/rest/daylogger/hasManuallyLoggedToday"
    try {
      const res = await get<{
        hasLogged: boolean
      }>(url, { date })
      return !!res?.hasLogged
    } catch (e) {
      console.error("An error occurred getting a time logging session:", e)
      return false
    }
  }

  public async hasPunchedInToday(date: string): Promise<boolean> {
    const url = "/rest/daylogger/hasPunchedInToday"
    try {
      const res = await get<{
        hasPunchedIn: boolean
      }>(url, { date })
      return !!res?.hasPunchedIn
    } catch (e) {
      console.error("An error occurred getting a time logging session:", e)
      return false
    }
  }

  public async getPunchedSteps(date: string): Promise<PunchedStep[]> {
    const url = "/rest/daylogger/punchedSteps"
    try {
      const res = await get<{
        steps?: PunchedStep[]
      }>(url, { date })
      return res?.steps ?? []
    } catch (e) {
      console.error("An error occurred getting a time logging session:", e)
      return []
    }
  }

  public async punchIn(): Promise<string | null> {
    const url = "/rest/daylogger/punchIn"
    let success = false
    let date: string | undefined
    try {
      const res = await post<{
        success: boolean
        date?: string
      }>(url, {})
      success = res.success
      date = res.date
    } catch (e) {
      console.error("An error occurred when starting a time logging session:", e)
      return null
    }
    return success && date ? date : null
  }

  public async punchOut(date: string): Promise<boolean> {
    const url = "/rest/daylogger/punchOut"
    let success = false
    try {
      const res = await post<{ success: boolean }>(url, { date })
      success = res.success
    } catch (e) {
      console.error("An error occurred during a time logging session:", e)
    }
    return success
  }

  public async punchOutForBreak(date: string): Promise<boolean> {
    const url = "/rest/daylogger/punchOutForBreak"
    let success = false
    try {
      const res = await post<{ success: boolean }>(url, { date })
      success = res.success
    } catch (e) {
      console.error("An error occurred during a time logging session:", e)
    }
    return success
  }

  public async punchInAfterBreak(date: string): Promise<boolean> {
    const url = "/rest/daylogger/punchInAfterBreak"
    let success = false
    try {
      const res = await post<{ success: boolean }>(url, { date })
      success = res.success
    } catch (e) {
      console.error("An error occurred during a time logging session:", e)
    }
    return success
  }

  public async deletePunchedStep(
    id: string,
    date: string
  ): Promise<boolean> {
    const url = "/rest/daylogger/deletePunchedStep"
    let success = false
    try {
      const res = await post<{ success: boolean }>(url, { date, id })
      success = res.success
    } catch (e) {
      console.error("An error occurred during a time logging session:", e)
    }
    return success
  }

  public async modifyPunchedStep(
    id: string,
    date: string,
    hour: number,
    minute: number
  ): Promise<boolean> {
    const url = "/rest/daylogger/modifyPunchedStep"
    let success = false
    try {
      const res = await post<{ success: boolean }>(url, {
        date, id, hour, minute
      })
      success = res.success
    } catch (e) {
      console.error("An error occurred during a time logging session:", e)
    }
    return success
  }

}