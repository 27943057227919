import * as React from "react"
import type PunchedStep from "../../configuration/PunchedStep"

const INTERVAL = 200

export default function useSumsForToday({ steps }: { steps: PunchedStep[] }): {
  sumStepsInSeconds: number
  paused: boolean
  started?: number
  currentStep?: PunchedStep
} {
  const [seconds, setSeconds] = React.useState<number>(0)
  const [runningSeconds, setRunningSeconds] = React.useState<number>(0)
  const [started, setStarted] = React.useState<number>()
  const currentStep = React.useMemo(() => {
    return steps[steps.length - 1]
  }, [steps])
  const paused = React.useMemo(
    () => currentStep?.type === "break-start",
    [currentStep]
  )
  React.useEffect(() => {
    setStarted(
      currentStep?.type === "break-end" || currentStep?.type === "start"
        ? currentStep?.utc?.timestamp
        : undefined
    )
  }, [currentStep?.type, currentStep?.utc?.timestamp])
  React.useEffect(() => {
    const interval = window.setInterval(() => {
      if (started !== undefined) {
        setRunningSeconds((Date.now() - started) / 1000)
      }
    }, INTERVAL)
    return () => window.clearInterval(interval)
  }, [started])
  React.useEffect(() => {
    let sum = 0
    let lastTimestamp: number | undefined
    for (const { type, utc } of steps) {
      const timestamp = utc.timestamp
      const isStarting = type === "break-end" || type === "start"
      if (lastTimestamp !== undefined && !isStarting) {
        sum += timestamp - lastTimestamp
      }
      if (isStarting) {
        lastTimestamp = timestamp
      } else {
        lastTimestamp = undefined
      }
    }
    setSeconds(sum / 1000)
  }, [steps]
  )
  const sumStepsInSeconds = React.useMemo(
    () => (!paused ? runningSeconds ?? 0 : 0) + seconds,
    [paused, runningSeconds, seconds]
  )
  return {
    sumStepsInSeconds,
    paused,
    currentStep,
    started
  }
}