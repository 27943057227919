/* eslint-disable max-len */
import Icon from "@ant-design/icons/lib/components/Icon"
import * as React from "react"
import { ReactComponent as Inactive } from "./palms.svg"

type IconProps = any // no good solution for this version of antd

export default function VacationOutline(props: IconProps): JSX.Element {
  return <Icon component={Component} {...props} />
}

function Component(): JSX.Element {
  return (
    // Icon provided by JM
    <Inactive />
  )
}
