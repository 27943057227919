/**
 * The context controller for the application
 *
 * Note: You should only read from the context normally; if you have to change
 *       context, be sure you know what you are doing!
 */
import {
  setContext as setContextAction,
  selectClient,
  selectOrgUnit,
  loadAllActiveClientsAsync,
  loadAllActiveOrgUnitsAsync
} from './redux/actions/ouContextDropdownActions'
import {
  getContextState,
  getContextNode as getContextNodeState,
  getHierarchy as getHierarchyState
} from './redux/selectors/ouContextDropdownSelectors'

/**
 * @return the current (i.e. selected) `Hierarchy` node
 *
 * state is the redux root state
 */
export const getContextNode = state => getContextNodeState(state)

/**
 * @return the current (i.e. selected) full organisational `Hierarchy`
 *
 * state is the redux root state
 */
export const getHierarchy = state => getHierarchyState(state)

/**
 * Returns the currently selected context
 *
 * state is the redux root state
 *
 * The `nextLocationName` in the result is the nearest containing location's
 * name, or an empty string. This can be used for making leaf node display names
 * less ambiguous
 *
 * @return `{ clientId, orgunitId, clientName, orgunitName, nextLocationName }`
 */
export const getContext = state => getContextState(state)

/**
 * Manually changes the current context client and department
 *
 * NOTE: You should ideally leave this up to the widget!
 */
export function setContext(dispatch, clientId, orgunitId) {
  if (clientId === 'undefined') {
    clientId = undefined
  }
  if (orgunitId === 'undefined') {
    orgunitId = undefined
  }
  // XXX: Isn't this a double dispatching?
  return dispatch(
    setContextAction(
      clientId ?? undefined,
      orgunitId ?? undefined
    )
  )
}

/**
 * Manually changes the current context client
 *
 * setOrgunit = (optional) flag to select the root orgUnit aswell.
 *
 * NOTE: You should ideally leave this up to the widget!
 */
export function setContextClient(dispatch, clientId, setOrgunit = true) {
  if (clientId === 'undefined') {
    clientId = undefined
  }
  // XXX: Isn't this a double dispatching?
  return dispatch(selectClient(clientId, setOrgunit))
}

/**
 * Manually changes the current context OU
 *
 * NOTE: You should ideally leave this up to the widget!
 */
export function setContextOrgUnit(dispatch, orgunitId) {
  if (orgunitId === 'undefined') {
    orgunitId = undefined
  }
  return dispatch(selectOrgUnit(orgunitId))
}


/**
 * Manually trigger a reload for all clients and orgunits.
 * For example: When creating a new client.
 */
export function refreshContext(dispatch) {
  return Promise.all([
    dispatch(loadAllActiveClientsAsync),
    dispatch(loadAllActiveOrgUnitsAsync)
  ])
}