import * as React from "react"
import MobileProcessManagerForm from "./MobileProcessManagerForm"
import type { OverviewProcess } from "../../commonConfiguration/processes/ProcessList"
import ProcessList from "../../commonConfiguration/processes/ProcessList"
import WebPushNotifications from "../../shared/WebPushNotifications"
import { Button, Spin } from "antd"
import type { Settings } from "../utils/SettingsPanel"
import type { IntlShape } from "react-intl"
import { injectIntl } from "react-intl"
import { ReloadOutlined } from "@ant-design/icons"
import ProcessDetails from "../../shared/processManagerUtils/ProcessDetails"

export interface Props {
  intl: IntlShape
  clientId: string
  departmentId: string
  employeeId: string
  changeDisplayedModule: (module: string) => void
  locale: string
  notificationCount: number
  saveUserSettings: (settings: Settings) => Promise<void>
  loadUserSettings: () => Promise<Settings>
}

interface State {
  currentProcessId?: string
  processes?: OverviewProcess[]
  propagateChanges: Date
}

class MobileProcessManager extends React.Component<Props, State> {
  private webPushNotifications: WebPushNotifications
  private emptyTransitionList = []

  constructor(props: Props) {
    super(props)
    this.state = {
      propagateChanges: new Date(),
      currentProcessId: undefined,
      processes: undefined,
    }
    this.propagateChanges = this.propagateChanges.bind(this)
    this.webPushNotifications = new WebPushNotifications()
    this.load = this.load.bind(this)
    this.fullyReloadList = this.fullyReloadList.bind(this)
  }

  componentDidMount(): void {
    this.webPushNotifications
      .subscribe()
      .catch(e => console.log("Error subscribing to WebPush:", e))
    void this.loadProcessManagerList({} as Props, {})
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    void this.loadProcessManagerList(prevProps, prevState)
  }

  render(): JSX.Element {
    const currentProcess: OverviewProcess | undefined =
      this.state.currentProcessId !== undefined
        ? this.state.processes?.find(
            (p: any) => p.getId() === this.state.currentProcessId
          )
        : undefined
    const currentProcessId =
      currentProcess !== undefined ? currentProcess.getId() : undefined
    const className = ["mobile-process-list-main-details"]
    return (
      <div className="mobile-process-manager">
        <div className="mobile-process-list-header">
          <div
            className="mobile-process-manager-icon"
            style={{
              maxWidth: "32px",
            }}
          >
            <img src="/island-tropical-solid.svg" alt="" />
          </div>
          {this.t("VacationRequests")}
        </div>
        <div className="mobile-process-lists-container">
          <Button
            style={{ float: "right" }}
            shape="circle"
            icon={<ReloadOutlined></ReloadOutlined>}
            onClick={this.fullyReloadList}
          ></Button>
          <div className="mobile-process-list-container">
            {this.state.processes && this.state.processes.length > 0 ? (
              this.renderProcessList(true)
            ) : this.state.processes !== undefined ? (
              this.t("NoVacationRequestsAvailable")
            ) : (
              <Spin />
            )}
          </div>
          {(this.state.processes?.filter((p: any) => !p.isCurrentlyOpen())
            ?.length ?? 0) > 0 ? (
            <>
              <h4>{this.t("OlderClosedVacationRequests")}</h4>
              <div className="mobile-process-list-container">
                {this.renderProcessList()}
              </div>
            </>
          ) : null}
        </div>
        <div className={className.join(" ")}>
          {currentProcess ? (
            <ProcessDetails
              processType={currentProcess.getProcessType()}
              showTitle={true}
              classPrefix="mobile-"
              clientId={this.props.clientId}
              departmentId={this.props.departmentId}
              employeeId={this.props.employeeId}
              id={currentProcessId}
            ></ProcessDetails>
          ) : null}
          <MobileProcessManagerForm
            availableTransitions={
              currentProcess !== undefined
                ? currentProcess.getAvailableTransitionTypes()
                : this.emptyTransitionList
            }
            processType={currentProcess?.getProcessType()}
            propagateChanges={this.propagateChanges}
            clientId={this.props.clientId}
            departmentId={this.props.departmentId}
            employeeId={this.props.employeeId}
            changeDisplayedModule={this.props.changeDisplayedModule}
            locale={this.props.intl.locale}
            id={
              /* TODO: use this.state.currentProcess instead! */
              currentProcess !== undefined ? currentProcess.getId() : undefined
            }
          ></MobileProcessManagerForm>
        </div>
      </div>
    )
  }

  private t(id: string, v: { [k: string]: string } = {}): string {
    return `${this.props.intl.formatMessage({ id }, v)}`
  }

  private renderProcessList(currentlyOpen = false) {
    const toLocaleDateString = (d: any) =>
      d?.toLocaleDateString(this.props.intl.locale, {
        weekday: "long",
        day: "numeric",
        month: "long",
      })
    const localDate = (d: any, withPreposition = true) => {
      let s = ""
      const dString = toLocaleDateString(d)
      const now = new Date()
      const yesterday = new Date(now)
      yesterday.setDate(yesterday.getDate() - 1)
      if (dString === toLocaleDateString(now)) {
        s = this.t("today")
      } else if (dString === toLocaleDateString(yesterday)) {
        s = this.t("yesterday")
      } else {
        if (withPreposition) {
          s = `${this.t("date-on")} ${dString}`
        } else {
          s = dString
        }
      }
      return s
    }
    return this.state.processes
      ?.filter((p: any) =>
        currentlyOpen ? p.isCurrentlyOpen() : !p.isCurrentlyOpen()
      )
      .map((p: OverviewProcess, idx: number) => {
        const cls = [
          "mobile-process-list-entry",
          "mobile-process-list-entry-type-" +
            p.getLastTransactionVisualisationType(),
        ]
        if (p.actionIsRequired()) {
          cls.push("mobile-process-list-action-required")
        }
        if (this.state.currentProcessId === p.getId()) {
          cls.push("mobile-process-list-active")
        }
        return (
          <div
            className={cls.join(" ")}
            key={idx}
            onClick={() =>
              this.setState({
                currentProcessId:
                  this.state.currentProcessId === p.getId()
                    ? undefined
                    : p.getId(),
              })
            }
          >
            <div className="mobile-process-list-entry-identification">
              <div className="mobile-process-list-entry-digest">
                {this.props.intl.formatMessage({
                  id: `full-${p.getProcessType()}`,
                })}
                : {p.getHACKYShortenedDigest()}
              </div>
            </div>
            <div className="mobile-process-list-entry-status">
              {!p.isNew() ? (
                <>
                  <div className="mobile-process-list-entry-last-transaction">
                    {p.getLastTransactionLabel()}
                  </div>
                  <div className="mobile-process-list-entry-last-changed">
                    {localDate(p.getLastChanged())}
                  </div>
                </>
              ) : (
                <div className="mobile-process-list-entry-new">
                  {this.t("NoReplyYet")}
                </div>
              )}
            </div>
          </div>
        )
      })
  }

  private loadProcessManagerList(
    prevProps: Props,
    prevState: Partial<State>
  ): void {
    // To make the opened dialogue reflect external changes, start here:
    // const specialCaseSelectedProcessChanged =
    //   this.state.currentProcessId !== prevState.currentProcessId
    if (
      this.props.employeeId !== prevProps.employeeId ||
      this.state.propagateChanges !== prevState.propagateChanges ||
      this.props.notificationCount !== prevProps.notificationCount ||
      this.props.intl.locale !== prevProps.intl.locale
    ) {
      this.fullyReloadList()
    }
  }

  private fullyReloadList(): void {
    this.setState(
      {
        currentProcessId: undefined,
        processes: undefined,
      },
      this.load
    )
  }

  private load(currentProcessId?: string): void {
    const processList = new ProcessList(
      this.props.departmentId,
      this.props.clientId,
      this.props.employeeId,
      this.props.intl.locale
    )
    void processList.loadOverviewList(true).then(() => {
      const processes = processList?.getList() ?? []
      this.setState({
        processes,
        currentProcessId,
      })
    })
  }

  private propagateChanges() {
    this.setState({
      currentProcessId: undefined,
      propagateChanges: new Date(),
    })
  }
}

const MobileProc = injectIntl(MobileProcessManager)
export default MobileProc
