/**
 * Translation utility for the process manager
 */

import messages from "./l10n/messages"
import translate from "../translate"

export default function t(
  locale: string,
  msgId: string,
  templateValues: {
    [key: string]: string
  } = {}
): string {
  return translate(locale, msgId, templateValues, messages)
}