import type FeatureType from "@mina-works/featuresets/dist/definition/interfaces/FeatureType"

export default async function areFeaturesAvailable({
  clientId, featureTypes
}: {
  clientId?: string
  featureTypes: FeatureType[]
}): Promise<Record<string, FeatureAvailability>> {
  const url = `/rest/featuresets/areFeaturesAvailable?${new URLSearchParams({
    clientId: clientId ?? '', featuretypes: JSON.stringify(featureTypes)
  }).toString()}`
  let result: Record<string, FeatureAvailability> = {}
  try {
    const res = await fetch(
      url,
      {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json; charset=utf-8'
        },
        method: 'GET',
      }
    )
    const json: Record<string, FeatureAvailabilityJSON> = (await res.json()).features
    result = featureTypes.reduce((acc, t) => {
      acc[t] = {
        ...json[t],
        quota: json[t].quota === null
          ? Number.POSITIVE_INFINITY
          : json[t].quota as number
      }
      return acc
    }, {} as Record<string, FeatureAvailability>)
  } catch (e) {
    console.error('An error occurred while getting the availability info:', e)
  }
  return result
}

interface FeatureAvailabilityJSON {
  available: boolean
  quota: number | null
}
export interface FeatureAvailability {
  available: boolean
  quota: number
}