const messages = {
  validationError: "Validierungsfehler",
  "Couldn´t find timezone for the user":
    "Es konnte keine gültige Zeitzone für den Benutzer ermittelt werden.",
  "Invalid date (past).":
    "Das angegebene Datum ist ungültig (in der Vergangenheit).",
  "ValidUntil must be after ValidFrom.":
    "Gültig bis muss zeitlich nach gültig ab liegen.",
  "Multiple coinciding Reassignments.":
    "Es können nicht mehrere Versetzungen für das selbe Datum angelegt werden.",
  "Overlapping date range with other TempAssignments.":
    "Die Verleihung überschneidet sich mit einer bereits existierenden Verleihung.",
  "Overlapping date range with other UserContracts.":
    "Die Vertragsdauer dieses Vertrages überschneidet sich mit einem bestehenden Vertrag.",
  authenticationError: "Anmeldefehler",
  "authentication failed": "Anmeldung fehlgeschlagen.",
  authorisationError: "Autorisierungsfehler",
  "permission denied": "Benötigtes Recht fehlt.",
  consistencyError: "Konsistenzfehler",
  "settings not found": "Es konnten keine Einstellungen gefunden werden.",
  passwordResetError: "Passwort vergessen fehlgeschlagen",
  "Already logged in": "Sie sind bereits angemeldet.",
  decodeTokenError: "Enschlüsselungsfehler",
  "invalid url parameter for decoding": "Der angegeben URL-Token ist ungültig.",
  registrationError: "Registrierungsfehler",
  "no candidate for token": "Es gibt für diesen Token keine offene Anmeldung.",
  usernameMissing: "Benutzername fehlt",
  "Username missing": "Bitte geben Sie einen gültigen Benutzernamen an.",
  userNotFound: "Benutzer nicht gefunden.",
  "The user could not be found.": "Es konnte kein Benutzer gefunden werden.",
  incorrectPassword: "Passwort inkorrekt",
  "The given password is wrong.":
    "Bitte überprüfen Sie das eingegeben Passwort auf Groß-/Kleinschreibung.",
  integrityError: "Integritätsfehler",
  "More than one valid TempAssignment was found!":
    "Es wurde mehr als eine gültige Verleihung gefunden.",
}

export default messages
