import * as React from "react"
import { LexicalComposer } from "@lexical/react/LexicalComposer"
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin"
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin"
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin"
import { ContentEditable } from "@lexical/react/LexicalContentEditable"
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary"
import { ClearEditorPlugin }  from "@lexical/react/LexicalClearEditorPlugin"
import { AutoFocusPlugin }  from "@lexical/react/LexicalAutoFocusPlugin"
// import type { LinkMatcher} from "@lexical/react/LexicalAutoLinkPlugin"
import { LinkPlugin, }  from "@lexical/react/LexicalLinkPlugin"
import { type EditorState } from "lexical"
import { HeadingNode, QuoteNode } from "@lexical/rich-text"
import { ListNode, ListItemNode } from "@lexical/list"
import { LinkNode, } from "@lexical/link"
import { CodeNode } from "@lexical/code"
import { TRANSFORMERS } from "@lexical/markdown"
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin"
import { ListPlugin } from "@lexical/react/LexicalListPlugin"
import InfoMessageEditorToolbar from "./Toolbar/index"
import { ResetContentPlugin } from "./ResetContentPlugin"
import { RegisterEditorCommandsPlugin } from "./RegisterEditorCommandsPlugin"
import { IntlProvider } from "react-intl"
import messages from "./messages"
import { RegisterEditorNodeTransformPlugin } from "./RegisterEditorNodeTransformPlugin"

const NODES = [
  HeadingNode,
  QuoteNode,
  CodeNode,
  ListNode,
  ListItemNode,
  LinkNode,
  // AutoLinkNode
]

export interface Props {
  locale: string
  contentJSON?: string // opaque EditorState!
  readOnly?: boolean
  updateMessage?: (m: string) => void
  triggerReset?: number
  triggerEmpty?: number
  enableHistoryPlugin?: boolean
}

export default function InfoMessageEditor({
  locale,
  updateMessage,
  contentJSON,
  triggerReset,
  readOnly,
  enableHistoryPlugin
}: Props): JSX.Element {
  locale = locale?.substring(0, 2) ?? 'de'
  const [editorState, setEditorState] = React.useState<EditorState | undefined>(
    contentJSON !== undefined
      ? (JSON.parse(contentJSON) as EditorState)
      : undefined
  )
  React.useEffect(() => {
    if (editorState !== undefined && updateMessage !== undefined) {
      updateMessage(JSON.stringify(editorState?.toJSON()))
    }
  }, [editorState, updateMessage])
  const onChange = React.useCallback((state: EditorState) => {
    setEditorState(state)
  }, [])
  return (
    <IntlProvider
      locale={locale}
      defaultLocale="de"
      messages={messages[locale]}
    >
    <div className="simple-editor-form">
      <div className="simple-editor-editor">
        <LexicalComposer
          initialConfig={{
            editable: !readOnly,
            nodes: NODES,
            namespace: "InfoMessage",
            onError: () => undefined,
            theme: {
            }
          }}
        >
          {
            readOnly ? <></> :
              <InfoMessageEditorToolbar></InfoMessageEditorToolbar>
          }
          <RegisterEditorCommandsPlugin />
          <RegisterEditorNodeTransformPlugin />
          <ResetContentPlugin
            json={contentJSON}
            triggerReset={triggerReset}
          ></ResetContentPlugin>
          <div className="simple-editor-lexical-container">
            <RichTextPlugin
              contentEditable={<ContentEditable></ContentEditable>}
              ErrorBoundary={LexicalErrorBoundary}
              placeholder={null}
            ></RichTextPlugin>
            <ClearEditorPlugin />
            <MarkdownShortcutPlugin
              transformers={TRANSFORMERS}
            ></MarkdownShortcutPlugin>
            {
              enableHistoryPlugin
                ?  <HistoryPlugin></HistoryPlugin>
                : null
            }
            <ListPlugin></ListPlugin>
            <OnChangePlugin onChange={onChange}></OnChangePlugin>
            <AutoFocusPlugin />
            <LinkPlugin />
          </div>
        </LexicalComposer>
      </div>
    </div>
    </IntlProvider>
  )
}
