import React from "react"
import { Select } from "antd"
import DepartmentWidget from "../../apps/shared/DepartmentWidget/DepartmentWidget"
import "./css/oucontext.css"
import { ContactsOutlined, PhoneOutlined } from "@ant-design/icons"

const OUContextDropdownComponent = ({
  selectClient,
  selectOrgUnit,
  showClientList,
  currentClientId,
  currentOrgUnitId,
  clients = [],
  orgUnits = [],
  intl,
  // hierarchy
}) => {
  let currentOrgUnit
  for (const o of orgUnits) {
    if ("" + o.orgUnit.id === "" + currentOrgUnitId) {
      currentOrgUnit = o.orgUnit
    }
  }
  // Placeholder: intl.formatMessage({ id: 'context.orgunit' }))
  const root = orgUnits.find(ou => ou.orgUnit.parent === null)
  const selectOptions = clientOptions(clients)
    .sort((a, b) => (a.text < b.text ? -1 : a.text === b.text ? 0 : 1))
    .map(opt => {
      const contactFull = (opt.contactFull ?? "").trim()
      return {
        value: opt.value,
        filterText: `${opt.text} ${opt.contactFull}`,
        label: (
          <div className="context-client-option">
            <div className="context-client-option-label">{opt.text}</div>
            <div className="context-client-option-addl">
              <ContactsOutlined></ContactsOutlined>
              &nbsp;
              {contactFull === ""
                ? intl.formatMessage({ id: "context.no-contact" })
                : `${intl.formatMessage({
                    id: "context.contact",
                  })}: ${contactFull}`}
            </div>
          </div>
        ),
      }
    })
  return (
    <div>
      {showClientList && (
        <Select
          placeholder={intl.formatMessage({ id: "context.select-client" })}
          allowClear={true}
          trigger={["click"]}
          className="ant-dropdown-link ou-context-client"
          showSearch={true}
          value={`${currentClientId}`}
          optionFilterProp="filterText"
          options={selectOptions}
          onChange={v => selectClient(v)}
          style={{
            minWidth: "120px",
            maxWidth: "700px",
            overflowX: "hidden",
            overflowY: "hidden",
          }}
          dropdownStyle={{
            minWidth: "380px",
            maxWidth: "900px",
          }}
        >
          {/*
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              className="ant-dropdown-link ou-context-client"
              data-e2e-test-id="ou-context-client"
            >
              {currentClient}
              <DownOutlined />
            </a>
        */}
        </Select>
      )}
      <DepartmentWidget
        root={root ? [new Department(root.orgUnit)] : []}
        selected={currentOrgUnit ? new Department(currentOrgUnit) : undefined}
        selectCallback={d => selectOrgUnit(d.getId())}
        dropdownClassName="oucontext"
        placeholder={intl.formatMessage({ id: "context.orgunit" })}
      ></DepartmentWidget>
    </div>
  )
}

function clientOptions(clients) {
  clients = [{ id: undefined, name: "-" }, ...clients]
  return clients.map(({ id, name, contactFull }) => ({
    key: "cli" + id,
    value: "" + id,
    text: name,
    title: name,
    contactFull,
  }))
}

class Department {
  constructor(ou) {
    this.ou = ou
  }
  getName() {
    return this.ou.getName()
  }
  getAbbreviation() {
    return this.ou.raw.abbrev /* TODO: MISSING API */
  }
  getChildren() {
    return this.ou.getChildren().map(c => new Department(c))
  }
  getColor() {
    const c = this.ou.raw.color
    if (c === undefined || c.length !== 3) {
      return { r: 255, g: 255, b: 255 }
    }
    return {
      r: c[0],
      g: c[1],
      b: c[2],
    }
  }
  getId() {
    return "" + this.ou.getId()
  }
}

export default OUContextDropdownComponent
