import * as React from "react"
import type { IntlShape } from "react-intl"
import { FormattedMessage, injectIntl } from "react-intl"
import PlannerDate from "../../shared/plannerDateTime/PlannerDate"
import type PlannerData from "../configuration/PlannerData"

export interface Props {
  intl: IntlShape
  plannerData: PlannerData
}

interface VacationValue {
  value: number // negative for reduction
  unit: "days" | "minutes"
}

interface State {
  remainingVacation: string
  remainingVacationUnit: "hours" | "days"
}
class MobileDashboardCalculatedInfo extends React.Component<Props, State> {
  private isUnmounting = false

  constructor(props: Props) {
    super(props)
    this.state = {
      remainingVacation: "0",
      remainingVacationUnit: "days",
    }
  }

  componentDidMount(): void {
    void this.updateVacationDays()
  }

  componentDidUpdate(props: Props): void {
    if (props.plannerData !== this.props.plannerData) {
      // TODO: more precise test?
      void this.updateVacationDays()
    }
  }

  componentWillUnmount(): void {
    this.isUnmounting = true
  }

  render(): JSX.Element | null {
    if (this.props.plannerData) {
      const calc = this.getCalculatedOvertimeAbsBreakdown()
      const netAndGrossDiffer =
        calc.net !== undefined &&
        (calc.net.hours !== calc.hours ||
          calc.net.minutes !== calc.minutes ||
          calc.net.sign !== calc.sign)
      const extraClass = this.props.plannerData.getCalculated()?.dirtySince
        ? " calculating"
        : ""
      return (
        <div className={`mobile-dashboard-calculated${extraClass}`}>
          <div
            className="mobile-dashboard-overtime"
            style={{
              color: this.getCalculatedTrafficLightColor(),
            }}
          >
            <div className="mobile-dashboard-calc-icon">
              <svg
                version="1.1"
                focusable="false"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <g fill={this.getCalculatedTrafficLightColor()}>
                  {/* eslint-disable-next-line max-len */}
                  <path d="M256,8C119,8,8,119,8,256s111,248,248,248s248-111,248-248S393,8,256,8z M348.5,321L348.5,321l-20,25c-5.5,6.9-15.6,8-22.5,2.5l0,0l0,0l-67-49.7c-9.5-7.6-15-19.1-15-31.2V112c0-8.8,7.2-16,16-16h32c8.8,0,16,7.2,16,16v144l58,42.5C352.9,304,354,314.1,348.5,321L348.5,321z"></path>
                </g>
              </svg>
            </div>
            <FormattedMessage id="YouHave"></FormattedMessage>&nbsp;
            {netAndGrossDiffer ? (
              <>
                <span className="mobile-calculated-value">
                  {this.getCalculatedOvertimeHours(calc.net!)}
                </span>{" "}
                {this.getCalculatedOvertimeUnit(calc.net!)} (
                {this.props.intl.formatMessage({ id: "net" })})&nbsp;/&nbsp;
              </>
            ) : null}
            <span className="mobile-calculated-value">
              {this.getCalculatedOvertimeHours(calc)}
            </span>{" "}
            {this.getCalculatedOvertimeUnit(calc)}{" "}
            {netAndGrossDiffer
              ? `(${this.props.intl.formatMessage({ id: "gross" })})`
              : ""}{" "}
            <FormattedMessage
              id="up-to-and-including"
              values={{ date: this.getEndOfCalculatedRange() }}
            ></FormattedMessage>
            &nbsp;
          </div>
          <div className="mobile-dashboard-vaca">
            <div className="mobile-dashboard-calc-icon">
              <img src="/island-tropical-solid.svg" alt="" />
            </div>
            <FormattedMessage id="YouHaveStill"></FormattedMessage>{" "}
            <span className="mobile-calculated-value">
              {this.getCalculatedVacationValue()}
            </span>{" "}
            <FormattedMessage
              id="YouHaveStillVacaDays"
              values={{ year: new Date().getFullYear() }}
            ></FormattedMessage>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  private async updateVacationDays() {
    const res = await fetch("/rest/userAccount/getLeaveDigest", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    const json = await res.json()
    const yearlyLeaveRemains = json.yearlyLeaveRemains as VacationValue
    const remainingVacationF = yearlyLeaveRemains?.value
    if (remainingVacationF !== undefined) {
      const remainingVacationUnit =
        yearlyLeaveRemains.unit === "days" ? "days" : "hours"
      const remainingVacation = Math.floor(remainingVacationF as number)
      let remainingVacationS = `${remainingVacation}`
      const d = remainingVacation - remainingVacationF
      if (remainingVacationUnit === "days" && d !== 0.0 && d !== 0) {
        remainingVacationS = remainingVacationF.toLocaleString(
          this.props.intl.locale,
          { maximumFractionDigits: 2 }
        )
      } else if (remainingVacationUnit === "hours") {
        const val = remainingVacationF ?? 0
        const h = Math.floor(val / 60)
        const m = Math.round(val % 60)
        remainingVacationS = `${h}:${m}`
      }
      if (!this.isUnmounting) {
        this.setState({
          remainingVacation: remainingVacationS,
          remainingVacationUnit,
        })
      }
    }
  }

  private getEndOfCalculatedRange(): string {
    const endDateStr: string | undefined =
      this.props.plannerData.getCalculated()?.endDate
    return endDateStr
      ? PlannerDate.fromString(endDateStr)
          .toDate()
          .toLocaleDateString(this.props.intl.locale)
      : "-"
  }

  private getCalculatedTrafficLightColor(): string {
    const c = this.props.plannerData.getCalculated()?.trafficLight
    switch (c) {
      case "red":
        return "rgb(161, 47, 75)"
      case "green":
        return "#5EB244"
      case "yellow":
        return "rgb(255, 174, 0)"
      default:
        return "black"
    }
  }

  private getCalculatedVacationValue(): string {
    const wunit = this.props.intl.formatMessage(
      { id: `with-unit-${this.state.remainingVacationUnit}` },
      { value: this.state.remainingVacation }
    )
    return wunit
  }

  private getCalculatedOvertimeHours({
    hours,
    minutes,
  }: {
    hours: number
    minutes: number
  }): string {
    const mins = `${minutes}`.padStart(2, "0")
    return `${hours}:${mins}`
  }

  private getCalculatedOvertimeUnit({ sign }: { sign: string }): string {
    if (sign === "-") {
      return this.props.intl.formatMessage({ id: "MinusHours" })
    } else {
      return this.props.intl.formatMessage({ id: "OvertimeHours" })
    }
  }

  private getCalculatedOvertimeAbsBreakdown(): SignedTimeWithNet {
    const signedMinutes =
      this.props.plannerData.getCalculated()?.workHoursBalance ?? 0
    const signedNetMinutes =
      this.props.plannerData.getCalculated()?.workHoursNetBalance
    const res = this.toHoursMinutesSign(signedMinutes)
    return {
      ...res,
      net: signedNetMinutes
        ? this.toHoursMinutesSign(signedNetMinutes)
        : undefined,
    }
  }

  private toHoursMinutesSign(rawMinutes: number): SignedTime {
    const sign = rawMinutes < 0 ? "-" : ""
    const minutesRaw = Math.abs(rawMinutes)
    const hours = Math.floor(minutesRaw / 60)
    const minutes = Math.round(minutesRaw - hours * 60)
    return {
      hours,
      minutes,
      sign,
    }
  }
}

interface SignedTime {
  hours: number
  minutes: number
  sign: string
}

interface SignedTimeWithNet extends SignedTime {
  net?: SignedTime
}

const MoDaCaIn = injectIntl(MobileDashboardCalculatedInfo)
export default MoDaCaIn
