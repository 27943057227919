import React from 'react'

import { Form, Group } from '../../../shared/vmi-connected-form'
import { ValidationErrors } from '../../../shared/vmi-connected-form'

import { makeViewModel } from './models/plannerSettingsFormModel'
import withForm from '../lib/withForm/withForm'

const validate = (values, dispatch, translate, props) => {
  const v = new ValidationErrors(
    translate('settings.error.validation', props)
  )
  return v
}

const PlannerSettingsForm = ({
  translate,
  connectedForm,
  toggleHandler,
  readOnly,
  onlyDetails
}) => {
  const { fields } = connectedForm

  const demandOptions = [
    {
      key: 'customers',
      value: 'customers',
      text: translate('settings.options.customers')
    },
    {
      key: 'revenue',
      value: 'revenue',
      text: translate('settings.options.revenue')
    },
    {
      key: 'packaging',
      value: 'packaging',
      text: translate('settings.options.packaging')
    },
  ]

  const intervalOptions = [
    {
      key: 'thirty',
      value: 'thirty',
      text: translate('settings.options.thirty')
    },
    {
      key: 'hour',
      value: 'hour',
      text: translate('settings.options.hour')
    }
  ]

  const barOptions = [
    {
      key: 'activity',
      value: 'activity',
      text: translate('settings.options.activity')
    },
    {
      key: 'site',
      value: 'site',
      text: translate('settings.options.site')
    },
    {
      key: 'activity-site',
      value: 'activity-site',
      text: translate('settings.options.activity-site')
    },
    {
      key: 'site-activity',
      value: 'site-activity',
      text: translate('settings.options.site-activity')
    }
  ]

  return (
    <React.Fragment>
      <Form
        connectedForm={ connectedForm }
        onToggle={ toggleHandler }
        readOnly={ readOnly }
        onlyDetails= { onlyDetails }
      >
        { fields.demandPlanning({ options: demandOptions }) }
        <Group>
          { fields.overstaffingColor() }
          { fields.understaffingColor() }
        </Group>
        <Group>
          { fields.onHolidays() }
          { fields.showPause() }
        </Group>
        <Group>
          { fields.pauseColor() }
          { fields.simulationColor() }
        </Group>
        { fields.interval({ options: intervalOptions }) }
        <Group>
          { fields.beginPlanner() }
          { fields.endPlanner() }
        </Group>
        { fields.showSundays() }
        { fields.barDisplay({ options: barOptions }) }
      </Form>
    </React.Fragment>
  )
}

export default withForm({
  validate,
  makeViewModel,
  form: 'planner'
})(PlannerSettingsForm)
