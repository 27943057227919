const messages: Record<string, Record<string, string>> = {
  de: {
    'sums-header': 'Geleistete Arbeitszeit',
    workHoursToday: "Arbeitsstunden heute",
    workHoursWeek: "Arbeitsstunden diese Woche",
    workHoursMonth: "Arbeitsstunden diesen Monat",
    workHoursYear: "Arbeitsstunden dieses Jahr",
    "show-sums": "Summen",
    close: 'Schließen',
    work: "Arbeit",
    // eslint-disable-next-line max-len
    warnIllegalPunchTime: "Bitte geben Sie einen gültigen Zeitpunkt ein (eine Zeiterfassung kann 24 Stunden nicht überschreiten und Zeitpunkte dürfen nicht in der Zukunft liegen)!",
    hasLogged: "An diesem Tag ist bereits eine Ist-Zeit vorhanden!",
    hasPunchedIn: "An diesem Tag läuft eine Zeiterfassung!",
    reallyDeleteStep:
      "Wollen Sie diesen Schritt wirklich aus Ihrer Zeiterfassung entfernen?",
    punch: "Zeiterfassung",
    calendar: "Kalender",
    dashboard: "Home",
    "full-employee-communication": "Allgemein",
    "full-employee-request-for-leave": "Urlaub",
    "full-employee-request-to-reduce-overtime": "Überstundenabbau",
    "employee-communication": "Anfragen",
    "employee-request-for-leave": "Anfragen",
    "employee-request-to-reduce-overtime": "Anfragen",
    timezonesDiffer: "in Ihrer Zeitzone um {value}",
    punchTitle: "Zeiterfassung",
    break: "Pause",
    "break-start": "Pause",
    "break-end": "Arbeit wird fortgesetzt",
    start: "Arbeit beginnt",
    end: "Arbeitstag endet",
    "end-of-day": "Feierabend",
    "cmd-start": "Arbeit",
    "cmd-break": "Pause",
    "cmd-end": "Ende",
    "running-diff-tz":
      "Lokale Zeitzone (zweiter dargestellter Zeitpunkt) weicht von der Zeitzone der Abteilung ab!",
    installMobileIcon: "Icon auf dem Startbildschirm installieren",
    "desktop-link": "Desktop-Anwendung",
    "choose-dept": "Abteilung auswählen...",
    net: "netto",
    gross: "brutto",
    "other-planned-employees": "Planung weiterer Mitarbeiter",
    Send: "Senden",
    Cancel: "Abbrechen",
    VacationRequest: "Urlaubsanfrage",
    OvertimeRequest: "Überstundenabbau",
    GeneralRequest: "Allgemein",
    MakeVacationRequest: "Urlaub",
    MakeOvertimeRequest: "Überstundenabbau",
    MakeGeneralRequest: "Allgemeine Anfrage",
    RequestVacationH: "Urlaub beantragen",
    RequestOvertimeH: "Überstundenabbau beantragen",
    RequestGeneralH: "Allgemeine Anfrage",
    VacationRequests: "Anfragen",
    MyVacationRequest: "Meine Urlaubsanfrage",
    MyGeneralRequest: "Meine allgemeine Anfrage",
    MyOvertimeRequest: "Meine Anfrage zum Überstundenabbau",
    VacationRequestFrom: "Urlaubsanfrage von {name}",
    OlderClosedVacationRequests: "Ältere abgeschlossene Anfragen",
    ArchivedVacationRequests: "Archivierte Anfragen",
    NoVacationRequestsAvailable: "Keine Anfragen vorhanden",
    NoReplyYet: "Noch keine Antwort",
    Employee: "Mitarbeiter",
    Department: "Abteilung",
    Content: "Inhalt",
    Status: "Stand",
    ChangedBy: "Geändert von",
    ChangedOn: "Geändert am",
    "history-by": "von",
    "vacareq-error-occurred": "Ein Fehler ist aufgetreten:",
    "vacareq-unexpected-error-occurred":
      "Ein unerwarteter Fehler ist aufgetreten:",
    "vacareq-cancel-all": "Schließen",
    adminmsg1:
      "Sie sind keinem Mandanten (Kunden) zugeordnet oder haben keinen Zugriff auf die mobile App.",
    adminmsg2: `
Diese Mobilansicht dient der Verwaltung der eigenen Planung. Der
Benutzer, mit dem sie angemeldet sind, kann
keine eigene Planung haben oder darf sie nicht sehen, oder er hat keinen Zugang zur mobilen App.
`,
    adminmsg3: `
Melden Sie sich mit einem regulären Mitarbeiter-Konto an, um diese
Mobilansicht zu verwenden, sofern Ihr Arbeitgeber eine Lizenz hat, die die Verwendung der mobilen Ansicht einschließt.
`,
    Breaks: "Pausen",
    mobileCouldntSignIn: "Die Anmeldung konnte nicht durchgeführt werden!",
    mobileWeirdCouldntSignIn:
      "Unbekannter Fehler: Die Anmeldung konnte nicht durchgeführt werden!",
    YouAreOffline: "Sie sind offline!",
    SignInFailed: "Die Anmeldung ist fehlgeschlagen",
    Username: "Benutzername",
    Password: "Passwort",
    ResetPassword: "Passwort zurücksetzen",
    PleaseEnterUsername: "Bitte geben Sie Ihren Benutzernamen ein!",
    PleaseEnterPassword: "Bitte geben Sie Ihr Passwort ein!",
    SignIn: "Anmelden",
    Imprint: "Impressum",
    BackToHome: "Zurück zur Startseite",
    PasswordAlreadyReset: "Passwort bereits zurückgesetzt.",
    FindResetMailExpl:
      "Sie finden die Nachricht mit dem Link in Ihrem E-Mail-Posteingang.",
    Dashboard: "Home",
    Kalender: "Kalender",
    Urlaubsanfragen: "Anfragen",
    Logout: "Abmelden",
    "date-on": "am",
    today: "heute",
    yesterday: "gestern",
    NoPlanInNextCoupleOfDays: "Keine Planung in den nächsten Tagen vorhanden",
    YouHave: "Sie haben",
    YouHaveStill: "Sie haben noch",
    YouHaveStillVacaDays: "in {year}",
    MinusHours: "Minusstunden",
    OvertimeHours: "Überstunden",
    BackToDashboard: "Zurück zu Home",

    Gestern: "Gestern",
    Heute: "Heute",
    Morgen: "Morgen",
    Samstag: "Samstag",
    Sonntag: "Sonntag",
    Montag: "Montag",
    Dienstag: "Dienstag",
    Mittwoch: "Mittwoch",
    Donnerstag: "Donnerstag",
    Freitag: "Freitag",

    "o'clock": "Uhr",
    abwesend: "abwesend",
    "keine Einträge": "keine Einträge",
    TODAY: "HEUTE",
    TOMORROW: "MORGEN",

    SwitchOffEmailNotif: "E-Mail-Benachrichtigungen",
    SwitchOnEmailNotif: "E-Mail-Benachrichtigungen",
    isRequired: "muss ausgefüllt sein",
    "time-until": "bis",
    "remove-entry": "Eintrag entfernen",
    "add-break": "Pause hinzufügen",
    "add-entry": "Eintrag hinzufügen",
    "minutes-break": "min",
    "mobile-break-label":
      "{durationInMinutes} Min. Pause zwischen {startTime} und {endTime} Uhr",
    "clear-day-expl":
      "Planung mit leerer Arbeitszeit (= Abwesenheit) überschreiben",
    "clear-day": "Ich war nicht da",
    "delete-day-expl":
      "Zur Planung zurückkehren (erfasste Arbeitszeit entfernen)",
    "delete-day": "Zurück zur Planung",
    cancel: "Abbrechen",
    save: "Speichern",
    "Logged Day": "Korrigierte Planung",
    "Planned Day": "Unveränderte Planung",
    "empty-logged-day": "Leerer Tag",
    "Change Message": "Grund der Änderung",
    modifyDay: "Arbeitseinsatz korrigieren",
    "up-to-and-including": "bis einschließlich {date}",
    "breaks-are-wrong": "Bitte überprüfen Sie die Pausen!",
    "times-are-wrong": "Bitte überprüfen Sie die Start- und Endzeit!",
    "cet-missing": "Bitte wählen Sie überall Kalendereintragstypen aus!",
    "nothing selected": "Nichts ausgewählt",
    "overlap-report": `Es gibt Überlappungen zwischen Einträgen.
Damit der Tag gespeichert werden kann müssen diese Überlappungen behoben werden.

Folgende Überlappungen wurden festgestellt:
{feedback}
`,
    "proceed-with-long-entry":
      "Ein Eintrag ist ungewöhnlich lang. Wollen Sie den Tag trotzdem speichern?",
    "with-unit-days": "{value} Urlaubstage",
    "with-unit-hours": "{value} Urlaubsstunden",
  },
  en: {
    'sums-header': 'Hours Worked',
    workHoursToday: "Work hours today",
    workHoursWeek: "Work hours this week",
    workHoursMonth: "Work hours this month",
    workHoursYear: "Work hours this year",
    "show-sums": "Sums",
    close: 'Close',
    work: "Work",
    break: "Break",
    warnIllegalPunchTime: "Please enter a valid time!",
    hasLogged: "A logged day has already been entered!",
    hasPunchedIn: "Time is being logged!",
    punch: "Zeiterfassung",
    reallyDeleteStep: "Do you really want to delete this step?",
    calendar: "Kalender",
    dashboard: "Home",
    "full-employee-communication": "Message",
    "full-employee-request-for-leave": "Leave",
    "full-employee-request-to-reduce-overtime": "Overtime",
    "employee-communication": "Request",
    "employee-request-for-leave": "Request",
    "employee-request-to-reduce-overtime": "Request",
    punchTitle: "Log Time",
    timezonesDiffer: "in your time zone: {value}",
    "break-start": "Break started",
    "break-end": "Break ended",
    start: "Working",
    end: "Workday finished",
    "end-of-day": "Workday ended",

    "cmd-start": "Work",
    "cmd-break": "Break",
    "cmd-end": "End",
    "running-diff-tz":
      "First value is your department time zone, second is local to you",

    installMobileIcon: "Install icon on home screen",
    "desktop-link": "Go to desktop app...",
    "choose-dept": "Select department...",
    net: "net",
    gross: "gross",
    "other-planned-employees": "Other employee's plans",
    Send: "Send",
    Cancel: "Cancel",
    VacationRequest: "Vacation Request",
    OvertimeRequest: "Reduce Overtime",
    MakeVacationRequest: "Make vacation request",
    MakeOvertimeRequest: "Make request to reduce overtime",
    MakeGeneralRequest: "Make general request",
    RequestVacationH: "Make a Vacation Request",
    RequestOvertimeH: "Make a Request to Reduce Overtime",
    VacationRequests: "Vacation Requests",
    MyVacationRequest: "My Vacation Request",
    MyOvertimeRequest: "My Overtime Request",
    VacationRequestFrom: "{name}'s Vacation Request",
    OlderClosedVacationRequests: "Closed Requests",
    ArchivedVacationRequests: "Archived Requests",
    NoVacationRequestsAvailable: "No vacation requests found",
    NoReplyYet: "No reply yet",
    Employee: "Employee",
    Department: "Department",
    Content: "Content",
    Status: "Status",
    ChangedBy: "Changed by",
    ChangedOn: "Changed on",
    "history-by": "by",
    "vacareq-error-occurred": "An error has occurred",
    "vacareq-unexpected-error-occurred": "An unexpected error has occurred",
    "vacareq-cancel-all": "Close",
    adminmsg1: "You are not associated with any tenant/client.",
    adminmsg2: `
This mobile app is used to manage an employee's own plans. Your
user is an administrator. They cannot have their own plans.
`,
    adminmsg3: `
Please use a regular employee account to sign in if you'd like to use
this mobile app.
`,
    Breaks: "Breaks",
    mobileCouldntSignIn: "Unable to sign in!",
    mobileWeirdCouldntSignIn: "Unknown Errro: Unable to sign in!",
    YouAreOffline: "You are offline!",
    SignInFailed: "Sign-in failed",
    Username: "Username",
    Password: "Password",
    ResetPassword: "Reset password",
    PleaseEnterUsername: "Please enter your username!",
    PleaseEnterPassword: "Please enter your password!",
    SignIn: "Sign in",
    Imprint: "Imprint",
    BackToHome: "Return to home page",
    PasswordAlreadyReset: "Password already reset.",
    FindResetMailExpl:
      "Find a message with the reset link in your email inbox.",
    Dashboard: "Home",
    Kalender: "Calendar",
    Urlaubsanfragen: "Vacation Requests",
    Logout: "Log out",
    "date-on": "on",
    today: "today",
    yesterday: "yesterday",
    NoPlanInNextCoupleOfDays: "No plan found in the next couple of days",
    YouHave: "You have",
    YouHaveStill: "You have",
    YouHaveStillVacaDays: "leave days left in {year}",
    MinusHours: "hours missing (minus)",
    OvertimeHours: "hours overtime",

    BackToDashboard: "Back to Home",

    Gestern: "Yesterday",
    Heute: "Today",
    Morgen: "Tomorrow",
    Samstag: "Saturday",
    Sonntag: "Sunday",
    Montag: "Monday",
    Dienstag: "Tuesday",
    Mittwoch: "Wednesday",
    Donnerstag: "Thursday",
    Freitag: "Friday",

    "o'clock": "o'clock",
    abwesend: "absent",
    "keine Einträge": "no entries",
    TODAY: "TODAY",
    TOMORROW: "TOMORROW",

    SwitchOffEmailNotif: "Switch off email notifications",
    SwitchOnEmailNotif: "Switch on email notifications",

    isRequired: "is required",
    "time-until": "until",

    "remove-entry": "Remove break",
    "add-break": "Add break",
    "add-entry": "Add entry",
    "minutes-break": "minutes",
    "mobile-break-label":
      "{durationInMinutes} minute break from {startTime} to {endTime}",
    "clear-day-expl": "Clear day (log absence)",
    "clear-day": "I was absent from work",
    "delete-day-expl": "Return to planned day",
    "delete-day": "Delete",
    cancel: "Cancel",
    save: "Save",
    "Logged Day": "Logged Day",
    "Planned Day": "Unchanged Plan",
    "empty-logged-day": "Empty Day",
    "Change Message": "Reason for change",
    modifyDay: "Modify day",
    "up-to-and-including": "up to and including {date}",
    "breaks-are-wrong": "Please check the breaks!",
    "times-are-wrong": "Please check the start and end times!",
    "cet-missing": "Please select calendar entry types!",
    "nothing selected": "Nothing selected",
    "overlap-report": `There is overlap between entries.
This overlap must be removed before the day can be saved.

The following instances of overlap have been found:
{feedback}
`,
    "proceed-with-long-entry":
      "An entry is unusually long. Would you still like to save it?",
    "with-unit-days": "{value} vacation days",
    "with-unit-hours": "{value} vacation hours",
  },
}

export default messages
