import types from '../actions/actionTypes'

export default function globalMessageReducer(
  state = {},
  action
) {
  switch (action.type) {
  case types.ADDGLOBALMESSAGE:
    return {
      ...state,
      globalMessage: action.message
    }
  case types.CLOSEGLOBALMESSAGE:
    return {
      ...state,
      globalMessage: undefined
    }
  default:
    return state
  }
}
