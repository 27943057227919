const messages = {
  "calendarReport.checkCol": "Select",
  "calendarReport.name": "Name",
  "calendarReports.title": "Week plan",
  "calendarReports.generateReport": "Generate week plan",
  "calendarReports.generateXLSXReport": "Generate week plan (XLSX)",
  "calendarReports.toggleBreaks": "Show breaks",
  "calendarReports.toggleAccounts": "Show account data",
  "calendarReports.toggleObfuscate": "Show obfuscated unavailabilites",
  "calendarReports.fiveDays": "Mo-Fr",
  "calendarReports.sixDays": "Mo-Sa",
  "calendarReports.sevenDays": "Mo-Su",
  "calendarReports.calendarWeek": "Select calendar week",
}

export default messages
