import type FeatureType from "@mina-works/featuresets/dist/definition/interfaces/FeatureType"
import * as React from "react"
import MobilePunchControls from "./MobilePunchControls"
import makePunchAPI, { PunchAPI } from "../configuration/PunchAPI"
import type PunchedStep from "../configuration/PunchedStep"
import MobilePunchRunning from "./MobilePunchRunning"
import { Button } from "antd"
import { useIntl } from "react-intl"
import MobilePunchSums from "./MobilePunchSums"

interface Props {
  availableFeatures: FeatureType[]
  clientId?: string
  departmentId?: string
  employeeId?: string
}

export default function MobilePunch(props: Props): JSX.Element {
  const punchAPI = React.useMemo(() => new PunchAPI(), [])
  // TODO: Extend safelyLoaded semantics to date validation!
  const [displaySums, setDisplaySums] = React.useState<boolean>(false)
  const [safelyLoaded, setSafelyLoaded] = React.useState<boolean>(false)
  const [started, setStarted] = React.useState<number | undefined>()
  const [date, setDate] = React.useState<string | undefined>()
  const [steps, setSteps] = React.useState<PunchedStep[]>([])
  const intl = useIntl()
  const currentStep: PunchedStep | undefined = React.useMemo(
    () => steps[steps.length - 1],
    [steps]
  )
  // TODO: Track today's info
  // TODO: LOAD INITIALLY to get the various states!
  // TODO: Every callback must first sync with the backend
  const loadPunchedSteps = React.useCallback(async () => {
    const tmpDate = date ?? (await punchAPI.getReliableDate())
    if (tmpDate) {
      setSteps(await punchAPI.getPunchedSteps(tmpDate))
      setSafelyLoaded(true)
      setDate(tmpDate)
    }
  }, [date, punchAPI])
  React.useEffect(() => {
    setStarted(
      currentStep?.type === "break-end" || currentStep?.type === "start"
        ? currentStep?.utc?.timestamp
        : undefined
    )
  }, [currentStep?.type, currentStep?.utc?.timestamp])
  const loadedEmployeeIdRef = React.useRef<string | undefined>()
  const startedRef = React.useRef<number | undefined>()
  React.useEffect(() => {
    // Reload steps after a POST request
    const oldStarted = startedRef.current
    startedRef.current = started
    if (oldStarted !== started) {
      void loadPunchedSteps()
    }
  }, [loadPunchedSteps, started])
  React.useEffect(() => {
    // Load steps after employee changes (e.g., initially)
    // TODO: Date changes, reload etc.
    const oldEmployeeId = loadedEmployeeIdRef.current
    loadedEmployeeIdRef.current = props.employeeId
    if (oldEmployeeId !== props.employeeId) {
      void loadPunchedSteps()
    }
  }, [loadPunchedSteps, props.employeeId])
  const play = React.useCallback(() => {
    if (currentStep === undefined || date === undefined) {
      void makePunchAPI()
        .punchIn()
        .then(d => {
          if (d !== null) {
            setDate(d)
            setStarted(Date.now()) // TODO: get from punchIn!
          } else {
            alert("Ein Fehler ist aufgetreten")
          }
        })
    } else {
      void makePunchAPI()
        .punchInAfterBreak(date)
        .then(success => {
          if (success) {
            setStarted(Date.now()) // TODO: get from punchInAfterBreak!
          } else {
            alert("Ein Fehler ist aufgetreten")
          }
        })
    }
  }, [currentStep, date])
  const pause = React.useCallback(() => {
    if (date) {
      void makePunchAPI()
        .punchOutForBreak(date)
        .then(success => {
          if (success) {
            setStarted(undefined)
          } else {
            alert("Ein Fehler ist aufgetreten")
          }
        })
    }
  }, [date])
  const stop = React.useCallback(() => {
    if (date) {
      void makePunchAPI()
        .punchOut(date)
        .then(success => {
          if (success) {
            setStarted(undefined)
          } else {
            alert("Ein Fehler ist aufgetreten")
          }
        })
    }
  }, [date])
  React.useEffect(() => {
    console.log('"started" changed, sync with backend')
  }, [])
  const showSums = React.useCallback(() => {
    setDisplaySums(true)
  }, [])
  const hideSums = React.useCallback(() => {
    setDisplaySums(false)
  }, [])
  return (
    <div className="mobile-punchcard-app">
      {displaySums ? (
        <MobilePunchSums
          steps={steps}
          date={date}
          hideSums={hideSums}
        ></MobilePunchSums>
      ) : (
        <>
          {safelyLoaded ? (
            <MobilePunchControls
              steps={steps}
              date={date}
              play={play}
              pause={pause}
              stop={stop}
            ></MobilePunchControls>
          ) : null}
          <MobilePunchRunning
            date={date}
            update={loadPunchedSteps}
            steps={steps}
          ></MobilePunchRunning>
          <Button onClick={showSums} className="mobile-punch-sums-btn">
            {intl.formatMessage({ id: "show-sums" })}
          </Button>
        </>
      )}
    </div>
  )
}
