import * as React from 'react'
import { Alert } from 'antd'

const FieldWithHelp = props => (
  <div
    className='field connected-form-field-with-help'
    style={{ margin: '10px' }}
  >
    { props.children }
    {
      props.help !== undefined
        ? <Alert showIcon type='info' message={ props.help } />
        : undefined
    }
  </div>
)

export default FieldWithHelp
