/**
 * These are the high level field types for the CoreForm declaration.
 * They can be implemented by the technology-specific parts of the
 * implementation in various ways. `undefined` is intended as the default,
 * and should always be interpreted as a single line text string.
 *
 * String is intended to signify a short (single-line) string, and
 * text is intended to signify longer text.
 *
 * Note that dates and times may appear to be a little difficult to grasp:
 *
 * - datetime is the equivalent of a fully fleshed out JS Date
 * - date is the date part, and should signify a calendar date, e.g. YYYY/MM/DD
 * - time is a point in time, specified as hours and minutes (e.g., eight in the
 *   morning)
 * - duration is a duration in minutes (the most "natural" variant)
 * - seconds is a duration in seconds
 * - milliseconds is a duration in milliseconds
 *
 * Please note that all names are fully spelled out. This makes it easier to
 * predict and remember them, but unfortunately, it clashes with common
 * practices (such as the usage of 'bool' instead of 'boolean' in PropTypes).
 *
 * TODO: formatted text
 * TODO: uncertain about radios
 */
export const VALIDFIELDTYPES = [
  undefined,
  'string',
  'text',
  'boolean',
  'checkboxGroup',
  'number',
  'list',
  'color',
  'datetime',
  'date',
  'time',
  'hours',
  'duration',
  'radio',
  'radioGroup',
  'combobox'
]

export interface FieldOptions {
  ['required']?: boolean
  ['min']?: number
  ['max']?: number
  ['step']?: number
  ['autoFocus']?: boolean
  ['multiple']?: boolean
  ['help']?: string
  ['allowNegative']?: boolean // for time fields
  ['unlimitedHours']?: boolean // for time fields
}

export type FieldOption = keyof FieldOptions

/**
 * ALLOWEDFIELDOPTIONS lists all fieldOptions keys that are allowed for
 * CoreForm fields; these have a mixture of general meanings, but should
 * *mostly* be independent of technologies. Cf. CoreForm.setForm for further
 * documentation.
 */
export const ALLOWEDFIELDOPTIONS: FieldOption[] = [
  'required',
  'min',
  'max',
  'step',
  'autoFocus',
  'multiple',
  'help',
  'allowNegative', // for time fields
  'unlimitedHours', // for time fields
]
