import { Checkbox } from "antd"
import type { CheckboxChangeEvent } from "antd/lib/checkbox"
import React from "react"
import type { IntlShape } from "react-intl"
import { injectIntl } from "react-intl"

export interface Props {
  intl: IntlShape
  style?: React.CSSProperties
  className?: string
  saveUserSettings: (settings: Settings) => Promise<void>
  loadUserSettings: () => Promise<Settings>
}

interface State {
  settings?: Settings
}

export interface Settings {
  notifyViaEmail: boolean
}

class SettingsPanel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {}
    this.switch = this.switch.bind(this)
  }

  componentDidMount(): void {
    this.load()
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    if (prevState.settings === this.state.settings) {
      // Don't load settings for internal updates
      this.load()
    }
  }

  render(): JSX.Element {
    const cls = ["mobile-settings-panel"]
    if (this.props.className) {
      cls.push(this.props.className)
    }
    return (
      <div className={cls.join(" ")} style={this.props.style}>
        <div className="mobile-side-menu-entry">
          <div
            style={{
              display: "inline-block",
              width: 35,
            }}
          >
            <Checkbox
              checked={this.state.settings?.notifyViaEmail}
              onChange={this.switch}
            ></Checkbox>
          </div>
          {this.state.settings?.notifyViaEmail
            ? this.props.intl.formatMessage({
                id: "SwitchOffEmailNotif",
              })
            : this.props.intl.formatMessage({
                id: "SwitchOnEmailNotif",
              })}
        </div>
      </div>
    )
  }

  private load() {
    void this.props
      .loadUserSettings()
      .then(settings => this.setState({ settings }))
  }

  private switch(e: CheckboxChangeEvent) {
    const notifyViaEmail = e.target.checked
    const settings = { notifyViaEmail }
    this.setState(
      { settings },
      () => void this.props.saveUserSettings(settings)
    )
  }
}

const SettingsPanelWithIntl = injectIntl(SettingsPanel)
export default SettingsPanelWithIntl
