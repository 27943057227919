/**
 * Utilities that can be used by implementations (plugins)
 */

type Direction = 'ascending' | 'descending' | 0

interface Directions {
  [direction: string]: Direction
}

export const ASC = 'ascending'
export const DESC = 'descending'
export const NEUTRAL = 0

const SORTDIRECTIONS: Directions = {
  neutral: NEUTRAL,
  ascending: ASC,
  descending: DESC,
  up: ASC,
  down: DESC,
  0: NEUTRAL,
  1: ASC,
  '-1': DESC
}

/**
 * Normalize names for sort directions; should ideally be imported from here!
 */
export function normalizeSortDirection(direction: string | number) {
  return SORTDIRECTIONS[('' + direction).toLowerCase()]
}
