import * as React from "react"
import UserMenuContainer from "../../login/containers/UserMenuContainer"
import OUContextDropdown from "../../../shared/OUContext/OUContextDropdownContainer"
import HorizontalNavbar from "../lib/HorizontalNavbar"
import HorizontalNavbarItem from "../lib/HorizontalNavbarItem"
import HorizontalNavbarSegment from "../lib/HorizontalNavbarSegment"
import type { IntlShape } from "react-intl"
import { injectIntl } from "react-intl"
import { mapStateToProps } from "../../login/redux/utils/loginUtils"
import { connect } from "react-redux"
import { Badge } from "antd"

export interface Props {
  intl: IntlShape
  selectedKey?: string
  loggedIn: boolean
  changeAppModule: (moduleName: string) => void
  onGoHome: () => void
  canViewCalendar: boolean
  canViewProcesses: boolean
  canViewConfiguration: boolean
  canViewUserReports: boolean
  canViewCalendarReports: boolean
  canViewUnavailabilityReports: boolean
  topPx?: number
  networkIsVerySlow: boolean
  username?: string
  vacationBadge: number
}

const CALENDARAPPKEYS = [
  "planner",
  "dayplanner",
  "weekplanner",
  "autoplan",
  "pbbTemplates",
]

const CONFIGURATIONAPPKEYS = [
  "calendarentrytypes",
  "qualifications",
  "userManager",
  "orgChart",
  "collectiveAgreementsManager",
  "calculationProfileManager",
  "authorisation",
  "holidayUpload",
  "home",
  "clientStats",
]

const REPORTAPPKEYS = [
  "timeSheet",
  "timeSheetBonuses",
  "weeklySchedule",
  "absenceOverview",
  "vacationList",
  "vacationSheet",
  "yearSummary",
]

class MainAppMenu extends React.Component<Props> {
  render(): JSX.Element {
    return (
      <HorizontalNavbar topPx={this.props.topPx ?? 0} extraClassName="mainmenu">
        <HorizontalNavbarSegment position="left">
          {this.renderLogo()}
          {this.renderContextWidget()}
        </HorizontalNavbarSegment>
        <HorizontalNavbarSegment position="center">
          {this.renderMainMenuItems()}
        </HorizontalNavbarSegment>
        <HorizontalNavbarSegment position="right">
          {this.renderUserMenu()}
        </HorizontalNavbarSegment>
      </HorizontalNavbar>
    )
  }

  private renderLogo() {
    return this.renderInertMenuElement(
      <div className="mm-logo">
        <a href="/" onClick={this.props.onGoHome} title="mina.works">
          <img
            alt="mina.works"
            src="mina_works_icon.svg"
            className="fullLogo"
          />
        </a>
      </div>
    )
  }

  private renderContextWidget() {
    return this.props.loggedIn
      ? this.renderInertMenuElement(<OUContextDropdown />)
      : null
  }

  private renderMainMenuItems() {
    return (
      <>
        {this.renderCalendarMenuItem()}
        {this.renderMessagesMenuItem()}
        {this.renderReportsMenuItem()}
        {this.renderProcessManagerMenuItem()}
        {this.renderConfigurationMenuItem()}
      </>
    )
  }

  private renderCalendarMenuItem() {
    if (this.props.canViewCalendar) {
      return this.renderMenuItem("planner", "Planung", "planner")
    }
  }

  private renderProcessManagerMenuItem() {
    if (this.props.canViewProcesses) {
      return this.renderMenuItem(
        "processManager",
        "Meldungen",
        "processManager",
        this.props.vacationBadge
      )
    }
  }

  private renderMessagesMenuItem() {
    return null
  }

  private renderReportsMenuItem() {
    return this.props.canViewUserReports
      ? this.renderMenuItem("weeklySchedule", "Berichte", "weeklySchedule")
      : null
  }

  private renderConfigurationMenuItem() {
    return this.props.canViewConfiguration
      ? this.renderMenuItem("configuration", "Verwaltung", "orgChart")
      : null
  }

  private renderUserMenu() {
    return this.renderInertMenuElement(<UserMenuContainer {...({} as any)} />)
  }

  private renderInertMenuElement(contents) {
    return <HorizontalNavbarItem>{contents}</HorizontalNavbarItem>
  }

  private renderMenuItem(
    key: string,
    label: string,
    appModuleName: string,
    badge: number = 0
  ) {
    return (
      <HorizontalNavbarItem
        value={key}
        currentValue={this.getCurrentSelectedAppKey()}
        onClick={() => this.props.changeAppModule(appModuleName)}
      >
        <Badge size="small" offset={[10, 4]} count={badge}>
          {this.props.intl.formatMessage({ id: label })}
        </Badge>
      </HorizontalNavbarItem>
    )
  }

  private getCurrentSelectedAppKey() {
    const selected = CONFIGURATIONAPPKEYS.includes(this.props.selectedKey)
      ? "configuration"
      : CALENDARAPPKEYS.includes(this.props.selectedKey)
      ? "planner"
      : REPORTAPPKEYS.includes(this.props.selectedKey)
      ? "calendarReports"
      : this.props.selectedKey
    return selected
  }
}

export default connect(mapStateToProps)(injectIntl(MainAppMenu))
