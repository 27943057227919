/**
 * The clipboard manager
 *
 * Very simple interface to interact with an application-global clipboard
 *
 * Note that all key-value combos are stored in local storage; the keys are
 * prefixed with `clipboard-` - so if you also use local storage for other
 * stuff, avoid keys starting with `clipboard-` in your own code!
 */

/**
 * IMPORTANT:
 * Add your clipboard keys to `legalKeys.ts` - this is to avoid typos!
 */
import LEGALKEYS from "./legalKeys"

export interface ClipboardStorage {
  length: number
  setItem: (k: string, v: string) => void
  getItem: (k: string) => string | null
  removeItem: (k: string) => void
  key: (i: number) => string | null
}

/**
 * NOTE:
 * This clipboard manager manages a single copied entry per type!
 *
 * It is modelled after the behaviour of a stack of size 1. Every item
 * pushed on top of another item leads to the previous item being removed
 * automatically.
 *
 * You can manage strings directly, or the clipboard can perform some
 * limited convenience-handling of automatic JSON conversions.
 */
export class AppClipboard {
  protected legalKeys: Record<string, boolean>
  private prefix = "clipboard-"

  constructor(
    private storage: ClipboardStorage = window.localStorage,
    options: {
      additionalLegalKeys?: Record<string, boolean>
    } = { additionalLegalKeys: {} }
  ) {
    this.legalKeys = {
      ...LEGALKEYS,
      ...options.additionalLegalKeys,
    }
  }

  /**
   * Stringify an entry and push it at the same time; note that
   * the entry must serialise in a sensible way for this to work,
   * and the user must be aware that it has been serialised!
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  pushJSON(typeKey: string, entry: any): number {
    return this.push(typeKey, JSON.stringify(entry))
  }

  /**
   * Get an entry, and parse it as a JSON object;
   * REMEMBER that you'll still have to manage complex
   * structures (e.g., convert Date objects inside the
   * JSON data etc.)
   *
   * Unlike pop, this doesn't also remove the entry!
   */
  peekJSON(typeKey: string): any {
    const entryJSON = this.peek(typeKey)
    return entryJSON !== null ? JSON.parse(entryJSON) : null
  }

  /**
   * Pop an entry, and parse it as a JSON object;
   * REMEMBER that you'll still have to manage complex
   * structures (e.g., convert Date objects inside the
   * JSON data etc.)
   */
  popJSON(typeKey: string): any {
    const entryJSON = this.pop(typeKey)
    return entryJSON !== null ? JSON.parse(entryJSON) : null
  }

  push(typeKey: string, entryJSON: string): number {
    const key = this.k(typeKey)
    try {
      this.storage.setItem(key, entryJSON)
      return 1
    } catch (e) {
      return 0
    }
  }

  pop(typeKey: string): string | null {
    const entryJSON = this.peek(typeKey)
    const key = this.k(typeKey)
    this.storage.removeItem(key)
    return entryJSON
  }

  peek(typeKey: string): string | null {
    const key = this.k(typeKey)
    return this.storage.getItem(key)
  }

  has(typeKey: string): boolean {
    return !!this.peek(typeKey)
  }

  clear(): void {
    const keys: (string | null)[] = []
    for (let i = 0; i < this.storage.length; i++) {
      const k = this.storage.key(i)
      if (this.isClipboardKey(k)) {
        keys.push(k)
      }
    }
    for (const k of keys) {
      if (k !== null) {
        this.storage.removeItem(k)
      }
    }
  }

  protected isLegalKey(k: string): boolean {
    return this.legalKeys[k]
  }

  private k(typeKey: string): string {
    if (!this.isLegalKey(typeKey)) {
      throw new Error(`key-not-found-in-legal-keys: ${typeKey}`)
    }
    return `${this.prefix}${typeKey}`
  }

  private isClipboardKey(storageKey: string | null) {
    return storageKey?.startsWith(this.prefix)
  }
}

const appClipboard = new AppClipboard()
export default appClipboard
