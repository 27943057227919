/**
 * A simple (but consistent) color bullet to use in
 * dropdowns/selects (for departments, CETs, etc.)
 *
 * Alpha channel is handled by the bullet itself!
 */

import React from "react"
import type { FullColor, SimpleColor } from "../../commonInterfaces/Color"
import type Color from "../../commonInterfaces/Color"

const ALPHA = 0.4

export interface Props {
  color: Color
  marginTop?: number
}

export default class ColorBullet extends React.PureComponent<Props> {
  render(): JSX.Element {
    let r = 255
    let g = 255
    let b = 255
    if (this.props.color) {
      r =
        (this.props.color as FullColor).getR !== undefined
          ? (this.props.color as FullColor).getR()
          : (this.props.color as SimpleColor).r
      g =
        (this.props.color as FullColor).getG !== undefined
          ? (this.props.color as FullColor).getG()
          : (this.props.color as SimpleColor).g
      b =
        (this.props.color as FullColor).getB !== undefined
          ? (this.props.color as FullColor).getB()
          : (this.props.color as SimpleColor).b
    }
    return (
      <div
        className="color-bullet-container"
        style={{
          display: "inline-block",
          marginRight: "1em",
          lineHeight: "100%",
          verticalAlign: "middle",
          height: "100%",
        }}
      >
        <div
          style={{
            position: "absolute",
            display: "block",
            height: "100%",
            width: "100%",
            left: "0px",
            top: "0px",
          }}
        >
          <div
            style={{
              display: "flex",
              height: "100%",
              width: "1em",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              className="color-bullet"
              style={{
                backgroundColor: `rgba(${r}, ${g}, ${b}, ${ALPHA})`,
                border: `2px solid rgb(${r}, ${g}, ${b})`,
                borderRadius: "6px",
                width: "8px",
                height: "8px",
                marginTop: "calc(50% - 8px)",
              }}
            >
              &nbsp;
            </div>
          </div>
        </div>
      </div>
    )
  }
}
