const SUBMITURL = '/rest/supporttickets/submitTicket'
const ENABLEDURL = '/rest/supporttickets/isEnabled'

export default function makeBackendService(): BackendService {
  return new BackendService()
}

export class BackendService {

  async submitTicket(
    subject: string,
    description: string
  ): Promise<boolean> {
    const r = await fetch(
      SUBMITURL,
      {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json; charset=utf-8'
        },
        method: 'POST',
        body: JSON.stringify({ async: false, subject, description })
      }
    )
    const json = await r.json()
    return json.success
  }

  async enabled(clientId: string): Promise<boolean> {
    const r = await fetch(
      `${ENABLEDURL}?${new URLSearchParams({ clientId }).toString()}/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
    const json = await r.json()
    return json?.enabled ?? false
  }

}
