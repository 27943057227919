import * as React from "react"
import MobileCalendar from "./calendar/MobileCalendar"
import MobileDashboard from "./dashboard/MobileDashboard"
import MobileProcessManager from "./processManager/MobileProcessManager"
import MobilePunch from "./punch/MobilePunch"
import type FeatureType from "@mina-works/featuresets/dist/definition/interfaces/FeatureType"
import type { Settings } from "./utils/SettingsPanel"
import { useIntl } from "react-intl"
import { REQUESTS } from "./routing/routing"

interface Props {
  availableFeatures: FeatureType[]
  displayModule?: string
  clientId: string
  departmentId: string
  employeeId: string
  changeDisplayedModule: (m: string) => void
  notificationCount: number
  saveUserSettings: (settings: Settings) => Promise<void>
  loadUserSettings: () => Promise<Settings>
}

export default function PrimaryLoggedInUserScreen({
  displayModule,
  clientId,
  departmentId,
  employeeId,
  changeDisplayedModule,
  availableFeatures,
  saveUserSettings,
  loadUserSettings,
  notificationCount,
}: Props): JSX.Element {
  const intl = useIntl()
  // TODO: Add proper routing, drop extra roundtrips
  let MainContent: JSX.Element
  switch (displayModule) {
    case "punch":
      MainContent = (
        <MobilePunch
          availableFeatures={availableFeatures}
          clientId={clientId}
          departmentId={departmentId}
          employeeId={employeeId}
        />
      )
      break
    case "calendar":
      MainContent = (
        <MobileCalendar
          availableFeatures={availableFeatures}
          clientId={clientId}
          departmentId={departmentId}
          employeeId={employeeId}
          changeDisplayedModule={changeDisplayedModule}
        />
      )
      break
    case REQUESTS:
      MainContent = (
        <MobileProcessManager
          saveUserSettings={saveUserSettings}
          loadUserSettings={loadUserSettings}
          clientId={clientId}
          employeeId={employeeId}
          departmentId={departmentId}
          changeDisplayedModule={changeDisplayedModule}
          locale={intl.locale}
          notificationCount={notificationCount}
        />
      )
      break
    case "dashboard":
    default:
      MainContent = (
        <MobileDashboard
          clientId={clientId}
          departmentId={departmentId}
          employeeId={employeeId}
          changeDisplayedModule={changeDisplayedModule}
        />
      )
  }
  return <div className="mobile-main-content-area">{MainContent}</div>
}
