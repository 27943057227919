import { valueAt, isEqual } from "../../../../shared/walkObjectHierarchy"
import types from "./actionTypes"

export default function userMasterDataReducer(state = {}, action) {
  switch (action.type) {
    case types.LOADUSERMASTERDATA:
      return loadUserMasterData(state, action)
    case types.TOGGLEUSERMASTERDATADIRTYFLAG:
      return {
        ...state,
        formIsDirty: action.isDirty !== undefined ? !action.isDirty : false,
      }
    default:
      return state
  }
}

function loadUserMasterData(state, action) {
  const data = action.masterData
  const oldData = valueAt(state, ["userMasterData"]) || {}
  if (isEqual(data, oldData)) {
    return state
  }
  return {
    ...state,
    userMasterData: data,
  }
}
