/**
 * A color picker component to integrate with react-redux
 */

import * as React from 'react'
import { Popover } from 'antd'

import nestedColors from './colors'

// Color picker: https://www.npmjs.com/package/react-color
// Example for redux-form Component:
// https://github.com/giladl82/redux-form-color-picker/blob/master/src/Form/ColorPicker.js

export interface ColorSpec {
  r?: number,
  g?: number,
  b?: number
}

export interface Props {
  value: ColorSpec,
  label?: string,
  error?: string,
  onChange: (color: ColorSpec) => void,
  dataE2E?: string
}
export default class ColorPicker extends React.PureComponent<Props> {

  getValue() {
    const illvals = [ null, undefined, '' ]
    const valid = component => ! illvals.includes(component)
    return (
      valid(this.props.value.r)
      && valid(this.props.value.g)
      && valid(this.props.value.b)
    )
      ? this.props.value
      : { r: 0, g: 0, b: 0 }
  }

  getHexValue(): string {
    return asHex(this.getValue()).toLowerCase()
  }

  render() {
    const handleChange = color => {
      this.props.onChange(color)
    }
    return (
      <div
        className='field colorpicker'
        data-e2e-test-id={this.props.dataE2E}
      >
        <label>{ this.props.label }</label>
        <div className={ this.props.error ? 'error' : '' }>
          <Popover
            placement = 'right'
            content = {
              <div
                className='mep24-color-picker'
                style={{
                  width: '272px',
                  height: '350px',
                  padding: '5px'
                }}
              >
                { this.getColorRows(handleChange) }
              </div>
            }
          >
            <div
              className='colorswatch'
              style={{
                backgroundColor: this.getHexValue(),
                border: '1px solid lightgrey',
                borderRadius: '4px',
                display: 'inline-block',
                height: '26px',
                width: '26px'
              }}
            />
          </Popover>
        </div>
      </div>
    )
  }

  getColorRows(handleChange) {
    return nestedColors.map(
      (row, idx) => (
        <div
          key={idx}
          className='mep24-color-picker-row'
          style={{
            width: '300px',
            height: '26px',
            float: 'left',
            margin: '0px'
          }}
        >
          { this.getColorCells(row, handleChange) }
        </div>
      )
    )
  }

  getColorCells(row, handleChange) {
    const cur = this.getHexValue()
    return row.map(
      (cell, idx) => (
        <div
          key = { idx }
          className = {
            'mep24-color-picker-cell' + (
              cur === cell.toLowerCase() ? ' active-color' : ''
            )
          }
          onClick = { () => handleChange(asRGB(cell)) }
          style = {{
            ...(
              cur === cell.toLowerCase()
                ? { border: '1px solid white' }
                : {}
            ),
            backgroundColor: cell,
            width: '26px',
            height: '26px',
            float: 'left',
          }}
        >
          &nbsp;
        </div>
      )
    )
  }
}

/**
 * Convert a hex color string #xxyyzz to { r, g, b }, where the values are
 * integers (short hex strings are supported, i.e. #12F => #1122FF)
 */
export const asRGB = hexStr => ({
  r: parseInt(fullHex(hexStr, 0), 16),
  g: parseInt(fullHex(hexStr, 1), 16),
  b: parseInt(fullHex(hexStr, 2), 16),
})

const fullHex = (hexStr, idx) => {
  if ( hexStr.length < 5 ) { // special short color code
    const c = hexStr.substr(1 + idx, 1)
    return c + c
  } else {
    return hexStr.substr(1 + idx * 2, 2)
  }
}

/**
 * Convert { r, g, b }, where the values are integers, to a hex color
 * string #xxyyzz
 */
export const asHex = ({ r = 0, g = 0, b = 0 }) => '#' + hex(r) + hex(g) + hex(b)

const hex = num => {
  let res = num.toString(16)
  if ( res.length === 1 ) {
    res = '0' + res
  }
  return res.toUpperCase()
}
