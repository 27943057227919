import types from '../actions/actionTypes'
import { valueAt, isEqual } from '../../../../shared/walkObjectHierarchy'

export default function (
  state = {},
  action
) {
  switch (action.type) {
  case types.LOADCLIENTROLESFAILED:
    return {
      ...state,
      loadFailed: true,
      loadError: action.error
    }
  case types.OPENEDITROLEDIALOG:
    return {
      ...state,
      openEditRoleDialog: true,
      openEditRoleId: action.roleId
    }
  case types.CLOSEEDITROLEDIALOG:
    return {
      ...state,
      openEditRoleDialog: false,
      openEditRoleId: undefined
    }
  case types.LOADDEFAULTROLES:
    return {
      ...state,
      defaultRoles: {
        byName: action.roles.reduce(
          (acc, role) => {
            acc[role.name] = role
            return acc
          },
          {}
        )
      }
    }
  case types.LOADCLIENTROLES:
    return loadClientRoles(state, action)
  case types.SAVECLIENTROLE:
    return saveClientRole(state, action)
  case types.DELETECLIENTROLE:
    return deleteClientRole(state, action)
  default:
    return state
  }
}

function saveClientRole(state, action) {
  const byId = valueAt(state, ['roles', 'byId'])
  const newRoles = {
    ...byId
  }
  newRoles[action.role.id] = action.role
  return {
    ...state,
    openEditRoleDialog: false,
    openEditRoleId: undefined,
    roles: {
      byId: {
        ...newRoles
      }
    }
  }
}

function deleteClientRole(state, action) {
  const byId = valueAt(state, ['roles', 'byId'])
  const newRoles = {
    ...byId
  }
  delete newRoles[action.roleId]
  return {
    ...state,
    openEditRoleDialog: false,
    openEditRoleId: undefined,
    roles: {
      byId: {
        ...newRoles
      }
    }
  }
}

function loadClientRoles(state, action) {
  const byId = action.roles.reduce(
    (acc, role) => {
      acc[role.id] = role
      return acc
    },
    {}
  )
  const oldById = valueAt(state, ['roles', 'byId'])
  if (isEqual(oldById, byId)) {
    return state
  }
  return {
    ...state,
    roles: { byId }
  }
}
