/**
 * Home page component, adapted from SemanticUI example
 */

// TODO: split into HOC/presentational component; generic HOC for login?!

import React from "react"
import { connect } from "react-redux"
import { injectIntl } from "react-intl"
import { Card, Button } from "antd"
import { ArrowRightOutlined } from "@ant-design/icons"

import { openLoginDialog } from "../modules/login/redux/actions/loginActions"
import { mapStateToProps } from "../modules/login/redux/utils/loginUtils"

/*
const GetStartedOld = ({intl, dispatchOpenLoginDialog, username, isLoggedIn}) => {
  return (
    <Card
      style={{
        minHeight: 470,
        backgroundImage: 'url("background.jpg")',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}
      bordered={ false }
    >
      <div style={{textAlign: 'center'}}>
        <h1
          style={{
            fontSize: '4em', fontWeight: 'normal',
            marginBottom: 0, marginTop: '1.5em', color: 'white'
          }}
        >
          <img alt='mina.works' src='mina.works_w.png' className='fullLogo' />
        </h1>
        <h2
          style={{
            fontSize: '1.7em', fontWeight: 'normal', color: 'white',
            marginBottom: '0'
          }}
        >
          { intl.formatMessage({id: 'getstarted.tagline'}) }
        </h2>
        <h3
          style={{
            fontSize: '1.2em', fontWeight: 'normal', color: 'white',
            marginBottom: '1.5em'
          }}
        >
          { intl.formatMessage({id: 'getstarted.tagline2'}) }
        </h3>
        { !isLoggedIn &&
          <Button
            size='large'
            onClick={ dispatchOpenLoginDialog }
          >
            { intl.formatMessage({id: 'getstarted.login'}) }
            <ArrowRightOutlined />
          </Button>
        }
        { isLoggedIn &&
          <h2 style={{ color: 'white' }}>
            { intl.formatMessage({id: 'getstarted.welcome'}) }, { username }
          </h2>
        }
      </div>
    </Card>
  )
}
*/

const GetStarted = ({
  intl,
  dispatchOpenLoginDialog,
  username,
  isLoggedIn,
}) => {
  return (
    <Card
      style={{
        backgroundSize: "cover",
        textAlign: "center",
        justifyContent: "center",
      }}
      bordered={false}
    >
      <div style={{ textAlign: "center" }}>
        <img
          alt="mina.works"
          src="/mina-works-icon-w.png"
          className="getStartedLogo"
        />
        <br style={{ clear: "both" }} />
        <br style={{ clear: "both" }} />
        {!isLoggedIn && (
          <Button size="large" onClick={dispatchOpenLoginDialog}>
            {intl.formatMessage({ id: "getstarted.login" })}
            <ArrowRightOutlined />
          </Button>
        )}
        {isLoggedIn && (
          <h2>
            {intl.formatMessage({ id: "getstarted.welcome" })}, {username}
          </h2>
        )}
      </div>
    </Card>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    dispatchOpenLoginDialog: () => dispatch(openLoginDialog()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(GetStarted))
