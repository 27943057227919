const types = {
  OPENEDITCALENDARENTRYTYPESDIALOG: "OPENEDITCALENDARENTRYTYPESDIALOG_",
  CLOSEEDITCALENDARENTRYTYPESDIALOG: "CLOSEEDITCALENDARENTRYTYPESDIALOG_",
  SAVECALENDARENTRYTYPE: "SAVECALENDARENTRYTYPE_",
  LOADCALENDARENTRYTYPES: "LOADCALENDARENTRYTYPES_",
  LOADINGCALENDARENTRYTYPESFAILED: "LOADINGCALENDARENTRYTYPESFAILED_",
  ARCHIVECET: "ARCHIVECET_",
  RESTORECET: "RESTORECET_",
  DELETECET: "DELETECET_",
  INCLUDEARCHIVEDCETS: "INCLUDEARCHIVEDCETS_",
}
export default types
