const types = {
  SAVEPBBTEMPLATE: 'SAVE_PBBTEMPLATE_',
  SAVEPBBTEMPLATEFAILED: 'SAVE_PBBTEMPLATE_FAILED_',
  CREATEPBBTEMPLATE: 'CREATE_PBBTEMPLATE_',
  CREATEPBBTEMPLATEFAILED: 'CREATE_PBBTEMPLATE_FAILED_',
  DELETEPBBTEMPLATE: 'DELETE_PBBTEMPLATE_',
  DELETEPBBTEMPLATEFAILED: 'DELETE_PBBTEMPLATE_FAILED_',
  LOADALLPBBTEMPLATES: 'LOAD_ALL_PBBTEMPLATES_',
  LOADALLPBBTEMPLATESFAILED: 'LOAD_ALL_PBBTEMPLATES_FAILED_',
  OPENPBBTEMPLATEFORMMODAL: 'OPEN_PBBTEMPLATE_FORM_MODAL_',
  CLOSEPBBTEMPLATEFORMMODAL: 'CLOSE_PBBTEMPLATE_FORM_MODAL_',
}

export default types
