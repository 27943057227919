/**
 * Login-related API
 *
 * Note: This module has no tests (these could only test implementation at this
 *       point)
 *
 * TODO: Think about file name again
 */

import { loginAsync, logoutAsync } from "./redux/actions/loginActions"
import {
  getIsLoggedInState,
  getUserIsAdminState,
  getUserIsRestrictedAdminState,
  getUserIsEnhancedState,
  getUserClientAndOrgunitId as getCOIDsSelector,
  getUserIdState,
  getUserCanManageAdminsState,
  getUserCanEditClientsState,
} from "./redux/selectors/loginSelectors"

export const login = (username, password, dispatch, path, navigate) =>
  dispatch(loginAsync(username, password, path, navigate))

export const logout = (dispatch, path, navigate) =>
  dispatch(logoutAsync(path, navigate))

export const getLoggedInUserId = state => {
  const res = getUserIdState(state)
  return res
}

export const getUserClientAndOrgunitId = state => {
  const res = getCOIDsSelector(state)
  return res
}

export const isLoggedIn = state => {
  const res = getIsLoggedInState(state)
  return res
}

export const isAdmin = state => {
  const res = getUserIsAdminState(state)
  return res
}

export const isPureAdmin = state => {
  const isAdmin = getUserIsAdminState(state)
  const isRestrictedAdmin = getUserIsRestrictedAdminState(state)
  return isAdmin && !isRestrictedAdmin
}

export const isRestrictedAdmin = state => {
  const res = getUserIsRestrictedAdminState(state)
  return res
}

export const isEnhanced = state => {
  const res = getUserIsEnhancedState(state)
  return res
}

export const canManageAdmins = state => {
  const res = getUserCanManageAdminsState(state)
  return res
}

export const canEditClients = state => {
  const res = getUserCanEditClientsState(state)
  return res
}

export const getUserAdminFlags = state => {
  return {
    isAdmin: getUserIsAdminState(state),
    isRestrictedAdmin: getUserIsRestrictedAdminState(state),
    isEnhanced: getUserIsEnhancedState(state),
  }
}

export const isUserOutsideClients = state => {
  const flags = getUserAdminFlags(state)
  return (
    flags.isAdmin === true ||
    flags.isRestrictedAdmin === true ||
    flags.isEnhanced === true
  )
}
