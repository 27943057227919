/**
 * New: logged times ("Ist-Zeiten")
 */
export const EDITOWNLOGGEDTIMES = "edit-own-logged-times"
export const PUNCHINTOLOGTIMES = "punch-in-to-log-times"
/* End new logged times */

export const VIEWACTIVITIES = "view-activities"
export const EDITACTIVITIES = "edit-activities"
export const VIEWQUALIFICATIONS = "view-qualifications"
export const EDITQUALIFICATIONS = "edit-qualifications"
export const ASSIGNQUALIFICATIONS = "assign-qualifications"
export const VIEWQUALIFICATIONSASSIGNMENTS = "view-qualifications-assignments"
export const EDITAUTOPLANS = "edit-autoplans"
export const VIEWUSERSEMPLOYEESMASTERDATA = "view-users+employees-master-data"
export const EDITUSERSEMPLOYEESMASTERDATA = "edit-users+employees-master-data"
export const DELETEUSERSEMPLOYEES = "delete-users+employees"
export const VIEWWORKCONTRACTS = "view-work-contracts"
export const EDITWORKCONTRACTS = "edit-work-contracts"
export const VIEWREASSIGNMENTS = "view-re-assignments"
export const EDITREASSIGNMENTS = "edit-re-assignments"
export const ASSIGNROLES = "assign-roles"
export const EDITROLES = "edit-roles"
export const VIEWROLEASSIGNMENTS = "view-role-assignments"
export const VIEWORGCHART = "view-org-chart"
export const EDITORGCHART = "edit-org-chart"
export const VIEWPLANNER = "view-planner"
export const EDITPLANNER = "edit-planner"
export const VIEWCOLLEAGUEPLANNER = "view-colleague-planner"
export const SHOWBALANCESPLANNER = "show-balances-planner"
export const CHANGEPASSWORD = "change-password"
export const VIEWPBBTEMPLATES = "view-pbbtemplates"
export const EDITPBBTEMPLATES = "edit-pbbtemplates"
export const VIEWCOLLECTIVEAGREEMENTS = "view-collectiveagreements"
export const EDITCOLLECTIVEAGREEMENTS = "edit-collectiveagreements"
export const VIEWWORKHOURSACCOUNT = "view-work-hours-account"
export const EDITWORKHOURSACCOUNT = "edit-work-hours-account"
export const VIEWLEAVEACCOUNT = "view-leave-account"
export const EDITLEAVEACCOUNT = "edit-leave-account"
export const VIEWSUPPORTBUTTON = "view-support-button"
export const VIEWUSERREPORTS = "view-user-reports"
export const VIEWCLIENTMASTERDATA = "view-client-master-data"
export const VIEWVACTIONOVERVIEWDETAILS = "view-vacation-overview-details"
export const EDITRETROACTIVELIMITS = "edit-retroactive-limits"
export const IGNORERETROACTIVELIMITS = "ignore-retroactive-limits"

/** We want to seperate the 'own' permissions, at least visually in the gui */
export const VIEWOWNWORKHOURSACCOUNT = "view-own-work-hours-account"
export const VIEWOWNLEAVEACCOUNT = "view-own-leave-account"
export const VIEWOWNPLANNER = "view-own-planner"
export const VIEWOWNMASTERDATA = "view-own-master-data"
export const EDITEMLPOYEELEAVEREQUEST = "edit-employee-leave-request"

/** New and questionable: */
export const VIEWTEMPASSIGNMENTS = "view-temp-assignments"
export const EDITTEMPASSIGNMENTS = "edit-temp-assignments"
export const VIEWSETTINGS = "view-settings"
export const EDITSETTINGS = "edit-settings"
