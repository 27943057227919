const messages = {
  "token-error-reused": "Token reused",
  "token-error-too-fast": "Too many retries. Please wait 30 seconds",
  "token-error-incorrect": "Incorrect or expired token used",
  "token-error-unknown":
    // eslint-disable-next-line max-len
    "Unknown token validation error – please try again later",
  otpsetup: "Manage 2fa",
  secondfactor: "Second Factor",
  "enter-second-factor":
    "Please enter the second factor generated by your authenticator app",
  username: "Username",
  password: "Password",
  login: "Sign in",
  logoutUser: `Sign out {username}`,
  loggedInAs: `Signed in as {username}`,
  usersettings: "Personal Settings",
  changePassword: "Change password",
  logout: `Sign out`,
  "login.failed": "Couldn`t sign in",
  passwordForgotten: "Password forgotten?",
  passwordReset: "Reset password",
  send: "Send",
  "Reset mail sent.": "Password reset mail sent.",
  "Check your mail and spam folders.": "Check your inbox and spam folder.",
  "A problem with the username occurred":
    "A problem with the username occurred",
  usernameMissing: "Username is missing.",
  passwordMissing: "Password is missing.",
  loginErrorHeader: "Login Error",
  loginErrorMessage:
    "Please check your credentials or use the password-forgotten? form.",
  problemReportUserMenu: "Problem Report",
  clearProblemReportUserMenu: "Clear Problem Report",
  "really-delete-problem-report":
    "Do you really want to delete all entries in the problem report?",
  "mobile-link": "Mobile App",
}

export default messages
