/**
 * Configuration object for (individual) core table columns
 */

/**
 * Available column types
 */
export const STRING = "string"
export const DATE = "date"
export const BOOL = "boolean"

interface Flags {
  ["visible"]?: boolean
  ["sortable"]?: boolean
  ["columnType"]?: string
  ["clickable"]?: boolean
}

export default class {
  private columnType = STRING
  private isVisibleFlag = true
  private isSortableFlag = true
  private isClickableFlag = true

  /**
   * @param name The name of the column (for indexing purposes) - this is
   *             columnName in more general contexts
   * @param title The column's title (can be added later using setTitle)
   * @param flags.visible The column is displayed
   * @param flags.columnType Optional column type (defaults to `string`):
   *                         - `date`
   *                         - `boolean`
   * @param flags.sortable The column can be sorted
   * @param flags.clickable Should a click in this column trigger the row
   *                        clickHandler (defaults to `true`)
   *
   * Please note that columnTypes are also defined in this module (as
   * `DATE`, `BOOL`, `STRING`)!
   */
  constructor(private name: string, private title: string, flags: Flags = {}) {
    const { visible, sortable, columnType, clickable } = flags
    this.name = name
    this.columnType = columnType || STRING
    this.isVisibleFlag = visible === undefined ? true : !!visible
    this.isSortableFlag = sortable === undefined ? true : !!sortable
    this.isClickableFlag = clickable === undefined ? true : !!clickable
    this.setTitle(title)
  }

  setType(columnType: string): void {
    this.columnType = columnType
  }
  setVisible(): void {
    this.isVisibleFlag = true
  }
  setInvisible(): void {
    this.isVisibleFlag = false
  }
  setSortable(): void {
    this.isSortableFlag = true
  }
  setUnsortable(): void {
    this.isSortableFlag = false
  }
  setClickable(): void {
    this.isClickableFlag = true
  }

  /**
   * This might be a second step if we are dealing with translations
   */
  setTitle(title: string): void {
    this.title = title
  }

  getName(): string {
    return this.name
  }
  getType(): string {
    return this.columnType
  }
  getTitle(): string {
    return this.title
  }
  isDate(): boolean {
    return this.getType() === DATE
  }
  isString(): boolean {
    return this.getType() === STRING
  }
  isBool(): boolean {
    return this.getType() === BOOL
  }
  isVisible(): boolean {
    return !!this.isVisibleFlag
  }
  isSortable(): boolean {
    return !!this.isSortableFlag
  }
  isClickable(): boolean {
    return !!this.isClickableFlag
  }
}
