const messages = {
  "context.context": "Aktueller Kontext",
  "context.client": "Kunde",
  "context.orgunit": "Abteilung",
  "context.no-contact": "Kein Ansprechpartner",
  "context.contact": "Ansprechpartner",
  "context.select-client": "Mandanten auswählen",
}

export default messages
