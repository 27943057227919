import { fetchREST } from "../../shared/rest.js"

import type { LoadedUserSettings, SavedUserSettings } from "./interfaces"

export const loadUserSettingsAsync = async (): Promise<LoadedUserSettings> => {
  const preferencesJSON = await fetchREST("/userGUISettings/getSettings")
  const preferences = preferencesJSON.json()
  return preferences
}

export const saveUserSettingsAsync = async (
  settings: SavedUserSettings
): Promise<void> => {
  await fetchREST(
    "/userGUISettings/saveSettings",
    { settings },
    { method: "POST" }
  )
  return
}
