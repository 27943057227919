/**
 * A time picker (hh:mm) component to integrate with react-redux
 *
 * This component by default represents *points in time*, i.e., hours in a day.
 * For this reason, the default behaviour is to disallow negative results, and
 * to stop at 23:59.
 *
 * If you allow negative results using `allowNegative`, be aware that the *hour
 * and the minutes* become negative (everything else would lead to errors
 * later).
 *
 * Pretty straightforward to use. Please note that while this doesn't directly
 * use @mep24/relativetime.RelativeTime, using that is recommended for further
 * processing if you're representing points in time!
 *
 * Special options:
 * - allowNegative (adds a selection between + and -)
 * - unlimitedHours (ignores 24 hour maximum for times)
 */

import * as React from "react"
import { Form, InputNumber, Select } from "antd"

// This is the *absolute* maximum for entry!
const MAXHOURS = 999999

export interface TimeValue {
  h: number
  m: number
}

export interface Props {
  value?: TimeValue
  defaultValue?: TimeValue
  onChange: (evt: any, value: TimeValue) => void
  label?: string
  error?: string
  form?: any
  meta?: any
  unlimitedHours?: boolean
  allowNegative?: boolean
  dataE2E?: string
}

export default class extends React.Component<Props> {
  isNegative() {
    return this.getH() < 0
  }

  getH() {
    const value = this.getControlledValue()
    const result =
      value !== undefined
        ? value.h !== undefined
          ? parseInt("" + value.h, 10)
          : 0
        : 0
    return result
  }

  getM() {
    const value = this.getControlledValue()
    return value !== undefined
      ? value.m !== undefined
        ? parseInt("" + value.m, 10)
        : 0
      : 0
  }

  getControlledValue() {
    const { value, defaultValue } = this.props
    if (value !== undefined) {
      return value
    } else {
      return defaultValue
    }
  }

  getValue(v) {
    // const v = e.target.value
    return v !== undefined ? parseInt(v, 10) : 0
  }

  render() {
    const handleChangeN = neg => {
      let h = this.getH()
      let m = this.getM()
      if (neg === "-") {
        h = -h
        m = -m
      }
      this.props.onChange(undefined, { h, m })
    }
    const handleChangeH = e => {
      let h = this.getValue(e) || 0
      let m = this.getM()
      if (this.isNegative()) {
        h = -h
        m = -m
      }
      this.props.onChange(e, { h, m })
    }
    const handleChangeM = e => {
      let m = this.getValue(e) || 0
      let h = this.getH()
      if (this.isNegative()) {
        h = -h
        m = -m
      }
      this.props.onChange(e, { h, m })
    }
    const prefix = this.props.allowNegative ? (
      <Select
        className="field-time-negative"
        style={{ width: "6em" }}
        defaultValue="+"
        onChange={handleChangeN}
      >
        <Select.Option value="+">+</Select.Option>
        <Select.Option value="-">-</Select.Option>
      </Select>
    ) : undefined
    // The child components use defaultValue instead of value because we don't
    // want them updating from the redux state until done
    return (
      <div className="field timepicker">
        <label>{this.props.label}</label>
        <div className={this.props.error ? "error" : ""}>
          <Form.Item
            label={""}
            validateStatus={this.props.meta.error ? "error" : "success"}
            hasFeedback={!!this.props.meta.error}
            help={this.props.meta.error}
            data-e2e-test-id={this.props.dataE2E}
          >
            {prefix}
            <InputNumber
              className="field-time-hours"
              style={{ width: "calc(50% - 4em)" }}
              min={0}
              max={this.props.unlimitedHours ? MAXHOURS : 23}
              step={1}
              value={Math.abs(this.getH())}
              onChange={handleChangeH}
            />
            <span
              className="field-time-separator"
              style={{
                fontSize: "large",
                fontWeight: "bold",
                margin: "0 3px",
                lineHeight: "2em",
                width: "2em",
              }}
            >
              :
            </span>
            <InputNumber
              className="field-time-minutes"
              style={{ width: "calc(50% - 4em)" }}
              min={0}
              max={59}
              step={1}
              value={this.getM()}
              onChange={handleChangeM}
            />
          </Form.Item>
        </div>
      </div>
    )
  }
}
