import type { PureDateTime } from '../legacy/puredatetime'

/**
 * Simple user representation for the frontend; don't use for special
 * user-centered modules such as the user management module (although you
 * might *extend* this user model for such purposes).
 */

export interface UserOptions {
  id?: string
  title?: string
  username?: string
  firstname?: string
  surname?: string
  orgunit?: any // FIXME: Needs to be specified
  isDeleted?: boolean
  isDeletedSince?: PureDateTime
  isHidden?: boolean
  isHiddenSince?: PureDateTime
  isLocked?: boolean
  isLockedSince?: PureDateTime
}

// TODO: Add temporary assignments
export default class User {

  private values!: UserOptions

  /**
   * @param id The user's ID; this may or may not be used by user code
   * @param username The user's name; this should be provided
   * @param firstname The user's firstname; optional
   * @param surname The user's surname; optional
   * @param orgunit The user's home orgunit as JSON
   */
  constructor({
    id,
    username = '',
    title = '',
    firstname = '',
    surname = '',
    orgunit = {},
    isDeleted = false,
    isDeletedSince,
    isHidden = false,
    isHiddenSince,
    isLocked = false,
    isLockedSince
  }: UserOptions = {}) {
    this.values = {
      id,
      title,
      username,
      firstname,
      surname,
      orgunit,
      isDeleted,
      isDeletedSince,
      isHidden,
      isHiddenSince,
      isLocked,
      isLockedSince
    }
  }

  getId(): string { return this.values.id }
  getUsername(): string { return this.values.username }
  getFirstname(): string { return this.values.firstname }
  getTitle(): string { return this.values.title ?? '' }
  getSurname(): string { return this.values.surname }
  getOrgunitId(): string { return this.values.orgunit.id }
  getOrgunitName(): string { return this.values.orgunit.name }
  isHidden(): boolean { return !!this.values.isHidden }
  isDeleted(): boolean { return !!this.values.isDeleted }
  isLocked(): boolean { return !!this.values.isLocked }

  /**
   * @returns False, or the date of the deletion
   */
  isDeletedSince(): PureDateTime | false {
    return this.isDeleted() && this.values.isDeletedSince
  }

  /**
   * @returns False, or the date of hidden
   */
  isHiddenSince(): PureDateTime | false {
    return this.isHidden() && this.values.isHiddenSince
  }

  /**
   * @returns False, or the date of hidden
   */
  isLockedSince(): PureDateTime | false {
    return this.isLocked() && this.values.isLockedSince
  }

  /**
   * Return a standard representation string of the user's id information:
   * username (Firstname Surname)
   *
   * This accounts for missing first- and surnames!
   */
  getFullIdentification(): string {
    let s: string = this.getFullname()
    const username = this.getUsername()
    if (s) {
      s += ' (' + username + ')'
    } else {
      s = username
    }
    return s
  }

  /**
   * Get the user's name as 'Firstname Surname'
   */
  getFullname(): string {
    const title = '' + this.getTitle()
    const firstname = '' + this.getFirstname()
    const surname = '' + this.getSurname()
    if (firstname || surname) {
      return [title, firstname, surname].filter(s => !!s).join(' ')
    } else {
      return ''
    }
  }

  /**
   * Get the user's full name, unless none is specified, and then get the
   * user's email address instead
   */
  getFullnameOrUsername(): string {
    return this.getFullname() || this.getUsername()
  }

  /**
   * Get the user's name as 'Surname, Firstname'
   */
  getAddressBookName(): string {
    const title = '' + this.getTitle()
    const firstname = '' + this.getFirstname()
    const surname = '' + this.getSurname()
    if (firstname || surname) {
      return [surname, firstname, title].filter(s => !!s).join(', ')
    } else {
      return ''
    }
  }

  toJSON(): any { return this.values }
}
