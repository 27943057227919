import type { DateTimeDate } from '../legacy/datetime'
import type RelativeTime from '../legacy/relativetime'
import { getPositiveWeekdayOffset } from '../legacy/time-utils'

export interface IndividualDayOpeningHours {
  opens?: RelativeTime
  closes?: RelativeTime
}

export type OpeningHours = IndividualDayOpeningHours[]

export default class OpeningHoursDelegate {

  // eslint-disable-next-line no-useless-constructor
  constructor(private openingHours: OpeningHours) { }

  getEarliestOpens(defaultValue: RelativeTime) {
    let earliest
    for (const oc of Object.values(this.openingHours)) {
      if (
        oc
        && oc.opens
        && (
          earliest === undefined
          || earliest.gt(oc.opens)
        )
      ) {
        earliest = oc.opens
      }
    }
    return earliest !== undefined ? earliest : defaultValue
  }

  getLatestCloses(defaultValue: RelativeTime) {
    let latest
    for (const oc of Object.values(this.openingHours)) {
      if (
        oc
        && oc.closes
        && (
          latest === undefined
          || latest.lt(oc.closes)
        )
      ) {
        latest = oc.closes
      }
    }
    return latest !== undefined ? latest : defaultValue
  }

  getOpensOnDate(datetimedate: DateTimeDate, defaultValue?: RelativeTime) {
    const dayname = datetimedate.getDayNameAsKey()
    const opens = this.getOpensClosesOnDayname(dayname).opens
    return opens !== undefined ? opens : defaultValue
  }
  getClosesOnDate(datetimedate: DateTimeDate, defaultValue?: RelativeTime) {
    const dayname = datetimedate.getDayNameAsKey()
    const closes = this.getOpensClosesOnDayname(dayname).closes
    return closes !== undefined ? closes : defaultValue
  }

  getOpensClosesOnDayname(dayname: string): IndividualDayOpeningHours {
    const idx = getPositiveWeekdayOffset('monday', dayname)
    let res = this.openingHours[idx]
    if (
      res
      && res.opens
      && res.closes
      // eslint-disable-next-line no-mixed-operators
      && res.opens.diffSeconds(res.closes) < 3601
      // eslint-disable-next-line no-mixed-operators
      || !res
      || !res.opens
      || !res.closes
    ) {
      res = {}
    }
    return res
  }

}