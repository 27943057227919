const types = {
  OPENEDITQUALIFICATIONDIALOG: "OPEN_EDIT_QUALIFICATION_DIALOG_",
  CLOSEEDITQUALIFICATIONDIALOG: "CLOSE_EDIT_QUALIFICATION_DIALOG_",
  LOADQUALIFICATIONS: "LOAD_QUALIFICATIONS_",
  SAVEQUALIFICATION: "SAVE_QUALIFICATION_",
  ARCHIVEQUALIFICATION: "ARCHIVE_QUALIFICATION_",
  RESTOREQUALIFICATION: "RESTORE_QUALIFICATION_",
  DELETEQUALIFICATION: "DELETE_QUALIFICATION_",
  CHANGEORDER: "CHANGE_ORDER_",
  INCLUDEARCHIVEDQUALIFICATIONS: "INCLUDE_ARCHIVED_QUALIFICATIONS_",
}
export default types
