import React from "react"
import { FormattedMessage, injectIntl } from "react-intl"
import { Divider } from "antd"

import VersionBadge from "./VersionBadge"

const FooterContent = props => {
  return (
    <div>
      <VersionBadge />
      <a target="_blank" href="https://mina.works/imprint/" rel="noreferrer">
        <FormattedMessage id="imprint"></FormattedMessage>
      </a>
    </div>
  )
}

export default injectIntl(FooterContent)
