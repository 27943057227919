import { Tag } from "antd"
import * as React from "react"

export interface Props {
  classNames?: string[]
  style?: React.CSSProperties
}

export default class BetaLabel extends React.PureComponent<Props> {
  render(): null {
    return null
  }
  /*
  render(): JSX.Element {
    return (
      <Tag
        className={this.getClassName()}
        closable={false}
        color="success"
        style={{
          fontVariant: "all-small-caps",
          fontVariantCaps: "all-small-caps",
          fontVariantLigatures: "normal",
          fontVariantNumeric: "normal",
          fontVariantAlternates: "normal",
          fontVariantPosition: "normal",
          fontVariantEastAsian: "normal",
          fontSize: "x-small",
          margin: "0 0 0 10px",
          padding: "0 6px",
          fontWeight: 400,
          ...(this.props.style ?? {}),
        }}
      >
        Beta
      </Tag>
    )
  }
  */

  private getClassName(): string {
    const classNames = ["mina-beta-label", ...(this.props.classNames ?? [])]
    return classNames.join(" ")
  }
}
