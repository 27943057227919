/*
import antdDe from 'antd/lib/locale-provider/de_DE'
import antdEn from 'antd/lib/locale-provider/en_US'
*/
import antdDe from "antd/lib/locale/de_DE"
import antdEn from "antd/lib/locale/en_US"

import "dayjs/locale/de"

/**
 * Since the locale files are big, we need to import all required files
 * at some point inside our app to actually use them.
 * (https://github.com/ant-design/ant-design/issues/5605).
 *
 * Without the import of the 'de' locale, everything would still be 'en'
 * as per the default fallback even tho the ConfigProvider got the
 * correct language.
 *
 * If other languages are required -> don't forget to add more imports.
 *
 * Also ses the README.md in mina-works-rect-client.
 */

export const getAntdLocale = (key: string): any => {
  key = key.substring(0, 2)
  return key === "de"
    ? {
        ...antdDe,
        sortTitle: "Sortieren",
        triggerDesc: "Absteigend sortieren",
        triggerAsc: "Aufsteigend sortieren",
        cancelSort: "Sortieren abbrechen",
        dateFormat: "DD.MM.YYYY",
        dateTimeFormat: "DD.MM.YYYY HH:mm:ss",
        weekFormat: "YYYY-w",
        monthFormat: "YYYY-MM",
      }
    : antdEn
}
