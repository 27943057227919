import definedColors from './colors'
import type {
  Color as ColorT,
  ColorSet as ColorSetT
} from './colors'

interface ColorMap {
  [name: string]: Color[]
}

interface BgToFgMap {
  [bgcolor: string]: string
}

class Colors {

  private colorGrid!: Color[][]
  private colorMap!: ColorMap
  private bgToFgMap!: BgToFgMap

  constructor(private rawColors: ColorSetT[]) {
    this.preprocessColors()
  }

  getColorKeys() {
    return Object.keys(this.colorMap)
  }

  getColorsFor(key: string) {
    return this.colorMap[key]
  }

  getForegroundColorAsHexFor(bgColorAsHex: string) {
    return this.bgToFgMap[bgColorAsHex]
  }

  getSimpleBackgroundColorGrid() {
    return this.colorGrid
      .map(
        row => row.map(c => c.getBackgroundColorAsHex())
      )
  }

  private preprocessColors() {
    this.colorGrid = [] // list of lists of colors
    this.colorMap = {} // map of name => full colors
    this.bgToFgMap = {} // map bgColor => fgColor
    this.rawColors.forEach(
      (block, idx) => {
        const m = this.colorMap[block.name]
        const b = block.colors.map(c => new Color(c))
        block.colors.forEach(
          c =>
            this.bgToFgMap[c.background.toLowerCase()]
            = c.foreground.toLowerCase()
        )
        this.colorMap[block.name] = [
          ...(m !== undefined ? m : []),
          ...b
        ]
        if (idx > 1) { this.colorGrid.push(b) }
      }
    )
  }

}

class Color {

  private bgColor: string
  private fgColor: string

  /**
   * background and foreground are hex colors
   * (e.g., #fff or #374044)
   */
  constructor({ background, foreground }: ColorT) {
    this.bgColor = background
    this.fgColor = foreground
  }

  getBackgroundColorAsHex() {
    return this.bgColor
  }

  getForegroundColorAsHex() {
    return this.fgColor
  }

}

export default new Colors(definedColors)