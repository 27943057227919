/**
 * Selector model for the organisational chart UI
 */
import { createSelector } from "reselect"

import { valueAt } from "../../../../shared/walkObjectHierarchy"

import Hierarchy from "../../../../shared/models/Hierarchy"

const getById = state => valueAt(state, ["orgChart", "orgUnits", "byId"])

export const getTransientNodeFor = state =>
  valueAt(state, ["orgChart", "transientNodeFor"])

export const getHierarchy = createSelector(
  getById,
  getTransientNodeFor,
  (byId, transientNodeFor) => {
    // TODO: Add complete transient json to state in action/orgChartReducer,
    //       get and combine with getById and only build hierarchy here.
    //       !Make deepcopy of getById!
    //       At the time of merging the parent of the transient node needs
    //       to get an update to it`s children attr. Currently done in save
    //       reducer.
    //       Add missing tests for action/reducer/selector!
    if (byId === undefined) {
      return Hierarchy.createHierarchy([])
    }
    const hierarchy = Hierarchy.createHierarchy(Object.values(byId))
    if (transientNodeFor !== undefined) {
      hierarchy.addTransientNodeFor(transientNodeFor)
    }
    return hierarchy
  }
)

/**
 * This selector returns a function!
 *
 * @param nodeId the id of the requested node in the hierarchy.
 * @return a node or null
 */
export const getNodeById = createSelector(getHierarchy, hierarchy => nodeId => {
  if (hierarchy === undefined) {
    return null // same return as find without result
  }
  return hierarchy.find(nodeId)
})

const getHierarchyChangedValue = state =>
  valueAt(state, ["orgChart", "hierarchyChanged"])
export const getHierarchyChanged = createSelector(
  getHierarchyChangedValue,
  changed => (changed !== undefined ? changed : false)
)

const getIncludeArchivedValue = state =>
  valueAt(state, ["orgChart", "includeArchived"])
export const getIncludeArchived = createSelector(
  getIncludeArchivedValue,
  include => !!include
)

interface DetailsState {
  types: string[]
  states: States
  timezones: string[]
  qualifications: Qualification[]
  qualificationCheckIsAvailable: boolean
}

interface States {
  [country: string]: string[]
}

interface Qualification {
  id: string
  name: string
}

interface Details extends DetailsState {
  countries: string[]
}

const getOrgunitDetails = (state): DetailsState | undefined =>
  valueAt(state, ["orgChart", "details"])
export const getOrgunitDetailsState = createSelector(
  getOrgunitDetails,
  (details?: DetailsState): Details => {
    if (details) {
      return {
        ...details,
        countries: Object.keys(details.states ?? []),
      }
    } else {
      return {
        types: [],
        states: {},
        timezones: [],
        countries: [],
        qualifications: [],
        qualificationCheckIsAvailable: false,
      }
    }
  }
)

const getFollowupOrgUnit = state =>
  valueAt(state, ["orgChart", "followupOrgUnit"])
export const getFollowupOrgUnitState = createSelector(
  getFollowupOrgUnit,
  unit => (unit !== undefined ? unit : false)
)

const getStack = state => valueAt(state, ["orgChart", "stack"])
export const getStackState = createSelector(getStack, stack =>
  stack !== undefined ? stack : []
)

export const getReadOnly = createSelector(
  state => valueAt(state, ["orgChart", "readOnly"]),
  readOnly => (readOnly !== undefined ? readOnly : true)
)

export const getChangeContextTo = createSelector(
  state => valueAt(state, ["orgChart", "changeContextTo"]),
  changeContextTo => changeContextTo
)
