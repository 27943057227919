/**
 * Module to make communication with service workers easier
 *
 * WIP, Parameters tbd
 */

export interface ServiceWorkerMessage {
  type: string
}

type UnsubscribeFromServiceWorkerMessage = () => void

interface EventMessage {
  data?: {
    type: string
  }
}

export function subscribeToServiceWorkerMessage<
  T extends ServiceWorkerMessage = any
>(
  type: string,
  callback: (data: T) => void
): UnsubscribeFromServiceWorkerMessage {
  const sub = (m: EventMessage) => {
    if (m.data !== undefined && type === m.data.type) {
      callback(m.data as T)
    }
  }
  const unsub = () =>
    navigator.serviceWorker?.removeEventListener("message", sub)
  navigator.serviceWorker?.addEventListener("message", sub)
  return unsub
}

export function postServiceWorkerMessage<T = any>(
  message: T
): Promise<void> {
  if (navigator.serviceWorker !== undefined) {
    return navigator.serviceWorker.ready
      .then(
        registration => registration?.active?.postMessage(message)
      )
  } else {
    return Promise.resolve()
  }
}