import { CoreForm } from '../../../../shared/view-model-form'

export function makeViewModel() {
  const fields = [
    { name: 'demandPlanning', type: 'list',
      label: 'settings.demandPlanning' },
    { name: 'overstaffingColor', type: 'color',
      label: 'settings.overstaffingColor' },
    { name: 'understaffingColor', type: 'color',
      label: 'settings.understaffingColor' },
    { name: 'onHolidays', type: 'boolean',
      label: 'settings.onHolidays' },
    { name: 'showPause', type: 'boolean',
      label: 'settings.showPause' },
    { name: 'pauseColor', type: 'color',
      label: 'settings.pauseColor' },
    { name: 'simulationColor', type: 'color',
      label: 'settings.simulationColor' },
    { name: 'interval', type: 'list',
      label: 'settings.interval' },
    { name: 'beginPlanner', type: 'time',
      label: 'settings.beginPlanner' },
    { name: 'endPlanner', type: 'time',
      label: 'settings.endPlanner' },
    { name: 'showSundays', type: 'boolean',
      label: 'settings.showSundays' },
    { name: 'barDisplay', type: 'list',
      label: 'settings.barDisplay' },
  ]
  return new CoreForm('plannerSettingsForm', fields)
}
