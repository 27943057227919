const messages = {
  validationError: "Validation error",
  "Couldn´t find timezone for the user":
    "A valid timezone for this user couldn`t be found.",
  "Invalid date (past).": "Date can`t be in the past.",
  "ValidUntil must be after ValidFrom.":
    "Valid until must be after valid from.",
  "Multiple coinciding Reassignments.":
    "Multiple Reassignments with the same time are not allowed.",
  "Overlapping date range with other TempAssignments.":
    "This TempAssignment overlaps with an existing TempAssignment.",
  "Overlapping date range with other UserContracts.":
    "This UserContract overlaps with an existing UserContract.",
  authenticationError: "Authentication error",
  "authentication failed": "Authentication failed.",
  authorisationError: "Authorisation error",
  "permission denied": "Permission denied",
  consistencyError: "Consistency error",
  "settings not found": "Settings couldn`t be found.",
  passwordResetError: "Password reset failed",
  "Already logged in": "You are already logged in.",
  decodeTokenError: "Decode token error",
  "invalid url parameter for decoding": "The url token ist invalid.",
  registrationError: "Registration error",
  "no candidate for token": "The given token has no open invitation.",
  usernameMissing: "Username missing",
  "Username missing": "Please enter a username.",
  userNotFound: "User not found",
  "The user could not be found.": "The user couldn`t be found.",
  incorrectPassword: "Incorrect password",
  "The given password is wrong.":
    "The given password is invalid. Please confirm your spelling.",
  integrityError: "Integrity error",
  "More than one valid TempAssignment was found!":
    "More than one valid TempAssignment for the given time was found.",
}

export default messages
