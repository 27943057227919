import { postServiceWorkerMessage } from "../../shared/serviceWorker"
import type { Settings } from "../../shared/telemetrySettings/interfaces/Settings"

const SYNCINTERVAL = 30000

/**
 * Given the overall simplicity of this feature, no backend service injection
 * or any other fancy techniques are used here. REMEMBER TO BUILD THESE IN IF
 * THIS COMPONENT BECOMES MORE COMPLEX/BLOATED!
 *
 * @returns stopTelemetrySync: () => void
 */
export default function startTelemetrySync(): () => void {
  let settings: Settings = {}
  const setSettings = (newSettings: Settings) => {
    settings = newSettings
    void postServiceWorkerMessage({ telemetry: { settings } })
  }
  const interval = setInterval(
    () => runSync(settings, setSettings),
    SYNCINTERVAL
  )
  runSync(settings, setSettings)
  return () => {
    clearInterval(interval)
  }
}

function runSync(settings: Settings, setSettings: (s: Settings) => void): void {
  void fetch(`/rest/telemetry/getSettings`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then(res => res.json())
    .then((json: { settings: Settings }) => {
      if (settingsDiffer(settings, json.settings)) {
        setSettings(json.settings)
      }
    })
}

function settingsDiffer(oldSettings: Settings, newSettings: Settings): boolean {
  const keys: (keyof Settings)[] = [
    "checkIntervalMilliseconds",
    "syncIntervalMilliseconds",
    "networkTimeoutMilliseconds",
  ]
  for (const key of keys) {
    if ((oldSettings ?? {})[key] !== (newSettings ?? {})[key]) {
      return true
    }
  }
  return false
}
