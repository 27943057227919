/* eslint-disable @typescript-eslint/no-unsafe-argument */
/**
 * REST module: Wraps and extends the fetch function
 */

// import { fetchDedupe } from 'fetch-dedupe'
import { getContext } from "./OUContext/controller"
import { parseOrgunitId } from "./parsers"

/**
 * URLs need to be constructed so as to reach the backend. This is
 * likely subject to change, and therefore centralized here
 */

// TODO: use package.json:
const RESTAPI = "/rest" // "http://localhost:3001"

/**
 * Fetch from the REST API
 *
 * @param url The url path (e.g., '/getSomething')
 * @param body The request body (will be converted to JSON)
 * @param options.fetch Override for the fetch function (for tests)
 * @param options.state If this is passed, the default context is extracted
 *        from the redux state and passed on to the server in the params
 *        (GET requests) or the body (JSON requests); please note that
 *        you need to remove the state from the options to switch
 *        this behaviour off! The fields are called 'contextOrgunitId' and
 *        'contextClientId'
 * @param options.timeout a timeout and a function with the response as an
 *        argument; executed if a timeout (in milliseconds) has been exceeded:
 *        `{
 *           milliseconds: number, handler: (response) => any,
 *           warnTimeout: number, warnTimeoutHandler: () => void
 *         }
 *        ` NOTE: The result
 *        of the fetch is the result of the timeout in such a case!
 * A couple of defaults are set unless overridden:
 * - method: 'GET'
 * - credentials: 'include'
 * - headers: { 'Content-Type': 'application/json' }
 *
 * **NOTE**: The timeouts are only triggered if the client if online at all when
 * starting the fetch. If `navigator.onLine` is `false`, no timeouts are triggered.
 * This status must be reported to the user in another way
 *
 * If `state` is passed in the options, this is used to pass a default
 * context (clientId and orgunitId) to the backend. (These fields can be
 * overridden.)
 *
 * The body is explicitly included, and automatically converted to JSON
 *
 * Note that for testing, fetch can be injected in the options!
 */
export function fetchREST(url, body = {}, options_ = {}) {
  // Currently, we're just passing everything on (proxy), without modifying the
  // URL
  const { timeout, ...options } = options_
  const opt = getOptions(body, options)
  url = RESTAPI + passDefaultContext(url, body, opt)
  const fetchFun = getFetchFun(options)
  if (opt.method.toUpperCase() === "POST") {
    opt.body = JSON.stringify(body)
  }
  const onlineBefore = navigator.onLine
  const start = new Date().getTime()
  const warnTimeout =
    onlineBefore &&
    timeout?.warnTimeout !== undefined &&
    timeout?.warnTimeoutHandler !== undefined
      ? setTimeout(() => timeout?.warnTimeoutHandler(), timeout?.warnTimeout)
      : undefined
  return fetchFun(url, opt).then(result => {
    if (warnTimeout !== undefined) {
      clearTimeout(warnTimeout)
    }
    const now = new Date().getTime()
    const runtimems = now - start
    handleErrorResult(result)
    if (
      onlineBefore &&
      start !== undefined &&
      runtimems > timeout?.milliseconds
    ) {
      result = timeout.handler(result)
    }
    return Promise.resolve(result)
  })
}

function passDefaultContext(url, body, options) {
  if (options.state) {
    // eslint-disable-next-line prefer-const
    let { clientId, orgunitId } = getContext(options.state)
    orgunitId = parseOrgunitId(orgunitId)
    delete options.state
    if (options.method.toUpperCase() === "GET") {
      url = extendURLWithContext(clientId, orgunitId, url)
    } else {
      if (
        body.contextClientId !== undefined ||
        body.contextOrgunitId !== undefined
      ) {
        throw new Error("will-not-overwrite-existing-context")
      }
      body.contextClientId = clientId
      body.contextOrgunitId = orgunitId
    }
  }
  return url
}

function extendURLWithContext(clientId, orgUnitId, url) {
  const ext =
    "contextClientId=" +
    encodeURIComponent(clientId) +
    "&contextOrgunitId=" +
    encodeURIComponent(orgUnitId)
  const op = url.includes("?") ? "&" : "?"
  return url + op + ext
}

function getOptions(body, options) {
  let opt = getDefaultOptions()
  opt = { ...opt, ...options }
  return opt
}

function getDefaultOptions() {
  return {
    credentials: "include",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json; charset=utf-8",
    },
    method: "GET",
  }
}

function getFetchFun(options) {
  let fetchFun = typeof fetch !== "undefined" ? fetch : a => a
  if (options.fetch) {
    fetchFun = options.fetch
    delete options.fetch
  }
  return fetchFun
}

function handleErrorResult(result) {
  if (result !== undefined && result.json !== undefined) {
    // XXX detached from Promise processing!
    try {
      result
        .clone()
        .json()
        .then(json => {
          if (json !== undefined && json.error) {
            // TODO: Make these more production-ready
            if (json.type === "authenticationError") {
              console.log(
                "An authentication error occurred on the backend! (This warning should be removed in later versions!)"
              )
              console.log(json)
            } else {
              console.log(
                "An error (" +
                  json.type +
                  ") occurred on the backend! (This warning should be removed in later versions!)"
              )
              console.log(json)
            }
          }
        })
        .catch(err => {
          console.log(
            "An error occurred when processing a JSON result! (This warning should be removed in later versions!)"
          )
          console.log(err)
        })
    } catch (err) {
      console.log(
        "An error occurred when processing a JSON result! (This warning should be removed in later versions!)"
      )
      console.log(err)
    }
  }
}
