import { COMMAND_PRIORITY_LOW } from "lexical"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import {
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  insertList,
  removeList,
} from "@lexical/list"

export function RegisterEditorCommandsPlugin(): null {
  const [editor] = useLexicalComposerContext()
  editor.registerCommand(
    REMOVE_LIST_COMMAND,
    () => {
      removeList(editor)
      return true
    },
    COMMAND_PRIORITY_LOW
  )
  editor.registerCommand(
    INSERT_UNORDERED_LIST_COMMAND,
    () => {
      insertList(editor, "bullet")
      return true
    },
    COMMAND_PRIORITY_LOW
  )
  return null
}
