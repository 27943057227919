import * as React from "react"
import { CLEAR_EDITOR_COMMAND } from "lexical"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"

export function ResetContentPlugin({
  json: newJSON,
  triggerReset,
}: {
  json?: string
  triggerReset?: number
  triggerEmpty?: number
}): null {
  const [editor] = useLexicalComposerContext()
  const update = React.useCallback(
    () =>
      editor.update(() => {
        if (newJSON === undefined) {
          editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined)
        } else {
          if (newJSON !== undefined) {
            const newRoot = editor.parseEditorState(newJSON)
            if (newRoot !== undefined) {
              editor.setEditorState(newRoot)
            }
          }
        }
      }),
    [editor, newJSON]
  )
  React.useEffect(update, [update])
  React.useEffect(update, [update, triggerReset, newJSON, editor])
  return null
}
