import { Button, Collapse, Divider, Form, Input, Spin } from "antd"
import * as React from "react"
import { useIntl } from "react-intl"
import makeBackendService from "./BackendService"
import type { TextAreaRef } from "antd/lib/input/TextArea"
import SupportRateWidget from "./SupportRateWidget"

export interface Props {
  showForm: boolean
  setShowForm: (flag: boolean) => void
}

export default function SupportChatForm({ setShowForm }: Props): JSX.Element {
  const intl = useIntl()
  const [loading, setLoading] = React.useState(false)
  const [feedback, setFeedback] = React.useState<{
    history: string[]
    correlationId?: string
    reply?: string
  }>({ history: [] })
  const ref = React.useRef<TextAreaRef>(null)
  const [message, setMessage] = React.useState<string>("")
  const changeMessage = React.useCallback(
    (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
      setMessage(evt.target.value as string)
    },
    [setMessage]
  )
  const submit = React.useCallback(() => {
    setLoading(true)
    void makeBackendService()
      .submitChatMessage(message)
      .then(({ reply, correlationId }) => {
        const repl =
          reply === "empty-reply"
            ? intl.formatMessage({ id: "empty-reply" })
            : reply === "failed"
            ? intl.formatMessage({ id: "failed" })
            : reply === "timeout"
            ? intl.formatMessage({ id: "timeout" })
            : reply
        setFeedback({
          history: [...feedback.history, message, repl],
          correlationId,
          reply: repl,
        })
      })
  }, [message, intl, feedback.history])
  const closeConversation = React.useCallback(() => {
    setLoading(true)
    void makeBackendService()
      .closeConversation()
      .then(({ reply }) => {
        const repl =
          reply === null
            ? intl.formatMessage({ id: "conversationClosed" })
            : reply
        setFeedback({
          history: [...feedback.history, repl],
          reply: repl,
        })
      })
  }, [intl, feedback.history])
  const cancel = React.useCallback(() => {
    setShowForm(false)
  }, [setShowForm])
  const handleKeyPress = (k: KeyboardEvent) => {
    if (k.key === "Escape") {
      window.removeEventListener("keydown", handleKeyPress)
      setShowForm(false)
    } else if ((k.metaKey || k.altKey) && k.key === "Enter") {
      submit()
    }
  }
  React.useEffect(() => setLoading(false), [feedback])
  React.useEffect(() => {
    if (
      loading === false &&
      ref.current?.resizableTextArea?.textArea !== undefined
    ) {
      ref.current.resizableTextArea!.textArea.scrollTop =
        ref.current.resizableTextArea!.textArea.scrollHeight
    }
  }, [loading])
  React.useEffect(() => {
    window.addEventListener("keydown", handleKeyPress)
    return () => window.removeEventListener("keydown", handleKeyPress)
  })
  return (
    <Spin spinning={loading}>
      <div className="submit-chat-form">
        <Form>
          <h4>{intl.formatMessage({ id: "support-h" })}</h4>
          <div className="submit-chat-form-help">
            {intl.formatMessage({ id: "chat-help" })}
            <Collapse ghost={true} size="small">
              <Collapse.Panel
                key="collapse-ai-privacy"
                header={intl.formatMessage({ id: "chat-privacy-h" })}
              >
                {intl.formatMessage({ id: "chat-privacy" })}
                <br />
                Link:{" "}
                <a target="_blank" href="https://openai.com/" rel="noreferrer">
                  OpenAI
                </a>
              </Collapse.Panel>
            </Collapse>
          </div>
          <Form.Item>
            <Input.TextArea
              ref={ref}
              readOnly={true}
              rows={12}
              disabled={
                feedback.history === undefined || feedback.history.length === 0
              }
              value={feedback.history.join("\n\n")}
            ></Input.TextArea>
          </Form.Item>
          <div className="submit-chat-form-warning">
            {intl.formatMessage({ id: "chat-warning" })}
          </div>
          <Form.Item>
            <Input.TextArea
              allowClear={true}
              rows={3}
              onChange={changeMessage}
              value={message}
              placeholder={intl.formatMessage({ id: "input-placeholder" })}
            ></Input.TextArea>
            <SupportRateWidget
              correlationId={feedback.correlationId}
            ></SupportRateWidget>
          </Form.Item>
          <Button type="primary" onClick={submit}>
            {intl.formatMessage({ id: "submit" })}
          </Button>{" "}
          <Button onClick={cancel}>
            {intl.formatMessage({ id: "cancel" })}
          </Button>
          <Divider type="vertical"></Divider>
          <Button type="primary" onClick={closeConversation}>
            {intl.formatMessage({ id: "closeConversation" })}
          </Button>{" "}
        </Form>
      </div>
    </Spin>
  )
}
