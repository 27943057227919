import React from 'react'

import { Divider } from 'antd'

import createPopupButton from '../../../../shared/ui/popupButton'

const SettingsFormWrapper = ({
  translate,
  deleteHandler,
  inheritedFrom,
  connectedForm,
  readOnly,
  children,
  hasSettings,
  canEdit
}) => {
  const { submitting, submit } = connectedForm
  const inheritedFromName = inheritedFrom()
  const availableSettings = hasSettings()
  let inheritedWarning = ''
  if (availableSettings && inheritedFromName) {
    inheritedWarning = `${translate('settings.inheritedfrom')} ${inheritedFromName}`
  }
  const buttons = [
    (
      createPopupButton({
        key: 'saveButton',
        primary: true,
        popUpContent: translate('save'),
        buttonContent: translate('save'),
        onClickHandler: submit,
        disabled: submitting
      })
    )
  ]
  if (
    availableSettings
    && !inheritedFromName
  ) {
    buttons.push(
      ( <Divider key='divider' type='vertical' /> ),
      (
        createPopupButton({
          key: 'deleteButton',
          negative: true,
          popUpContent: translate('delete'),
          buttonContent: translate('delete'),
          onClickHandler: deleteHandler,
          disabled: submitting
        })
      )
    )
  }

  return (
    <div>
      { inheritedWarning }
      { children }
      { !readOnly && canEdit && buttons }
    </div>
  )
}

export default SettingsFormWrapper
