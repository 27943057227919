import valueAt from '../valueAt'
import types from '../actions/actionTypes'

export default function reduxReactTableReducer(
  state = {},
  action
) {
  switch (action.type) {
  case types.SETBATCHSTART:
    return setForTable(state, action.name, {
      batchStart: action.batchStart
    })
  case types.SETBATCHSIZE:
    return setForTable(state, action.name, {
      batchSize: action.batchSize
    })
  case types.SETCOLUMNFILTER:
    return setColumnFilter(
      state,
      action.name,
      action.columnName,
      action.filterText
    )
  case types.SETFILTER:
    return setForTable(state, action.name, {
      filterText: action.filterText
    })
  case types.SORT:
    return setForTable(state, action.name, {
      sortColumnName: action.sortColumnName,
      sortDirection: action.sortDirection
    })
  default:
    return state
  }
}

function setColumnFilter(state, name, columnName, filterText) {
  if (name === undefined) {
    return state
  }
  const byName = valueAt(state, ['tables', 'byName'], {})
  const thistable = valueAt(byName, [name], {})
  return {
    ...state,
    tables: {
      byName: {
        ...byName,
        [name]: {
          ...thistable,
          columnFilters: {
            ...thistable.columnFilters,
            [columnName]: {filterText}
          }
        }
      }
    }
  }
}

function setForTable(state, name, obj) {
  if (name === undefined) {
    return state
  }
  const byName = valueAt(state, ['tables', 'byName'], {})
  const thistable = valueAt(byName, [name], {})
  return {
    ...state,
    tables: {
      byName: {
        ...byName,
        [name]: {
          ...thistable,
          ...obj
        }
      }
    }
  }
}
