import React, { useState } from "react"
import { Form, Input, Card, Button, Alert } from "antd"

import "./css/userManager.css"

const RegistrationForm = ({
  translations,
  onSubmitHandler,
  registrationError,
  validatePassword,
  validateEquality,
}) => {
  const [password, setPassword] = useState({})
  const [repeatPassword, setRepeatPassword] = useState({})
  const [score, setScore] = useState(0)
  const onPasswordChange = async e => {
    const pw = e.currentTarget.value
    const { validateStatus, errorMsg, score } = await validatePassword(pw)
    setPassword({
      validateStatus,
      errorMsg,
      value: pw,
    })
    setScore({ score })
  }
  const onRepeatChange = e => {
    const repeat = e.currentTarget.value
    setRepeatPassword({
      ...validateEquality(repeat, password.value),
      value: repeat,
    })
  }
  return (
    <div className="registration-form">
      <h3>{translations["pages.registration.title"]}</h3>
      <p>{translations["pages.registration.description"]}</p>
      <Form onFinish={onSubmitHandler} layout="vertical">
        {registrationError.error ? (
          <div>
            <Alert
              message={translations["registration." + registrationError.type]}
              type="error"
            />
            <br />
          </div>
        ) : undefined}
        <Form.Item
          name="password"
          label={translations["registration.password"]}
          validateStatus={password.validateStatus}
          help={password.errorMsg}
        >
          <Input.Password
            value={password.value}
            onChange={onPasswordChange}
            placeholder={translations["registration.password"]}
          />
        </Form.Item>
        <meter value={score.value} max="4"></meter>
        <Form.Item
          name="repeatPassword"
          label={translations["registration.password-repeat"]}
          validateStatus={repeatPassword.validateStatus}
          help={repeatPassword.errorMsg}
        >
          {
            <Input.Password
              value={repeatPassword.value}
              onChange={onRepeatChange}
              placeholder={translations["registration.password-repeat"]}
            />
          }
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          disabled={
            password.validateStatus !== "success" ||
            repeatPassword.validateStatus !== "success"
          }
          data-e2e-test-id="button-save-register-user"
        >
          {translations.save}
        </Button>
      </Form>
    </div>
  )
}

export default RegistrationForm
