import React, { Component } from 'react'
import { connect } from 'react-redux'

import { runAllSubscribers } from '../controllers/runAll'
import {
  getAllSubscribersState,
  getTimerIdState,
} from '../redux/selectors/actionProviderSelectors'

// TODO: This should be configurable, within limits...
// TODO: Add async characteristics?
const SUBSCRIBER_INTERVAL = 120000

const mapStateToProps = state => ({
  subscribers: getAllSubscribersState(state),
  timerId: getTimerIdState(state)
})

const mapDispatchToProps = dispatch => ({
  runAll: subscribers => runAllSubscribers(dispatch, subscribers)
})

/**
 * This class uses internal instance state for some of its functionality
 * (NOT this.state, though - just instance vars)
 * TODO: It should probably use state for subscribers as well (review!)
 */
class ActionProvider extends Component {

  componentDidMount() {
    this.setupTimers()
  }

  componentWillUnmount() {
    if (this.timerId !== undefined) {
      clearInterval(this.timerId)
    }
  }

  componentDidUpdate() {
    // TODO: honour individual (options) timers by passing a global timing
    //       parameter into runAllSubscribers
    this.setupTimers()
  }

  setupTimers() {
    const fun = () => {
      if (this.isRunning) {
        console.log("WARNING! ActionProvider subscribers are too slow!")
        console.log("         SKIPPING execution once...")
      } else {
        this.isRunning = true
        try {
          this.props.runAll(this.props.subscribers)
        } finally {
          this.isRunning = false
        }
      }
    }
    if (this.timerId !== undefined) {
      clearInterval(this.timerId)
    }
    this.timerId = setInterval(fun, SUBSCRIBER_INTERVAL)
    fun()
  }

  render() {
    return (
      <div
        className="actionProviderWrapper"
        style={{
          padding: 0, margin: 0,
          width: 0, height: 0
        }}
      >
        { this.props.children }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  ActionProvider
)
