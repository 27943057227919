/**
 * Shared util to manualy translate the antd DatePicker.
 *
 * For another approach with the antd ConfigProvider see:
 * import { getAntdLocale } from '../../../../../shared/antdLocales'
 */

import type { PickerLocale } from 'antd/lib/date-picker/generatePicker'
import locales from './locales/antdDatePickerLocales'

export default (locale: string = 'de'): PickerLocale => {
  const loc = locale.substring(0, 2)
  const res = locales[loc]
  return res
}