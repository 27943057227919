import types from "./actionTypes"

export default function licenseStatsReducer(state = {}, action) {
  switch (action.type) {
    case types.LOADLICENSESTATS:
      return {
        ...state,
        licenseStats: action.stats,
      }
    default:
      return state
  }
}
