import * as React from "react"

export default function useMouseDown(): boolean {
  const [isMouseDown, setMouseDown] = React.useState<boolean>(false)
  React.useEffect(() => {
    const handleMouseUp = () => {
      document.removeEventListener("mouseup", handleMouseUp)
      setMouseDown(false)
    }
    const handleMouseDown = () => {
      document.removeEventListener("mouseup", handleMouseUp)
      document.addEventListener("mouseup", handleMouseUp)
      setMouseDown(true)
    }
    document.addEventListener("mousedown", handleMouseDown)
    return () => {
      document.removeEventListener("mousedown", handleMouseDown)
      document.removeEventListener("mouseup", handleMouseUp)
    }
  }, [])
  return isMouseDown
}
