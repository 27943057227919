const types = {
  SAVECLIENTROLE: 'SAVE_CLIENT_ROLE_',
  DELETECLIENTROLE: 'DELETE_CLIENT_ROLE_',
  OPENEDITROLEDIALOG: 'OPEN_EDIT_ROLE_DIALOG_',
  CLOSEEDITROLEDIALOG: 'CLOSE_EDIT_ROLE_DIALOG_',
  LOADCLIENTROLES: 'LOAD_CLIENT_ROLES_',
  LOADCLIENTROLESFAILED: 'LOAD_CLIENT_ROLES_FAILED_',
  LOADDEFAULTROLES: 'LOAD_DEFAULT_ROLES_'
}

export default types
