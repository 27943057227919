/**
 * Controller to handle permission checks in the frontend.
 *
 * Checks for a single permission or a list of permissions
 * on the current context and the parent are available.
 *
 * This mirrors the server-side permission checks but of course should
 * **never** be used as a replacement for them.
 *
 * Modules should import the needed Permission from ../permissions and
 * then use hasPermission with their current state.
 */

import { ALLPERMISSIONS } from "../permissions"

import {
  getPermissionsState,
  getParentPermissionsState,
  getClientPermissionsState,
} from "./lib/redux/permissionCheckSelectors"
import { getUserAdminFlags } from "../../modules/login/controller"

// Export for testing
export const adminBypass = (
  permission,
  state,
  injected = { getUserAdminFlags }
) => {
  const flags = injected.getUserAdminFlags(state)
  // A pure admin and a restricted admin get a full pass here (for now)
  if (flags.isAdmin === true) {
    return true
  }
  /*
  // In the future we might need to do different checks for each role
  if (flags.isAdmin === true && flags.isRestrictedAdmin === false) {
    // A pure admin bypasses all checks!
    return true
  } else if (flags.isRestrictedAdmin === true) {
    // A restricted admin bypasses everything except edits on clients
    // FUTURE: This might depend on the specific restrictions.
    const restrictedPermissions = ["TODO"]
    if (restrictedPermissions.includes(permission)) {
      return false
    } else {
      return true
    }
  } else if (flags.isEnhanced === true) {
    // An enhanced user has a handfull of implicit permissions
    // FUTURE: This might depend on the specific flags.
    const implicitPermissions = [ALLPERMISSIONS.VIEWCLIENTMASTERDATA, "TODO"]
    if (implicitPermissions.includes(permission)) {
      return true
    } else {
      return false
    }
  }
  */
  return false
}

/**
 * Checks if the current user has the 'permission' in the
 * current context. This respects permission inherintance.
 */
export const hasPermission = (permission, state) => {
  if (adminBypass(permission, state)) {
    return true
  }
  if (permission === undefined) {
    return false
  }
  validatePermission(permission)
  const permissionsState = getPermissionsState(state)
  return permissionsState.includes(permission)
}

/**
 * Checks if the current user has the 'permission' in the
 * current client.
 */
export const hasPermissionAnywhere = (permission, state) => {
  if (adminBypass(permission, state)) {
    return true
  }
  if (permission === undefined) {
    return false
  }
  validatePermission(permission)
  const permissionsState = getClientPermissionsState(state)
  return permissionsState.includes(permission)
}

export const hasAllPermissions = (permissions, state) => {
  const checks = permissions.map(permission => hasPermission(permission, state))
  return !checks.includes(false)
}

/**
 * Check if user has at least on of the `permissions`.
 * Useful if view and edit both grant basic access to a certain element.
 */
export const hasOneOfPermissions = (permissions, state) => {
  const checks = permissions.map(permission => hasPermission(permission, state))
  return checks.includes(true)
}

export const hasOneOfPermissionsAnywhere = (permissions, state) => {
  const checks = permissions.map(permission =>
    hasPermissionAnywhere(permission, state)
  )
  return checks.includes(true)
}

export const hasPermissionOnParent = (permission, state) => {
  if (adminBypass(permission, state)) {
    return true
  }
  validatePermission(permission)
  const permissionsState = getParentPermissionsState(state)
  return permissionsState.includes(permission)
}

export const hasAllPermissionsOnParent = (permissions, state) => {
  const checks = permissions.map(permission =>
    hasPermissionOnParent(permission, state)
  )
  return !checks.includes(false)
}

const validatePermission = permission => {
  if (!Object.values(ALLPERMISSIONS).includes(permission)) {
    throw new Error({
      error: true,
      name: "invalid-permission",
      message: "Type or name of permission is invalid",
    })
  }
}
