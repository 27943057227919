/**
 * Save the information from a form (post-transition); includes
 * validation functionality
 *
 * Primary functionality is the default export `saveForm`
 */

import type { IAvailableTransitionType } from "../../commonInterfaces/processes/IAvailableTransitionType"
import type { IFormField } from "../../commonInterfaces/processes/RawFormInfo"
import t from "./translate"

/**
 * This is some straightforward context info for the workflow
 * instance loaded
 */
export interface WorkflowInstanceInfo {
  id?: string
  processType: string
  departmentId: string
  clientId: string
  selectedTransition?: IAvailableTransitionType
}

/**
 * Get all form fields, and map to values and field definitions.
 *
 * This is quite flexible, for example if your container types
 * contain both form field definitions and values, it's sensible
 * to have T=Id (e.g., `FieldMapper<YourContainerT, YourContainerT>`).
 */
export interface FieldMapper<T, Id> {
  getId(field: T): Id
  getAllFields(): T[]
  getValue(fieldId: Id): any
  getFormInfo(fieldId: Id, field: T): IFormField
}

export interface UIInteraction {

  /**
   * How to publish validation errors and other warnings:
   *
   * (Note that if you want to just use `alert`, you'll have
   * to bind it to `window`!)
   */
  alert: (msg: string) => void

  locale: string

  /**
   * Invoked on successfully saving the form:
   */
  success: () => void
}

export default function saveForm<Field = any, FieldId = any>(
  workflowInstanceInfo: WorkflowInstanceInfo,
  fieldMapper: FieldMapper<Field, FieldId>,
  uiInteraction: UIInteraction
): Promise<void> {
  const pfs = new ProcessFormSaver(workflowInstanceInfo, fieldMapper, uiInteraction)
  return pfs.save()
}

export class ProcessFormSaver<Field = any, FieldId = any> {
  constructor(
    private wf: WorkflowInstanceInfo,
    private fields: FieldMapper<Field, FieldId>,
    private ui: UIInteraction
  ) { }

  public async save(): Promise<void> {
    let saveFormFields
    try {
      saveFormFields = this.makeSaveForm()
    } catch (e) {
      if (e instanceof ValidationError) {
        this.ui.alert(e.message)
        return
      }
    }
    const body: { [k: string]: any } = {
      formInfo: {
        workflowType: this.wf.processType,
        orgunitId: this.wf.departmentId,
        contextOrgunitId: this.wf.departmentId,
        contextClientId: this.wf.clientId,
        locale: this.ui.locale.substr(0, 2),
        ...saveFormFields,
      },
    }
    let url = "/rest/workflows/newWorkflowInstance"
    if (this.wf.id) {
      body.formInfo.transition = this.wf.selectedTransition
      body.formInfo.workflowInstanceId = this.wf.id
      url = "/rest/workflows/newWorkflowTransaction"
    }
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
    const json = await res.json()
    if (json.statusCode !== "ok") {
      alert(
        `${t(this.ui.locale, "vacareq-error-occurred")}: ${json.description}`
      )
    } else {
      return this.ui.success()
    }
  }

  private makeSaveForm() {
    const res: { [k: string]: any } = {}
    for (const f of this.fields.getAllFields()) {
      const id = this.fields.getId(f)
      const v = this.fields.getValue(id)
      const formField = this.fields.getFormInfo(id, f)
      const isValid = this.validate(formField, v)
      if (!isValid) {
        const message = `${formField.label
          } ${t(this.ui.locale, "isRequired")}`
        throw new ValidationError(message)
      }
      switch (formField.formelement) {
        case "daterange":
          res[formField.name] = v
          break
        case "textarea":
          res[formField.name] = v ?? ''
          break
        default:
          res[formField.name] = v
      }
    }
    return res
  }

  private validate(formfield: IFormField, v: any) {
    return !formfield.required || (v !== undefined && v !== null && v !== "")
  }
}

class ValidationError extends Error { }