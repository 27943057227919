/**
 * Selector model for login information
 * XXX TODO: Not yet attached to any normalized user!
 * XXX Doesn't use advanced selectors (due to its flat nature)
 */
import { createSelector } from "reselect"
import { valueAt } from "../../../../shared/walkObjectHierarchy"

// Note: This is just the pure attribute/flag. For the business
// logic you might still need to consider if the user is a restricted
// admin or not!
const getUserIsAdmin = state => valueAt(state, ["login", "user", "isAdmin"])
export const getUserIsAdminState = createSelector(
  getUserIsAdmin,
  isAdmin => !!isAdmin
)

const getUserIsRestrictedAdmin = state =>
  valueAt(state, ["login", "user", "isRestrictedAdmin"])
export const getUserIsRestrictedAdminState = createSelector(
  getUserIsRestrictedAdmin,
  isRestrictedAdmin => !!isRestrictedAdmin
)

const getUserCanManageAdmins = state =>
  valueAt(state, ["login", "user", "canManageAdmins"])
export const getUserCanManageAdminsState = createSelector(
  getUserCanManageAdmins,
  canManageAdmins => !!canManageAdmins
)

const getUserCanEditClients = state =>
  valueAt(state, ["login", "user", "canEditClients"])
export const getUserCanEditClientsState = createSelector(
  getUserCanEditClients,
  canEditClients => !!canEditClients
)

const getUserIsEnhanced = state =>
  valueAt(state, ["login", "user", "isEnhancedUser"])
export const getUserIsEnhancedState = createSelector(
  getUserIsEnhanced,
  isEnhanced => !!isEnhanced
)

const getUsername = state => valueAt(state, ["login", "user", "username"])
export const getUsernameState = createSelector(
  getUsername,
  uname => uname || ""
)

const getUserId = state => valueAt(state, ["login", "user", "id"])
export const getUserIdState = createSelector(getUserId, uid => uid || "")

export const getUserClientAndOrgunitId = createSelector(
  state => valueAt(state, ["login", "user", "clientId"]),
  state => valueAt(state, ["login", "user", "orgunitId"]),
  (clientId, orgunitId) => ({ clientId, orgunitId })
)

export const getUserClientId = createSelector(
  state => valueAt(state, ["login", "user", "clientId"]),
  clientId => clientId
)

export const getUserOrgunitId = createSelector(
  state => valueAt(state, ["login", "user", "orgunitId"]),
  orgunitId => orgunitId
)

const getIsNetworkVerySlow = state => valueAt(state, ["login", "networkIsSlow"])
export const getIsNetworkVerySlowState = createSelector(
  getIsNetworkVerySlow,
  isVS => isVS
)

/**
 * Is logged in state can have three valid values:
 * - undefined (no information available as yet)
 * - true (is logged in query returned true)
 * - false (is logged in query returned false)
 */
const getIsLoggedIn = state => valueAt(state, ["login", "isLoggedIn"])
export const getIsLoggedInState = createSelector(
  getIsLoggedIn,
  isLoggedIn => isLoggedIn // three values: undefined, false, true
)

const getOpenLoginDialog = state => valueAt(state, ["login", "openDialog"])
export const getOpenLoginDialogState = createSelector(
  getOpenLoginDialog,
  o => !!o
)

const getOpenPasswordResetDialog = state =>
  valueAt(state, ["login", "openPasswordResetDialog"])
export const getOpenPasswordResetDialogState = createSelector(
  getOpenPasswordResetDialog,
  o => !!o
)

const getInitialResetUsername = state =>
  valueAt(state, ["login", "initialResetUsername"])
export const getInitialResetUsernameState = createSelector(
  getInitialResetUsername,
  uname => uname || ""
)

const getPasswordResetError = state =>
  valueAt(state, ["login", "passwordResetError"])
export const getPasswordResetErrorState = createSelector(
  getPasswordResetError,
  err => (err !== undefined ? err : {})
)

const getLoginError = state => valueAt(state, ["login", "loginError"])
export const getLoginErrorState = createSelector(getLoginError, err =>
  err !== undefined ? err : {}
)
