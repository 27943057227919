import types from "../actions/actionTypes"
import type JsonUser from "../JsonUser"

export default function userDataViewReducer(state = {}, action: Action) {
  switch (action.type) {
    case types.LOADUSERDATAVIEW:
      return {
        ...state,
        user: action.user,
      }
    default:
      return state
  }
}

interface Action {
  type: string
  user?: JsonUser
}
