// TODO: Handle translations more locally (currently needs translations
// to be imported in caller!)

import { Button } from "antd"
import * as React from "react"
import { useIntl } from "react-intl"
import type { IAvailableTransitionType } from "../../commonInterfaces/processes/IAvailableTransitionType"
import type { IWorkflowFormInfo } from "../../commonInterfaces/processes/RawFormInfo"
import {
  GENERALREQUEST,
  OVERTIMEREQUEST,
  VACATIONREQUEST,
} from "../../commonConfiguration/processes/processTypes"

interface Props {
  availableTransitions: IAvailableTransitionType[]
  id?: string
  onCancel: () => void
  afterLoadTransition: (
    t?: IAvailableTransitionType,
    formInfo?: IWorkflowFormInfo
  ) => void
  processType?: string
  setProcessType: (t: string) => void
  departmentId: string
  clientId: string
  classPrefix: string
}

export default function ProcessFormTransitionButtons(
  props: Props
): JSX.Element {
  const intl = useIntl()
  // const [processType, setProcessType] = React.useState<string | undefined>(
  //   props.processType
  // )
  // React.useEffect(() => {
  //   if (props.processType) {
  //     setProcessType(props.processType)
  //   }
  // }, [props.processType])
  // const setVacation = React.useCallback(
  //   () => setProcessType("employee-request-for-leave"),
  //   []
  // )
  // const setOvertime = React.useCallback(
  //   () => setProcessType("employee-request-to-reduce-overtime"),
  //   []
  // )
  const loadTransition = React.useCallback(
    async (processType: string, t?: IAvailableTransitionType) => {
      /**
       * FormInfo is required for rendering the form
       *
       * This is either retrieved directly for a *new* process
       * (no ID, no button pressed), **or** it is retrieved
       * following a button press selecting *one* of the
       * available transactions.
       */
      let url = "/rest/workflows/getWorkflowTransactionFormInfo"
      let body: { [k: string]: any } = {
        workflowType: processType,
        orgunitId: props.departmentId,
        contextOrgunitId: props.departmentId,
        contextClientId: props.clientId,
        locale: intl.locale,
      }
      if (props.id !== undefined) {
        body.workflowInstanceId = props.id
        body = {
          ...body,
          ...t,
        }
      } else {
        url = "/rest/workflows/getNewWorkflowInstanceFormInfo"
      }
      const res = await fetch(url + "?" + encodeURIComponentsForActions(body), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      const forminfo: IWorkflowFormInfo = await res.json()
      if (
        forminfo.feedback !== undefined &&
        forminfo.feedback?.statusCode !== "ok"
      ) {
        alert(
          `${intl.formatMessage({
            id: "vacareq-unexpected-error-occurred",
          })}: ${forminfo.feedback?.description ?? "-"}`
        )
      } else {
        props.setProcessType(processType)
        props.afterLoadTransition(t, forminfo)
      }
    },
    [intl, props]
  )

  return (
    <div className={`${props.classPrefix}process-new-transition`}>
      {props.id && props.processType ? (
        <>
          {props.availableTransitions.map((t, idx) => {
            // TODO: This should be memoized:
            const go = () => void loadTransition(props.processType!, t)
            const label = t.label
            const Label =
              label && label.length > 0
                ? `${label[0].toUpperCase()}${label.substr(1)}`
                : ""
            return (
              <Button
                className={`mobile-button-${t.visualisationtype}`}
                key={idx}
                onClick={go}
              >
                {Label}
              </Button>
            )
          })}
          <CancelButton
            classPrefix={props.classPrefix}
            onCancel={props.onCancel}
          ></CancelButton>
        </>
      ) : (
        <div className={`${props.classPrefix}process-form-start`}>
          <Button
            type="default"
            shape="round"
            onClick={() => void loadTransition(VACATIONREQUEST)}
          >
            {intl.formatMessage({ id: "MakeVacationRequest" })}
          </Button>
          <Button
            type="default"
            shape="round"
            onClick={() => void loadTransition(OVERTIMEREQUEST)}
          >
            {intl.formatMessage({ id: "MakeOvertimeRequest" })}
          </Button>
          <Button
            type="default"
            shape="round"
            onClick={() => void loadTransition(GENERALREQUEST)}
          >
            {intl.formatMessage({ id: "MakeGeneralRequest" })}
          </Button>
        </div>
      )}
    </div>
  )
}

function CancelButton(props: { classPrefix: string; onCancel: () => void }) {
  const intl = useIntl()
  return (
    <Button
      className={`${props.classPrefix}process-form-cancel`}
      onClick={props.onCancel}
    >
      {intl.formatMessage({ id: "vacareq-cancel-all" })}
    </Button>
  )
}

function encodeURIComponentsForActions(
  props: Record<string, string | number | boolean>
): string {
  const fixURIComponent = (v: string | number | boolean) =>
    encodeURIComponent(v === undefined ? "" : v)
  return Object.getOwnPropertyNames(props)
    .reduce((acc: string[], key) => {
      acc.push(`${key}=${fixURIComponent(props[key])}`)
      return acc
    }, [])
    .join("&")
}
