import type { AnyAction } from "redux"
import types from "../actions/actionTypes"

// TODO: What is the return state typing?
export default function (state = {}, action: AnyAction): any {
  switch (action.type) {
    case types.LOADUAREPORTID:
      return {
        ...state,
        reportId: action.id,
      }
    case types.LOADUNAVAILABILITIES:
      return {
        ...state,
        unavailabilities: action.unavailabilities,
      }
    default:
      return state
  }
}
