/**
 * Utilities for dealing with state slices in sensible ways
 */

interface AnyObject {
  [key: string]: any
}

/**
 * Utility for safely unwinding an object hierarchy. This can be used in
 * selectors, which often have to walk down into the depths of a nested
 * object with no clue as to which intermediary key might fail
 *
 * @param object The nested object
 * @param path An Array with the keys in descending order
 * @param defaultValueDeprecated Default to return if traversal fails
 */
export function valueAt(object: AnyObject, path: string[], defaultValueDeprecated?: any) {
  let defaultValue: any
  if (defaultValueDeprecated !== undefined) {
    console.log('***************************************************************************')
    console.log('DEPRECATION WARNING: It is a sign of an error in your')
    console.log('                     selectors if valueAt is supplied')
    console.log('                     a default value!')
    console.log('')
    console.log('                     path =', path)
    console.log('                     defaultValue =', defaultValueDeprecated)
    console.log('***************************************************************************')
    defaultValue = defaultValueDeprecated
  }
  path.forEach(key => {
    if (object === undefined) { // TODO: Remove
      return defaultValue
    }
    object = object[key]
  })
  return object === undefined ? defaultValue : object // TODO: simplify
}

/**
 * Compare object hierarchies reliably; mostly used for normalized state
 * slices (state.mySlice.myType.byId)
 *
 * Note that referential equality leads to positive result as well!
 *
 * Note: This may not work for all cases! It is intended for the above
 *       mentioned byId and similar cases where hierarchies can be
 *       traversed recursively in the browser (i.e. they aren't too deeply
 *       nested for the available stack size); it can be used (with caution)
 *       for simple(r) shouldComponentUpdate cases
 *
 * This will probably not work with functions or classes
 *
 * @param a An object that might be similar to b
 * @param b An object that might be similar to a
 *
 * A note about the code: This is slightly optimised to avoid redundancies
 * (order of the code, mixed checks for referential and primitive equality)
 */
export function isEqual(a?: AnyObject, b?: AnyObject): boolean {
  if (a === undefined) {
    return b === undefined
  } else if (a === null) {
    return b === null
  } else if (a === b) {
    return true
  } else if (typeof a === 'boolean' || typeof b === 'boolean') {
    return false
  } else if (typeof a !== 'object' || typeof b !== 'object') {
    /*
    Can't use Object(a) === a because of numbers
    valueOf might be useful (`(1).valueOf() === 1 -> true`)
    */
    return false
  } else {
    return objectIsEqual(a, b)
  }
}

function objectIsEqual(a: AnyObject, b: AnyObject): boolean {
  const akeys = Object.keys(a)
  const bkeys = Object.keys(b)
  // XXX Not tail recursive (wouldn't help much in current browsers anyway):
  return (akeys.length === bkeys.length)
    && akeys.every(k => bkeys.includes(k) && isEqual(a[k], b[k]))
}
