import types from '../actions/actionTypes'

export default function confirmDialogReducer(
  state = {},
  action
) {
  switch (action.type) {
  case types.OPENCONFIRMPORTAL:
    return {
      ...state,
      openConfirmPortal: true
    }
  case types.CLOSECONFIRMPORTAL:
    return {
      ...state,
      openConfirmPortal: false
    }
  default:
    return state
  }
}
