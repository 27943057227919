import * as React from "react"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { LinkNode } from "@lexical/link"

export function RegisterEditorNodeTransformPlugin(): null {
  const [editor] = useLexicalComposerContext()
  React.useEffect(() => {
    const removeLinkTransformListener = editor.registerNodeTransform<LinkNode>(
      LinkNode,
      n => {
        if (
          n !== null &&
          n !== undefined &&
          n.getTarget() !== "_blank" // precondition
        ) {
          n.setTarget("_blank")
          n.setRel("noreferrer")
        }
      }
    )
    return () => removeLinkTransformListener()
  }, [editor])
  return null
}
