const messages = {
  "userReports.title": "Work hours repot",
  "userReports.generateReport": "Generate month report",
  "userReports.generateXLSXReport": "Generate month report (XLSX)",
  "userReports.type": "Type",
  "userReports.from": "From",
  "userReports.to": "To",
  "userReports.Date": "Date",
  "userReports.Week": "Week",
  "userReports.Month": "Month",
  "userReports.Year": "Year",
}

export default messages
