import { DEFAULTTELEMETRYSYNCINTERVALMS } from "../apps/shared/telemetrySettings/constants"
import { fetchREST } from "./rest"

const toEmail = "support@mep24software.de"

const GETSETTINGSINTERVALMS = 5 * 60000 // TODO: INCREASE
const CHECKINTERVALMS = 2000
const MAXSYNCEDLINES = 3000

const PROBLEMSTORAGEKEY = "problems_with_network_"

// TODO: Regularly sync settings FROM backend (telemetry/getSettings, no arguments)

export default class ProblemReportUtil {
  private settings: Settings = {
    checkIntervalMilliseconds: CHECKINTERVALMS,
    syncIntervalMilliseconds: DEFAULTTELEMETRYSYNCINTERVALMS,
  }

  private checkProblemsTimeout: number
  private syncTimeout: number
  private getSettingsTimeout: number
  private problems?: string[]

  constructor(
    private username: string,
    private clientId: string,
    private problemsChangedCallback: () => void
  ) {
    this.getSettings = this.getSettings.bind(this)
    this.checkProblems = this.checkProblems.bind(this)
    this.syncProblems = this.syncProblems.bind(this)
    this.clearProblems = this.clearProblems.bind(this)
    this.sendProblems = this.sendProblems.bind(this)
    this.downloadProblems = this.downloadProblems.bind(this)
  }

  start(): void {
    this.getSettingsTimeout = window.setInterval(
      this.getSettings,
      GETSETTINGSINTERVALMS
    )
    this.getSettings()
    this.resetCheckProblemsTimeout()
    this.resetSyncTimeout()
  }

  stop(): void {
    if (this.getSettingsTimeout) {
      clearTimeout(this.getSettingsTimeout)
    }
    this.stopCheckProblemsTimeout()
    this.stopSyncTimeout()
  }

  getProblems(): string[] {
    return this.problems
  }

  sendProblems(): void {
    const ln = `mailto:${toEmail}?subject=Problembericht%20mina.works&body=${encodeURIComponent(
      this.getBodyText()
    )}`
    window.open(ln)
  }

  downloadProblems(): void {
    const w = window.open()
    w.document.body.innerHTML = `<pre>${this.getBodyText()}</pre>`
  }

  /**
   * Sync problem report with telemetry backend
   */
  syncProblems(): void {
    if ((this.problems?.length ?? 0) > 0) {
      // TODO: persistent synched state after successful fetch!
      const problemreport: string[] = this.problems.slice(0, MAXSYNCEDLINES)
      console.log("sending problem report to backend...", new Date())
      fetchREST(
        "/telemetry/pushClientProblems",
        { problemreport, clientId: this.clientId },
        {
          method: "POST",
        }
      )
        .then(res => res.json())
        .then(({ success }) => {
          if (success) {
            this.clearProblems()
          } else {
            console.log(
              "Unsuccessful problem report sync, retaining problems in storage"
            )
          }
        })
    }
  }

  /**
   * Sync settings with telemetry backend
   */
  getSettings(): void {
    void fetchREST("/telemetry/getSettings")
      .then(res => res.json())
      .then((settings: Settings) => {
        if (
          settings.checkIntervalMilliseconds !== undefined &&
          settings.checkIntervalMilliseconds !== this.checkProblemsTimeout
        ) {
          this.checkProblemsTimeout =
            settings.syncIntervalMilliseconds ?? CHECKINTERVALMS
          this.resetCheckProblemsTimeout()
        }
        if (
          settings.syncIntervalMilliseconds !== undefined &&
          settings.syncIntervalMilliseconds !== this.syncTimeout
        ) {
          this.syncTimeout =
            settings.syncIntervalMilliseconds ?? DEFAULTTELEMETRYSYNCINTERVALMS
          this.resetSyncTimeout()
        }
        // TODO: Persist other settings to storage/send message/whatever
      })
  }

  checkProblems(): void {
    const problemsJSON = window.localStorage.getItem(PROBLEMSTORAGEKEY)
    const problems = problemsJSON ? JSON.parse(problemsJSON) : undefined
    if (problemsJSON !== JSON.stringify(this.problems)) {
      this.problems = problems
      this.problemsChangedCallback()
    }
  }

  clearProblems(): void {
    this.problems = []
    window.localStorage.removeItem(PROBLEMSTORAGEKEY)
    this.problemsChangedCallback()
  }

  private resetSyncTimeout() {
    this.stopSyncTimeout()
    this.syncTimeout = window.setInterval(
      this.syncProblems,
      this.settings.syncIntervalMilliseconds
    )
    this.syncProblems()
  }

  private resetCheckProblemsTimeout() {
    this.stopCheckProblemsTimeout()
    this.checkProblemsTimeout = window.setInterval(
      this.checkProblems,
      this.settings.checkIntervalMilliseconds
    )
    this.checkProblems()
  }

  private stopSyncTimeout(): void {
    if (this.syncTimeout) {
      clearTimeout(this.syncTimeout)
    }
  }

  private stopCheckProblemsTimeout(): void {
    if (this.checkProblemsTimeout) {
      clearTimeout(this.checkProblemsTimeout)
    }
  }

  private getBodyText() {
    const body = `Problembericht für ${this.username}

${this.problems.join("\n")}
`
    return body
  }
}

export interface Settings {
  checkIntervalMilliseconds?: number
  syncIntervalMilliseconds?: number
  // networkTimeoutMilliseconds?: number
}
