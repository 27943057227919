import c from './constants'
import type SimpleDateTimeObject from './SimpleDateTimeObject'

export function getLowercaseEnglishWeekdayName(date: Date): string {
  // We index from Monday, but JS Date indexes start at Sunday...
  const dateDayIdx = date.getDay()
  const dayIdxFromMon = dateDayIdx === 0 ? 6 : dateDayIdx - 1
  const idx = dayIdxFromMon === 7 ? 0 : dayIdxFromMon
  return c.WEEKDAYS[idx]
}

export function pad00(s: number | string) {
  s = '' + s
  if ( s.length === 1 ) {
    return '0' + s
  } else if ( s.length === 0 ) {
    return '00'
  } else {
    return s
  }
}

/**
 * Add days to date (destructively, inline)
 *
 * @param jsDate a JS Date
 * @param numberOfDays The number of days to add
 *
 * @return the same JS Date with days added
 */
export function addDaysToDate(jsDate: Date, numberOfDays = 1) {
  jsDate.setDate(jsDate.getDate() + numberOfDays )
  return jsDate
}

/**
 * Calculate the number of days that offsetDayName is offset from baseDayName.
 *
 * Note that we *only move to the right*!
 */
export function getPositiveWeekdayOffset(
  baseDayName: string, offsetDayName: string
) {
  const baseIdx = c.WEEKDAYS.indexOf(baseDayName)
  let offsetIdx = c.WEEKDAYS.indexOf(offsetDayName)
  if ( offsetIdx < baseIdx ) {
    offsetIdx += c.WEEKDAYS.length
  }
  return offsetIdx - baseIdx
}

/**
 * Normalize JS Date to a simple mapping object; note that the month is
 * converted into a "real-world" 1-based month!
 */
export function dateAsObject(jsDate: Date): SimpleDateTimeObject {
  return {
    year: jsDate.getFullYear(),
    month: jsDate.getMonth() + 1,
    day: jsDate.getDate(),
    hour: jsDate.getHours(),
    minute: jsDate.getMinutes(),
    second: jsDate.getSeconds()
  }
}

export function objectToJSDate(o: SimpleDateTimeObject) {
  return new Date(
    o.year,
    o.month - 1,
    o.day,
    o.hour,
    o.minute,
    o.second
  )
}