export async function get<T>(url: string, params?: Record<string, string>): Promise<T> {
  const args = params === undefined ? '' : `?${new URLSearchParams(params).toString()}`
  const res = await fetch(`${url}${args}`,
    {
      credentials: "include",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json; charset=utf-8",
      },
      method: "GET",
    }
  )
  return res.json()
}

export async function post<
  T,
  Body extends Record<string, any> | undefined = any
>(
  url: string,
  body: Body,
  params?: Record<string, string>
): Promise<T> {
  const args = params === undefined ? '' : `?${new URLSearchParams(params).toString()}`
  const res = await fetch(`${url}${args}`,
    {
      credentials: "include",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json; charset=utf-8",
      },
      body: body !== undefined ? JSON.stringify({ ...body, async: false }) : undefined,
      method: "POST",
    }
  )
  return res.json()
}

export async function postWithAsyncDefaults<
  T,
  Body extends Record<string, any> | undefined
>(
  url: string,
  body: Body,
  params?: Record<string, string>
): Promise<T> {
  const args = params === undefined ? '' : `?${new URLSearchParams(params).toString()}`
  const res = await fetch(`${url}${args}`,
    {
      credentials: "include",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json; charset=utf-8",
      },
      body: body !== undefined ? JSON.stringify(body) : undefined,
      method: "POST",
    }
  )
  return res.json()
}