import { CoreForm } from '../../../../shared/view-model-form'

export function makeViewModel() {
  const fields = [
    { name: 'mustTrackTime', type: 'boolean',
      label: 'settings.mustTrackTime' },
    { name: 'inheritDepartment', type: 'boolean',
      label: 'settings.inheritDepartment' },
    { name: 'inheritTask', type: 'boolean',
      label: 'settings.inheritTask' },
    { name: 'inheritPauses', type: 'boolean',
      label: 'settings.inheritPauses' },
    { name: 'inheritTime', type: 'list',
      label: 'settings.inheritTime' },
    { name: 'arrivalEarly', type: 'number',
      label: 'settings.arrivalEarly' },
    { name: 'arrivalLate', type: 'number',
      label: 'settings.arrivalLate' },
    { name: 'departureEarly', type: 'number',
      label: 'settings.departureEarly' },
    { name: 'departureLate', type: 'number',
      label: 'settings.departureLate' }
  ]
  return new CoreForm('timetrackingSettingsForm', fields)
}
