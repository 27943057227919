import types from '../actions/actionTypes'

export default function languagePickerReducer(
  state = {},
  action
) {
  switch (action.type) {
  case types.CHANGELANGUAGE:
    return {
      ...state,
      language: action.language
    }
  default:
    return state
  }
}
