import * as React from "react"
import Time from "./support/Time"
import FieldWithHelp from "./support/FieldWithHelp"

import { pad00 } from "../../legacy/time-utils"

const TimeUI = ({ input: { value, onChange }, ...props }) => {
  return (
    <FieldWithHelp {...props}>
      <Time
        {...props}
        unlimitedHours={true}
        value={{
          h: value[0] !== undefined ? parseInt(value[0], 10) : 0,
          m: value[1] !== undefined ? parseInt(value[1], 10) : 0,
        }}
        error={props.meta.error ? props.meta.error : undefined}
        onChange={(e, { h, m }) => onChange([h, m])}
      />
    </FieldWithHelp>
  )
}

function ReadOnlyField({ input: { value }, label, dataE2E }) {
  return (
    <div className="field detailsConnectedFormField">
      <label>{label}</label>
      <div
        className="detailsConnectedFormFieldValue detailsConnectedFormTimeField"
        data-e2e-test-id={dataE2E}
      >
        {value[0] !== undefined ? pad00(value[0]) : "00"}:
        {value[1] !== undefined ? pad00(value[1]) : "00"}
      </div>
    </div>
  )
}

export default readOnly => (!readOnly ? TimeUI : ReadOnlyField)
