import { combineReducers } from "redux"

import candidateReducer from "./candidateReducer"
import userEditReducer from "./userEditReducer"
import userListingReducer from "./userListingReducer"
import userDataViewReducer from "./userDataViewReducer"

import userMasterDataReducer from "../../userMasterData/redux/userMasterDataReducer"
import reassignmentReducer from "../../userReassignment/redux/reassignmentReducer"
import userRoleAssignmentReducer from "../../userRoleAssignment/redux/userRoleAssignmentReducer"
import userQualificationReducer from "../../userQualification/redux/userQualificationReducer"
import workHoursAccountWithCacheReducer from "../../userWorkHoursAccountWithCache/redux/workHoursAccountReducer"
import broadcastUserChange from "../../broadcastUserChange/reducer"
import usernameEditReducer from "../../usernameEditForm/redux/usernameEditReducer"

export default combineReducers({
  candidate: candidateReducer,
  reassignment: reassignmentReducer,
  workHoursAccountCached: workHoursAccountWithCacheReducer,
  userEdit: userEditReducer,
  userListing: userListingReducer,
  userMasterData: userMasterDataReducer,
  userRolesAssignment: userRoleAssignmentReducer,
  userQualification: userQualificationReducer,
  userDataView: userDataViewReducer,
  broadcastUserChange,
  usernameEdit: usernameEditReducer,
})
