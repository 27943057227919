import types from "../actions/actionTypes"

export default function confirmPasswordReducer(state = {}, action) {
  switch (action.type) {
    case types.OPENPASSWORDPORTAL:
      return {
        ...state,
        openPasswordPortalId: action.id,
      }
    case types.CLOSEPASSWORDPORTAL:
      return {
        ...state,
        openPasswordPortalId: undefined,
      }
    default:
      return state
  }
}
