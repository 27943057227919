import * as React from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"

export default function withRouter<Props>(
  WrappedComponent: typeof React.Component
): React.FC<Props> {
  return (props: Props) => {
    const [search] = useSearchParams()
    return (
      <WrappedComponent
        {...props}
        navigate={useNavigate()}
        location={useLocation()}
        search={search}
      ></WrappedComponent>
    )
  }
}
