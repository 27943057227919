import types from "./actionTypes"
import { fetchREST } from "../../../../shared/rest.js"
import { encodeURIComponentsForActions } from "../../../../shared/uriUtils"
// import {
//  showGlobalMessage,
//  convertBackendError
// } from '../../../globalMessage/controller'

function changeLanguage(language) {
  return { type: types.CHANGELANGUAGE, language }
}

export function changeLanguageAsync(language, userId) {
  if (!userId) {
    return dispatch => dispatch(changeLanguage(language))
  }

  return (dispatch, getState) =>
    fetchREST(
      "/userGUISettings/saveUserLanguage",
      { language, userId },
      { method: "POST", state: getState() }
    )
      .then(res => res.json())
      .then(json => {
        if (json.error) {
          // showGlobalMessage(dispatch, convertBackendError(json))
          return Promise.resolve()
        }
        dispatch(changeLanguage(language))
        return Promise.resolve()
      })
      .catch(err => {
        console.log(err)
      })
}

export function setLanguageFromUserGUISettingsAsync(userId) {
  return (dispatch, getState) =>
    fetchREST(
      "/userGUISettings/getUserLanguage?" +
        encodeURIComponentsForActions({ userId }),
      { method: "GET", state: getState() }
    )
      .then(res => res.json())
      .then(json => {
        if (json.error) {
          // showGlobalMessage(dispatch, convertBackendError(json))
          return Promise.resolve()
        }
        if (json.language) {
          dispatch(changeLanguage(json.language))
        }
        return Promise.resolve()
      })
      .catch(err => {
        console.log(err)
      })
}
