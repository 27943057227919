/**
 * Simple component that retrieves a permission list for the current user
 * in the current context (see action).
 *
 * Is subscribed, because 'normal' users don`t often change the context and
 * could miss a newly granted or revoked permission otherwise.
 *
 * Handles the transient context (org chart) by using the id of the parent orga
 * for the purpose of getting permissions.
 */

import { Component } from 'react'
import { connect } from 'react-redux'

import { getContext } from '../../OUContext/controller'
import genUniqueId from '../../uniqueId'
import { isEqual } from '../../walkObjectHierarchy'
import {
  subscribe,
  unsubscribe
} from '../../../modules/actionProvider/controllers/subscribe'
import { getTransientNodeFor }
  from '../../../modules/orgChart/redux/selectors/orgChartSelectors'

import {
  loadUserContextPermissionsAsync
} from './redux/permissionCheckActions'

const mapStateToProps = state => ({
  context: getContext(state),
  transientParentId: getTransientNodeFor(state)
})

const mapDispatchToProps = dispatch => ({
  dispatchSubscribe: (uniqueId, context) => (
    subscribe(dispatch, {
      uniqueId,
      mainFunction: dispatch => {
        return Promise.all([
          dispatch(loadUserContextPermissionsAsync(context))
        ])
          .then(() => Promise.resolve())
      }
    })
  ),
  dispatchUnsubscribe: uniqueId => unsubscribe(dispatch, uniqueId),
})

class PermissionCheckContainer extends Component {
  componentDidMount() {
    this.subscribe()
  }

  componentWillUnmount() {
    this.props.dispatchUnsubscribe(this.uniqueId)
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.context, this.props.context)) {
      this.subscribe()
    }
  }

  subscribe() {
    if (this.uniqueId) {
      this.props.dispatchUnsubscribe(this.uniqueId)
    }
    this.uniqueId = genUniqueId()
    // sometimes we have no real orgunit context but want to use the permissions
    // of the parent orgunit (transient orgunits in org chart).
    const clientId = this.props.context.clientId
    const orgunitId = this.props.context.orgunitId
    if (orgunitId === 'new' && this.props.transientParentId) {
      this.props.dispatchSubscribe(
        this.uniqueId,
        {
          clientId,
          orgunitId: this.props.transientParentId
        })
    } else if (orgunitId && orgunitId !== 'new') {
      this.props.dispatchSubscribe(this.uniqueId, { clientId, orgunitId })
    }

  }

  render() {
    return null
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(
  PermissionCheckContainer)
