import { valueAt, isEqual } from '../../../walkObjectHierarchy'
import types from './actionTypes'

export default function (
  state = {},
  action
) {
  switch (action.type){
  case types.LOADUSERCONTEXTPERMISSIONS:
    return loadPermissions(state, action)
  case types.LOADUSEROUSWITHPERMISSION:
    return loadOUs(state, action)
  default:
    return state
  }
}

function loadPermissions(state, action) {
  const oldPermissions = valueAt(state, ['permissions']) || {}
  const permissions = action.permissions
  if (!isEqual(oldPermissions, permissions)) {
    return {
      ...state,
      permissions
    }
  }
  return state
}

function loadOUs(state, action) {
  const permission = action.permission
  const orgunits = action.orgunits || []
  const invertedPermissions = valueAt(state, ['invertedPermissions']) || {}
  const oldOrgunits = invertedPermissions[permission]
  const createKey = ous => ous
    ? ous
      .map( ({ id }) => id )
      .sort( (a, b) => a < b ? -1 : 1 )
      .join('-')
    : ''
  const sortedNew = createKey(orgunits)
  const sortedOld = createKey(oldOrgunits)
  if ( sortedNew !== sortedOld ) {
    return {
      ...state,
      invertedPermissions: {
        ...invertedPermissions,
        [permission]: orgunits
      }
    }
  }
  return state
}
