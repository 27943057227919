import { valueAt, isEqual } from "../../../../shared/walkObjectHierarchy"
import types from "../actions/actionTypes"

// TODO: We may (!!) want to combine reducers for the client slice!

export default function clientManagerReducer(state = {}, action) {
  switch (action.type) {
    case types.OPENCLIENTMANAGERDIALOG:
      return {
        ...state,
        openDialog: true,
        openHistoryDialog: false,
        currentClientId: action.clientId,
      }
    case types.OPENCLIENTMANAGERHISTORYDIALOG:
      return {
        ...state,
        openDialog: false,
        openHistoryDialog: true,
        currentClientId: action.clientId,
      }
    case types.CANCELEDITCLIENT:
      return {
        ...state,
        openDialog: false,
        openHistoryDialog: false,
        currentClientId: undefined,
        licensesById: undefined,
        editLicenseId: undefined,
      }
    case types.SAVECLIENT:
      return saveClient(state, action)
    case types.DELETECLIENT:
      return deleteClient(state, action)
    case types.LOADALLCLIENTS:
      return loadAll(state, action)
    case types.SAVELICENSE:
      return saveLicense(state, action)
    case types.OPENLICENSEFORM:
      return {
        ...state,
        openLicenseForm: true,
        editLicenseId: action.licenseId,
      }
    case types.CLOSELICENSEFORM:
      return {
        ...state,
        openLicenseForm: false,
        editLicenseId: undefined,
      }
    case types.LOADLICENSES:
      return {
        ...state,
        licensesById: action.licenses.reduce((acc, license) => {
          acc[license.id] = license
          return acc
        }, {}),
      }
    case types.LOADCLIENTEXPIRATION:
      return {
        ...state,
        expiration:
          Object.keys(action.expiration).length === 0
            ? undefined
            : action.expiration,
      }
    case types.DELETECLIENTEXPIRATION:
      return { ...state, expiration: undefined }
    case types.UPDATECLIENTEXPIRATION:
      return {
        ...state,
        expiration:
          Object.keys(action.expiration).length === 0
            ? undefined
            : action.expiration,
      }
    default:
      return state
  }
}

function saveClient(state, action) {
  const clients = valueAt(state, ["clients"]) || {}
  const byId = { ...(valueAt(state, ["clients", "byId"]) || {}) }
  return {
    ...state,
    openDialog: false,
    clients: {
      ...clients,
      byId: {
        ...byId,
        [action.client.id]: action.client,
      },
    },
  }
}

function deleteClient(state, action) {
  const clients = valueAt(state, ["clients"]) || {}
  const byId = { ...(valueAt(state, ["clients", "byId"]) || {}) }
  delete byId[action.clientId]
  return {
    ...state,
    openDialog: false,
    clients: {
      ...clients,
      byId,
    },
  }
}

// TODO: This is too time-consuming!
function loadAll(state, action) {
  const oldById = Object.values(valueAt(state, ["clients", "byId"]) || []).sort(
    (a, b) => (a.id < b.id ? -1 : 1)
  )
  const oldStats = Object.values(
    valueAt(state, ["clients", "totalStats"]) || {}
  )
  const newById = action.payload.clients.sort((a, b) => (a.id < b.id ? -1 : 1))
  const newStats = action.payload.totalStats
  if (!isEqual(oldById, newById) || !isEqual(oldStats, newStats)) {
    return {
      ...state,
      clients: {
        byId: action.payload.clients.reduce((acc, client) => {
          acc[client.id] = client
          return acc
        }, {}),
      },
      totalStats: newStats,
    }
  } else {
    return state
  }
}

function saveLicense(state, action) {
  const byId = { ...(valueAt(state, ["licensesById"]) || {}) }
  return {
    ...state,
    openLicenseForm: false,
    editLicenseId: undefined,
    licensesById: {
      ...byId,
      [action.license.id]: action.license,
    },
  }
}
