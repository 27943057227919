import React from 'react'

import { Form, Group } from '../../../shared/vmi-connected-form'
import { ValidationErrors } from '../../../shared/vmi-connected-form'

import { makeViewModel } from './models/signalSettingsFormModel'
import withForm from '../lib/withForm/withForm'

const validate = (values, dispatch, translate, props) => {
  const v = new ValidationErrors(
    translate('settings.error.validation', props)
  )
  return v
}

const SignalSettingsForm = ({
  connectedForm,
  toggleHandler,
  readOnly,
  onlyDetails
}) => {
  const { fields } = connectedForm

  return (
    <Form
      connectedForm={ connectedForm }
      onToggle={ toggleHandler }
      readOnly={ readOnly }
      onlyDetails= { onlyDetails }
    >
      <Group>
        { fields.plusHoursYellowFrom() }
        { fields.plusHoursRedFrom() }
      </Group>
      <Group>
        { fields.minusHoursYellowFrom() }
        { fields.minusHoursRedFrom() }
      </Group>
    </Form>
  )
}

export default withForm({
  validate,
  makeViewModel,
  form: 'signal'
})(SignalSettingsForm)
