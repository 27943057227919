import Color from './Color'
import EntryClassification from './EntryClassification'
import Holiday from './Holiday'
import PlannerDate from "../../../shared/plannerDateTime/PlannerDate"

export default class OrganisationalUnit {

  private holidays: {
    [plannerDateKey: string]: Holiday
  } = {}

  constructor(
    private readonly id: string,
    private readonly name: string,
    private readonly fullnameWithPath: string,

    // XXX new:
    private readonly abbrv: string,

    private readonly color: Color | null,
    private readonly archived: PlannerDate[],
    // TODO: This should be a subtype (two uses conflated here):

    // XXX new:
    private readonly disabled: boolean, // access denied
    private readonly children: OrganisationalUnit[] | undefined,

    private readonly availableClassifications?: EntryClassification[],
    holidays?: Holiday[]
  ) {
    for (const h of holidays ?? []) {
      this.holidays[h.getDate().getKey()] = h
    }
  }

  static fromJSON(json: Record<string, any>): OrganisationalUnit {
    const holidays: Holiday[] = []
    for (const h of Object.values(json.holidays as Record<string, any> ?? {})) {
      holidays.push(Holiday.fromJSON(h as { date: string, name: string }))
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const color: Color | undefined = json.color ? Color.fromJSON(json.color) : undefined
    const archived: PlannerDate[] = json.archived === false ? [] : (json.archived ?? []).map(
      (d: string) => PlannerDate.fromJSON(d)
    )
    const children: OrganisationalUnit[] = (json.children ?? []).map(
      (c: Record<string, any>) => OrganisationalUnit.fromJSON(c)
    )
    const classifications: EntryClassification[] = (
      json.availableClassifications ?? []
    ).map((c: Record<string, any>) => EntryClassification.fromJSON(c))
    return new OrganisationalUnit(
      json.id as string,
      json.name as string,
      json.fullname as string,
      json.abbrv as string,
      color ?? null,
      archived,
      json.isDisabled as boolean,
      children,
      classifications,
      holidays
    )
  }

  getId(): string { return '' + this.id }
  getFullName(): string { return `${this.name}${this.getParenthAbbrev(' ')}` }
  getFullNameWithPath(): string { return `${this.fullnameWithPath ?? this.getFullName()}` }
  getName(): string { return this.name }
  getAbbrv(): string { return this.abbrv }
  getAbbreviation(): string { return this.abbrv }
  getChildren(): OrganisationalUnit[] { return this.children ?? [] }
  isRoot(): boolean { return this.children !== undefined }
  isDisabled(): boolean { return !!this.disabled }
  isArchived(date: PlannerDate): boolean {
    for (const d of this.archived) {
      if (date.equals(d)) { return true }
    }
    return false
  }
  getColor(): Color | null {
    return this.color
  }
  getHoliday(date: PlannerDate): Holiday {
    return this.holidays[date.getKey()] ?? null
  }
  getKey(): string {
    return `orgunit-${this.getId()}`
  }
  getAvailableClassifications(): EntryClassification[] {
    return this.availableClassifications ?? []
  }

  findById(id: string): OrganisationalUnit {
    const findNode = (nodes: OrganisationalUnit[]): OrganisationalUnit => {
      let res: OrganisationalUnit | undefined
      for (const n of nodes.filter(node => !!node)) {
        if (n.getId() === `${id}`) {
          res = n
        } else {
          res = findNode(n.getChildren())
        }
        if (res) { break }
      }
      return res as OrganisationalUnit
    }
    return findNode([this])
  }

  flattenDepthFirst(): OrganisationalUnit[] {
    return this.flattenNodeDepthFirst(this)
  }

  /**
   * Returns the name of a subnode, including the path
   *
   * WARNING: This returns null if the subnode can't be
   * found!
   */
  getPathName(id: string): string | null {
    const p = this.getPath(id)
    if (p.length > 0) {
      const path = p
        .map(o => o.getName())
        .join(' / ')
      return `${p[p.length - 1].getName()} (${path})`
    } else {
      return null
    }
  }

  getPath(id: string): OrganisationalUnit[] {
    if (this.getId() === id) {
      return [this]
    } else if (this.getChildren()) {
      for (const c of this.getChildren()) {
        const subpath = c.getPath(id)
        if (subpath.length > 0) {
          return [this, ...subpath]
        }
      }
    }
    return []
  }

  toJSON(): Record<string, any> {
    const holidays: Record<string, any> = {}
    for (const d in this.holidays) {
      if (Object.prototype.hasOwnProperty.call(this.holidays, d)) {
        holidays[d] = this.holidays[d].toJSON()
      }
    }
    return {
      id: this.getId(),
      name: this.getName(),
      abbrv: this.getAbbrv(),
      color: this.getColor()?.toJSON(),
      archived: this.archived.map(a => a.toJSON()),
      isDisabled: this.isDisabled,
      children: this.getChildren().map(o => o.toJSON()),
      availableClassifications: this.getAvailableClassifications().map(
        c => c.toJSON()
      ),
      holidays
    }
  }

  clone(): OrganisationalUnit {
    return new OrganisationalUnit(
      this.getId(),
      this.getName(),
      this.getFullNameWithPath(),
      this.getAbbrv(),
      this.getColor()?.clone() ?? null,
      this.archived.map(d => d.clone()),
      this.isDisabled(),
      this.getChildren().map(c => c.clone()),
      this.getAvailableClassifications().map(c => c.clone()),
      Object.values(this.holidays).map(h => h.clone())
    )
  }

  private flattenNodeDepthFirst(node: OrganisationalUnit): OrganisationalUnit[] {
    return [
      node,
      ...node.getChildren()
        .reduce(
          (flattened: OrganisationalUnit[], c) => [...flattened, ...this.flattenNodeDepthFirst(c)],
          []
        )
    ]
  }

  private getParenthAbbrev(lead = '') {
    const a = this.getAbbreviation()
    if (a) {
      return `${lead}(${a})`
    } else {
      return ''
    }
  }

}