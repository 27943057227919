import { valueAt, isEqual } from "../../../../shared/walkObjectHierarchy"
import types from "./actionTypes"

export default function workHoursAccountReducer(state = {}, action) {
  switch (action.type) {
    case types.DELETEWORKHOURSCORRECTION:
      return {
        ...state,
        workHoursAccountChanged: new Date(), // results in new calc & load
      }
    case types.LOADDELETEDWORKHOURSCORRECTIONS: {
      return {
        ...state,
        deletedWorkHoursCorrections: action.corrections,
      }
    }
    case types.CLEARACCOUNT:
      return {
        ...state,
        account: undefined,
        weekAccounts: undefined,
      }
    case types.LOADWORKHOURSACCOUNT:
      return loadWorkHoursAccount(state, action)
    case types.LOADWEEKDAYS:
      return {
        ...state,
        weekAccount: action.weekAccount,
        weekAccountYear: action.year,
      }
    default:
      return state
  }
}

function loadWorkHoursAccount(state, action) {
  const account = action.account
  const oldAccount = valueAt(state, ["account"])
  if (isEqual(account, oldAccount)) {
    return state
  }
  return {
    ...state,
    account,
    weekAccount: undefined, // reset the days from a formerly shown year
  }
}
