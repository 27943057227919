import * as React from "react"
import { PunchAPI } from "../configuration/PunchAPI"
import { useIntl } from "react-intl"

export default function MobileLoggedHasPunched({
  date,
}: {
  date: string
}): JSX.Element {
  const intl = useIntl()
  const [hasPunchedIn, setHasPunchedIn] = React.useState(false)
  React.useEffect(() => {
    console.log("Running", date)
    void new PunchAPI().hasPunchedInToday(date).then(setHasPunchedIn)
  }, [date])
  return (
    <div className="mobile-punch-has-logged-status">
      {hasPunchedIn ? intl.formatMessage({ id: "hasPunchedIn" }) : null}
    </div>
  )
}
