const types = {
  LOADALLREASSIGNMENTS: 'LOAD_ALL_REASSIGNMENTS_',
  OPENEDITREASSIGNMENTDIALOG: 'OPEN_EDIT_REASSIGNMENT_DIALOG_',
  CLOSEEDITREASSIGNMENTDIALOG: 'CLOSE_EDIT_REASSIGNMENT_DIALOG_',
  OPENASSIGNUNASSIGNEDDIALOG: 'OPEN_ASSIGN_UNASSIGNED_DIALOG_',
  CLOSEASSIGNUNASSIGNEDDIALOG: 'CLOSE_ASSIGN_UNASSIGNED_DIALOG_',
  SAVEREASSIGNMENT: 'SAVE_REASSIGNMENT_',
  DELETEREASSIGNMENT: 'DELETE_REASSIGNMENT_',
  LOADVALIDORGUNITS: 'LOAD_VALID_REASSIGNMENT_ORG_UNTIS_',
  REASSIGNUNASSIGNED: 'REASSIGNED_UNASSIGNED_'
}

export default types
