/* eslint-disable max-len */
const messages: Record<string, Record<string, string>> = {
  de: {
    'ticket-sent': "Ihre Anfrage wurde erfolgreich gespeichert",
    'ticket-failed': "Leider konnte Ihre Anfrage nicht gespeichert werden",
    'support-btn': 'Support',
    'support-h': 'Kontakt aufnehmen',
    submit: 'Abschicken',
    cancel: 'Abbrechen',
    'ticket-description': 'Beschreibung',
    'ticket-subject': 'Betreff',
    'ticket-help': 'Ihre Angaben werden zusammen mit Ihrem Benutzernamen, Ihrem Namen und Ihrer Abteilung an mina.works weitergeleitet:',
  },
  en: {
    'ticket-sent': "Your request was sent",
    'ticket-failed': "Your request could not be sent",
    'support-btn': 'Support',
    'support-h': 'Submit a Support Ticket',
    submit: 'Submit',
    cancel: 'Cancel',
    'ticket-subject': 'Subject',
    'ticket-description': 'Text',
    'ticket-help': 'Information shared with mina.works will include your username, name, and department',
  }

}

export default messages