import * as React from "react"
import type { IntlShape } from "react-intl"
import { injectIntl } from "react-intl"
import type { Entry } from "../configuration/PlannedDay"
import type PlannedDay from "../configuration/PlannedDay"

export interface Props {
  intl: IntlShape
  day: PlannedDay
  showHeader?: boolean
}

class DayContent extends React.Component<Props> {
  render(): JSX.Element | null {
    const d = this.props.day
    if (d === null || d === undefined) {
      return null
    }
    return d.isAllDay()
      ? this.getAllDayContent(d)
      : this.getRegularDayContent(d)
  }

  private getAllDayContent(d: PlannedDay) {
    const seg = d.getEntries()[0]
    const endDateString = d.getEndDate(this.props.intl.locale)
    let endDateLabel = ""
    if (endDateString) {
      endDateLabel = ` ${this.props.intl.formatMessage({
        id: "time-until",
      })} ${endDateString}`
    }
    return (
      <div className="mobile-dashboard-entry-label mobile-holiday-label">
        {seg.getLabel()}
        {endDateLabel}
      </div>
    )
  }

  private getRegularDayContent(d: PlannedDay) {
    const breaks = (e: Entry) =>
      e.getBreaks().map((b, idx) => (
        <span key={idx} className="mobile-dashboard-entry-break">
          {b.getTranslatedLabel((id, p) =>
            this.props.intl.formatMessage({ id }, p)
          )}
        </span>
      ))
    return (
      <div className="mobile-dashboard-day-entries">
        {this.showHeader() ? (
          <div className="mobile-dashboard-day-hero-time-range">
            {d.getTimeRangeTitle(id => this.props.intl.formatMessage({ id }))}
          </div>
        ) : null}
        <div className="mobile-dashboard-day-department-name">
          {d.getDepartmentName()}
        </div>
        <div className="mobile-dashboard-day-entries">
          {d.getEntries().map((e, idx) =>
            e.isEmptyDay() ? null : (
              <div className="mobile-dashboard-entry" key={idx}>
                <div className="mobile-dashboard-entry-times">
                  <div className="mobile-dashboard-entry-start-time">
                    {e.getStartTime()}
                  </div>
                  <div className="mobile-dashboard-entry-end-time">
                    {e.getEndTime()}
                  </div>
                </div>
                <div
                  className="mobile-dashboard-entry-label"
                  style={{
                    borderLeft: `3px solid ${e.getCSSColor()}`,
                  }}
                >
                  <span className="mobile-dashboard-entry-label">
                    {e.getEntryName()}
                  </span>
                  {e.getBreaks().length > 0 ? (
                    <>
                      <span className="mobile-dashboard-entry-breaks">
                        {breaks(e)}
                      </span>
                    </>
                  ) : null}
                  {e.getDepartmentName() ? (
                    <div className="mobile-dashboard-entry-one-off">
                      {e.getDepartmentName()}
                    </div>
                  ) : null}
                </div>
              </div>
            )
          )}
        </div>
      </div>
    )
  }

  private showHeader(): boolean {
    return this.props.showHeader === undefined || this.props.showHeader === true
  }
}

export default injectIntl(DayContent)
