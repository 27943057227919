import * as p from "./permissions"
export * from "./permissions"

export const ALLPERMISSIONS = p

// Explicit and manual seperation of permissions!
export const USERPERMISSIONS = [
  p.VIEWOWNLEAVEACCOUNT,
  p.VIEWOWNMASTERDATA,
  p.VIEWOWNWORKHOURSACCOUNT,
  p.VIEWOWNPLANNER,
]

export const GENERALPERMISSIONS = Object.values(p).filter(
  permission => USERPERMISSIONS.indexOf(permission) === -1
)

/** Hidden for now, no functionality available */
/*
export const VIEWTEMPASSIGNMENTS = 'view-temp-assignments'
export const EDITTEMPASSIGNMENTS = 'edit-temp-assignments'
export const VIEWSETTINGS = 'view-settings'
export const EDITSETTINGS = 'edit-settings'
*/
