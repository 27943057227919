const REQ_PW_SCORE = 3

export const validatePasswordHandler = password => (
  import('zxcvbn')
    .then(zxcvbn => {
      const { score } = zxcvbn.default(password)
      const isValid = score >= REQ_PW_SCORE
      return Promise.resolve({ isValid, score })
    })
)

// might be to simple to warrant an exported function :)
export const identicalPasswordsHandler = (password, passwordRepeat) => {
  const equal = password === passwordRepeat
  return equal
}
