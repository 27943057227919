const types = {
  OPENADDUSERDIALOG: "OPEN_ADD_USER_DIALOG_",
  CLOSEADDUSERDIALOG: "CLOSE_ADD_USER_DIALOG_",
  OPENEDITUSERDIALOG: "OPEN_EDIT_USER_DIALOG_",
  CLOSEEDITUSERDIALOG: "CLOSE_EDIT_USER_DIALOG_",
  SETDEFAULTTAB: "EDIT_USER_SET_DEFAULT_TAB_",
  OPENEDITCANDIDATEUSERDIALOG: "OPEN_EDIT_CANDIDATE_USER_DIALOG_",
  CLOSEEDITCANDIDATEUSERDIALOG: "CLOSE_EDIT_CANDIDATE_USER_DIALOG_",
  LOADUSERLIST: "LOAD_USER_LIST_",
  LOADALLUNASSIGNEDUSERS: "LOAD_ALL_UNASSIGNED_USERS_",
  LOADALLUNASSIGNEDANDMISASSIGNEDUSERS:
    "LOAD_ALL_UNASSIGNED_AND_MISASSIGNED_USERS_",
  LOADALLMISASSIGNEDUSERS: "LOAD_ALL_MISASSIGNED_USERS_",
  REMOVECANDIDATEUSER: "REMOVE_CANDIDATE_USER_",
  UPDATEEXPIRESAT: "UPDATE_EXPIRES_AT_",
  PASSWORDCHANGED: "PASSWORD_CHANGED_",
  APPENDPASSWORDCHANGEERROR: "APPEND_PASSWORD_CHANGE_ERROR_",
  APPENDREGISTRATIONERROR: "APPEND_REGISTRATION_ERROR_",
  TOGGLEDESCENDANTS: "TOGGLE_DESCENDANTS_",
  TOGGLEHIDDEN: "TOGGLE_HIDDEN_",
  TOGGLEDELETED: "TOGGLE_DELETED_",
  LOADUSERDATAVIEW: "LOAD_USER_DATAVIEW_",
  BATCHSUCCESS: "BATCH_SUCCESS_",
  BATCHRESTOREERROR: "BATCH_RESTORE_ERROR_",
  CLOSEBATCHRESTOREMODAL: "CLOSE_BATCH_RESTORE_MODAL_",
  OPENBATCHRESTOREMODAL: "OPEN_BATCH_RESTORE_MODAL_",
  LOADREMAININGLICENSES: "LOAD_REMAINING_LICENSES_",
  LOADUSERENTRYYEAR: "LOAD_USER_ENTRY_YEAR_",
}

export default types
