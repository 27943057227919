import colors from '../../../colors'

export default colors.getSimpleBackgroundColorGrid()

/*
[
  [
    "#4ca4c7",
    "#374044",
  ],
  [
    "#f8f9f9",
    "#eaedef",
    "#dce1e3",
    "#ccd4d7",
    "#bac5c9",
    "#a7b5ba",
    "#91a2a9",
    "#778c94",
    "#5e6e74",
    "#374044",
  ],
  [
    "#e9f3f8",
    "#d1e7f0",
    "#b6dae8",
    "#98cbdf",
    "#76b9d4",
    "#4ca4c7",
    "#4494b3",
    "#3c819d",
    "#316b82",
    "#234c5d",
  ],
  [
    "#ebeef8",
    "#d5dbf2",
    "#bdc7ea",
    "#a0aee1",
    "#7d90d6",
    "#4c67c7",
    "#445cb3",
    "#3b509c",
    "#304280",
    "#222e59",
  ],
  [
    "#efecf9",
    "#dfd7f2",
    "#cbbfeb",
    "#b5a3e2",
    "#9980d7",
    "#6f4cc7",
    "#6344b3",
    "#563b9b",
    "#47307f",
    "#312159",
  ],
  [
    "#f6ebf9",
    "#ecd6f2",
    "#e1beea",
    "#d3a2e1",
    "#c37ed6",
    "#ac4cc7",
    "#9b44b3",
    "#873b9c",
    "#6f3181",
    "#4e225b",
  ],
  [
    "#f9ebf5",
    "#f2d6ea",
    "#eabede",
    "#e1a2cf",
    "#d67ebd",
    "#c74ca4",
    "#b34493",
    "#9d3b81",
    "#81316a",
    "#5b234b",
  ],
  [
    "#f9ecee",
    "#f2d6dd",
    "#eabfc8",
    "#e2a2b0",
    "#d77f92",
    "#c74c67",
    "#b3445c",
    "#9c3b51",
    "#813142",
    "#5b222f",
  ],
  [
    "#f8eeea",
    "#f1dbd3",
    "#e9c7ba",
    "#e0af9c",
    "#d59379",
    "#c76f4c",
    "#b36444",
    "#9d573b",
    "#814831",
    "#5c3323",
  ],
  [
    "#f7f3e7",
    "#efe8cd",
    "#e6dbb2",
    "#ddcd94",
    "#d3bd72",
    "#c7ac4c",
    "#b39b44",
    "#9d883c",
    "#827131",
    "#5e5123",
  ],
  [
    "#f2f7e6",
    "#e5efcc",
    "#d7e6b1",
    "#c7dd92",
    "#b7d271",
    "#a4c74c",
    "#94b444",
    "#829e3c",
    "#6c8332",
    "#4d5e24",
  ],
  [
    "#ebf7e8",
    "#d6f0cf",
    "#bfe7b4",
    "#a6de96",
    "#89d374",
    "#67c74c",
    "#5db344",
    "#519d3c",
    "#438332",
    "#305e24",
  ],
  [
    "#e8f8ed",
    "#d0f0d9",
    "#b6e8c4",
    "#98deac",
    "#75d490",
    "#4cc76f",
    "#44b364",
    "#3c9d58",
    "#328349",
    "#245e34",
  ],
  [
    "#e8f7f4",
    "#cff0e9",
    "#b5e7dc",
    "#97dece",
    "#74d3be",
    "#4cc7ac",
    "#44b49b",
    "#3c9d88",
    "#328371",
    "#245e51"
  ]
]
*/