export interface Errors {
  [fieldName: string]: string
}

/**
 * Use this class to report back validation errors during the validate step
 * (in submit).
 *
 * Errors are field-specific, but the error Message (passed to the constructor,
 * or set using `setErrorMessage`) is what is displayed at the top of the form
 * if an error prevents form submission.
 */
export default class ValidationErrors {

  private errors: Errors
  private _hasErrors: boolean

  constructor(private errorMessage: string) {
    this.errors = {}
    this._hasErrors = false
  }

  /**
   * If you use this, the validation will fail even if no separate errors
   * are recorded!
   *
   * If you just want to supply a default error message if any errors are
   * recorded, use the constructor!
   */
  setErrorMessage(msg: string) {
    this.errorMessage = msg
    this._hasErrors = true
  }

  addError(fieldName: string, errorMessage: string) {
    this._hasErrors = true
    this.errors[fieldName] = errorMessage
  }

  hasErrors() {
    return this._hasErrors
  }

  getErrorMessage() {
    return this.hasErrors() ? this.errorMessage : ''
  }

  getReduxFormErrors() {
    return {
      ...this.errors,
      _error: this.errorMessage
    }
  }
}
