import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'

import { connectForm } from '../../../../shared/connectedForm'
import {
  EDITSETTINGS
} from '../../../../shared/permissions'

import {
  hasPermission
} from '../../../../shared/permissionCheck/controller'

import {
  getContext
} from '../../../../shared/OUContext/controller'
import { getAllActiveOrgUnitsState }
  from '../../../../shared/OUContext/redux/selectors/ouContextDropdownSelectors'

import {
  saveFormAsync,
  loadFormSettingsAsync,
  deleteFormSettingsAsync
}
  from '../../../settings/redux/actions/settingsActions'
import { getFormSettingsState }
  from '../../../settings/redux/selectors/settingsSelectors'

import SettingsFormContainerBase from './SettingsFormContainerBase'

const withForm = data => WrappedComponent => {
  class SettingsFormContainer extends SettingsFormContainerBase {
    constructor(props) {
      super(props)
      this.state = { readOnly: false }
      this.data = data
      this.WrappedComponent = WrappedComponent
    }
  }

  const onSubmitSuccess = ({ fields, dispatch, props }) => {
    const form = data.form
    const defaultProps = {
      userId: props.userId,
      isUser: props.isUser,
      clientId: props.oucontext.clientId,
      orgunitId: props.userId ? undefined : props.oucontext.orgunitId
    }
    // combine form fields to save in backend
    const saveFields = []
    Object.entries(fields).forEach(([type, value]) => {
      const extendedField = {
        ...defaultProps,
        type,
        value
      }
      saveFields.push(extendedField)
    })
    dispatch(saveFormAsync({ form, saveFields }))
  }

  const mapStateToProps = state => ({
    oucontext: getContext(state),
    formSettings: getFormSettingsState(state, data.form),
    orgUnits: getAllActiveOrgUnitsState(state),
    canEdit: hasPermission(EDITSETTINGS, state)
  })

  const mapDispatchToProps = dispatch => ({
    dispatchLoadFormSettings: props => {
      return dispatch(loadFormSettingsAsync(props))
    },
    dispatchDeleteFormSettings : props => {
      return dispatch(deleteFormSettingsAsync(props))
    }
  })

  const wrapWithReduxForm = connectForm({
    coreForm: data.makeViewModel(),
    validate: data.validate,
    onSubmitSuccess
  })

  return connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(
      wrapWithReduxForm(
        SettingsFormContainer
      )
    )
  )
}

export default withForm
