import * as React from "react"
import type PunchedStep from "../configuration/PunchedStep"
import { useIntl } from "react-intl"
import { isoDateStringToJSDate } from "@mina-works/datetime-utils"
import MobilePunchHasLogged from "./MobilePunchHasLogged"
import MobilePunchRunningStep from "./MobilePunchRunningStep"

interface Props {
  date?: string
  steps: PunchedStep[]
  update: () => Promise<void>
}

export default function MobilePunchRunning({
  steps,
  update,
  date,
}: Props): JSX.Element {
  const intl = useIntl()
  const revSteps = [...steps]
  revSteps.reverse()
  return (
    <div className="punched-running-container">
      {date ? <MobilePunchHasLogged date={date}></MobilePunchHasLogged> : null}
      <div className="punched-running">
        {steps[0] ? (
          <div className="punched-running-date">
            {isoDateStringToJSDate(steps[0].date).toLocaleDateString(
              intl.locale,
              {
                weekday: "long",
                day: "numeric",
                month: "long",
                year: "numeric",
              }
            )}
          </div>
        ) : null}
        {revSteps.map((step, idx) => {
          const disabled = revSteps.length > 1 && step.type === "start"
          return (
            <MobilePunchRunningStep
              steps={revSteps}
              stepNumber={idx}
              update={update}
              key={step.id}
              step={step}
              deleteDisabled={disabled}
            ></MobilePunchRunningStep>
          )
        })}
      </div>
    </div>
  )
}
