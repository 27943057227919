// XXX: User, and possibly extend, the shared User model for all user-selectors.
//      Also use models for contained MasterData, Qualifications, ... etc.

import { createSelector } from "reselect"
import { valueAt } from "../../../../shared/walkObjectHierarchy"
import User from "../../../../shared/models/User"
import type State from "../../../../redux/state"
import type JsonUser from "../JsonUser"

export const getRemainingLicensesState = createSelector(
  (state: State) =>
    valueAt(state, ["userManager", "userListing", "remainingLicenses"]),
  remaining => remaining
)

const getOpenAddUserDialog = (state: State) =>
  valueAt(state, ["userManager", "userListing", "openAddUserDialog"])
export const getOpenAddUserDialogState = createSelector(
  getOpenAddUserDialog,
  o => !!o
)

export const getShowDescendants = createSelector(
  (state: State) =>
    valueAt(state, ["userManager", "userListing", "showDescendants"]),
  show => !!show
)

export const getShowHidden = createSelector(
  (state: State) =>
    valueAt(state, ["userManager", "userListing", "showHidden"]),
  show => !!show
)

export const getShowDeleted = createSelector(
  (state: State) =>
    valueAt(state, ["userManager", "userListing", "showDeleted"]),
  show => !!show
)

interface UsersById {
  [id: string]: UserJSON
}
interface UserJSON {
  stateId: string
  clientId: number
  hasCurrentAutoplan: boolean
  hasCurrentContract: boolean
  id: number
  listingName: string
  location: string
  orgunitId: number
  orgunitName: string
  qualifications: string
  roles: string
  sortableName: string
  status: string
  username: string
  isHidden: boolean
  isDeleted: boolean
  isLocked: boolean
  deletedAt: null | string
  hiddenAt: null | string
  lockedAt: null | string
}
export const getUserList = (state: State): UsersById =>
  valueAt(state, ["userManager", "userListing", "userList", "byId"])
export const getUserListState = createSelector(
  getUserList,
  (byId: UsersById): UserJSON[] =>
    Object.values(byId ?? {}).sort((a, b) =>
      a.sortableName.localeCompare(b.sortableName)
    )
)

// NOTE: "Active" in this context is legacy vocabulary, denoting a user that
//       hasn't been deleted!
export const getAllActiveUsers = (state: State): any =>
  valueAt(state, ["userManager", "userListing", "allActiveUsers", "byId"])
export const getAllActiveUsersState = createSelector(
  getAllActiveUsers,
  (users: JsonUser[]): JsonUser[] => createUserFromState(users)
)

export const getAllUnassignedUsersById = (state: State): any =>
  valueAt(state, ["userManager", "userListing", "unassignedUsers", "byId"]) ||
  {}

export const getAllUnassignedUsers = createSelector(
  getAllUnassignedUsersById,
  (users: JsonUser[]): JsonUser[] => createUserFromState(users)
)

export const getAllMisassignedUsersById = (state: State): any =>
  valueAt(state, ["userManager", "userListing", "misassignedUsers", "byId"]) ||
  {}

export const getAllMisassignedUsers = createSelector(
  getAllMisassignedUsersById,
  (users: JsonUser[]): JsonUser[] => createUserFromState(users)
)

export const getAllUsersByOrgunitId = createSelector(
  getAllActiveUsers,
  (users: JsonUser[]) =>
    Object.values(users || []).reduce((acc, u) => {
      const user = createUser(u)
      const orgunitId = user.getOrgunitId()
      if (acc[orgunitId]) {
        acc[orgunitId].push(user)
      } else {
        acc[orgunitId] = [user]
      }
      return acc
    }, {})
)

export const getAllVisibleUsersByOrgunitId = createSelector(
  getAllActiveUsers,
  (users: JsonUser[]) =>
    Object.values(users || []).reduce((acc, u) => {
      const user = createUser(u)
      const orgunitId = user.getOrgunitId()
      if (!u.isLocked || !u.isHidden) {
        if (acc[orgunitId]) {
          acc[orgunitId].push(user)
        } else {
          acc[orgunitId] = [user]
        }
      } else {
        console.log("user is deactivated:", u)
      }
      return acc
    }, {})
)

export const getAllUsersByUserId = createSelector(getAllActiveUsers, users =>
  Object.values((users || []) as JsonUser[]).reduce(
    (acc: { [ouId: string]: User[] }, u: JsonUser) => {
      const user = createUser(u)
      const userId = user.getId()
      if (acc.userId) {
        acc.orgunitId.push(user)
      } else {
        acc[userId] = [user]
      }
      return acc
    },
    {}
  )
)

const createUser = (u: JsonUser) =>
  new User({
    id: u.id,
    username: u.username,
    title:
      u.masterdata && u.masterdata.title !== null
        ? u.masterdata.title
        : undefined,
    firstname:
      u.masterdata && u.masterdata.firstname !== null
        ? u.masterdata.firstname
        : undefined,
    surname:
      u.masterdata && u.masterdata.lastname !== null
        ? u.masterdata.lastname
        : undefined,
    orgunit: u.orgunit,
  })

const createUserFromState = (users: JsonUser[]) =>
  Object.values(users || [])
    .map(createListingUser)
    .sort((a, b) =>
      (a as JsonUser).sortableName.localeCompare((b as JsonUser).sortableName)
    ) as JsonUser[]

// TODO: Cleanup after JsonUser is defined
interface ListingUser extends JsonUser {
  isDeleted: boolean
  isHidden: boolean
}

const createListingUser = (user: JsonUser): ListingUser => {
  // XXX: Do all that metadata stuff server-side?
  const departmentName = user.orgunit ? user.orgunit.name : ""
  const closestLocation = user.closestLocation ? user.closestLocation : {}
  const locationName =
    Object.keys(closestLocation).length > 0 ? user.closestLocation.name : ""
  let sortableName = ""
  if (user.masterdata) {
    const firstname = user.masterdata.firstname ? user.masterdata.firstname : ""
    const lastname = user.masterdata.lastname ? user.masterdata.lastname : ""
    sortableName =
      lastname && firstname
        ? `${lastname}, ${firstname}`
        : lastname
        ? lastname
        : ""
  }
  /**
   * Possible states from combination of flags.
   *
   * This is implied business-application logic and subject to change.
   * Could be enhanced with reset-token-expired state etc.
   */
  const isDeleted = user.isDeleted
  const isLocked = user.isLocked
  const isHidden = user.isHidden
  const isCandidate = user.isCandidateUser
  let status = "unknown"
  if (!isDeleted && !isHidden && !isLocked) {
    status = "active-login"
  }
  if (!isDeleted && !isHidden && isLocked) {
    status = "active-no-login"
  }
  if (isDeleted) {
    status = "deleted"
  }
  if (isHidden) {
    status = "hidden"
  }
  if (isCandidate) {
    status = "candidate"
  }
  return {
    ...user,
    isDeleted, // XXX: Already in ...user
    isHidden, // XXX: Already in ...user
    departmentName,
    locationName,
    sortableName,
    status,
  }
}
