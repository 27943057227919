import { PlusOutlined } from "@ant-design/icons"
import { Button, Input } from "antd"
import * as React from "react"
import type { IntlShape } from "react-intl"
import { FormattedMessage, injectIntl } from "react-intl"
import type { OrganisationalUnitV3 } from "../../commonInterfaces/PlannerV3"
import BetaLabel from "../../shared/BetaLabel"
import type { Break, Entry } from "../configuration/PlannedDay"
import type PlannerData from "../configuration/PlannerData"
import EditableLoggedEntry from "./EditableLoggedEntry"
import MobileEntryForm from "./MobileEntryForm"

const TextArea = Input.TextArea

interface Props {
  intl: IntlShape
  ouId: string
  entries: EditableLoggedEntry[]
  organisationalUnits: OrganisationalUnitV3[]
  show: boolean
  plannerData: PlannerData
  loading: boolean
  date: string
  saveLogged: (
    date: string,
    entries: EditableLoggedEntry[],
    message: string
  ) => void
  deleteLogged: (date: string) => void
  emptyLogged: (date: string) => void
}

interface State {
  editingEntries: EditableLoggedEntry[]
  message?: string
  startDate?: string
  loggedEntryUpdated?: number
}

class MobileCalendarEditDay extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.addNewLoggedEntry = this.addNewLoggedEntry.bind(this)
    this.removeLoggedEntry = this.removeLoggedEntry.bind(this)
    this.loggedEntryUpdated = this.loggedEntryUpdated.bind(this)
    this.saveLogged = this.saveLogged.bind(this)
    this.emptyLogged = this.emptyLogged.bind(this)
    this.deleteLogged = this.deleteLogged.bind(this)
    this.cancelLogged = this.cancelLogged.bind(this)
    this.state = {
      editingEntries: [],
    }
  }

  componentDidMount(): void {
    this.handleEntryUpdate()
  }

  componentDidUpdate(oldProps: Props): void {
    this.handleEntryUpdate(oldProps)
  }

  render() {
    return this.props.show ? (
      <div className="mce-edit-day">
        <div className="mce-edit-header">
          <FormattedMessage id="modifyDay"></FormattedMessage>
          <BetaLabel></BetaLabel>
        </div>
        {this.renderEntries()}
        <div className="mce-entry-forms-controls">
          <Button onClick={this.addNewLoggedEntry} type="default">
            <PlusOutlined />{" "}
            <FormattedMessage id="add-entry"></FormattedMessage>
          </Button>
        </div>
        {this.renderFormFooter()}
      </div>
    ) : null
  }

  private renderEntries() {
    return this.state.editingEntries.map((e, idx) => (
      <MobileEntryForm
        loading={this.props.loading}
        entryUpdatedCallback={this.loggedEntryUpdated}
        ouId={this.props.ouId}
        idx={idx}
        removeEntry={this.removeLoggedEntry}
        key={`mce-logged-entry-${idx}`}
        entry={e}
        startDate={this.getStartDate()}
        organisationalUnits={this.props.organisationalUnits}
      ></MobileEntryForm>
    ))
  }

  private renderFormFooter() {
    const entryLength = this.state.editingEntries?.length
    const e0 = entryLength > 0 ? this.state.editingEntries[0] : null
    const isEmpty = entryLength === 1 && e0?.isEmptyDay()
    const breaksAreWrong = this.areBreaksWrong()
    return (
      <>
        <div className="mce-entry-forms-empty-message">
          {isEmpty ? (
            <FormattedMessage id="empty-logged-day"></FormattedMessage>
          ) : null}
        </div>
        <div className="mce-entry-forms-message">
          <TextArea
            className="logged-message"
            onChange={e => this.setMessage(e.target.value)}
            value={this.getMessage()}
            rows={1}
            placeholder={this.props.intl.formatMessage({
              id: "Change Message",
            })}
          ></TextArea>
        </div>
        {breaksAreWrong ? (
          <div className="mce-entry-forms-warning">
            {this.props.intl.formatMessage({ id: "breaks-are-wrong" })}
          </div>
        ) : null}
        <div className="mce-save-controls">
          <Button
            type="primary"
            disabled={breaksAreWrong}
            onClick={this.saveLogged}
          >
            <FormattedMessage id="save"></FormattedMessage>
          </Button>
          <Button onClick={this.cancelLogged}>
            <FormattedMessage id="cancel"></FormattedMessage>
          </Button>
          <br />
          <Button
            type="link"
            className="mce-form-delete"
            onClick={this.deleteLogged}
            title={this.props.intl.formatMessage({ id: "delete-day-expl" })}
          >
            <FormattedMessage id="delete-day"></FormattedMessage>
          </Button>
        </div>
      </>
    )
  }

  private getMessage(): string {
    return (
      this.state.message ?? this.state.editingEntries[0]?.getMessage() ?? ""
    )
  }

  private setMessage(message: string): void {
    this.setState({ message })
  }

  private saveLogged(): void {
    const breaksAreWrong = this.areBreaksWrong()
    const timeMissing = this.timeMissing()
    const cetMissing = this.cetMissing()
    const hasLongEntryDenied = this.hasLongEntryDenied()
    const { overlap, feedback } = this.isThereAnyEntryOverlap()
    if (
      !hasLongEntryDenied &&
      !overlap &&
      !breaksAreWrong &&
      !timeMissing &&
      !cetMissing
    ) {
      this.props.saveLogged(
        this.getStartDate(),
        this.state.editingEntries,
        this.state.message ?? ""
      )
    } else if (timeMissing) {
      // More important, handle first
      alert(this.props.intl.formatMessage({ id: "times-are-wrong" }))
    } else if (cetMissing) {
      alert(this.props.intl.formatMessage({ id: "cet-missing" }))
    } else if (breaksAreWrong) {
      alert(this.props.intl.formatMessage({ id: "breaks-are-wrong" }))
    } else if (overlap) {
      alert(
        this.props.intl.formatMessage({ id: "overlap-report" }, { feedback })
      )
    }
  }

  private hasLongEntryDenied(): boolean {
    const hasLongEntry = this.state.editingEntries.some(
      e => e.getApproximateLengthInHours() > 10
    )
    if (hasLongEntry) {
      return !window.confirm(
        this.props.intl.formatMessage({ id: "proceed-with-long-entry" })
      )
    } else {
      return false
    }
  }

  // TODO: This must be available for TEMPLATES as well!
  private isThereAnyEntryOverlap(): { overlap: boolean; feedback: string } {
    const entries = this.state.editingEntries
    const result: EntryOverlap = {
      overlap: false,
      feedback: [],
    }
    let overlapIndex = 0
    entries.sort(cmpEntries)
    for (let i = 0; i < entries.length - 1; i++) {
      const e1 = entries[i]
      const e2 = entries[i + 1]
      const e1StartTime = e1.getStartTime() ?? "00:00"
      const e1EndTime = e1.getEndTime() ?? "24:00"
      const e2StartTime = e2.getStartTime() ?? "00:00"
      console.assert(e1StartTime.length === 5)
      console.assert(e1EndTime.length === 5)
      console.assert(e2StartTime.length === 5)
      const overlapIntoNextDayAllDay = e1.isOvernight()
      const endsAfterNextEntryStarts = e1EndTime > e2StartTime
      const e1IsOvernight = e1StartTime > e1EndTime
      const e1AndE2OnSameDay = e1.getStartDate() === e2.getStartDate()
      if (
        overlapIntoNextDayAllDay ||
        (endsAfterNextEntryStarts && (e1IsOvernight || e1AndE2OnSameDay))
      ) {
        result.overlap = true
        result.feedback?.push({
          entry1: e1,
          entry2: e2,
          position: overlapIndex,
          condition: overlapIntoNextDayAllDay
            ? "overlap-into-next-day-all-day-entry"
            : "overlap-into-regular-entry",
        })
      }
      if (this.props.date === e1.getStartDate()) {
        overlapIndex += 1 // skip previous day overnight shifts
      }
    }
    return {
      ...result,
      feedback:
        result.feedback
          ?.map(f => this.formatEntryOverlapFeedback(f))
          ?.join("\n") ?? "",
    }
  }

  private formatEntryOverlapFeedback(f: EntryOverlapFeedback): string {
    const e1 = f.entry1
    const e1n = e1.getEntryName()
    const e1s = e1.getStartTime()?.toString() ?? ""
    const e1e = e1.getEndTime()?.toString() ?? ""
    const e2 = f.entry2
    const e2n = e2.getEntryName()
    const e2s = e2.getStartTime()
    const e2e = e2.getEndTime()
    return `- ${e1n} (${e1s}-${e1e}) und ${e2n} (${e2s}-${e2e})`
  }

  private cetMissing(entries?: EditableLoggedEntry[]): boolean {
    for (const e of entries ?? this.state.editingEntries) {
      if (e.getClassification() === undefined) {
        return true
      }
    }
    return false
  }

  private timeMissing(entries?: EditableLoggedEntry[]): boolean {
    for (const e of entries ?? this.state.editingEntries) {
      const startTime = e.getStartTime()
      const endTime = e.getEndTime()
      if (startTime === undefined || endTime === undefined) {
        return true
      }
    }
    return false
  }

  private areBreaksWrong(entries?: EditableLoggedEntry[]): boolean {
    for (const e of entries ?? this.state.editingEntries) {
      const startTime = e.getStartTime()
      const endTime = e.getEndTime()
      const overnight = e.isOvernight()
      const breaks = this.getSortedBreaks(e)
      for (const b of breaks) {
        // TODO: Make lazy to optimize
        const c1 = startTime === endTime
        const c2 = this.overnightBreakIsInvalid(
          b,
          overnight,
          startTime,
          endTime
        )
        const c3 = this.regularBreakIsInvalid(b, overnight, startTime, endTime)
        const c4 = this.breakDurationExceedsBounds(b)
        if (c1 || c2 || c3 || c4) {
          return true
        }
      }
    }
    return false
  }

  private getSortedBreaks(e: EditableLoggedEntry) {
    const breaks = e.getBreaks().slice()
    breaks.sort((a, b) =>
      !a.getStartTime() ||
      (b.getEndTime() !== undefined && a.getStartTime()! < b.getEndTime()!)
        ? -1
        : 1
    )
    return breaks
  }

  private regularBreakIsInvalid(
    b: Break,
    overnight: boolean,
    startTime?: string,
    endTime?: string
  ): boolean {
    if (b.getStartTime() === undefined || b.getEndTime() === undefined) {
      return true
    } else {
      startTime = startTime ?? "00:00"
      endTime = endTime ?? "24:00"
      const breakIsNotOvernight = b.getEndTime()! > b.getStartTime()!
      const breakOverlapsOutsideOvernightEntry = () =>
        (b.getStartTime()! < startTime! && b.getEndTime()! > endTime!) ||
        (b.getStartTime()! > endTime! && b.getEndTime()! < startTime!)
      const breakOverlapsOutsideRegularEntry = () =>
        b.getStartTime()! < startTime! || b.getEndTime()! > endTime!
      return (
        breakIsNotOvernight && // entry is overnight
        ((overnight && breakOverlapsOutsideOvernightEntry()) || // entry is regular
          (!overnight && breakOverlapsOutsideRegularEntry()))
      )
    }
  }

  private breakDurationExceedsBounds(b: Break): boolean {
    // TODO: Make this work with DST?
    const maxMinutes = getMinutesBetween(
      b.getStartTime() ?? "00:00",
      b.getEndTime() ?? "00:00"
    )
    const duration = b.getDurationInMinutes()
    return duration > maxMinutes || duration < 1
  }

  private overnightBreakIsInvalid(
    b: Break,
    overnight: boolean,
    startTime?: string,
    endTime?: string
  ) {
    startTime = startTime ?? "00:00"
    endTime = endTime ?? "24:00"
    return (
      // short circuit eval avoids undefined here!
      b.getStartTime() === undefined ||
      b.getEndTime() === undefined ||
      (b.getEndTime()! < b.getStartTime()! &&
        (!overnight ||
          b.getEndTime()! > endTime ||
          b.getStartTime()! < startTime))
    )
  }

  private emptyLogged(): void {
    this.props.emptyLogged(this.getStartDate())
  }

  private deleteLogged(): void {
    this.props.deleteLogged(this.getStartDate())
  }

  private cancelLogged(): void {
    this.handleEntryUpdate()
  }

  private addNewLoggedEntry() {
    // Note: We're trying to preserve references here as far as possible!
    // TODO: Limit entries to exactly one for all-day/multiday entries
    // TODO: Move "get autoplan day" to utility!
    this.setState({
      editingEntries: [
        ...this.state.editingEntries,
        new EditableLoggedEntry(
          // TODO: Breaks on add
          { startDate: this.deriveStartDateFromEntries(), breaks: [] },
          this.props.plannerData.getPlannerV3()
        ),
      ],
    })
  }

  private removeLoggedEntry(idx: number) {
    const editingEntries = this.state.editingEntries.map(e => e)
    editingEntries.splice(idx, 1)
    this.setState({ editingEntries })
  }

  private loggedEntryUpdated() {
    this.setState({ loggedEntryUpdated: Date.now() })
  }

  private handleEntryUpdate(oldProps?: Props) {
    // Handle updated entries *from the outside* (e.g., opening a new
    // form).
    if (
      oldProps === undefined ||
      oldProps.entries !== this.props.entries ||
      oldProps.plannerData !== this.props.plannerData ||
      oldProps.loading !== this.props.loading
    ) {
      this.setState({
        editingEntries: this.props.entries
          .filter(e => !e.mightBeAllDayEntry())
          .map(e => e.clone()),
        startDate: this.deriveStartDateFromEntries(),
      })
    }
  }

  private deriveStartDateFromEntries() {
    const startDate: string | null = this.props.entries[0]?.getStartDate()
    return startDate
  }

  private getStartDate() {
    return this.props.date
  }
}

/**
 * Calculate the distance between two times; these must be within 24 hours
 * of each other (technically 23.99999...), but they *may* cross midnight
 *
 * Since PlannerTime only has minute precision, the result won't pretend
 * to be more precise than that.
 */
export function getMinutesBetween(t1: string, t2: string): number {
  const normalize = (s: string) => s.padStart(5, "0")
  const getHour = (s: string) => parseInt(s.substring(0, 2), 10)
  const getMinute = (s: string) => parseInt(s.substring(3, 5), 10)
  t1 = normalize(t1)
  t2 = normalize(t2)
  if (t2 === t1) {
    return 0
  } else if (t2 < t1) {
    return getMinutesBetween(t1, "24:00") + getMinutesBetween("00:00", t2)
  } else if (getHour(t1) === getHour(t2)) {
    return getMinute(t2) - getMinute(t1)
  } else {
    const minutes = 60 - getMinute(t1) + getMinute(t2)
    const hours = getHour(t2) - getHour(t1) - 1
    return hours * 60 + minutes
  }
}

export default injectIntl(MobileCalendarEditDay)

export const cmpEntries = (a: Entry, b: Entry): number => {
  if (a.getStartDate() < b.getStartDate()) {
    return -1
  } else if (a.getStartDate() > b.getStartDate()) {
    return 1
  } else if (
    a.getStartTime() !== undefined &&
    (b.getStartTime === undefined || a.getStartTime()! < b.getStartTime()!)
  ) {
    return -1
  } else if (
    b.getStartTime() &&
    (a.getStartTime() === undefined || b.getStartTime()! < a.getStartTime()!)
  ) {
    return 1
  }
  return 0
}

interface EntryOverlap {
  overlap: boolean
  feedback?: EntryOverlapFeedback[]
}

interface EntryOverlapFeedback {
  position: number
  entry1: Entry
  entry2: Entry
  condition: OverlapErrorCondition
}

type OverlapErrorCondition =
  | "overlap-into-next-day-all-day-entry"
  | "overlap-into-regular-entry"
