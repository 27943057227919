import * as React from "react"
import { Button } from "antd"
import { useIntl } from "react-intl"
import makePunchAPI from "../configuration/PunchAPI"
import type PunchedStep from "../configuration/PunchedStep"
import useSumsForToday from "./hooks/useSumsForToday"

interface Props {
  hideSums: () => void
  steps: PunchedStep[]
  date?: string
}

export default function MobilePunchSums({
  hideSums,
  date,
  steps,
}: Props): JSX.Element {
  const intl = useIntl()
  const punchAPI = React.useMemo(() => makePunchAPI(), [])
  const [runningMinutesYear, setRunningMinutesYear] = React.useState(0)
  const [runningMinutesMonth, setRunningMinutesMonth] = React.useState(0)
  const [runningMinutesWeek, setRunningMinutesWeek] = React.useState(0)
  const { sumStepsInSeconds } = useSumsForToday({ steps })
  React.useEffect(() => {
    if (date) {
      void punchAPI
        .getRawMinsSummary(date)
        .then(
          ({
            rawWorkMinutesForRunningMonth,
            rawWorkMinutesForRunningWeek,
            rawWorkMinutesForRunningYear,
          }) => {
            setRunningMinutesWeek(rawWorkMinutesForRunningWeek)
            setRunningMinutesMonth(rawWorkMinutesForRunningMonth)
            setRunningMinutesYear(rawWorkMinutesForRunningYear)
          }
        )
    }
  }, [date, punchAPI, steps])
  const d = React.useMemo(
    () => displayTimeSeconds(sumStepsInSeconds),
    [sumStepsInSeconds]
  )
  const w = React.useMemo(
    () => displayTimeMinutes(runningMinutesWeek),
    [runningMinutesWeek]
  )
  const m = React.useMemo(
    () => displayTimeMinutes(runningMinutesMonth),
    [runningMinutesMonth]
  )
  const y = React.useMemo(
    () => displayTimeMinutes(runningMinutesYear),
    [runningMinutesYear]
  )
  return (
    <div className="mobile-punch-sums">
      <div className="mobile-punch-sums-h">
        {intl.formatMessage({ id: "sums-header" })}
      </div>
      <table cellPadding={0} cellSpacing={0}>
        <tbody>
          <tr>
            <td>{intl.formatMessage({ id: "workHoursToday" })}:</td>
            <td className="mobile-punch-sums-wh">{d}</td>
          </tr>
          <tr>
            <td>{intl.formatMessage({ id: "workHoursWeek" })}:</td>
            <td className="mobile-punch-sums-wh">{w}</td>
          </tr>
          <tr>
            <td>{intl.formatMessage({ id: "workHoursMonth" })}:</td>
            <td className="mobile-punch-sums-wh">{m}</td>
          </tr>
          <tr>
            <td>{intl.formatMessage({ id: "workHoursYear" })}:</td>
            <td className="mobile-punch-sums-wh">{y}</td>
          </tr>
        </tbody>
      </table>
      <Button onClick={hideSums} className="mobile-punch-sums-btn">
        {intl.formatMessage({ id: "close" })}
      </Button>
    </div>
  )
}

const displayTimeSeconds = (s: number): string => {
  return displayTimeMinutes(Math.floor(s / 60))
}

const displayTimeMinutes = (min: number): string => {
  const h = Math.floor(min / 60)
  const m = `${Math.floor(min % 60)}`.padStart(2, "0")
  return `${h}:${m}`
}
