/**
 * Translation utility for the process manager
 */

export interface Messages {
  [locale: string]: {
    [msgId: string]: string
  }
}

export default function translate(
  locale: string,
  msgId: string,
  templateValues: {
    [key: string]: string
  },
  messages: Messages,
  defaultMessage?: string
): string {
  let result = defaultMessage ?? msgId
  const template = messages[locale][msgId]
  if (template !== undefined) {
    result = template
    for (const k in templateValues) {
      if (Object.prototype.hasOwnProperty.call(templateValues, k)) {
        result.replace(`{${k}}`, templateValues[k])
      }
    }
  }
  return result
}