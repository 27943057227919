import React from "react"
import { connect } from "react-redux"
import { injectIntl } from "react-intl"
import { Upload } from "antd"
import { ScheduleOutlined, InboxOutlined } from "@ant-design/icons"
import { XMLParser } from "fast-xml-parser"
import { getUserIsAdminState } from "../../../login/redux/selectors/loginSelectors"
import { getHolidayYears } from "../../redux/selectors/settingsSelectors"
import {
  saveHolidaysFor,
  loadAllHolidays,
} from "../../redux/actions/settingsActions"

import "./css/holidays.css"

const mapStateToProps = state => {
  return {
    isAdmin: getUserIsAdminState(state), // Ok for admin and restricted admin.
    holidayYears: getHolidayYears(state),
  }
}

const mapDispatchToProps = dispatch => ({
  saveHolidaysFor: (year, statesMap) =>
    dispatch(saveHolidaysFor(year, statesMap)),
  loadAllHolidays: () => dispatch(loadAllHolidays()),
})

class HolidayUpload extends React.PureComponent {
  componentDidMount() {
    this.props.loadAllHolidays()
  }

  render() {
    if (!this.props.isAdmin) {
      return null
    }
    return (
      <div className="holiday-form">
        <div className="settings-uploaded-holiday-years">
          <ScheduleOutlined />
          &nbsp;
          <span className="settings-uploaded-holidays-years-description">
            {this.props.intl.formatMessage({
              id: "settings.uploaded-holiday-years",
            })}
          </span>
          &nbsp;
          <span className="settings-uploaded-holidays-years-list">
            {this.props.holidayYears.join(", ")}
          </span>
        </div>
        <Upload.Dragger
          customRequest={({ file, onSuccess, onError }) => {
            const fileReader = new FileReader()
            fileReader.onload = () => {
              const xml = new XMLParser({
                ignoreAttributes: false,
                attributeNamePrefix: "",
              })
              const parsedXML = xml.parse(fileReader.result)
              if (parsedXML && parsedXML.holidays && parsedXML.holidays.state) {
                const { states, year } = remapState(parsedXML.holidays.state)
                this.props.saveHolidaysFor(year, states)
                onSuccess("ok")
              } else {
                alert(
                  this.props.intl.formatMessage({
                    id: "settings.holidays-xml-format-error",
                  })
                )
                onError()
              }
            }
            fileReader.readAsText(file)
          }}
        >
          <InboxOutlined />
          {this.props.intl.formatMessage({ id: "upload-holiday-xml" })}
        </Upload.Dragger>
      </div>
    )
  }
}

/**
 * Create a proper map of states to holidays from the raw JSON
 * XXX We're currently assuming state names are unique across all countries
 *     supported - if this is no longer the case, a country level must be
 *     added to the mapping
 *
 * @result: { 'Bavaria': [  { holiday: '...', date } ] }
 */
function remapState(obj) {
  const states = {}
  let year
  obj.forEach(state => {
    const stateName = state.key
    state.holiday.forEach(({ name, issueDate }) => {
      const prev = initKeyArray(states, stateName)
      if (!prev.find(el => el.holiday === name)) {
        const [y, m, d] = issueDate.split("-")
        year = y
        prev.push({
          holiday: name,
          year: parseInt(y, 10),
          month: parseInt(m, 10) - 1,
          day: parseInt(d, 10),
          comment: "Warning! Month is in JS format! (-1)",
        })
      }
    })
  })
  return { states, year }
}

function initKeyArray(obj, key) {
  if (!(key in obj)) {
    obj[key] = []
  }
  return obj[key]
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(HolidayUpload))
