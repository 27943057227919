import type { RadioChangeEvent } from "antd"
import { Radio } from "antd"
import * as React from "react"
import { useIntl } from "react-intl"
import SupportChatButtonApp from "../SupportChatButtonApp/SupportChatButtonApp"
import SupportTicketsButtonApp from "../SupportTicketsButtonApp/SupportTicketsButtonApp"

export interface Props {
  showWindow: boolean
  setShowWindow: (flag: boolean) => void
  openaiEnabled: boolean
  clientId: string
}

export default function SupportButton({
  showWindow,
  setShowWindow,
  openaiEnabled,
  clientId,
}: Props): JSX.Element {
  const intl = useIntl()
  const [selectedRadio, setSelectedRadio] =
    React.useState<RadioOptions>("selected-ticket")
  const radioChanged = React.useCallback(
    (e: RadioChangeEvent) => {
      const val = e.target.value
      switch (val) {
        case "selected-close":
          setShowWindow(false)
          break
        case "selected-chat":
        case "selected-ticket":
          setSelectedRadio(val as RadioOptions)
          break
        default:
      }
    },
    [setShowWindow]
  )
  return (
    <div
      className="mina-works-support-window-main"
      style={{
        display: showWindow ? "block" : "none",
      }}
    >
      {openaiEnabled ? (
        <Radio.Group value={selectedRadio} onChange={radioChanged}>
          <Radio.Button value="selected-ticket">
            {intl.formatMessage({ id: "selected-ticket" })}
          </Radio.Button>
          <Radio.Button value="selected-chat" disabled={!openaiEnabled}>
            {intl.formatMessage({ id: "selected-chat" })}
          </Radio.Button>
          <Radio.Button value="selected-close">
            {intl.formatMessage({ id: "selected-close" })}
          </Radio.Button>
        </Radio.Group>
      ) : null}
      <div className="mina-works-support-window-content">
        {selectedRadio === "selected-chat" ? (
          <SupportChatButtonApp
            clientId={clientId}
            showForm={showWindow}
            setShowForm={setShowWindow}
            locale={intl.locale}
          ></SupportChatButtonApp>
        ) : (
          <SupportTicketsButtonApp
            clientId={clientId}
            showForm={showWindow}
            setShowForm={setShowWindow}
            locale={intl.locale}
          ></SupportTicketsButtonApp>
        )}
      </div>
    </div>
  )
}

type RadioOptions = "selected-ticket" | "selected-chat" | "selected-close"
