import { createSelector } from "reselect"
import { valueAt } from "../../../../shared/walkObjectHierarchy"
import { getUserList as getActiveUsersById } from "./userListingSelectors"

const getOpenEditCandidateUserDialog = state =>
  valueAt(state, ["userManager", "candidate", "openEditCandidateUserDialog"])
export const getOpenEditCandidateUserDialogState = createSelector(
  getOpenEditCandidateUserDialog,
  o => !!o
)

const getEditCandidateId = state =>
  valueAt(state, ["userManager", "candidate", "editCandidateId"])
export const getCurrentCandidateState = createSelector(
  getActiveUsersById,
  getEditCandidateId,
  (byId, id) =>
    id !== undefined && byId !== undefined
      ? byId[id] !== undefined
        ? byId[id]
        : {}
      : {}
)

const getPasswordChangeError = state =>
  valueAt(state, ["userManager", "candidate", "passwordChangeError"])
export const getPasswordChangeErrorState = createSelector(
  getPasswordChangeError,
  err => (err !== undefined ? err : {})
)

export const getPasswordChangedAtState = createSelector(
  state => valueAt(state, ["userManager", "candidate", "passwordChangedAt"]),
  changedAt => changedAt
)

const getRegistrationError = state =>
  valueAt(state, ["userManager", "candidate", "registrationError"])
export const getRegistrationErrorState = createSelector(
  getRegistrationError,
  err => (err !== undefined ? err : {})
)
