import types from './actionTypes'

export default function (
  state = {},
  action
) {
  switch (action.type) {
  case types.LOADSERVERSETTINGS:
    return {
      ...state,
      serverSettings: action.settings
    }
  default:
    return state
  }
}
