import type { AnyAction } from "redux"

import type State from "../../../../redux/state"

import { valueAt } from "../../../walkObjectHierarchy"

import types from "./actionTypes"
import type { Department, DepartmentUser } from "../interfaces"

export default function (state = {}, action: AnyAction): any {
  switch (action.type) {
    case types.LOADREPORTDEPARTMENTSANDUSERS:
      return {
        ...state,
        updated: Date.now(),
        allowedDepartments: action.departments,
      }
    case types.LOADREPORTONEOFFDEPARTMENTSANDUSERS:
      return mergeDepartments(state, action)
    case types.SETSELECTEDDEPARTMENTSANDUSERS:
      return {
        ...state,
        selectedDepartmentsAndUsers: action.departmentsAndUsers,
      }
    default:
      return state
  }
}

const mergeDepartments = (state: State, action: AnyAction) => {
  const current = valueAt(state, ["allowedDepartments"]) as
    | Department[]
    | undefined
  const additions = action.departments as Department[]
  if (!current) {
    return {
      ...state,
      allowedDepartments: additions,
    }
  } else {
    return {
      ...state,
      allowedDepartments: current.map(d => {
        const additionalUsers = additions.find(dd => dd.id === d.id)?.users
        const users = d.users
        if (additionalUsers) {
          additionalUsers.forEach(au => {
            if (users.find(u => u.id === au.id) === undefined) {
              users.push(au)
            }
          })
        }
        users.sort(sortOnName)
        return {
          ...d,
          users,
        }
      }),
    }
  }
}

const sortOnName = (a: DepartmentUser, b: DepartmentUser): number =>
  (a.fullnameAndSalutation || a.username).localeCompare(
    b.fullnameAndSalutation || b.username
  )
