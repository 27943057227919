/**
 * A CoreForm View Model holds an abstraction of the semantics of a form (e.g.,
 * the form fields, their labels and their types, the translations, but not
 * the actual HTML or React or PDF representations of form fields etc.)
 *
 * To be rendered and connected to the data storage, an "implementation" module
 * must be used to render that form and otherwise complete it.
 */

import {
  VALIDFIELDTYPES,
  ALLOWEDFIELDOPTIONS,
} from './types'
import type {
  ForEachFunc,
  Field,
  Form
} from './interfaces'

/**
 * NOTE: CoreForm currently keeps very simple objects as representations for
 *       forms and translations; this may change in the future, but at the
 *       moment the general construction of the view model abstraction with its
 *       various layers and delegates is complicated enough as it is
 */
export default class CoreForm {

  public form: Form = {} // must be public for equals fn

  /**
   * The options are shortcuts to various methods in this class to enable
   * single-step construction and instantiation (without having to make
   * multiple method calls)
   */
  constructor(
    private name: string,
    form?: Field[]
  ) {
    this.name = name
    if (form !== undefined) {
      this.setForm(form)
    }
  }

  forEachField(fun: ForEachFunc) {
    Object.getOwnPropertyNames(this.form).forEach((name: string) => {
      const value = this.form && this.form[name]
      fun(value, name)
    })
  }

  getName() {
    return this.name
  }

  getFields() {
    return JSON.parse(JSON.stringify(this.form))
  }

  getFieldNames() {
    return Object.keys(this.form)
  }

  equals(other: CoreForm) {
    if ( // TODO: Too shoddy, improve!
      this.form === other.form
      || JSON.stringify(this.form) === JSON.stringify(other.form)
    ) {
      return true
    }
  }

  /**
   * Add a complete form
   *
   * @param fields An array of simple objects describing the form field
   *               {
   *                 name, label, placeholder, type,
   *                 [options=[{key, label, value}]],
   *                 fieldOptions // validation and such like, can be
   *                              // required, min, max, step, multiple
   *                              // help (a help message for the field),
   *                              // autoFocus (anything in ALLOWEDFIELDOPTIONS)
   *               }
   *
   * `fieldOptions` depend on usage and field type. For example, `multiple` is
   * only relevant for SELECT dropdowns (field type `select` or `dropdown`), and
   * denotes a multiselect
   *
   * Note that value must not be set. All labels etc. must be **untranslated
   * message IDs**.
   */
  setForm(fields: Field[]) {
    this.form = fields.reduce(
      (acc: Form, f: Field) => {
        this.verifyFormFieldIntegrity(f)
        acc[f.name] = f
        return acc
      },
      {}
    )
  }

  // TODO: switch off in production (PERFORMANCE)
  verifyFormFieldIntegrity({type, fieldOptions}: Field) {
    if (
      this.verifyFormFieldTypeIntegrity(type)
        && this.verifyFormFieldOptionsIntegrity(fieldOptions)
    ) {
      return true
    }
    throw new Error('form-field-integrity-error')
  }

  verifyFormFieldTypeIntegrity(type: string) {
    return VALIDFIELDTYPES.includes(type)
  }

  verifyFormFieldOptionsIntegrity(fieldOptions: object | undefined) {
    return fieldOptions === undefined
      || Object.getOwnPropertyNames(fieldOptions).every(
        optionName => ALLOWEDFIELDOPTIONS.some(k => k === optionName)
      )
  }

}
