/**
 * Selector model for the action subscription provider
 */
import { createSelector } from 'reselect'
import { valueAt } from '../../../../shared/walkObjectHierarchy'

const getAllSubscribers = state =>
  valueAt(state, ['actionProvider', 'subscribers'])
export const getAllSubscribersState = createSelector(
  getAllSubscribers,
  subscribers => subscribers !== undefined ? subscribers : []
)

export const getTimerIdState = state => (
  valueAt(state, ['actionProvider', 'timerId'])
)
