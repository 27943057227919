import { Button, Form, Input } from "antd"
import * as React from "react"
import { useIntl } from "react-intl"
import makeBackendService from "./BackendService"

export interface Props {
  showForm: boolean
  setShowForm: (flag: boolean) => void
}

export default function SupportTicketsForm({
  setShowForm,
}: Props): JSX.Element {
  const intl = useIntl()
  const [feedback, setFeedback] = React.useState<
    { msg: string; success: boolean } | undefined
  >()
  const [description, setDescription] = React.useState<string>("")
  const [subject, setSubject] = React.useState<string>("")
  const changeSubject = React.useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      setSubject(evt.target.value as string)
    },
    [setSubject]
  )
  const changeDescription = React.useCallback(
    (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
      setDescription(evt.target.value as string)
    },
    [setDescription]
  )
  const submit = React.useCallback(() => {
    void makeBackendService()
      .submitTicket(subject, description)
      .then((success: boolean) =>
        setFeedback({
          success,
          msg: success
            ? intl.formatMessage({ id: "ticket-sent" })
            : intl.formatMessage({ id: "ticket-failed" }),
        })
      )
  }, [setFeedback, subject, description, intl])
  const cancel = React.useCallback(() => {
    setShowForm(false)
  }, [setShowForm])
  const handleKeyPress = (k: KeyboardEvent) => {
    if (k.key === "Escape") {
      window.removeEventListener("keydown", handleKeyPress)
      setShowForm(false)
    }
  }
  React.useEffect(() => {
    if (feedback !== undefined) {
      alert(feedback.msg)
      if (feedback.success) {
        setShowForm(false)
      }
    }
  }, [feedback, setShowForm])
  React.useEffect(() => {
    window.addEventListener("keydown", handleKeyPress)
    return () => window.removeEventListener("keydown", handleKeyPress)
  })
  return (
    <div className="submit-ticket-form">
      <Form>
        <h4>{intl.formatMessage({ id: "support-h" })}</h4>
        <div className="submit-ticket-form-help">
          {intl.formatMessage({ id: "ticket-help" })}
        </div>
        <Form.Item>
          <Input
            allowClear={true}
            placeholder={intl.formatMessage({ id: "ticket-subject" })}
            onChange={changeSubject}
            value={subject}
          ></Input>
        </Form.Item>
        <Form.Item>
          <Input.TextArea
            allowClear={true}
            rows={12}
            onChange={changeDescription}
            value={description}
            placeholder={intl.formatMessage({ id: "ticket-description" })}
          ></Input.TextArea>
        </Form.Item>
        <Button type="primary" onClick={submit}>
          {intl.formatMessage({ id: "submit" })}
        </Button>{" "}
        <Button onClick={cancel}>{intl.formatMessage({ id: "cancel" })}</Button>
      </Form>
    </div>
  )
}
