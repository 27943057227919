// TODO: WARNING! Don't implement formatters here - they must be separate

export default class PlannerDate {

  private key?: string

  constructor(
    private readonly year: number,
    private readonly month: number,
    private readonly day: number
  ) { }

  static fromJSON(s: string): PlannerDate {
    return this.fromString(s)
  }

  static fromString(s: string): PlannerDate {
    const [y, m, d]: string[] = s.split('-')
    return new this(parseInt(y, 10), parseInt(m, 10), parseInt(d, 10))
  }

  // TODO: This is a dangerous extension and should probably
  // be handled differently! (OCP violation)
  static fromDate(d?: Date): PlannerDate | undefined {
    return d !== undefined ? new this(
      d.getFullYear(),
      d.getMonth() + 1,
      d.getDate()
    ) : undefined
  }

  // TODO: This is a dangerous extension and should probably
  // be handled differently! (OCP violation)
  addDays(n: number): PlannerDate {
    const d = this.toDate(n)
    return PlannerDate.fromDate(d) as any
  }

  // TODO: This is a dangerous extension and should probably
  // be handled differently! (OCP violation)
  toDate(withDaysAdded: number = 0): Date {
    return new Date(Date.UTC(this.getYear(), this.getMonth() - 1, this.getDay() + withDaysAdded))
  }

  clone(): PlannerDate {
    return new PlannerDate(this.year, this.month, this.day)
  }
  getDay(): number { return this.day }
  getMonth(): number { return this.month }
  getYear(): number { return this.year }

  equals(other?: PlannerDate): boolean {
    return !!(other
      && this.getYear() === other.getYear()
      && this.getMonth() === other.getMonth()
      && this.getDay() === other.getDay()
    )
  }

  lt(other?: PlannerDate): boolean {
    return !!(other
      && this.toString() < other.toString()
    )
  }

  lte(other?: PlannerDate): boolean {
    return this.equals(other) || this.lt(other)
  }

  gt(other?: PlannerDate): boolean {
    return !!(other && other.lt(this))
  }

  gte(other?: PlannerDate): boolean {
    return this.equals(other) || this.gt(other)
  }

  /**
   * Sortable, hashable key for this planner date; the same two dates
   * have identical keys! (I.e., keys are not unique to instances, and
   * can be used in place of `equals` for comparison if necessary.)
   *
   * Optimised for reuse.
   */
  getKey(): string {
    return this.toString()
  }

  toJSON(): string {
    return this.toString()
  }

  toString(): string {
    if (this.key === undefined) {
      this.key = this.toStringOnce()
    }
    return this.key
  }

  validate(): boolean {
    return this.year > 1900
      && this.year < 2100
  }

  /**
   * Used by autoplans: 0-indexed day number
   * starting on *mondays* (instead of sundays!)
   */
  getDayNumberFromMonday(): number {
    let dayNumber = this.getDayNumberFromSunday()
    if (dayNumber === 0) {
      dayNumber = 6
    } else {
      dayNumber -= 1
    }
    return dayNumber
  }

  /**
   * Like Date.getDay: 0-indexed day number
   * starting on *sundays*
   */
  getDayNumberFromSunday(): number {
    return this.toDate().getDay()
  }

  private toStringOnce(): string {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    const y = ('' + this.getYear()).padStart(4, '0')
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    const m = ('' + this.getMonth()).padStart(2, '0')
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    const d = ('' + this.getDay()).padStart(2, '0')
    return `${y}-${m}-${d}`
  }

}
