/**
 * Uses the controller functions instead of the actions directly.
 */

import { addGlobalMessage } from "./redux/actions/globalMessageActions"

/**
 * Add a global message.
 *
 * @prop dispatch The dispatch function from mapDispatchToProps or actions.
 * @prop message The message to be displayed above the app. Can either be a
 *               simple string for a default info message or it can be a object
 *               with some more details like:
 *               {
 *                 type: 'success'||'info'||'warning'||'error',
 *                 message: 'Import error',
 *                 description: 'Import data is wrong!'
 *               }
 */
export function showGlobalMessage(dispatch, message) {
  if (typeof message === "string") {
    return dispatch(addGlobalMessage({ message }))
  }
  return dispatch(addGlobalMessage(message))
}

/**
 * Many/most of the backend error messages follow the same json structure.
 * This function converts this json directly to the GlobalMessage format.
 *
 * Adds a flag to translate the message and description before displaying.
 */
export function convertBackendError(json) {
  const msg = {
    type: "error",
    message: json.type ? json.type : "",
    description: json.errorMessage ? json.errorMessage : "",
    frontendTranslation: true,
  }
  return msg
}
