import { CloseOutlined } from "@ant-design/icons"
import { Button, Checkbox } from "antd"
import * as React from "react"
import type { LocaleKey } from "./configuredMessages/main"
import uimessages from "./locale"
import "./logininfo.css"
import InfoMessageEditor from "../shared/SimpleWYSIWYGEditor"

const LOADURL = "/rest/usermessage/getPublishedInfoMessage"
const SAVEURL = "/rest/authentication/saveLoginInfoVersionSeen"

export interface Props {
  locale: LocaleKey
  userId: string
}

interface State {
  closed?: boolean
  keepForNextTime?: boolean
  message?: {
    contentJSON: string
    title: string
    version: number
  }
}

export default class LoginInfo extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { keepForNextTime: true }
    this.close = this.close.bind(this)
  }

  componentDidMount(): void {
    if (this.props.userId !== undefined) {
      void this.load()
    }
  }

  componentDidUpdate(props: Props): void {
    if (this.props.userId !== undefined && this.props.userId !== props.userId) {
      // always trigger - we won't be updated a lot
      void this.load().then(() => this.setState({ closed: false }))
    }
  }

  render(): JSX.Element | null {
    if (this.show()) {
      return this.renderMessagePopup()
    } else {
      return null
    }
  }

  private show() {
    return this.state.closed ||
      !this.props.userId ||
      this.state.message === undefined ||
      (this.state.message.title === undefined &&
        this.state.message.contentJSON === undefined)
      ? false
      : this.state.message
  }

  private renderMessagePopup(): JSX.Element {
    return (
      <div className="login-info-popup">
        <div className="login-info-popup-window">
          <div
            className="login-info-popup-close-container"
            style={{
              width: "70px",
              float: "right",
            }}
          >
            <Button
              className="login-info-close"
              type="link"
              onClick={this.close}
            >
              <CloseOutlined></CloseOutlined>
            </Button>
          </div>
          <h2 style={{ height: "35px" }}>{this.state.message?.title ?? ""}</h2>
          <div className="login-info-messages">
            <InfoMessageEditor
              locale={this.props.locale}
              contentJSON={this.state.message?.contentJSON}
              readOnly={true}
            ></InfoMessageEditor>
          </div>
          <div className="login-info-checkbox">
            <Checkbox
              checked={this.state.keepForNextTime}
              onChange={e =>
                this.setState({ keepForNextTime: e.target.checked })
              }
            >
              {this.translate("Keep until next time?")}
            </Checkbox>{" "}
          </div>
        </div>
      </div>
    )
  }

  private close() {
    let cb = () => undefined
    if (
      !this.state.keepForNextTime &&
      this.state.message?.version !== undefined
    ) {
      cb = () => void this.saveVersionSeen(this.state.message?.version ?? -1)
    }
    this.setState({ closed: true }, cb)
  }

  private translate(msgId: string) {
    let msg = uimessages[this.props.locale][msgId]
    if (msg === undefined) {
      // default to German
      msg = uimessages.de[msgId]
    }
    return msg
  }

  private async saveVersionSeen(versionSeen: number): Promise<void> {
    await fetch(SAVEURL, {
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ versionSeen }),
    })
  }

  private async load() {
    const response = await fetch(LOADURL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    const json = await response.json()
    if (json.success) {
      this.setState({ message: json.message })
    }
  }
}
