export default function makeCounterString({
  seconds,
}: {
  seconds: number
}): string {
  const secondsSum = seconds < 0 ? 0 : seconds
  const hours = secondsSum ? Math.floor(secondsSum / 3600) : 0
  const displayhours = secondsSum ? hours : 0
  const displayminutes = secondsSum
    ? Math.floor(Math.floor(secondsSum % 3600) / 60)
    : 0
  const displayseconds = secondsSum ? Math.floor(secondsSum % 60) : 0
  const hoursString = `${displayhours}`.padStart(2, "0")
  const minutesString = `${displayminutes}`.padStart(2, "0")
  const secondsString = `${displayseconds}`.padStart(2, "0")
  return `${hoursString}:${minutesString}:${secondsString}`
}
